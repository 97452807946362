import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IBankDetail } from '../interfaces/IBankDetail';

@Injectable({
  providedIn: 'root'
})
export class BankDetailsService {

  constructor(
    private http: HttpClient
  ) { }

  getBankDetails(companyId: number): Observable<IBankDetail[]> {
    return this.http.get<IBankDetail[]>(`${environment.API_URL}/bankDetailsapi/bankdetails`, 
    { params: {companyId: companyId.toString() }});
  }
}
