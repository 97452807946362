import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, pipe, Subject} from 'rxjs';
import {ITable} from './interfaces/ITable';
import {environment} from '../environments/environment';
import {ISales} from './interfaces/ISales';
import {IWaiters} from './interfaces/IWaiters';
import {IReservations} from './interfaces/IReservations';
import {IReservationStatus} from './interfaces/IReservationStatus';
import {IReminder} from './interfaces/IReminder';
import {tap} from 'rxjs/operators';
import {IProduct} from './interfaces/IProduct';
import {IOptions} from './interfaces/IOptions';
import {ISale} from './interfaces/ISale';
import {ICompany} from './interfaces/ICompany';
import {map} from 'rxjs/operators';
import {IPayments} from "./interfaces/IPayments";
import {ICategory} from "./interfaces/ICategory";
import { ICheckOut } from "./interfaces/ICheckOut";

@Injectable({
  providedIn: 'root'
  })
export class DataService {

  constructor(private http: HttpClient) {}
 // private API_URL = 'http://88.99.184.172:82/api';
 private API_URL = environment.API_URL;
  // TABLES

  getTablesfromDB(companyId): Observable<ITable[]> {
    return this.http.get<ITable[]>(this.API_URL + '/table/index', {params: { companyId}});
  }
  updateTableCoordinates(item: ITable){
    return this.http.put( this.API_URL + '/table/update/', item);
  }
  saveTable(table): Observable<any>{
    return this.http.post(this.API_URL + '/table/create', table);
  }
  updateSingleTable(table): Observable<ITable>{
    return this.http.post<ITable>(this.API_URL + '/table/updateSingle', table);
  }
  getTableByID(companyID, tableID): Observable<any>{
    return this.http.get<ITable>(this.API_URL + '/table/details/', {params: {companyID, tableID}});
  }
  getTableByID2(companyID, tableID){
   return  this.http.get(this.API_URL + '/table/details/', {params: {companyID, tableID}})}

  tableToDelete(table){
    const id = table.id;
    return this.http.post(this.API_URL + '/table/delete', {}, { params: {id}});
  }

  getToken(){
    return localStorage.getItem('token');
  }
  getWaiterData(){
    return JSON.parse(localStorage.getItem('waiterObject'));
  }
  getCompanyId(): Observable<ICompany>{
    return this.http.get<ICompany>(this.API_URL + '/posUsers/getposuserscompanyid');
  }
  // WAITERS

  getWaiters(): Observable<IWaiters[]>{
    return this.http.get<IWaiters[]>(this.API_URL + '/posUsers/getposusers');
  }
  getWaiterID(){
    return JSON.parse(localStorage.getItem('waiterObject')).waiterID;
  }
  loginPIN(Id, pin): Observable<IWaiters>{
    return this.http.post<IWaiters>(this.API_URL + '/posUsers/pinlogin', {Id, pin});
  }
  logout(Id, pin): Observable<ICheckOut>{
    return this.http.post<any>(this.API_URL + '/posUsers/logout', {Id, pin});
  }
  checkOutWaiter(Id, pin ): Observable<ICheckOut>{
    return this.http.post<any>(this.API_URL + '/posUsers/checkout', {Id, pin});
  }

  //WAITER CONFIGURATION
  createWaiterConfiguration(waiterID: number, companyID: number, screenSize: number){
    return this.http.post(this.API_URL + '/waiterConfiguration/Create',{ waiterID, companyID, screenSize })
  }
  getWaiterConfigurationByID(waiterID){
    return this.http.get(this.API_URL + '/waiterConfiguration/GetByWaiterId/', { params: {waiterID} });
  }
  updateWaiterConfiguration(waiterID, screenSize){
    return this.http.get(this.API_URL + '/waiterConfiguration/Update', {params:{waiterID, screenSize}});
  }
  getWaiterConfigs(waiterId: number) {
    return this.http.get(`${this.API_URL}/waiterConfiguration/WaiterShortcutFunctions?waiterId=${waiterId}`);
  }

  updateWaiterConfigs(waiterId, firstFunction: string, secondFunction: string, thirdFunction: string, fourthFunction: string, fifthFunction: string, sixthFunction: string) {
    return this.http.get(this.API_URL +'/waiterConfiguration/UpdateWaiterShortcutFunctions', 
    { params: 
      { 
        waiterId,  
        firstFunction, 
        secondFunction, 
        thirdFunction, 
        fourthFunction, 
        fifthFunction, 
        sixthFunction 
      }});
  }

  // CATEGORIES

  getPOSCategories(companyId){
    return this.http.get<ICategory[]>(this.API_URL + '/categoriesapi/index/',
      { params: { companyId}});
  }

  getAllProducts(companyId) {
    return this.http.get<ICategory[]>(this.API_URL + '/categoriesapi/getAllProducts/',
    {params: { companyId}});
  }

  getProductsForThisCategory(id): Observable<IProduct>{
    return this.http.get<IProduct>(this.API_URL + '/productsapi/details/', { params: { id} });
  }

  cancelFiscal(id,userId) : Observable<any> {
    
    return this.http.get(this.API_URL + '/salesapi/CancelFiscal',{ params: {id,userId}});
  }

  // OPTIONS

  getProductOptions(productId): Observable<IOptions[]>{
    return this.http.get<IOptions[]>(this.API_URL + '/productdetailsapi/options/', {params: {productId}});
  }
  getProductOptionsByID(id){
    return this.http.get<any>(this.API_URL + '/productdetailsapi/details/', {params: {id}});
  }
  // Sale debts
  getSaleDebts(companyId): Observable<any>{
    return this.http.get<any>(this.API_URL + '/debtcardapi/SaleDebts' , {params: {companyId}});
  }

  // SALES

  //Get sales for today's date
  getSales(companyId, saleTypes: string): Observable<any>{
    return this.http.get<any>(this.API_URL + '/salesapi/sales' , {params: {companyId: companyId, saleTypesString: saleTypes}});
  }
 
  updateSales(id,orderStatus) : Observable<any> {
    
    return this.http.get(this.API_URL + '/salesapi/changeStatus',{ params: {id,orderStatus}});
  }

  deleteSale(id:number, userId:number):Observable<any>{
    return this.http.delete<any>(`${this.API_URL}/salesapi/deleteSale`,{params: {id: id.toString(), userId: userId.toString()}});
  }

  getSaleById(id): Observable<any>{
    return  this.http.get<any>(this.API_URL + '/salesapi/salebyid', {params: {id}});
  }

  registerSaleNoTable(data: any, clientID: number = 0, cardName: string = null): Observable<ISales>{
    return this.http.post<ISales>(this.API_URL + '/salesapi/ResgisterSaleNoTable', data,
    {params: {clientID: clientID.toString(), cardName: cardName} });
  }

  syncSales(sales: ISale[]): Observable<any>  {
    return this.http.post(this.API_URL + '/salesapi/syncSales', sales);
  }

  registerSale(data, tableID, tStatus): Observable<ISales>{
    return this.http.post<ISales>(this.API_URL + '/salesapi/ResgisterSale', data, {params: {tableID, tStatus}});
  }

  updateTypeSale(id: number): Observable<any>{
    return this.http.post(this.API_URL + '/salesapi/UpdateSaleInvoice', id);
  }


  registerSales(data: any): Observable<{message: string}> {
    return this.http.post<{message: string}>(`${this.API_URL}/salesapi/registersales`, data);
  }

  registerAndCloseSale(data, tableID): Observable<ISales>{
    return this.http.post<ISales>(this.API_URL + '/salesapi/PayCashAndCloseSale', data, {params: {tableID}});
  }

  updateSale(data, tableID, tStatus ){
    return this.http.post<ISales>(this.API_URL + '/salesapi/updateSale',   data, {params: {tableID, tStatus}} );
  }

  cashAndCloseSale(data, tableID){
    return this.http.post<ISales>(this.API_URL + '/salesapi/UpdateCashAndCloseSale',   data, {params: {tableID}} );
  }
  getSaleDetailsByID(id){
    return this.http.get<any>(this.API_URL + '/salesapi/saledetailsbyid/', {params: {id}});
  }

  getAllSales(id){
    return this.http.get(this.API_URL + '/ordersapi/index', {params: {id}});
  }

  // TABLE STATUS

  saveTableStatus(data){
    return this.http.post(this.API_URL + '/tablestatus/create', data);
  }

   getTableStatusByID(id) {
    return this.http.get<ISales[]>(this.API_URL + '/tablestatus/getStatusByID/', {params: {id}});
  }
  updateTableStatus(data){
    return this.http.put(this.API_URL + '/tablestatus/update', data);
  }


  getNewPRoducts(companyId){
    return this.http.get(this.API_URL + '/productcategories/GetCategoriesWithProducts/',
      {params: { companyId}});
  }

  // RESERVATIONS

  createReservation(data): Observable<IReservations[]>{
    return this.http.post<IReservations[]>(this.API_URL + '/reservationsapi/create',  data);
  }
  getActiveReservations(id): Observable<IReservations[]>{
    return this.http.get<IReservations[]>(this.API_URL + '/reservationsapi/index', {params: {id}});
  }
   // it is used to retrieve all reservations (active and closed )
  getAllReservations(companyID): Observable<{active: IReservations[], closed: IReservations[]}> {
    return this.http.get<{active: IReservations[], closed: IReservations[]}>(`${this.API_URL}/reservationsapi/getallreservations`,
    {params: {companyID}});
  }
  // it is used to retrieve all reservations (active and closed ) by date
  getAllReservationsByDate(companyID, date): Observable<{active: IReservations[], closed: IReservations[]}> {
    return this.http.get<{active: IReservations[], closed: IReservations[]}>(`${this.API_URL}/reservationsapi/getallreservationsbydate`,
    {params: {companyID, date}});
  }
  // it is used to retrieve reservation by tableId and date
  getReservationByTableId(companyID, tableID, date): Observable<IReservations> {
    return this.http.get<IReservations>(`${this.API_URL}/reservationsapi/getreservationbytableid`, {params: {companyID, tableID, date}});
  }
  getReservationsByDate(date, companyID, status): Observable<IReservations[]>{
    return this.http.get<IReservations[]>(this.API_URL + '/reservationsapi/getReservationByDate/',
      {params: {date, companyID, status}}).pipe(res => res);
  }
  checkReservationTime(timeFrom, timeTo,  companyID, tableID, date): Observable<any>{
    return this.http.get<any>(this.API_URL + '/reservationsapi/checkDate/',
      {params: {timeFrom, timeTo, companyID, tableID, date}});
  }
  updateReservation(data): Observable<IReservations[]>{
    return this.http.post<IReservations[]>(this.API_URL + '/reservationsapi/update',  data);
  }
  deleteReservation(id){
    return this.http.post(this.API_URL + '/reservationsapi/delete', {}, {params: {id}})
      .pipe();
  }
  getReminder(date, tableID,  companyID): Observable<IReminder>{
    return this.http.get<IReminder>(this.API_URL + '/reservationsapi/reminder', { params: {date, tableID, companyID}});
  }
  expireReservation(id: number) {
    return this.http.post(`${this.API_URL}/reservationsapi/expirereservation?id=${id}`, {});
  }
  getPayments(): Observable<IPayments[]>{
    return this.http.get<IPayments[]>(this.API_URL + '/salesapi/getpayments');
  }

  sendDayReportOwner(companyId: number): Observable<boolean> {
    return this.http.get<boolean>(this.API_URL + '/salesapi/SendDayReportOwner', {params: { companyId: companyId.toString() }} );
  }

  //Daily reports
  addMoney(addMoney, companyId, waiterId){
    try{
      return this.http.get(this.API_URL + '/DailyReportsApi/AddMoney', { params: { addMoney, companyId, waiterId } });
     }
     catch(e){
       console.error(e)
     }
  }

  closeReport(companyId, waiterId){
    try{
      return this.http.get(this.API_URL + '/DailyReportsApi/CloseReport', { params: { companyId, waiterId } });
     }
     catch(e){
       console.error(e)
     }
  }

  getDailyReport(companyId, waiterId){
    try{
      return this.http.get(this.API_URL + '/DailyReportsApi/GetDailyReport', { params: { companyId, waiterId } });
     }
     catch(e){
       console.error(e)
     }
  }

  closeDailyReport(companyId, waiterId){
    try{
      return this.http.get(this.API_URL + '/DailyReportsApi/SendDayReportOwner', { params: { companyId, waiterId } });
     }
     catch(e){
       console.error(e)
     }
  }

  //Happy hours
  getHappyHours(companyId){
    try{
      return this.http.get(this.API_URL + '/HappyHourApi/CurrentHappyHours', { params: { companyId } });
     }
     catch(e){
       console.error(e)
     }
  }

  currentDayHappyHours(companyId,  startDate, endDate, weekDays){
    try{
      return this.http.get(this.API_URL + '/HappyHourApi/CurrentDayHappyHours', { params: { companyId, startDate, endDate, weekDays } });
     }
     catch(e){
       console.error(e)
     }
  }
}
