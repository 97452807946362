import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, filter, map, startWith } from 'rxjs/operators';
import { Client } from 'src/app/interfaces/IClient';
import { ClientSelectedType } from 'src/app/types/ClientSelectedType';

@Component({
  selector: 'app-loyaltyCard-autocomplete-input',
  templateUrl: './loyaltyCard-autocomplete-input.component.html',
  styleUrls: ['./loyaltyCard-autocomplete-input.component.css']
})
export class LoyaltyCardAutocompleteInputComponent implements OnInit, OnChanges {
  loyaltyCardSearchInput = new FormControl();
  filteredLoyaltyCards: Observable<Client[]>;
  @Input() value: Client = null;
  @Input() clients: Client[] = [];
  @Input() disabled: boolean;
  @Input() orderHasItems: any;
  @Output() onLoyaltyCardChange = new EventEmitter<ClientSelectedType>();
  @Output() onRemovedLoyaltyCard = new EventEmitter<any>();
  points = false;


  ngOnInit(): void {
    this.onUserInput();
    // this.loyaltyCardSearchInput.disable()
  }

  ngOnChanges(changes: SimpleChanges): void {
      if ('clients' in changes) {
        this.onUserInput();
      }
      if ('disabled' in changes) {
        this.disabled ? this.loyaltyCardSearchInput.disable() : this.loyaltyCardSearchInput.enable();
      }
      if ('value' in changes) {
        this.loyaltyCardSearchInput.setValue(this.value);
      }
      if(this.loyaltyCardSearchInput?.value && this.loyaltyCardSearchInput?.value.points == undefined){
        this.points = false
      }
      else{
        this.points = true
      }


  }
  

  onUserInput(): void {
    // this.loyaltyCardSearchInput.setValue('');

    this.filteredLoyaltyCards = this.loyaltyCardSearchInput.valueChanges.pipe(debounceTime(200)).pipe(
      startWith(''),
      map((value: any) => {
       
        return this.filterLoyaltyCard(value)
      }));
  }
  private filterLoyaltyCard(value: string): Client[] {
        if (typeof value !== 'string') {
      // Handle the case when filterValue is not a string
      value = ''
  }
      var filterValue = value?.toString()?.toLowerCase();

    var loyaltyCards = this.clients.reduce((acc, curr) => {
      return acc.concat(curr.loyaltyCards.map((card:any) => ({...card, nameSQ: curr.nameSQ, nameMK: curr.nameMK, id: curr.id})));
  }, []);
    
    var filteredLoyaltyCards = loyaltyCards.filter(card => card.name.toLowerCase().indexOf(filterValue) === 0);
    var test = filteredLoyaltyCards.filter(card=>card.nameSQ.toLocaleLowerCase() == this.value.nameSQ.toLowerCase() && card.isActive == true)
    return test;
  }

  onOptionSelected(event: any, client: Client): void {
    this.onLoyaltyCardChange.emit(client);
  }

 public clearInput1(): void {
    this.loyaltyCardSearchInput.setValue('');
    this.onRemovedLoyaltyCard.emit(null)

  }

  getOptionText(client: Client): string {
    return client ? client.name : '';
  }

}
