<div class="modal fade" id="saleDetailsModal" #saleDetailsModal tabindex="-1" role="dialog" aria-labelledby="saleDetailsModalLabeL" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="saleDetailsModalLabeL">{{"CODE" | translate}}: {{sale?.noSale}}</h5>
        <div class="modal-buttons">
        <div (click)="shortSaleReport(sale.id)" class="short-invoice">
          <button class="short-invoice-button details-button">
            {{"DELIVERY_NOTE" | translate}}
          </button>
        </div>
        <div class="invoice">
        <button (click)="createSaleReport(sale.id)" class="invoice-button details-button">{{"OFFER_BILL" | translate}}</button>
        </div>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <img src="../../../assets/images/x-inactive-new.png" class="closeIcon" >
        </button>
      </div>
      <div class="modal-body p-4">
        <div class="col p-0  border rounded-lg fontArial saleListTable">
          <table class="table table-striped">
            <tr>
              <th>{{'PRODUCT' | translate }}</th>
              <th>{{'PRODUCT_DESC' | translate }}</th>
              <th>{{'QUANTITY' | translate }}</th>
              <th>{{'SALE_WITHOUT_VAT' | translate }}</th>
              <th>{{'RABAT' | translate }}</th>
              <th>%</th>
              <th>{{'RABAT_PRICE' | translate }}</th>
              <th>{{'VAT' | translate }}</th>
              <th>%</th>
              <th>{{'PRICE_VAT' | translate }}</th>
              <th>{{'TOTAL' | translate }}</th>
            </tr>
            <tr *ngFor="let saleDetail of sale?.saleDetails">
              <td>{{saleDetail?.productName}}</td>
              <td>{{saleDetail?.productDescription}}</td>
              <td>{{saleDetail?.qt}}</td>
              <td>{{saleDetail?.sellingPriceNoVat | number:'0.4-5'}}</td>
              <td>{{saleDetail?.rebatePrice | number:'0.4-5'}}</td>
              <td>{{saleDetail?.rebate}}</td>
              <td>{{saleDetail?.sellingPricewithRebate | number:'0.4-5'}}</td>
              <td>{{saleDetail?.totalVat | number:'0.3-4' }}</td>
              <td>{{saleDetail?.vat}}</td>
              <td>{{saleDetail?.sellingPriceWithVat | number:'0.4-5'}}</td>
              <td>{{saleDetail?.total}}</td>
              </tr>
              <tr>
                <td colspan="10"></td>
                <td>{{ sale?.total }}</td>
              </tr>
          </table>
          <div *ngIf="sale?.saleType === 3 || sale?.saleType === 7">
            <table class="table table-striped">
              <tr>
                <th>{{'TYPE_OF_PAYMENT' | translate }}</th>
                <th>{{'TOTAL' | translate }}</th>
              </tr>
              <tr *ngFor="let salePayment of sale?.salePayments">
                <td>{{salePayment?.payementName}}</td>
                <td>{{salePayment?.total}}</td>
              </tr>
            </table>
          </div>
      </div>

      <div class="d-flex justify-content-start mt-3">
        <button *ngIf="sale?.hasFiscal == true" data-dismiss="modal" (click)="cancelFiscal(sale?.id)" class="short-invoice-button details-button">
          {{"CANCEL" | translate}}
        </button>
        <button *ngIf="!sale?.isDeleted && saleEditRole && sale?.hasFiscal == false" data-dismiss="modal" [routerLink]="'/wholesale/edit/' + sale?.id" class="short-invoice-button details-button">
          {{"EDIT" | translate}}
        </button>
        <button *ngIf="!sale?.isReady == true" data-dismiss="modal" (click)="updateOrderSale(sale)" class="short-invoice-button details-button">
          {{'READY' | translate}}
        </button>
      </div>
    </div>
  </div>
  </div>
</div>

<div class="modal fade" id="offerDetailsModal" tabindex="-1" role="dialog" aria-labelledby="offerDetailsModalLabeL" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="saleDetailsModalLabeL">{{"CODE" | translate}}: {{sale?.noSale}}</h5>
        <div class="modal-buttons">

        <div (click)="offerReport(sale.id)" class="offer">
          <button class="offer-button">
            {{"OFFER" | translate}}
          </button>
        </div>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <img src="../../../assets/images/x-inactive-new.png" class="closeIcon" >
        </button>
      </div>
      <div class="modal-body p-4">
        <div class="col p-0  border rounded-lg fontArial offerListTable">

          <table class="table table-striped">
            <tr>
              <th>{{'PRODUCT' | translate }}</th>
              <th>{{'PRODUCT_DESC' | translate }}</th>
              <th>{{'QUANTITY' | translate }}</th>
              <th>{{'SALE_WITHOUT_VAT' | translate }}</th>
              <th>{{'RABAT' | translate }}</th>
              <th>%</th>
              <th>{{'RABAT_PRICE' | translate }}</th>
              <th>{{'VAT' | translate }}</th>
              <th>%</th>
              <th>{{'PRICE_VAT' | translate }}</th>
              <th>{{'TOTAL' | translate }}</th>
            </tr>
            <tr *ngFor="let saleDetail of sale?.saleDetails">
              <td>{{saleDetail?.productName}}</td>
              <td>{{saleDetail?.productDescription}}</td>
              <td>{{saleDetail?.qt}}</td>
              <td>{{saleDetail?.sellingPriceNoVat | number:'0.4-5'}}</td>
              <td>{{saleDetail?.rebatePrice | number:'0.4-5'}}</td>
              <td>{{saleDetail?.rebate}}</td>
              <td>{{saleDetail?.sellingPricewithRebate | number:'0.4-5'}}</td>
              <td>{{saleDetail?.totalVat | number:'0.3-4' }}</td>
              <td>{{saleDetail?.vat}}</td>
              <td>{{saleDetail?.sellingPriceWithVat | number:'0.4-5'}}</td>
              <td>{{saleDetail?.total | number:'0.2-3'}}</td>
            </tr>
            <tr>
              <td colspan="10"></td>
              <td class="saleTotal">{{sale?.total | number:'0.2-3'}}</td>
            </tr>
          </table>
      </div>

      <div class="d-flex justify-content-start mt-3">

        <button
        class="pay" *ngIf="saleCreateRole"
        (click)="updateSale()">{{'OFFER_BILL' | translate }}</button>

        <button *ngIf="!sale?.isDeleted && saleEditRole && sale?.hasFiscal == false" data-dismiss="modal" [routerLink]="'/offer/edit/' + sale?.id" class="pay">
          {{"EDIT" | translate}}
        </button>
      </div>
    </div>
  </div>
  </div>
</div>

<div class="modal fade" id="proInvoiceDetailsModal" tabindex="-1" role="dialog" aria-labelledby="proInvoiceDetailsModalLabeL" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="saleDetailsModalLabeL">{{"CODE" | translate}}: {{sale?.noSale}}</h5>
        <div class="modal-buttons">


        <div class="pro-invoice">
        <button (click)="proInvoiceReport(sale.id)" class="pro-invoice-button">{{"PRO_INOVICE" | translate}}</button>
        </div>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <img src="../../../assets/images/x-inactive-new.png" class="closeIcon" >
        </button>
      </div>
      <div class="modal-body p-4">
        <div class="col p-0  border rounded-lg fontArial proInvoiceTable">

          <table class="table table-striped">
            <tr>
              <th>{{'PRODUCT' | translate }}</th>
              <th>{{'PRODUCT_DESC' | translate }}</th>
              <th>{{'QUANTITY' | translate }}</th>
              <th>{{'SALE_WITHOUT_VAT' | translate }}</th>
              <th>{{'RABAT' | translate }}</th>
              <th>%</th>
              <th>{{'RABAT_PRICE' | translate }}</th>
              <th>{{'VAT' | translate }}</th>
              <th>%</th>
              <th>{{'PRICE_VAT' | translate }}</th>
              <th>{{'TOTAL' | translate }}</th>
            </tr>
            <tr *ngFor="let saleDetail of sale?.saleDetails">
              <td>{{saleDetail?.productName}}</td>
              <td>{{saleDetail?.productDescription}}</td>
              <td>{{saleDetail?.qt}}</td>
              <td>{{saleDetail?.sellingPriceNoVat | number:'0.4-5'}}</td>
              <td>{{saleDetail?.rebatePrice | number:'0.4-5'}}</td>
              <td>{{saleDetail?.rebate}}</td>
              <td>{{saleDetail?.sellingPricewithRebate | number:'0.4-5'}}</td>
              <td>{{saleDetail?.totalVat | number:'0.3-4' }}</td>
              <td>{{saleDetail?.vat}}</td>
              <td>{{saleDetail?.sellingPriceWithVat | number:'0.4-5'}}</td>
              <td>{{saleDetail?.total | number:'0.2-3'}}</td>
            </tr>
            <tr>
              <td colspan="10"></td>
              <td class="saleTotal">{{sale?.total | number:'0.2-3'}}</td>
            </tr>
          </table>
      </div>

      <div class="d-flex justify-content-start mt-3">

        <button
        class="pay" *ngIf="saleCreateRole"
        (click)="updateSale()">{{'OFFER_BILL' | translate }}</button>

        <button *ngIf="!sale?.isDeleted && saleEditRole && sale?.hasFiscal == false" data-dismiss="modal" [routerLink]="'/wholesale/edit/' + sale?.id" class="pay">
          {{"EDIT" | translate}}
        </button>
      </div>
    </div>
  </div>
  </div>
</div>

<div class="modal fade" id="reservedSaleDetailsModal" tabindex="-1" role="dialog" aria-labelledby="reservedSaleDetailsModalLabeL" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="saleDetailsModalLabeL">{{"CODE" | translate}}: {{sale?.noSale}}</h5>
        <div class="modal-buttons">
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <img src="../../../assets/images/x-inactive-new.png" class="closeIcon" >
        </button>
      </div>
      <div class="modal-body p-4">
        <div class="col p-0  border rounded-lg fontArial reservedSaleTable">

          <table class="table table-striped">
            <tr>
              <th>{{'PRODUCT' | translate }}</th>
              <th>{{'PRODUCT_DESC' | translate }}</th>
              <th>{{'QUANTITY' | translate }}</th>
              <th>{{'SALE_WITHOUT_VAT' | translate }}</th>
              <th>{{'RABAT' | translate }}</th>
              <th>%</th>
              <th>{{'RABAT_PRICE' | translate }}</th>
              <th>{{'VAT' | translate }}</th>
              <th>%</th>
              <th>{{'PRICE_VAT' | translate }}</th>
              <th>{{'TOTAL' | translate }}</th>
            </tr>
            <tr *ngFor="let saleDetail of sale?.saleDetails">
              <td>{{saleDetail?.productName}}</td>
              <td>{{saleDetail?.productDescription}}</td>
              <td>{{saleDetail?.qt}}</td>
              <td>{{saleDetail?.sellingPriceNoVat | number:'0.4-5'}}</td>
              <td>{{saleDetail?.rebatePrice | number:'0.4-5'}}</td>
              <td>{{saleDetail?.rebate}}</td>
              <td>{{saleDetail?.sellingPricewithRebate | number:'0.4-5'}}</td>
              <td>{{saleDetail?.totalVat | number:'0.3-4' }}</td>
              <td>{{saleDetail?.vat}}</td>
              <td>{{saleDetail?.sellingPriceWithVat | number:'0.4-5'}}</td>
              <td>{{saleDetail?.total | number:'0.2-3'}}</td>
            </tr>
            <tr>
              <td colspan="10"></td>
              <td class="saleTotal">{{sale?.total | number:'0.2-3'}}</td>
            </tr>
          </table>
      </div>

      <div class="d-flex justify-content-start mt-3">

        <button
        class="pay" *ngIf="saleCreateRole"
        (click)="updateSale()">{{'OFFER_BILL' | translate }}</button>

        <button *ngIf="!sale?.isDeleted && saleEditRole && sale?.hasFiscal == false" data-dismiss="modal" [routerLink]="'/wholesale/edit/' + sale?.id" class="pay">
          {{"EDIT" | translate}}
        </button>
      </div>

    </div>
  </div>
  </div>
</div>

<div class="modal fade" id="canceledSaleDetailsModal" tabindex="-1" role="dialog" aria-labelledby="canceledSaleDetailsModalLabeL" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="saleDetailsModalLabeL">{{"CODE" | translate}}: {{sale?.noSale}}</h5>
        <div class="modal-buttons">

        <div class="canceled-invoice">
        <button (click)="createSaleReport(sale.id)" class="canceled-invoice-button">{{"OFFER_BILL" | translate}}</button>
        </div>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <img src="../../../assets/images/x-inactive-new.png" class="closeIcon" >
        </button>
      </div>
      <div class="modal-body p-4">
        <div class="col p-0  border rounded-lg fontArial canceledSaleTable">

          <table class="table table-striped">
            <tr>
              <th>{{'PRODUCT' | translate }}</th>
              <th>{{'PRODUCT_DESC' | translate }}</th>
              <th>{{'QUANTITY' | translate }}</th>
              <th>{{'SALE_WITHOUT_VAT' | translate }}</th>
              <th>{{'RABAT' | translate }}</th>
              <th>%</th>
              <th>{{'RABAT_PRICE' | translate }}</th>
              <th>{{'VAT' | translate }}</th>
              <th>%</th>
              <th>{{'PRICE_VAT' | translate }}</th>
              <th>{{'TOTAL' | translate }}</th>
            </tr>
            <tr *ngFor="let saleDetail of sale?.saleDetails">
              <td>{{saleDetail?.productName}}</td>
              <td>{{saleDetail?.productDescription}}</td>
              <td>{{saleDetail?.qt}}</td>
              <td>{{saleDetail?.sellingPriceNoVat | number:'0.4-5'}}</td>
              <td>{{saleDetail?.rebatePrice | number:'0.4-5'}}</td>
              <td>{{saleDetail?.rebate}}</td>
              <td>{{saleDetail?.sellingPricewithRebate | number:'0.4-5'}}</td>
              <td>{{saleDetail?.totalVat | number:'0.3-4' }}</td>
              <td>{{saleDetail?.vat}}</td>
              <td>{{saleDetail?.sellingPriceWithVat | number:'0.4-5'}}</td>
              <td>{{saleDetail?.total | number:'0.2-3'}}</td>
            </tr>
            <tr>
              <td colspan="10"></td>
              <td class="saleTotal">{{sale?.total | number:'0.2-3'}}</td>
            </tr>
          </table>
      </div>
    </div>
  </div>
  </div>
</div>