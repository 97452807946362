import { ChangeDetectorRef, Component, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/data.service';
import { ISale } from 'src/app/interfaces/ISale';
import { environment } from 'src/environments/environment';
import { IConfigs } from 'src/app/interfaces/IConfigs';
import { ConfigsService } from 'src/app/services/configs.service';
import { SaleType } from 'src/app/Enums/SaleType';

@Component({
  selector: 'app-orderstatus',
  templateUrl: './orderstatus.component.html',
  styleUrls: ['./orderstatus.component.css']
})
export class OrderstatusComponent implements OnInit {
  imgLogo = "";
  configs: IConfigs;
  allSales: ISale[] = [];
  currentSale: ISale;
  bC = new BroadcastChannel('pos_channel');
  subscriptions: Subscription[] = [];
  preparingOrders: ISale[];
  ordersReady: ISale[];
  isGaming: boolean = false;

  constructor(
    private configsService: ConfigsService,
    private cdr: ChangeDetectorRef,
    protected api: DataService) { }

  ngOnInit(): void {
    this.imgLogo = environment.IMG_PATH_LOGO + JSON.parse(localStorage.getItem('waiterObject')).logo;

    this.bC.onmessage = (msg: any) => {
      if(this.configs?.settings?.isGaming){
        var sum = msg.data.sale.saleDetails.reduce((total, y) => total + y.qt, 0);
        var minutes = Math.round(sum * 60);
        var createdDate = new Date(msg.data.sale.created);
        var current = new Date();
        var timeDiff = current.getTime() - createdDate.getTime();
        var createdMinutes = Math.floor(timeDiff / (1000 * 60));
        if(createdMinutes >= minutes){
          this.ordersReady.unshift(msg.data.sale);
        }else{
          this.preparingOrders.unshift(msg.data.sale);
        }
        this.cdr.detectChanges();
      }else{
        if (msg.data.status === 'new') {
          this.preparingOrders.unshift(msg.data.sale);
        } else {
          const index = this.preparingOrders.findIndex(x => x.id === msg.data.sale.id);
          if (index > -1) {
            this.ordersReady.unshift(this.preparingOrders[index]);
            this.preparingOrders.splice(index, 1);
          }
        } 
        if (msg.data.status === 'ready') {
          this.ordersReady.unshift(msg.data.sale); 
          this.cdr.detectChanges();
        } 
        this.filterReadyOrders(this.ordersReady);
        this.cdr.detectChanges();
      }
    };
    this.configsService.getConfigs().subscribe((res: IConfigs) => {
      this.configs = res;
      this.getAllSales();
      if(res?.settings?.isGaming){
        // Check checkPreparingOrders every minute
        setInterval(() => {
          this.checkPreparingOrders();
        }, 60000);

        // Send request to the service every hour
        setInterval(() => {
          this.getAllSales();
        }, 3600000);

        this.isGaming = true;
      }
    },
      err => { console.log(err); }
    );
  }

  checkPreparingOrders() {
    this.preparingOrders = this.preparingOrders.filter(x=> {
      var minutes = x.saleDetails.reduce((total, y) => total + y.appointedMinutes, 0);
      var createdDate = new Date(x.created);
      var current = new Date();
      var timeDiff = current.getTime() - createdDate.getTime();
      var createdMinutes = Math.floor(timeDiff / (1000 * 60));
      if(createdMinutes >= minutes){
        this.ordersReady.unshift(x);
        return false;
      }
      return true;
    });
  }

  filterReadyOrders(ordersReady: ISale[]): void {
    this.ordersReady = ordersReady.filter(x=> {
      var startTime = moment(new Date()); //todays date
      var end = moment(x.created); // another date
      var minutes = moment.duration(startTime.diff(end)).asMinutes();
      return minutes < 30
    
    });
  }

  getAllSales(){
    const companyID = JSON.parse(localStorage.getItem('waiterObject')).companyID;  
    const saleTypes =  [SaleType.Sold, SaleType.DeliveryNote, SaleType.Invoice]
    const saleTypesString = saleTypes.join(',');
    this.subscriptions.push(
    this.api.getSales(companyID, saleTypesString).subscribe((sales: ISale[]) =>{
      this.allSales = sales;
      if(this.configs?.settings?.isGaming){
        this.ordersReady = this.allSales.filter(x=> {
          var minutes = x.saleDetails.reduce((total, y) => total + y.appointedMinutes, 0);
          var createdDate = new Date(x.created);
          var current = new Date();
          var timeDiff = current.getTime() - createdDate.getTime();
          var createdMinutes = Math.floor(timeDiff / (1000 * 60));
          if (createdMinutes >= minutes) {
            var elapsedMinutes = createdMinutes - minutes
            if (elapsedMinutes <= 30) {
              return true;
            }
          }
          return false;
        });
        this.preparingOrders = this.allSales.filter(x=> {
          var minutes = x.saleDetails.reduce((total, y) => total + y.appointedMinutes, 0);
          var createdDate = new Date(x.created);
          var current = new Date();
          var timeDiff = current.getTime() - createdDate.getTime();
          var createdMinutes = Math.floor(timeDiff / (1000 * 60));
          return minutes > createdMinutes
        });
      }else{
        this.ordersReady = this.allSales.filter(x=> {
          var startTime = moment(new Date()); //todays date
          var end = moment(x.created); // another date
          var minutes = moment.duration(startTime.diff(end)).asMinutes();
          return x.orderStatus === true && minutes < 30
        });
        this.preparingOrders = this.allSales.filter(x=> x.orderStatus == false);
      }
    }));
    
  }



}
