

<div class="my-auto text-center p-5 mainBG ">
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-10    p-0 mx-auto my-auto loginBG">
    <div class="col-12 p-0 radius50 d-flex logoHeader text-center mx-auto justify-content-center">
      <div class="col-md-4 my-auto mx-auto">
        <img src="assets/images/conome-logo.png" class="logo img-fluid " >
      </div>
    </div>
    <form [formGroup]="loginForm"
          (ngSubmit)="loginPin()"
          class="pt-4 pl-0 pr-0 col-md-10 mx-auto">
      <div class="row pb-0 mx-auto">
        <div class="form-group col-md-6  p-2">
          <!--          <label>&nbsp;</label>-->
          <input type="text"
                 class="form-control"
                 formControlName="username"
                 id="username"
                 #username
                 [class.is-invalid]="loginForm.get('username').invalid
                 && loginForm.get('username').touched"
                 placeholder="{{'ENTER-USERNAME' | translate}}" [autofocus]="true" autofocus>
        </div>
        <div class="form-group col-md-3  p-2">
          <!--          <label>&nbsp;</label>-->
          <input type="text"
                 class="form-control"
                 formControlName="pin"
                 [class.is-invalid]="loginForm.get('pin').invalid
                 && loginForm.get('pin').touched"
                 [maxlength]="4"
                 [minLength]="4"
                 pattern="[0-9]+"
                 placeholder="{{'ENTER-PIN' | translate}}" [maxLength]="4">
        </div>
        <div class="form-group col-md-3 p-2">
          <button type="submit"
                  [disabled]="loginForm.invalid"
                  class="btn loginButton btn-block">{{"LOGIN" | translate}}</button>
        </div>
      </div>
    </form>
    <div class="col-md-10  p-0 pb-4 mx-auto my-auto">
      <div class="row pb-4 mx-auto my-auto">
        <div class="col-md-4 col-lg-4 col-xl-3 p10" *ngFor="let waiter of waiters">
          <button
            *ngIf="waiters"
            class="btn waiter btn-block"
            (click)="goToLogin(waiter)"
          >
            {{waiter.username}}
          </button>
        </div>
        <div class="col-md-4 col-lg-4 col-xl-3 p10 totalGrid"  *ngFor="let grid of totalGrid">
          <button class="btn gridButton btn-block">
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
