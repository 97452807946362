import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DataService } from '../../data.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from '../../../environments/environment';
import { AlertService } from '../../services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateKeysService } from '../../services/translate-keys.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IPayments } from '../../interfaces/IPayments';
import { IProduct } from '../../interfaces/IProduct';
import { ICategory } from '../../interfaces/ICategory';
import { IIngredient } from '../../interfaces/IIngredient';
import { OfflineCrud } from 'src/app/services/offline-crud.service';
import { ISale } from 'src/app/interfaces/ISale';
import { WindowService } from 'src/app/services/window.service';
import { IOptions } from 'src/app/interfaces/IOptions';
import { Client } from 'src/app/interfaces/IClient';
import { ClientSelectedType } from 'src/app/types/ClientSelectedType';
import { ClientAutocompleteInputComponent } from 'src/app/shared/components/client-autocomplete-input/client-autocomplete-input.component';
import { LoyaltyCardAutocompleteInputComponent } from 'src/app/shared/components/loyaltyCard-autocomplete-input/loyaltyCard-autocomplete-input.component';

import { IConfigs } from 'src/app/interfaces/IConfigs';
import { ConfigsService } from 'src/app/services/configs.service';
import { IWaiterAuthObject } from 'src/app/interfaces/IWaiterAuthObject';
import { IOfflineFiscal } from 'src/app/interfaces/IOfflineFiscal';
import { compressToEncodedURIComponent } from "lz-string";
import { SalePayment } from 'src/app/interfaces/SalePayment';
import { ClientService } from 'src/app/services/client.service';
import { IWaiters } from 'src/app/interfaces/IWaiters';
import { NavbarComponent } from '../navbar/navbar.component';
import { SignalrService } from 'src/app/services/signalr.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements OnInit, OnDestroy {
  constructor(
    protected api: DataService,
    public fb: FormBuilder,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected alertService: AlertService,
    public translate: TranslateService,
    public translateKeys: TranslateKeysService,
    private configsService: ConfigsService,
    private offlineService: OfflineCrud,
    private windowService: WindowService,
    private clientService: ClientService,
    protected changeDetector: ChangeDetectorRef,
    private signalrService: SignalrService
  ) {
    this.checkIfDeviceIsMobile()
  }
  CompanyID: number = this.getCompanyID();
  WaiterID: number = this.api.getWaiterID();
  rightScreenDB: number
  leftWidth: number;
  rightWidth: number;
  grabber:number = 1;
  grabberClicked: boolean = false;
  grabberIsSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  grabbed: boolean = false;
  screenWidth: number;
  percentageLeft: number = 0.68;
  percentageRight: number = 0.31;
  percentageGrabber: number = 0.01;
  bC = new BroadcastChannel('pos_channel');
  configs: IConfigs;
  client: Client;
  selectedOption: any;
  currentProductQuantity = 1;
  vat = 0;
  calculationData = {
    total: 0,
    vat: 0,
    subTotal: 0
  };
  isReserved = false;
  pointsToUse = 0;
  clients: Client[] = [];
  hasPoints: boolean = false;
  loyaltyCardNames:any[] = [];
  loyaltyCard: any;
  clientsPercentage = 0
  selectedSpecialVatForDelivery = false
  showSpecialVat = false
  vatForDelivery = 0
  happyHour:boolean=false;

  @ViewChild(NavbarComponent, { static: false }) navbar: NavbarComponent;
  @ViewChild('closeModal') closeModal: ElementRef;
  @ViewChild('closeModal1') closeModal1: ElementRef;
  @ViewChild('selectOption') selectOption: ElementRef;
  @ViewChild('splitButton') splitButton: ElementRef;
  @ViewChild('clientAutocomplete')
  clientAutocomplete: ClientAutocompleteInputComponent;
  loyaltyCardAutocomplete: LoyaltyCardAutocompleteInputComponent

  public IMG_PATH = environment.IMG_PATH;
  public IMG_PATH_CATEGORY = environment.IMG_PATH_CATEGORY;
  public currentProduct: IProduct = null;
  public orderObject = [];
  public categories: ICategory[] = [];
  public filteredProductCategories: any[] = [];
  public filteredAllCategoriesProduct: any[] = [];
  public offlineSales: ISale[] = [];
  barcodes: any[] = []
  isCreatedAllCategories: boolean = false
  foundOneAndAdded: boolean = false
  currentSale: any;
  currentTableStatus: any;
  currentTable;
  stateEvent;
  selectedIndex = null;
  selectedOptionID = -1;
  editPriceId = -1;
  subTotal = 0;
  payments: IPayments[];
  filteredPayments: IPayments[] = [];
  isChanged: boolean = false;
  saleCreateRole: boolean = false;
  waiters;
  subscriptions: Subscription[] = [];
  selectedCategoryIndex = -1;
  categoryIndex = -1;
  payment;
  change;
  comment;
  date = new Date();
  mmyyyy = 0 + '/' + this.date.getFullYear();
  isOnMobile: boolean;
  isStickyModalOpen: boolean = false;
  showPaymentModal: boolean = false;
  quantity!: number;
  isSplit: boolean = false;
  splitTotal: number;
  clickedPayments: { id: number, name: string, total: number }[] = [];
  data: ISale = {
    modUser: this.api.getWaiterData().waiterID,
    noSale: this.mmyyyy,
    dailyID: 1,
    companyID: this.getCompanyID(),
    clientID: null,
    type: 1,
    dateSale: this.date,
    total: Number(this.calculationData.total),
    hasReport: false,
    hasFiscal: false,
    comment: 'Sale ' + this.mmyyyy,
    isMKChecked: false,
    isDebtChecked: false,
    paymentID: null,
    currencyName: 'MKD',
    accountantClientID: null,
    saleType: 3,
    status: false,
    saleDetails: [],
    salePayments: [],
    orderStatus: false
  };
  paymentModalVisible = false;
  startTime: Date;
  endTime: Date;
  closestEndTime: any[] = [];
  @ViewChild('modal') modal: ElementRef;
  @ViewChild('paymentModal') paymentModal: ElementRef;
  async ngOnInit() {
        this.grabberIsSelected.subscribe(res => {
      this.grabbed = res;
    })
    this.getScreenSizeFromDB();
    this.getConfigs();
    this.getCategoriesFromApi();
    this.getOfflineSales();
    this.orderObject = [];
    this.getSalesFromLocalDb();
    this.saleUpdateListener();
    this.getPayments();
    this.getClients();
    this.getWaiterRoles();
    this.calculateQuantity();
    this.subscriptions.push(
      this.offlineService.onlineSubject.subscribe((isOnline: boolean) => {
        if (isOnline) {
          // this.offlineService.syncSalesWithServer();
        }
      })
    );
  }

//onClick events
categoryClick(index: number,categoryId: number){
  this.selectedCategoryIndex = categoryId;
  this.categoryIndex = index;
  this.clearFilters()
}

changeStatusSpecialVatForDelivery(){
  this.selectedSpecialVatForDelivery = !this.selectedSpecialVatForDelivery

}

enableSpecialVatForDelivery(){
  // Iterate through each object in the this.categories array
  var copyCategories  = this.categories
  var specialVatPercentage = this.vatForDelivery / 100
  if(this.client?.percentage > 0 ){
    if(this.selectedSpecialVatForDelivery){

      copyCategories.forEach(category => {
        category.products.forEach(product => {
          product.options.forEach(option => {
            if(option.hasOwnProperty('deliveryVat') && option['deliveryVat'] > 0  && option.vat <= 5){}
            else{
              //deri ktau shko ctrl z
              //option.sellingPriceWithVat = option.sellingPriceNoVat + (option.sellingPriceNoVat * specialVatPercentage);
              option['sellingPriceWithSpecialVat'] = option.sellingPriceNoVat + (option.sellingPriceNoVat * specialVatPercentage);
              
              option['deliveryVat'] = this.vatForDelivery 


            }     
            });
        });
      });

      if(this.orderObject.length > 0){
          this.orderObject.forEach((orderObj: any) => {
            if(!orderObj.selectedOptions.hasOwnProperty('deliveryVat')){
              const rebatePercentage = orderObj.selectedOptions.rebatePercentage / 100; // Convert percentage to decimal

              const sellingPriceNoVat = orderObj.selectedOptions.sellingPriceNoVat;
              const priceAfterVat = sellingPriceNoVat + (sellingPriceNoVat * specialVatPercentage); // Adding 5% VAT
              const priceAfterRebate = priceAfterVat - (priceAfterVat * rebatePercentage); // Removing 33% discount
              orderObj.selectedOptions.sellingPriceWithRebate = priceAfterRebate;
              orderObj.selectedOptions['deliveryVat'] = this.vatForDelivery 
              orderObj.total = Number(priceAfterRebate.toFixed(2))
              orderObj.sellingPriceWithVat = Number((orderObj.selectedOptions.sellingPriceWithVat.toFixed(2)))

              this.calculateVATForOption(orderObj.selectedOptions)
            }
          });
          this.updateProductTotal()
        this.total();
      }

    }
    else{
      
      if(this.orderObject.length > 0){
        this.orderObject.forEach((orderObj: any) => {
          if(orderObj.selectedOptions.hasOwnProperty('deliveryVat') || orderObj.hasOwnProperty('deliveryVat') || (orderObj.options.some(option => option.hasOwnProperty('deliveryVat')))){
            const rebatePercentage = orderObj.selectedOptions.rebatePercentage / 100; // Convert percentage to decimal
            const sellingPriceNoVat = orderObj.selectedOptions.sellingPriceNoVat;
            const priceAfterVat = sellingPriceNoVat + (sellingPriceNoVat * (orderObj.selectedOptions.vat/100)); // Adding products VAT
            const priceAfterRebate = priceAfterVat - (priceAfterVat * rebatePercentage); // Removing 33% discount
            orderObj.selectedOptions.sellingPriceWithRebate = priceAfterRebate
            delete orderObj.selectedOptions['deliveryVat'];
            this.calculateVATForOption(orderObj.selectedOptions)
            orderObj.sellingPriceWithVat = Number((orderObj.selectedOptions.sellingPriceWithVat.toFixed(2)))

            orderObj.total = Number(priceAfterRebate.toFixed(2))
          }
        });
        this.updateProductTotal()
      this.total();
      }
      copyCategories.forEach(category => {
        category.products.forEach(product => {
          product.options.forEach(option => {
            if (option.hasOwnProperty('deliveryVat') && option['deliveryVat'] > 0)  {
              option.sellingPriceWithVat = Number((option.sellingPriceNoVat + (option.vat / 100) * option.sellingPriceNoVat).toFixed(2));
              delete option['sellingPriceWithSpecialVat']
              delete option['deliveryVat'];
            }
          });
        });
      });
    }

  }
  else{

  
    if(this.selectedSpecialVatForDelivery){
        copyCategories.forEach(category => {
          category.products.forEach(product => {
            product.options.forEach(option => {
              if(option.hasOwnProperty('deliveryVat') && option['deliveryVat'] > 0){}
              else{
                //if(option.vat > 0){                
                  var vatPercentage = option.vat / 100
                  var sellingPriceWithVat = option.sellingPriceNoVat * (1 + vatPercentage) // has full vat 
                  var sellingPriceWithSpecialVat = option.sellingPriceNoVat * (1 + specialVatPercentage) // has the special vat
                  var difference = sellingPriceWithVat - sellingPriceWithSpecialVat // differnce betweeen amount with full vat and amount with special vat
                  option.sellingPriceNoVat = Number((option.sellingPriceNoVat + difference).toFixed(2)) // add the difference to the noVat therefore the total will be still the same as with full vat
                  option.sellingPriceWithVat = Number(sellingPriceWithVat.toFixed(2))
                  option['deliveryVat'] = specialVatPercentage
                //}
              }     
              });
          });
        });  
        this.updateProductTotal()
        this.total();
        if(this.orderObject.length > 0){
            this.orderObject.forEach((orderObj: any) => {
              if(!orderObj.selectedOptions.hasOwnProperty('deliveryVat')){
                var vatPercentage = orderObj.selectedOptions.vat / 100
                var sellingPriceWithVat = orderObj.selectedOptions.sellingPriceNoVat * (1 + vatPercentage) // has full vat 
                var sellingPriceWithSpecialVat = orderObj.selectedOptions.sellingPriceNoVat * (1 + specialVatPercentage) // has the special vat
                var difference = sellingPriceWithVat - sellingPriceWithSpecialVat // differnce betweeen amount with full vat and amount with special vat
                
                orderObj.selectedOptions.sellingPriceNoVat = Number((orderObj.selectedOptions.sellingPriceNoVat + difference).toFixed(2)) // add the difference to the noVat therefore the total will be still the same as with full vat
                orderObj.sellingPriceNoVat =  orderObj.selectedOptions.sellingPriceNoVat
                orderObj.selectedOptions.sellingPriceWithVat = Number(sellingPriceWithVat.toFixed(2))
                orderObj.selectedOptions['deliveryVat'] = specialVatPercentage
                orderObj.total = Number(orderObj.selectedOptions.sellingPriceWithVat.toFixed(2))
                orderObj.sellingPriceWithVat = orderObj.selectedOptions.sellingPriceWithVat
                this.calculateVATForOption(orderObj.selectedOptions)
              }
            });
            this.updateProductTotal()
          this.total();
        }

      }

    else{
      if(this.orderObject.length > 0){
        this.orderObject.forEach((orderObj: any) => {
          if(orderObj.selectedOptions.hasOwnProperty('deliveryVat') || orderObj.hasOwnProperty('deliveryVat') || (orderObj.options.some(option => option.hasOwnProperty('deliveryVat')))){
            var vatPercentage = orderObj.selectedOptions.vat / 100
            if(orderObj.selectedOptions.sellingPriceWithVat < Number((orderObj.selectedOptions.sellingPriceNoVat * (1+ vatPercentage))).toFixed(2)){
              orderObj.selectedOptions.sellingPriceNoVat = Number((orderObj.selectedOptions.sellingPriceNoVat / (1 +  Math.abs(vatPercentage - specialVatPercentage))).toFixed(2));
              orderObj.selectedOptions.sellingPriceWithVat = Number((orderObj.selectedOptions.sellingPriceNoVat + (vatPercentage * orderObj.selectedOptions.sellingPriceNoVat)).toFixed(2));
            }else if(orderObj.selectedOptions.sellingPriceWithVat > Number((orderObj.selectedOptions.sellingPriceNoVat * (1+ vatPercentage))).toFixed(2)){
              var vatPercentageTest = (vatPercentage * 100);
              var specialVatPercentageTest = (specialVatPercentage * 100);
              if(vatPercentageTest > 0){
              orderObj.selectedOptions.sellingPriceNoVat = Number((orderObj.selectedOptions.sellingPriceNoVat + (10 +  Math.abs(vatPercentageTest - specialVatPercentageTest))).toFixed(2));
              orderObj.selectedOptions.sellingPriceWithVat = Number((orderObj.selectedOptions.sellingPriceNoVat + (vatPercentage * orderObj.selectedOptions.sellingPriceNoVat)).toFixed(2));
              }
              else{
                orderObj.selectedOptions.sellingPriceNoVat = Number((orderObj.selectedOptions.sellingPriceWithVat).toFixed(2));
                  orderObj.selectedOptions.sellingPriceWithVat = Number((orderObj.selectedOptions.sellingPriceWithVat).toFixed(2));
                
              }
            }
            
            delete orderObj.selectedOptions['deliveryVat'];
              this.calculateVATForOption(orderObj.selectedOptions)
              orderObj.total = Number(orderObj.selectedOptions.sellingPriceWithVat.toFixed(2))
              orderObj.sellingPriceWithVat = orderObj.selectedOptions.sellingPriceWithVat

          }
        });
        this.updateProductTotal()
      this.total();
      }

      copyCategories.forEach(category => {
        category.products.forEach(product => {
          product.options.forEach(option => {
            if (option.hasOwnProperty('deliveryVat') && option['deliveryVat'] > 0)  {
              var vatPercentage = option.vat / 100
              if(vatPercentage > 0){
                option.sellingPriceNoVat = Number((option.sellingPriceNoVat / (1 +  Math.abs(vatPercentage - specialVatPercentage))).toFixed(2));
                option.sellingPriceWithVat = Number((option.sellingPriceNoVat + (vatPercentage * option.sellingPriceNoVat)).toFixed(2));
                delete option['deliveryVat'];
              }
              else{
                option.sellingPriceNoVat = Number((option.sellingPriceWithVat).toFixed(2));
                  option.sellingPriceWithVat = Number((option.sellingPriceWithVat).toFixed(2));
                  delete option['deliveryVat'];
              }
            }
          });
        });
      });

    }
  }



}

checkIfDeviceIsMobile() {
  this.isOnMobile = window.innerWidth <= 1450 ? true : false
}
toggleStickyModal() {
  this.isStickyModalOpen = !this.isStickyModalOpen;
  this.paymentModalVisible = !this.paymentModalVisible;
}

closeStickyModal() {
  this.isStickyModalOpen = false;
}

calculateQuantity() {
  let quantities = this.orderObject.map((x) => {
     return x.qt
  })
  let num = quantities.length > 0 ? quantities?.reduce((a, b) => a + b) : 0
  this.quantity = num
}
@ViewChild('pencilImage') pencilImageRef: ElementRef; 

productClick(product: any){
  this.getProductIngridients(product);
  this.selectDefaultOption()
  
  }
payClick(){
  this.isReserved = false;
  this.checkClient();
}
bookClick(){
  this.isReserved = true;
  this.checkClient();
}
// changeEditPrice(option: any, index: any){
//   if (this.client?.percentage > 0){
//     this.editPriceId = option.id
//     this.editPriceID(index)
//     this.onPercentage(option)
//     this.isChanged = true
//   }
//   else{
//     this.editPriceId = -1
//   }

// }
getSelectedOptionPrice(currentOption: any){
  this.currentProduct.selectedOptions.sellingPriceWithRebate = currentOption.sellingPriceWithRebate
}
productOptionsClick(index: number, option: IOptions){
  this.selectedOptionID = index;
    this.setSelectedOption(option);
}
// editPriceID(i){
//     this.editPriceId === i ? (this.editPriceId = -1): (this.editPriceId = i)
// }
openPaymentModal() {
  this.showPaymentModal = true;
  this.paymentModalVisible=true;
}

closePaymentModal() {
  this.showPaymentModal = false;
  this.paymentModalVisible= false;
}

// Resizable panel

getScreenSizeFromDB(){
  this.screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  this.api.getWaiterConfigurationByID(this.WaiterID).subscribe(
    (res) => {
      try {
        this.rightScreenDB = res[0].screenSize;
        this.percentageRight = this.rightScreenDB;
        this.percentageLeft = 100 - this.rightScreenDB;
        this.percentageGrabber = 1;
        this.leftWidth = this.percentageLeft;
        this.rightWidth = this.percentageRight;
        this.grabber = this.percentageGrabber;
      } catch (error) {
          this.handleFallback();
        const rightSize = this.percentageRight * 100;
        this.api.createWaiterConfiguration(this.WaiterID, this.CompanyID, rightSize).subscribe()
      }
    },
    (error) => {
      this.handleFallback();
      const rightSize = this.percentageRight * 100;
      this.api.createWaiterConfiguration(this.WaiterID, this.CompanyID, rightSize).subscribe()
    });
}
handleFallback() {
  this.screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  this.percentageLeft = 0.68;
  this.percentageRight = 0.31;
  this.percentageGrabber = 0.01;
  this.leftWidth = this.screenWidth * this.percentageLeft;
  this.rightWidth = this.screenWidth * this.percentageRight;
  this.grabber = this.screenWidth * this.percentageGrabber;
  this.grabberClicked = false;
}
onMouseDown(event: MouseEvent) {
  this.grabberClicked = true;
  this.grabberIsSelected.next(true);
}
onTouchStart(event: TouchEvent) {
  this.grabberClicked = true;
  const touch = event.touches[0];
  this.grabberIsSelected.next(true);
  event.preventDefault();
}

@HostListener('window:resize', ['$event'])
onWindowResize() {
  this.checkIfDeviceIsMobile()
}

@HostListener('document:mousemove', ['$event'])
onMouseMove(event: MouseEvent) {
  if (!this.grabberClicked) {
    return;
  }
  this.resize(event.clientX);
  event.preventDefault();
}
@HostListener('document:touchmove', ['$event'])
onTouchMove(event: TouchEvent) {
  if (!this.grabberClicked) {
    return;
  }
  const touch = event.touches[0];
  this.resize(touch.clientX);
  event.preventDefault();
}
@HostListener('document:mouseup', ['$event'])
onMouseUp(event: MouseEvent) {
  if (this.grabbed && this.rightScreenDB!==this.rightWidth){
    this.saveScreenSize();
  }
  this.grabberIsSelected.next(false);
  this.grabberClicked = false;
}
@HostListener('document:touchend', ['$event'])
onTouchEnd(event: TouchEvent) {
  if (this.grabbed){
    this.saveScreenSize();
  }
  this.grabberIsSelected.next(false);
  this.grabberClicked = false;
}
preventDefault(event: Event) {
  event.preventDefault();
}
resize(clientX: number) {
  this.screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const containerWidth = document.querySelector('.content')?.clientWidth;
  if (!containerWidth) return;
  const availableWidth = containerWidth - this.grabber; // Adjust for grabber width
  const minLeftWidth = Math.round(this.screenWidth * 0.40); // Set minimum width for left panel
  const minRightWidth = Math.round(this.screenWidth * 0.22); // Set minimum width for right panel
  const newPosition = Math.max(minLeftWidth, Math.min(clientX, availableWidth - minRightWidth));
  const newLeftPercentage = Math.round((newPosition / containerWidth) * 100);
  const newRightPercentage = Math.round(((availableWidth - newPosition) / containerWidth) * 100);
  this.leftWidth = newLeftPercentage;
  this.rightWidth = newRightPercentage;
  this.grabber = 1;
}
saveScreenSize() {
  const roundRightWidth = Math.round(this.rightWidth);
  const rightSize = this.percentageRight * 100
  if (this.rightScreenDB !== this.rightWidth) {
    if (this.rightWidth + this.leftWidth > 100) {
      if(!rightSize){
        this.api.updateWaiterConfiguration(this.WaiterID, rightSize).subscribe()
      }
    }
    else {
      this.api.updateWaiterConfiguration(this.WaiterID, roundRightWidth).subscribe()
    }
  }
}

//end of resizable panel

  getWaiterRoles() {
    const waiterObject = JSON.parse(localStorage.getItem('waiterObject'));
    if(waiterObject.vatForDelivery > 0){
      this.showSpecialVat = true
      this.vatForDelivery = waiterObject.vatForDelivery
    }
    else{
      this.showSpecialVat = false
      this.vatForDelivery = 0
    }
    if (this.configs?.settings.offlineSales == false) {
      this.api.getWaiters()
        .subscribe(async (waiters: IWaiters[]) => {
          this.waiters = waiters.find(x => x.id == waiterObject.waiterID);
          this.waiters.userRole.forEach(data => {
            if (data.rolename == "Sale create") {
              this.saleCreateRole = true;
            }
          })
        });
    } else {
      this.offlineService.getWaitersFromLocalDb().then(async (data: IWaiters[]) => {
        this.waiters = data.find(x => x.id == waiterObject.waiterID);
        this.waiters.userRole.forEach(data => {
          if (data.rolename == "Sale create") {
            this.saleCreateRole = true;
          }
        })
      })
    }
  }

  getOfflineSales(): void {
    this.offlineService.getSalesFromLocalDb()
      .then((sales: ISale[]) => {
        this.offlineSales = sales;
      });
  }

  getConfigs() {
    this.configsService.getConfigs().subscribe((res: IConfigs) => {
      this.configs = res;
      this.getWaiterRoles();

    },
      err => { console.log(err); }
    );
    if (this.configs == undefined || this.configs == null) {
      this.offlineService.getOfflineConfigs().then((res: IConfigs) => {
        this.configs = res;
      },
        err => { console.log(err); }
      );
    }
    return;
  }

  getClients(): void {
    this.clientService.getClients().subscribe((clients: Client[]) => {
      this.clients = clients;
      this.clients.forEach((client: Client) => {
        client.loyaltyCards.forEach((loyaltyCard) => {
          this.loyaltyCardNames.push(loyaltyCard);
        });
      });
    });
  }
  changeEvent() {
    if(this.isSplit){
      if (this.payment > this.splitTotal) {
        this.change = this.payment - this.splitTotal;
      } else {
        this.change = null;
      }
    }else{
      if (this.payment > this.calculationData.total) {
        this.change = this.payment - this.calculationData.total;
      } else {
        this.change = null;
      }
    }
  }
  saleUpdateListener() {
    this.subscriptions.push(
      this.offlineService.saleUpdated.subscribe((saleID: number) => {
        // this.checkTableStatus();
        // this.getCurrentTable();
        this.getPayments();
      })
    );
  }

  getSalesFromLocalDb(): void {
    this.orderObject = [];
    // this.offlineService.getSalesFromLocalDb(this.getTableId()).then((sale: ISale)=> {
    //   if(sale) {
    //     this.currentSale = sale;
    //     this.orderObject = sale.saleDetails;
    //   }
    // });
  }

  getPayments() {
    this.api.getPayments().subscribe(
      (payments) => {
        this.payments = payments;

        // Remove points from paymentMethod
        this.filteredPayments = this.payments.filter(x => x.name !== 'Points');
      },
      (error) => { }
    );
  }

  // checkTableStatus(){
  //     const  tableID = this.getTableId();
  //     const  companyID =  this.getCompanyID();
  //     this.api.getTableByID(companyID, tableID)
  //       .subscribe(table => {
  //         if (table[0].tableStatus != null){
  //           this.offlineService.addTableStatusToLocalDB(table[0].tableStatus, this.getTableId());
  //           this.getSalesForTable(table[0].tableStatus.saleID);
  //           this.editMode.next(true);
  //           return table[0];
  //          }
  //   });
  // }


  order(data: IProduct) {

    const nameByBrowserLang = this.getProductNameByBrowserLang(data);

    data.productName = nameByBrowserLang;
    if (data.selectedOptions) {

      data.productName = `${nameByBrowserLang} ${data.selectedOptions.productName}`;
      this.vat += Number(data.selectedOptions.totalVat);
    }
    if (data.selectedIngredients.length >= 0) {
      this.formatIngredientsDetails(data);
    }

    data.productDetailsID = data.selectedOptions.id;

    this.orderObject.push(data);
    this.total();
    this.selectedOption = {};
    this.editPriceId = -1;
    this.updateProductTotal();
    this.calculateQuantity();
    this.filteredProductCategories = [];
    (document.getElementById("navbar-search-input") as HTMLInputElement).value = ""
  }

  getProductNameByBrowserLang(data: IProduct): string {
    const lang = this.translate.currentLang;
    if (lang === 'mk') {
      return data.nameMK;
    } else {
      return data.nameSQ;
    }
  }
  checkIfproductInOrder(data) {
    return this.orderObject.includes(data);
  }

  getProductIngridients(product) {
    this.currentProduct = null;
          this.currentProduct = { ...product };
      this.currentProduct.selectedIngredients = [];
    }
    
  getCategoriesFromApi() {
    const waiterObject = JSON.parse(localStorage.getItem('waiterObject'));
    this.alertService.swalAutoLoader();
    this.api.getPOSCategories(waiterObject.companyID).subscribe(
      (response: ICategory[]) => {
        this.categories = response;
        this.sortProducts();
        this.createAllCategories();
        this.getHappyHourPrices();

        this.sortOptions();
        Swal.close();
      },
      (error) => { }
    );
  }
  // check if product has no options
  getOptions() {
    if (
      this.currentProduct.options.length < 1 ||
      this.currentProduct.options.length === undefined
    ) {
      return true;
    } else {
      return false;
    }
  }
  plusQuantity() {
    this.currentProductQuantity += 1;
  }
  minusQuantity() {
    if (this.currentProductQuantity === 1) {
      this.alertService.swalFire(
        'warning',
        this.translateKeys.doTranslate('LOW_QUANTITY_1'),
        false,
        true,
        '',
        this.translateKeys.doTranslate('CLOSE')
      );
    } else {
      this.currentProductQuantity -= 1;
    }
  }
  onSubmit(productId: number, productDetailId: number) {
    if (this.checkQuantity(productId, productDetailId)) {
            // productCategory.qt -= this.currentProductQuantity;
      // Shembull1
      // sellingpricewithvat=118
      // pricewithrabat=59 (cmimi i ri)
      // Rabat=100-(59*100/118)=50

      let isProductInTheCard = this.orderObject.findIndex(x =>
        x.productID === this.currentProduct.productID &&
        x.selectedOptions.id === this.currentProduct.selectedOptions.id &&
        x.productDescription === this.currentProduct.productDescription &&
        x.selectedOptions.sellingPriceWithRebate === this.currentProduct.selectedOptions.sellingPriceWithRebate &&
        x.selectedOptions.sellingPriceWithVat === this.currentProduct.selectedOptions.sellingPriceWithVat
      );      

      if (isProductInTheCard === -1) {
        this.checkAndUpdateQuantity(productId, productDetailId);
        this.currentProduct.qt = this.currentProductQuantity;
        if (![null, undefined, ''].includes(this.currentProduct.selectedOptions.sellingPriceWithRebate)) {
          this.currentProduct.sellingPriceWithVat =
            this.currentProduct.selectedOptions.sellingPriceWithRebate;
            this.currentProduct.sellingPriceNoVat = this.currentProduct.selectedOptions.sellingPriceNoVat;
        }else{
          this.currentProduct.sellingPriceWithVat =
            this.currentProduct.selectedOptions.sellingPriceWithVat;
            this.currentProduct.sellingPriceNoVat = this.currentProduct.selectedOptions.sellingPriceNoVat;
        }
              this.currentProduct.rebate =
          Number(
            (100 -
            (this.currentProduct.selectedOptions.sellingPriceWithRebate * 100) /
            this.currentProduct.selectedOptions.sellingPriceWithVat).toFixed(1)
          ) || 0;
          
        

        if (this.currentProduct.rebate < 0) {
          this.currentProduct.rebate = 0;
        }

        this.currentProduct.selectedOptions.qt = this.currentProductQuantity;
              this.calculateVATForOption(this.currentProduct.selectedOptions);
        this.order(this.currentProduct);
      }else{
        const addProductQt = this.currentProductQuantity;
        for (let i = 0; i < addProductQt; i++) {
          this.editItemplus(isProductInTheCard);
        }
      }
      this.currentProductQuantity = 1;
      this.closeProductModal();
      this.returnFocusToSearchInput();
    }
    this.isChanged = !this.isChanged;
  }

  // Edit item minus add product qt
  returnQt(productId: number, productDetailId: number) {
    for (let category of this.categories) {
      if (![null, undefined].includes(category.products.find(x => x.productID == productId))) {
        category.products.find((x) => x.productID === productId).options.find(y => y.id === productDetailId).qt += 1;
        break;
      }
    }
  }

  checkAndUpdateQuantity(productId: number, productDetailId: number): boolean {
    for (let category of this.categories) {
      if (
        ![null, undefined].includes(
          category.products.find((x) => x.productID == productId && x.options.find(y => y.id === productDetailId))
        )
      ) {
        if (this.configs?.settings?.minusSale == false) {

          var qt = category.products.find((x) => x.productID === productId).options.find(y => y.id === productDetailId).qt;
          if (this.currentProductQuantity <= qt) {
            category.products.find((x) => x.productID === productId).options.find(y => y.id === productDetailId).qt -=
              this.currentProductQuantity;
            return true;
          } else {
            this.alertService.swalFire(
              'warning',
              this.translateKeys.doTranslate('NO_QUANTITY'),
              false,
              true,
              '',
              this.translateKeys.doTranslate('CLOSE')
            );
            return false;
          }
        } else {
          var qt = category.products.find((x) => x.productID === productId).options.find(y => y.id === productDetailId).qt;
          category.products.find((x) => x.productID === productId).options.find(y => y.id === productDetailId).qt -=
            this.currentProductQuantity;
          return true;
        }
      }
    }
  }

  checkQuantity(productId: number, productDetailId: number): boolean {
    for (let category of this.categories) {
      const product = category.products.find((x) => x.productID == productId);
      const option = product?.options.find(y => y.id === productDetailId);
  
      if (option) {
        if (this.configs?.settings?.minusSale === false && this.currentProductQuantity > option.qt) {
          this.alertService.swalFire(
            'warning',
            this.translateKeys.doTranslate('NO_QUANTITY'),
            false,
            true,
            '',
            this.translateKeys.doTranslate('CLOSE')
          );
          return false;
        }
        return true;
      }
    }
    return false;
  }  

  editItemplus(index) {
    this.currentProductQuantity = 1;
    let product;
    product = this.orderObject[index];
    if (this.checkAndUpdateQuantity(product.productID, product.selectedOptions.id)) {
      // this.currentProduct.qt++;
      product.selectedOptions.qt++;
      product.qt = product.selectedOptions.qt;
      this.calculateVATForOption(product.selectedOptions);
      this.updateProductTotal();
      this.total();
      this.calculateQuantity();
    }
  }
  editItemminus(index) {
    let product;
    product = this.orderObject[index];
    if (Number(product.selectedOptions.qt) > 0) {
      // this.currentProduct.qt --;
      product.selectedOptions.qt--;
      product.qt = product.selectedOptions.qt;
      this.calculateVATForOption(product.selectedOptions);
      this.updateProductTotal();
      const productDetailId = product.selectedOptions.id;
      this.returnQt(product.productID, productDetailId);
      this.calculateQuantity();
    }

    if (product.selectedOptions.qt === 0) {
      this.orderObject.splice(index, 1);
      if(this.orderObject.length == 0 && this.hasPoints == true){
        this.returnPoints();
      }

    }

    this.total();
  }
  total() {
    let total = 0;
    let vat = 0;
    this.orderObject.forEach((product) => {
      if (product.selectedOptions) {
        vat += Number((product.selectedOptions.totalVat.toFixed(2)));
        // subTotal += product.selectedOptions.qt * product.selectedOptions.sellingPriceNoVat;
        if(!product.selectedOptions.hasOwnProperty('deliveryVat') && (!product.selectedOptions.hasOwnProperty('sellingPriceWithRebate') || product.selectedOptions.sellingPriceWithVat == product.selectedOptions.sellingPriceWithRebate))
          {
            if(product.selectedOptions.sellingPriceWithVat > product.selectedOptions.sellingPriceNoVat){
              var totalVat2 = product.selectedOptions.qt * (product.selectedOptions.sellingPriceWithVat - product.selectedOptions.sellingPriceNoVat);
              vat += Number((totalVat2).toFixed(2));
            }else{
              vat += Number((product.selectedOptions.totalVat).toFixed(2));
            } 
        } else {
          vat += Number((product.selectedOptions.totalVat).toFixed(2));
        }

        if (![null, undefined].includes(product.selectedOptions.sellingPriceWithRebate)) {
          total += Number((product.selectedOptions.qt * product.selectedOptions.sellingPriceWithRebate).toFixed(2));
          product.rebate = Number((product.rebate).toFixed(2));
        } else {
          total +=  Number((product.selectedOptions.qt * product.selectedOptions.sellingPriceWithVat).toFixed(2));
        }
      }else{
        total += product.qt * product.sellingPriceWithVat
      }
      this.calculationData.total += Number(total.toFixed(2));
    });
    this.calculationData = {
      total: Number(total.toFixed(2)),
      vat: Number(vat.toFixed(2)),
      subTotal: Number((total - Number(vat)).toFixed(2))
    };
    this.calculateQuantity();
  }

  sortProducts() {
    this.categories.forEach((category) => {
      category.products.sort((a, b) => {
        if (a.productID > b.productID) {
          return 1;
        }
        if (b.productID > a.productID) {
          return -1;
        }
        return 0;
      });
    });
  }

  sortOptions() {
    this.categories.forEach((category) => {
      category.products.forEach((option) => {
        option.options.sort((a, b) => {
          if (a.id > b.id) {
            return 1;
          }
          if (b.id > a.id) {
            return -1;
          }
          return 0;
        });
      });
    });
  }


  registerSale(paymentId: number) {
    this.quantity=0;
    this.data.saleType = 3;
    this.data.paymentID = paymentId;
    if(this.hasPoints){
      this.data.total = Number(this.calculationData.total) - Number(this.pointsToUse);
    }else{
      this.data.total = Number(this.calculationData.total);
    }
    this.data.status = true;
    this.data.orderStatus = true;
    this.data.saleDetails = this.orderObject;
    if(this.comment != undefined || this.comment != null){
      this.data.comment = this.comment;
    }else{
      if(!this.configs?.settings?.isGaming){
        this.data.comment = 'Sale ' + this.mmyyyy;
      }else{
        this.data.comment = "";
      }
    }
    let clientId = 0;
    if (this.client !== undefined && this.client !== null) {
      clientId = this.client.id;
      this.data.clientID = this.client.id;
    } else {
      this.data.clientID = null;
    }
    if(this.client?.name){
      this.data['LoyaltyCardName'] = this.client.name
    }
    if(this.selectedSpecialVatForDelivery){
      this.data['isDeliverySale'] = true
    }
    else{
      this.data['isDeliverySale'] = false
    }
    if (!this.checkDetails()) {
      this.alertService.swalAutoLoader();
      this.data.salePayments = this.createSalePayments(paymentId);
      if (this.configs?.settings?.offlineSales === false) {
        var orderMessage = 'ready';
        var paymentID = this.data.paymentID;
        let paymentName = this.payments.find(x => x.id === paymentID)?.name;
        if (this.isReserved) {
          paymentID = -2;
          this.data.orderStatus = false;
          orderMessage = 'new';
        }
        if(paymentName?.toLowerCase() == "printo"){
          this.data.saleType = 7;
        }

        // Iterate over saleDetails to update sellingPriceNoVat based on rebatePercentage
        this.data.saleDetails.forEach((detail: any) => {
          if (detail) {
            // Check if sellingPriceNoVat and rebate are present
            if(detail.sellingPriceWithRebate){
              detail.sellingPriceWithVat = detail.sellingPriceWithRebate
            }
            detail.selectedOptions.totalVat = Number(detail.selectedOptions.totalVat.toFixed(2))
            if (detail.selectedOptions.sellingPriceNoVat && detail.selectedOptions.rebatePercentage) {
              detail.selectedOptions.sellingPriceNoVat =
              detail.selectedOptions.sellingPriceWithRebate - Number(detail.selectedOptions.totalVat.toFixed(2));
            }
          }
        });
        
        // ONLINE SALE FLOW
        
        this.api.registerSaleNoTable(this.data, this.client ? this.client.id : 0, this.client ? this.client.name : null).subscribe(async (response) => {
          // this.signalrService.sendMessage('SendMessage', this.createSignalr(response.saleID, paymentID, 0, false, true));
          this.isSplit = false;
          this.createFiscal(response.saleID, paymentID, 0, false, true);
          this.calculationData = {
            total: 0,
            subTotal: 0,
            vat: 0
          }
          this.data.id = response.saleID;
          this.data.dailyID = response.dailyId;
          this.data.created = response.created;
          this.bC.postMessage({ status: orderMessage, sale: this.data });
          delete this.data.id;
          this.pointsToUse = 0;
          this.refreshClients();

          await this.alertSuccessClearData();
        }, (error) => {
          this.alertService.swalFire('error', error.message, false, true, '', this.translateKeys.doTranslate('CLOSE')).then();
        });

      } else {
        // OFFLINE SALE FLOW
        this.data.idbId != null ? this.data.idbId += 1 : 1;
        this.data.hasFiscal = true;
        Swal.close();
        this.offlineService.addSaleToLocalDB(this.data).then(async (sale: ISale) => {
          this.getOfflineSales();
          this.createOfflineFiscal(this.data);
          this.calculationData = {
            total: 0,
            subTotal: 0,
            vat: 0
          }
          this.pointsToUse = 0;
          await this.alertSuccessClearData();
        }).catch(err => {
          console.log("Err sale", err);
          // TODO translate
          this.alertService.swalFire('error', this.translateKeys.doTranslate('OOPS!_SOMETHING_WENT_WRONG!'), false, true, '', this.translateKeys.doTranslate('CLOSE')).then();
        })
      }
      this.payment = null;
      this.change = 0;
      this.comment = null;
    }
  }
  orderSale() {
    if(this.hasPoints){
      this.data.total = Number(this.calculationData.total) - Number(this.pointsToUse);
    }else{
      this.data.total = Number(this.calculationData.total);
    }
    this.data.status = true;
    this.data.orderStatus = false;
    this.data.saleDetails = this.orderObject;
    let clientId = 0;
    if (this.client !== undefined && this.client !== null) {
      clientId = this.client.id;
      this.data.clientID = this.client.id;
    } else {
      this.data.clientID = null;
    }
    if (!this.checkDetails()) {
      this.alertService.swalAutoLoader();
      if (this.configs?.settings?.offlineSales === false) {
        // ONLINE SALE FLOW
        this.isReserved = true;
        if(this.selectedSpecialVatForDelivery){
          this.data['isDeliverySale'] = true
        }
        else{
          this.data['isDeliverySale'] = false
        }
        this.api.registerSaleNoTable(this.data, this.client ? this.client.id : 0, this.client ? this.client.name : null).subscribe(async (response) => {
          // this.signalrService.sendMessage('SendMessage', this.createSignalr(response.saleID, -2));          
          this.isSplit = false;
          this.createFiscal(response.saleID, -2);
          this.calculationData = {
            total: 0,
            subTotal: 0,
            vat: 0
          }
          this.pointsToUse = 0;
          this.refreshClients();
          this.data.id = response.saleID;
          this.data.dailyID = response.dailyId;
          this.bC.postMessage({ status: 'new', sale: this.data });
          delete this.data.id;
          await this.alertSuccessClearData();
        }, (error) => {
          this.alertService.swalFire('error', error.message, false, true, '', this.translateKeys.doTranslate('CLOSE')).then();
        });

      } else {
        // OFFLINE SALE FLOW
        this.data.idbId != null ? this.data.idbId += 1 : 1;
        this.data.hasFiscal = true;
        Swal.close();
        this.offlineService.addSaleToLocalDB(this.data).then(async (sale: ISale) => {
          this.getOfflineSales();
          this.createOfflineFiscal(this.data);
          this.calculationData = {
            total: 0,
            subTotal: 0,
            vat: 0
          }
          this.pointsToUse = 0;
          await this.alertSuccessClearData();
        }).catch(err => {
          console.log("Err sale", err);
          // TODO translate
          this.alertService.swalFire('error', this.translateKeys.doTranslate('OOPS!_SOMETHING_WENT_WRONG!'), false, true, '', this.translateKeys.doTranslate('CLOSE')).then();
        })
      }
      this.payment = null;
      this.change = 0;
    }
  }

  async alertSuccessClearData(): Promise<void> {
    Swal.close();
    this.alertService.swalFire('success', this.translateKeys.doTranslate('ORDER_CREATED'), true, false, this.translateKeys.doTranslate('OK'))
      .then(() => {
        this.data.saleDetails = [];
        this.orderObject = [];
        this.clearAutoComplete();
        this.returnFocusToSearchInput();
      });
  }

  returnFocusToSearchInput(): void {
      this.navbar.searchInput.nativeElement.focus();
  }

  clearAutoComplete(): void {
    this.clientAutocomplete.clearInput();
  }

  refreshClients(): void {
    this.getClients();
    this.getOfflineSales();
  }

  getUserId() {
    let userId: number;
    this.activatedRoute.paramMap.subscribe((param) => {
      userId = Number(param.get('id'));
    });
    return userId;
  }

  getFiscalInfo() {
    return JSON.parse(localStorage.getItem('waiterObject'));
  }
  goBack() {
    this.router.navigate(['kamarieri/' + this.getUserId()]);
  }
  getCompanyID() {
    return JSON.parse(localStorage.getItem('waiterObject')).companyID;
  }
  closeProductModal() {
    this.closeModal.nativeElement.click();
  }
  deselect(e) {
    this.editPriceId = -1
    if (this.currentProduct.options.length > 0) {
      e.nativeElement.classList.add('deselectClass');
      e.nativeElement.checked = false;
    }
  }
  getSalesForTable(saleID) {
    this.orderObject = [];
    this.alertService.swalAutoLoader();
    this.api.getSaleById(saleID).subscribe((sale) => {
      this.currentSale = sale[0];
      sale[0].saleDetails.forEach((detail) => {
        if (detail.selectedOptions === null) {
          delete detail.selectedOptions;
        }
        this.orderObject.push(detail);
      });
    });
    Swal.close();
  }

  passEvent(e) {
    this.stateEvent = e.value;
  }
  getStateEvent() {
    return this.stateEvent;
  }

  checkDetails() {
    let status = false;

    if (
      this.orderObject === null ||
      this.orderObject === undefined ||
      this.orderObject.length < 1
    ) {
      status = true;
      this.alertService.swalFire(
        'info',
        this.translateKeys.doTranslate('SHOPPING_CART_EMPTY'),
        true,
        false,
        this.translateKeys.doTranslate('OK')
      );
    }
    return status;
  }
  toHex(colorString) {
    return colorString.toString(16);
  }
  toDecimal(colorString) {
    return parseInt(colorString, 16);
  }
  selectIngredient(ingredient?, index?) {
    const ing = { ...ingredient };
    ing.qt = 1;
    const item = this.currentProduct.selectedIngredients.find(
      (x) => x.id === ingredient.id
    );
    if (item) {
      const indexi = this.currentProduct.selectedIngredients.indexOf(item);
      this.currentProduct.selectedIngredients.splice(indexi, 1);
    } else {
      this.currentProduct.selectedIngredients.push(ing);
    }
  }
  selectDefaultOption() {
    setTimeout(() => {
      if (this.currentProduct.options.length > 0) {
        this.selectOption.nativeElement.click();
                this.currentProduct.selectedOptions = {
          productName: this.currentProduct.options[0].productName,
          sellingPriceWithVat:
            this.currentProduct.options[0].sellingPriceWithVat,
          sellingPriceNoVat: this.currentProduct.options[0].sellingPriceNoVat,
          totalVat: Number((
            this.currentProductQuantity *
            (this.currentProduct.options[0].sellingPriceWithVat -
              this.currentProduct.options[0].sellingPriceNoVat)).toFixed(2)),
          vat: this.currentProduct.options[0].vat,
                    qt: this.currentProductQuantity,
                    optionQt: this.currentProduct.options[0].qt,
          id: this.currentProduct.options[0].id,
        };
      }
      if (this.client?.percentage > 0){
        this.currentProduct.selectedOptions.rebatePercentage = this.client.percentage;
        if( (this.currentProduct.options[0].hasOwnProperty('happyRebatePercentage') && this.currentProduct.options[0].happyRebatePercentage > 0)){
          this.currentProduct.selectedOptions.happyRebatePercentage = this.currentProduct.options[0].happyRebatePercentage;
          this.currentProduct.selectedOptions.sellingPriceWithHappyRebate = this.currentProduct.options[0].sellingPriceWithHappyRebate;
        }
        this.onPercentage(this.currentProduct.options[0])
      }
      else if( (this.currentProduct.options[0].hasOwnProperty('happyRebatePercentage') && this.currentProduct.options[0].happyRebatePercentage > 0)){
        this.currentProduct.selectedOptions.rebatePercentage = this.currentProduct.options[0].rebatePercentage;
        this.currentProduct.selectedOptions.sellingPriceWithRebate = this.currentProduct.options[0].sellingPriceWithRebate;
        this.currentProduct.selectedOptions.happyRebatePercentage = this.currentProduct.options[0].happyRebatePercentage;
        this.currentProduct.selectedOptions.sellingPriceWithHappyRebate = this.currentProduct.options[0].sellingPriceWithHappyRebate;
      }
    }, 100);
  }
  changeQT(type, ing: IIngredient, index?: number) {
    const newING = { ...ing };
    switch (type) {
      case 'minus': {
        ing.qt--;
        break;
      }
      case 'plus': {
        ing.qt++;
        break;
      }
    }
    if (ing.qt > ing.defaultQt) {
      newING.qt = ing.qt - ing.defaultQt;
    } else {
      if (ing.qt === ing.defaultQt) {
        this.currentProduct.selectedIngredients = [];
      }
    }
    const item = this.currentProduct.selectedIngredients.find(
      (x) => x.id === ing.id
    );
    if (item) {
      item.qt = newING.qt;
    } else {
      this.pushIngredients({ ...newING });
      // @ts-ignore
    }
    return ing;
  }
  pushIngredients(ingredient) {
    this.currentProduct.selectedIngredients.push(ingredient);
  }

  setDefaultIngredients() {
    this.resetIngredients();
    this.resetAllIngredients();
    this.currentProduct.options[
      this.selectedOptionID
    ].optionIngredients.forEach((i) => {
      this.currentProduct.selectedIngredients.push({ ...i });
    });
  }
  saveIngredients() {
    this.currentProduct.saleIngredients =
      this.currentProduct.selectedIngredients;
    this.alertService.swalFire(
      'success',
      'Ingredients Added',
      true,
      false,
      'OK',
      '',
      1000
    );
  }
  resetIngredients() {
    this.currentProduct.selectedIngredients = [];
    // @ts-ignore
    this.currentProduct.draftSelectedIngredients = [];
  }
  updateProductTotal() {
    this.orderObject.forEach((item) => {
      this.calculateVATForOption(item.selectedOptions);
      if (item.options.length < 1) {
        item.total = item.selectedOptions.qt * item.sellingPriceWithVat;
        if (item.selectedIngredients.length > 0) {
          item.selectedIngredients.forEach((ingredient) => {
            item.total +=
              item.selectedOptions.qt * (ingredient.qt * ingredient.price);
          });
        }
      } else if (
        item.options.length >= 1 &&
        item.selectedOptions &&
        item.selectedOptions.sellingPriceWithVat
      ) {
        item.total =
          item.selectedOptions.qt *
          (item.selectedOptions.sellingPriceWithRebate ||
            item.selectedOptions.sellingPriceWithVat);
        if (item.selectedIngredients.length > 0) {
          item.selectedIngredients.forEach((ingredient) => {
            item.total +=
              item.selectedOptions.qt * (ingredient.qt * ingredient.price);
          });
        } else {
          if (![undefined, null].includes(item.selectedOptions.sellingPriceWithRebate) && !isNaN(item.selectedOptions.sellingPriceWithRebate))
             {
            item.total =
              Number((item.selectedOptions.qt *
              item.selectedOptions.sellingPriceWithRebate).toFixed(2));
          } else {
            item.total = Number((
              item.selectedOptions.qt *
              item.selectedOptions.sellingPriceWithVat).toFixed(2));
          }
        }
      }
    });
  }

  minusIngredient(ingredient: IIngredient, index) {
    if (ingredient.qt === 0 || ingredient.qt === ingredient.defaultQt) {
      return;
    } else {
      ingredient.qt--;
      const item = this.currentProduct.selectedIngredients.find(
        (x) => x.id === ingredient.id
      );
      if (item) {
        item.qt--;
      }
    }
  }
  plusIngredient(ingredient: IIngredient) {
    ingredient.qt++;
    const item = this.currentProduct.selectedIngredients.find(
      (x) => x.id === ingredient.id
    );
    if (item) {
      item.qt = ingredient.qt;
    } else {
      this.currentProduct.selectedIngredients.push({ ...ingredient });
    }
  }
  minusExtraIngredient(ingredient: IIngredient, index) {
    if (ingredient.qt === 0) {
      const item = this.currentProduct.selectedIngredients.find(
        (x) => x.id === ingredient.id
      );
      if (item) {
        const index = this.currentProduct.selectedIngredients.indexOf(item);
        this.currentProduct.selectedIngredients.splice(index, 1);
      }
      return;
    } else {
      const item = this.currentProduct.selectedIngredients.find(
        (x) => x.id === ingredient.id
      );
      if (item) {
        item.qt--;
        if (item.qt === 0) {
          const ind = this.currentProduct.selectedIngredients.indexOf(item);
          this.currentProduct.selectedIngredients.splice(ind, 1);
        }
      }
    }
  }
  plusExtraIngredient(ingredient: IIngredient, index) {
    ingredient.qt++;
    const item = this.currentProduct.selectedIngredients.find(
      (x) => x.id === ingredient.id
    );
    if (item) {
      item.qt = ingredient.qt;
    } else {
      this.currentProduct.selectedIngredients.push({ ...ingredient });
    }
  }
  /**
   * Hides an ingredient if it already is  part of default ingredients;
   * @param ingredient;
   */
  hideIngredient(ingredient) {
    if (this.currentProduct.options.length > 0) {
      const defaultIngredient = this.currentProduct.options[
        this.selectedOptionID
      ].optionIngredients.find((x) => x.id === ingredient.id);
      if (defaultIngredient) {
        return true;
      } else {
        return false;
      }
    }
  }
  formatIngredientsDetails(product: IProduct) {
    product.selectedIngredients.forEach((ingredient) => {
      if (ingredient.qt === ingredient.defaultQt) {
        ingredient.price = 0;
        ingredient.hasExtraIngredients = false;
      } else if (ingredient.qt > ingredient.defaultQt) {
        ingredient.qt -= ingredient.defaultQt;
        ingredient.hasExtraIngredients = true;
      }
    });
    product.saleIngredients = product.selectedIngredients;
  }
  resetAllIngredients() {
    if (this.currentProduct.productIngredients.length > 0) {
      if (this.currentProduct.options.length > 0) {
        this.currentProduct.options.forEach((option) => {
          if (option.optionIngredients.length > 0) {
            option.optionIngredients.forEach((ingredient) => {
              ingredient.qt = ingredient.defaultQt;
            });
          }
        });
      }
      this.currentProduct.productIngredients.forEach((ingredient) => {
        ingredient.qt = 0;
      });
    }
  }
  // Used by POS to create fiscal without db connection
  createOfflineFiscal(data: ISale): void {
    const companyInfo = this.getFiscalInfo() as IWaiterAuthObject;
    data.companyType = companyInfo.companyType;
    let obj = JSON.stringify(this.prepareForPrintOfflineFiscal(data));
    this.windowService.windowRef.location = `crm:/13/${companyInfo.fiscalType.toLowerCase()}/${compressToEncodedURIComponent(obj)}`;
  }
  prepareForPrintOfflineFiscal(data: ISale): IOfflineFiscal {
    const saleDetails = data.saleDetails.map((x: any) => {
      return {
        productName: x.productName,
        qt: x.qt,
        sellingPriceWithVat: x.sellingPriceWithVat,
        vat: Number(x.selectedOptions.vat),
        rebate: Number(x.rebate)
      }
    });
    return {
      total: data.total,
      companyType: data.companyType,
      saleDetails: saleDetails
    }

  }
  createFiscal(saleId: number, paymentID: number, oldSaleId: number = 0, isOrder: boolean = false, isPosOffline: boolean = false) {
    if(paymentID !== undefined){
      let paymentName = this.payments.find(x => x.id === paymentID)?.name;
      if (paymentID === -2) {
        this.windowService.windowRef.location = `crm:/14/${this.getUserId()}/${saleId}`;
      }
      if (paymentName === 'Printo') {
        // print void fiscal
        this.windowService.windowRef.location = `crm:/12/${this.getUserId()}/${saleId}/${oldSaleId}/${isOrder}/${isPosOffline}`;
      } else if (paymentName === 'Cash') {
        // print fiscal
        this.windowService.windowRef.location = `crm:/1/${this.getUserId()}/${saleId}`;
      } else if(paymentName === 'MasterCard' || paymentName === 'Visa') {
        // payment with card
        this.windowService.windowRef.location = `crm:/9/${this.getUserId()}/${saleId}`;
      }
    }
  }
  createSignalr(saleId: number, paymentID: number, oldSaleId: number = 0, isOrder: boolean = false, isPosOffline: boolean = false): string {
    if(this.isSplit){
      return `crm:/15/${this.getUserId()}/${saleId}`;
    }
    if(paymentID !== undefined){
      let paymentName = this.payments.find(x => x.id === paymentID)?.name;
      if (paymentID === -2) {
        return `crm:/14/${this.getUserId()}/${saleId}`;
      }
      if (paymentName === 'Printo') {
        // print void fiscal
        return `crm:/12/${this.getUserId()}/${saleId}/${oldSaleId}/${isOrder}/${isPosOffline}`;
      } else if (paymentName === 'Cash') {
        // print fiscal
        return `crm:/1/${this.getUserId()}/${saleId}`;
      } else if(paymentName === 'MasterCard' || paymentName === 'Visa') {
        // payment with card
        return `crm:/9/${this.getUserId()}/${saleId}`;
      }
    }
  }

  setSelectedOption(option: IOptions) {
    //This will set vatId from productDetail
    this.currentProduct.vatID = option.vatId;

    this.currentProduct.selectedOptions = {
      created: option.created,
      id: option.id,
      isDeleted: option.isDeleted,
      modUser: option.modUser,
      modified: option.modified,
      optionIngredients: option.optionIngredients,
      productID: option.productID,
      productName: option.productName,
      sellingPriceNoVat: option.sellingPriceNoVat,
      sellingPriceWithVat: option.sellingPriceWithVat,
      vat: option.vat,
      qt: this.currentProductQuantity,
      optionQt: option.qt,
      sellingPriceWithRebate: option.sellingPriceWithRebate,
      rebatePercentage: option.rebatePercentage,
      sellingPriceWithHappyRebate: option.sellingPriceWithHappyRebate,
      happyRebatePercentage: option.happyRebatePercentage
    };
  }

  resetPriceValues(){
    this.currentProduct.options.forEach(option => {
      if(this.client && this.client.percentage > 0){
        option.rebatePercentage = this.client.percentage
        option.sellingPriceWithRebate = Number((option.sellingPriceWithVat - (this.client.percentage / 100) * option.sellingPriceWithVat).toFixed(2));
      } else{
        option.sellingPriceWithRebate = option.sellingPriceWithHappyRebate ? option.sellingPriceWithHappyRebate : undefined; 
        option.rebatePercentage = option.happyRebatePercentage ? option.happyRebatePercentage : undefined
      }
    })
  }

  calculateOptionPrices(option: IOptions, priceWithRebate: number) {
    if (option.sellingPriceWithRebate === 0) {
      this.currentProduct.selectedOptions.totalVat = 0;
    } else {
      this.currentProduct.selectedOptions.totalVat =
        option.qt *
        ((option.sellingPriceNoVat -
          (option.sellingPriceWithVat - priceWithRebate)) *
          (option.vat / 100));
      this.currentProduct.selectedOptions.totalVat = option.qt * ((option.sellingPriceNoVat - (option.sellingPriceWithVat - priceWithRebate)) * (option.vat / 100));
    }
    if (!isNaN(priceWithRebate)) {
      this.currentProduct.selectedOptions.rebatePercentage = this.roundedNumber((1 - (priceWithRebate / option.sellingPriceWithVat)) * 100);
      this.currentProduct.selectedOptions.sellingPriceWithRebate = Number(priceWithRebate);

      option.rebatePercentage = this.roundedNumber((1 - (priceWithRebate / option.sellingPriceWithVat)) * 100);
      option.sellingPriceWithRebate = Number(priceWithRebate);

    }
  }

  // onPercentage(option: IOptions) {
  //   if (!isNaN(option.sellingPriceWithVat - (this.currentProduct.selectedOptions.rebatePercentage / 100) * option.sellingPriceWithVat)) {
  //     this.currentProduct.selectedOptions.sellingPriceWithRebate =
  //       (option.sellingPriceWithVat - (this.currentProduct.selectedOptions.rebatePercentage / 100) * option.sellingPriceWithVat).toFixed(2);
  //   }
  //   this.isChanged = true;
  // }

  onPercentage(option: IOptions | IOptions[]) {
    if (Array.isArray(option)) {
      // Handle array of options
      option.forEach(singleOption => {
        this.calculateSellingPrice(singleOption);
      });
    } else {
      // Handle single option object
      this.calculateSellingPrice(option);
    }
  }
  
  calculateSellingPrice(option: IOptions) {
    if (!isNaN(option.sellingPriceWithVat - (this.currentProduct.selectedOptions.rebatePercentage / 100) * option.sellingPriceWithVat)) {
      if(!option.hasOwnProperty('deliveryVat')){
        option.sellingPriceWithVat = Number((option.sellingPriceNoVat * (1 + (option.vat / 100))).toFixed(2)) // reset the selling price with vat to the price with full vat

      }
      this.currentProduct.selectedOptions.sellingPriceWithRebate =
        Number((option.sellingPriceWithVat - (this.currentProduct.selectedOptions.rebatePercentage / 100) * option.sellingPriceWithVat).toFixed(2));
      option['sellingPriceWithRebate'] = this.currentProduct.selectedOptions.sellingPriceWithRebate
      option['rebatePercentage'] = Number((this.currentProduct.selectedOptions.rebatePercentage))
    }
    this.isChanged = true;
    this.calculateVATForOption(option)
  }
  

  onPencilChange(option: IOptions){
    if(this.editPriceId === -1 && (this.client?.percentage === undefined || this.client?.percentage < 0)){
      delete option.sellingPriceWithRebate; 
      delete option.rebatePercentage
      delete this.currentProduct.selectedOptions.rebatePercentage
      delete this.currentProduct.selectedOptions.sellingPriceWithRebate
      if(option.happyRebatePercentage){
        option.sellingPriceWithRebate = option.sellingPriceWithHappyRebate; 
        option.rebatePercentage = option.happyRebatePercentage
        this.currentProduct.selectedOptions.rebatePercentage = option.happyRebatePercentage
        this.currentProduct.selectedOptions.sellingPriceWithRebate = option.sellingPriceWithHappyRebate
      }
    }
    else if(this.editPriceId !== -1 && (option.happyRebatePercentage && option.happyRebatePercentage > 0) && (this.client?.percentage === undefined || this.client?.percentage < 0)){
      option.sellingPriceWithRebate = option.sellingPriceWithHappyRebate; 
      option.rebatePercentage = option.happyRebatePercentage
      this.currentProduct.selectedOptions.rebatePercentage = option.happyRebatePercentage
      this.currentProduct.selectedOptions.sellingPriceWithRebate = option.sellingPriceWithHappyRebate
    }
    else if(this.editPriceId !== -1 && ((option.happyRebatePercentage < 0 || option.happyRebatePercentage === undefined) && ((option.rebatePercentage && option.rebatePercentage > 0) && (this.client?.percentage === undefined || this.client?.percentage < 0)))){
      delete option.sellingPriceWithRebate; 
      delete option.rebatePercentage
      delete this.currentProduct.selectedOptions.rebatePercentage
      delete this.currentProduct.selectedOptions.sellingPriceWithRebate
    }
  }

  onChange() {
    this.isChanged = false;
  }

  onKeyUpPercentage(option: IOptions) {
    if (this.currentProduct.selectedOptions.rebatePercentage >= 100) {
      this.currentProduct.selectedOptions.rebatePercentage = 99;
      this.currentProduct.selectedOptions.sellingPriceWithRebate =
        option.sellingPriceWithVat - (this.currentProduct.selectedOptions.rebatePercentage / 100) * option.sellingPriceWithVat;

    } else if (this.currentProduct.selectedOptions.rebatePercentage < 0) {
      this.currentProduct.selectedOptions.rebatePercentage = 0;
      this.currentProduct.selectedOptions.sellingPriceWithRebate = this.currentProduct.selectedOptions.sellingPriceWithVat;
    }
  } 

  onKeyUpRebate(option: IOptions) {
    if (this.currentProduct.selectedOptions.sellingPriceWithRebate > option.sellingPriceWithVat) {
      this.currentProduct.selectedOptions.rebatePercentage = 0;

    }
  }
  calculateVATForOption(option: IOptions) {
    var specialVatPercentage = this.vatForDelivery / 100

    if(this.client?.percentage > 0 && this.selectedSpecialVatForDelivery){
      option['deliveryVat'] = specialVatPercentage
    }
    // if user added rebate
    if (![null, undefined].includes(option.sellingPriceWithRebate) && !isNaN(option.sellingPriceWithRebate)) {
      if (option.sellingPriceWithRebate == 0) {
        option.totalVat = 0;
      } else {
        if(option.hasOwnProperty('deliveryVat')){
          var vatPercentage = option.vat / 100
          const rebatePercentage = option.rebatePercentage / 100; // Convert percentage to decimal
          const sellingPriceNoVat = option.sellingPriceNoVat;
          const priceAfterSpecialVat = (sellingPriceNoVat + (sellingPriceNoVat * specialVatPercentage)); // Adding 5% VAT
          const priceAfterFullVat = (sellingPriceNoVat + (sellingPriceNoVat * vatPercentage)); // Adding 18% VAT
          const difference = priceAfterFullVat - priceAfterSpecialVat
          const priceAfterRebate = priceAfterFullVat - (priceAfterFullVat * rebatePercentage) ; // Removing 33% discount
          option.sellingPriceWithRebate = Number(priceAfterRebate);
          option.totalVat = option.qt * (option.sellingPriceWithRebate / (1 + (specialVatPercentage)) * (specialVatPercentage));
          //option.totalVat = option.qt * ((option.sellingPriceWithRebate / (1 + (specialVatPercentage* 100) / 100)) * (specialVatPercentage * 100) / 100);
        }
        else{

        
        option.totalVat =
          option.qt *
          (((option.sellingPriceWithRebate / (1 + option.vat / 100)) *
            option.vat) /
            100);
        option.sellingPriceWithRebate = Number(option.sellingPriceWithRebate);
        }
      }
    } else {
      // without rebate
      option.totalVat =
        option.qt * (option.sellingPriceWithVat - option.sellingPriceNoVat);
    }
  }

  instantProductSell() {
    this.currentProduct = this.filteredProductCategories[0]
    this.getProductIngridients(this.currentProduct)
    this.setSelectedOption(this.currentProduct.options[0])
    this.onSubmit(this.currentProduct.productID, this.currentProduct.selectedOptions.id)
    this.filteredProductCategories = [];


    (document.getElementById("navbar-search-input") as HTMLInputElement).value = ""
    let inputElement = document.getElementById("navbar-search-input") as HTMLInputElement
    inputElement.click()


    // let element:HTMLElement = document.getElementById('navbar-search-input') as HTMLElement;
    // element.setAttribute("text"," ")
    // console.log("test");

    // this.order(this.currentProduct);
    // this.foundOneAndAdded = true;
  }

   async selectClientByLoyaltyCard(loyaltyCardNumber: any) {
    
    this.clientService.getClientByloyaltyId(loyaltyCardNumber).subscribe(res => {
      this.client = res
      this.selectedClient(this.client)
    });
    


  }





  createAllCategories() {
    const data = [...this.categories.map(x => x.products)];
    const allProducts: IProduct[] = [];
    const productIDsAdded: Set<number> = new Set();
    data.forEach(products => {
        products.forEach(product => {
            if (!productIDsAdded.has(product.productID)) {
                allProducts.push(product);
                productIDsAdded.add(product.productID);
            }
        });
    });

    this.categories.unshift(
      {
        id: 0,
        created: "02.12.2021",
        modified: "02.12.2021",
        modUser: 0,
        image: null,
        companyID: 0,
        isService: false,
        nameMK: "Сите",
        nameSQ: "Të gjitha",
        productIds: 0,
        products: allProducts
      })

    if (this.selectedCategoryIndex !== 0) {
      setTimeout(() => {
        let element: HTMLElement = document.getElementById('pills-0-tab') as HTMLElement;
        element.click();
      }, 1);

    }
  }

  filterProducts(text: string): void {
    let isMatchedByBarcode = false;

    this.filteredProductCategories = this.categories[this.categoryIndex].products
    .map((product) => {
      // Filter matching product details
      const matchingDetails = product.options?.filter((detail) => {
        const detailBarcodeMatch = 
          detail.barcode == Number(text) || detail.barcodes.includes(text);
        if (detailBarcodeMatch) {
          isMatchedByBarcode = true;
        }
        return (
          detail.productName.toLocaleLowerCase().includes(text.toLocaleLowerCase()) ||
          detail.noProduct == Number(text) ||
          detailBarcodeMatch
        );
      }) || [];

      // Check if the product itself matches or if there are matching details
      const productBarcodeMatch = 
      product.barcode == Number(text) || product.barcodes.includes(text);

      const productMatch =
        product.nameSQ.toLocaleLowerCase().includes(text.toLocaleLowerCase()) ||
        product.noProduct == Number(text) ||
        productBarcodeMatch;

      // Update the flag if the product itself matches by barcode
      if (productBarcodeMatch) {
        isMatchedByBarcode = true;
      }

      if (productMatch || matchingDetails.length > 0) {
        return {
          ...product,
          options: productMatch ? product.options : matchingDetails,
        };
      }
      return null;
    })
    .filter((product) => product !== null);
    
    if (
      this.filteredProductCategories.length === 1 &&
      this.filteredProductCategories[0].options.length === 1 &&
      isMatchedByBarcode
    ) {
      this.instantProductSell();
    }

    if (false){
      //if(!this.client){ maybe we should allow only if there is not a client here but will be tested
        this.selectClientByLoyaltyCard(text);
      //}
    }
  }
  filteredCategoriesProduct(filteredCategoriesProduct: any) {
    throw new Error('Method not implemented.');
  }

  clearFilters(): void {
    if (this.selectedCategoryIndex !== 0) {
      this.filteredProductCategories = [];
    }
  }
  selectedClient(client: ClientSelectedType) {
      if(this.selectedSpecialVatForDelivery){
        this.changeStatusSpecialVatForDelivery()
      }
       this.enableSpecialVatForDelivery()

    if(client == undefined){
      this.client = client;
      this.returnPoints();
      this.resetPrices();
      this.updateProductTotal()
    }
    if(client!=null && client == undefined){
      this.client = client;
      this.returnPoints();
      this.resetPrices();
      this.updateProductTotal()
    }
    this.client = client;
    if(this.client?.percentage > 0){

      this.categories.forEach(category => {
        category.products.forEach(p => {
          p.options.forEach(option => {
              option.rebatePercentage = this.client.percentage
              option.sellingPriceWithRebate = Number((option.sellingPriceWithVat - (this.client.percentage / 100) * option.sellingPriceWithVat).toFixed(2));
          });
        });
      });

      if(this.orderObject.length > 0){
        this.orderObject.forEach((orderObj: any) => {
          orderObj.selectedOptions.rebatePercentage = this.client.percentage
          orderObj.rebate  = this.client.percentage
          orderObj.total = Number((orderObj.selectedOptions.sellingPriceWithVat - (orderObj.selectedOptions.rebatePercentage / 100) * orderObj.selectedOptions.sellingPriceWithVat).toFixed(2));
          orderObj.sellingPriceWithRebate = Number((orderObj.total).toFixed(2))
          orderObj.selectedOptions.sellingPriceWithRebate = Number((orderObj.total).toFixed(2))
          orderObj.sellingPriceWithVat = orderObj.selectedOptions.sellingPriceWithRebate;
        });
      }
      
      this.updateProductTotal()
      this.total();
    }

  }

  checkCondition(percentage: number): boolean {
    if (percentage !== 0 && percentage !== null) {
      this.clientsPercentage = percentage
      return true;
    } else {
      this.clientsPercentage = 0
      return false;
    }
  }

  resetPrices(){
    this.categories.forEach(category => {
      category.products.forEach(p => {
        p.options.forEach(option => {
            option.rebatePercentage = option.happyRebatePercentage ? option.happyRebatePercentage : 0
            option.sellingPriceWithRebate = option.sellingPriceWithHappyRebate ? option.sellingPriceWithHappyRebate : 0
        });
      });
    });

    if(this.orderObject.length > 0){
      this.orderObject.forEach((orderObj: any) => {
        orderObj.selectedOptions.rebatePercentage = orderObj.selectedOptions.happyRebatePercentage
        orderObj.selectedOptions.sellingPriceWithRebate = orderObj.selectedOptions.sellingPriceWithHappyRebate
        orderObj.rebate  = orderObj.selectedOptions.happyRebatePercentage
        orderObj.sellingPriceWithVat = orderObj.selectedOptions.sellingPriceWithHappyRebate ? orderObj.selectedOptions.sellingPriceWithHappyRebate : orderObj.selectedOptions.sellingPriceWithVat
        orderObj.sellingPriceWithRebate = orderObj.selectedOptions.sellingPriceWithHappyRebate ? orderObj.selectedOptions.sellingPriceWithHappyRebate : (orderObj.selectedOptions.sellingPriceWithRebate ? orderObj.selectedOptions.sellingPriceWithRebate : orderObj.selectedOptions.sellingPriceWithVat)
        if(true){
          orderObj.total = orderObj.selectedOptions.sellingPriceWithHappyRebate ? Number((orderObj.selectedOptions.sellingPriceWithHappyRebate).toFixed(2)) : Number((orderObj.selectedOptions.sellingPriceWithVat).toFixed(2));
        }
      });
    }
    this.total();

  }
  
  @ViewChild('resetLoyaltyCardModal') resetLoyaltyCardModal: ElementRef;

  removeLoyaltyCard(value: any){    
    this.resetPrices()
    // if(this.orderObject.length > 0 && false){
    //   const modalElement = this.resetLoyaltyCardModal.nativeElement as HTMLElement;
    //   modalElement.style.display = 'block';
    // }
    // else{
      if(value == null){
        this.client.name = ''
      }
      this.clientsPercentage = 0
      this.client.percentage = 0
      if(this.selectedSpecialVatForDelivery){
        this.changeStatusSpecialVatForDelivery()
        this.updateProductTotal()
      }
      this.updateProductTotal()
      this.enableSpecialVatForDelivery()
      
    //}
      }
  closeModalFunction(){
    
    const modalElement = this.resetLoyaltyCardModal.nativeElement as HTMLElement;
    modalElement.style.display = 'none';
  }

  refreshWindow(){
    window.location.reload();
  }

  // checkValidUntil(): boolean {
  //   return new Date() < new Date(this.configs?.validUntil);
  // }

  checkClient() {
    if (this.client === undefined) {
      this.paymentModalVisible = true;
    } else if (this.client === null) {
      this.paymentModalVisible = false;
      this.alertService.swalFire(
        'error',
        this.translateKeys.doTranslate('CLIENT_ERR'),
        false,
        true,
        '',
        this.translateKeys.doTranslate('CLOSE')
      );
    } else {
      this.paymentModalVisible = true;
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => {
      s.unsubscribe();
    });

  }

  useClientPoints(event: any) {
    event.target.disabled = true;
    this.hasPoints = true;
    if (this.client.points > this.calculationData.total) {
      this.client.points -= this.calculationData.total;
      this.pointsToUse = this.calculationData.total;
    } else {
      this.pointsToUse = this.client.points;
      this.client.points = 0;
    }
    this.client.points = Number((this.client.points).toFixed(2))

  }

  createSalePayments(PaymentID: number): SalePayment[] {

    let salePayments: SalePayment[] = [];
    if(this.isSplit){
      this.clickedPayments.forEach((payment) => {
        salePayments.push({
          PaymentID: payment.id,
          total: payment.total
        });
      })
    }else{
      salePayments.push({
        PaymentID,
        total: this.calculationData.total - this.pointsToUse
      });
    }

    // If client points was used add to salepayment object
    if (this.client && this.pointsToUse > 0) {
      const paymentId = this.payments.find(x => x.name === 'Points')?.id;
      salePayments.push({
        PaymentID: paymentId,
        total: this.pointsToUse
      });
    }

    return salePayments;
  }

  returnPoints(): void {
    this.hasPoints = false;
    if(this.client !== undefined && this.client !== null){
      this.client.points += this.pointsToUse;
    }
    this.pointsToUse = 0;
  }


  shortcutFunctions(shortcut: string){
    if(this.saleCreateRole){    
      let chashId = this.payments.find(x => x.name.toLocaleLowerCase() === "cash")?.id;
      let printoId = this.payments.find(x => x.name.toLocaleLowerCase() === "printo")?.id;
      if(shortcut == "sales/register"){
        this.registerSale(chashId);
      }else if(shortcut == "sales/void"){
        this.registerSale(printoId);
      }
    }
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    let waiterShortcuts = JSON.parse(localStorage.getItem('waiterShortcuts'));
    switch (event.code) {
      case "F2":
        this.shortcutFunctions(waiterShortcuts.firstFunction);
        break;
      case "F4":
        this.shortcutFunctions(waiterShortcuts.secondFunction);
        break;
      case "F5":
        this.shortcutFunctions(waiterShortcuts.thirdFunction);
        break;
      case "F7":
        this.shortcutFunctions(waiterShortcuts.fourthFunction);
        break;
      case "F8":
        this.shortcutFunctions(waiterShortcuts.fifthFunction);
        break;
      case "F9":
        this.shortcutFunctions(waiterShortcuts.sixthFunction);
        break;
      default:
        break;
    }
  }
  /**
   * this method is used to get path image, if don't have image on DB we use a default image
   * @param img is the path of image category
   * @param index is the selected index category
   * @returns the path os selected category image
   */
  getCategoryImage(img: string | null, index: number): string {
    const isSelectedCategory = index === this.categoryIndex;
    const defaultImage = isSelectedCategory
      ? '../assets/images/ipos_contrast.png'
      : '../assets/images/ipos.png';
  
    return img == null ? defaultImage : this.IMG_PATH_CATEGORY + img;
  }  

  splitPayment(){
    this.isSplit = !this.isSplit;
    if(this.isSplit == false){
      this.clickedPayments = [];
      this.splitTotal = 0;
    }else{
      this.splitTotal = this.calculationData.total - this.pointsToUse;
    }
  }

  handlePayment(payment: any): void {
    if(this.isSplit == true){
      if (!this.clickedPayments.some(x => x.id === payment.id) && ![null, undefined, ''].includes(this.payment)) {
          this.splitTotal = Math.round(this.splitTotal * 100);
          this.payment = Math.round(this.payment * 100);
          if(this.payment > this.splitTotal){
            this.payment = this.splitTotal;
            this.splitTotal = 0;
          }else{
            this.splitTotal -= this.payment;
          }
          this.clickedPayments.push({ id: payment.id, name: payment.name, total: this.payment /= 100 });
          this.payment = null;
          this.change = null;
          this.splitTotal = Math.max(0, this.splitTotal);
          this.splitTotal = this.splitTotal /= 100;
      }else{
        this.alertService.swalFire(
          'warning',
          this.translateKeys.doTranslate('NULL_PAYMENT'),
          false,
          true,
          '',
          this.translateKeys.doTranslate('CLOSE')
        );
      }
    }else{
      this.registerSale(payment.id);
    }
  }

  editPayemnt(paymentID: number){
    const payment = this.clickedPayments.find(x => x.id === paymentID);
    this.splitTotal += payment.total;
    this.payment = payment.total;
    this.clickedPayments = this.clickedPayments.filter(payment => payment.id !== paymentID);
  }

  isPaymentClicked(payment: any): boolean {
    if((this.isSplit && payment.name.toLowerCase() == 'printo') || (this.splitTotal <= 0 && this.isSplit)){
      return true;
    }else{
      return this.clickedPayments.some(x => x.id === payment.id);
    }
  }

  paySplitButton(){
    if (this.splitTotal <= 0 && this.isSplit) {
      this.registerSale(1);
    } else {
      this.splitPayment();
    }
  }

  splitText(): string {
    return (this.splitTotal <= 0 && this.isSplit)  ? this.translateKeys.doTranslate('PAY') : this.translateKeys.doTranslate('SPLIT_PAYMENT');
  }

  closeModalButton(){
    if(this.isSplit){
      const modalElement: HTMLElement = this.paymentModal.nativeElement;
      if (modalElement.getAttribute('aria-hidden') === 'true') {
        this.clickedPayments = [];
        this.splitTotal = this.calculationData.total - this.pointsToUse;
        this.payment = null;
        this.change = null;
      }
    }
  }


  getHappyHourPrices(){
    this.api.getHappyHours(this.CompanyID).subscribe(res => {
      if (Array.isArray(res)) {
        res.forEach(happyHour => {
          this.closestEndTime.push(happyHour.endTime);
          happyHour.productDetails.forEach(pd => {
            this.happyHour=true;
            // Update price for products that are not in card
            this.categories.forEach(category => {
              category.products.forEach(p => {
                p.options.forEach(option => {
                  if(option.id === pd.id){
                    option.sellingPriceWithRebate = pd.sellingPriceWithRebate
                    option.rebatePercentage = this.roundedNumber(Number(pd.rebate));
                    option.sellingPriceWithHappyRebate = pd.sellingPriceWithRebate
                    option.happyRebatePercentage = this.roundedNumber(Number(pd.rebate));
                  }
                });
              });
            });

            // Update price for products that are in card
            if(this.orderObject.length > 0){
              this.orderObject.forEach(option =>{
                if(option.selectedOptions.id === pd.id){
                  option.sellingPriceWithRebate = pd.sellingPriceWithRebate
                  option.rebatePercentage = this.roundedNumber(Number(pd.rebate));
                  option.sellingPriceWithHappyRebate = pd.sellingPriceWithRebate
                  option.happyRebatePercentage = this.roundedNumber(Number(pd.rebate));

                }
              })
              this.updateProductTotal();
              this.total();
            }
          });
        });
        if(res.length > 0){
          this.remainingTime();
        }
      } 
    });
  }

  remainingTime(){
    const endTimes = this.closestEndTime;
    const currentTime = new Date();
    const endTimesInMilliseconds = endTimes.map(dateTime => {
      const time = dateTime.split('T')[1].split(':')[0] + ':' + dateTime.split('T')[1].split(':')[1];
      const [hours, minutes] = time.split(':').map(Number);
      const endTime = new Date(currentTime);
      endTime.setHours(hours, minutes, 0, 0);
      return endTime.getTime();
    }).filter(time => !isNaN(time));

    endTimesInMilliseconds.sort((a, b) => a - b);

    const currentTimeInMilliseconds = currentTime.getTime();
    const closestEndTimeInMilliseconds = endTimesInMilliseconds.reduce((prev, curr) => {
      return (Math.abs(curr - currentTimeInMilliseconds) < Math.abs(prev - currentTimeInMilliseconds) ? curr : prev);
    }, Infinity);

    const remainingTime = closestEndTimeInMilliseconds - currentTimeInMilliseconds;

    setTimeout(() => {
      this.alertService.swalFire(
        'info',
        this.translateKeys.doTranslate('HAPPY_HOUR_ENDED_INFO'),
        true,
        true,
        this.translateKeys.doTranslate('YES'),
        this.translateKeys.doTranslate('NO')
      ).then((response) => {
        if (!response.isConfirmed) {
          this.refreshWindow();
        }
      });
    }, remainingTime);
  }

  roundedNumber(value: number){
    if(value){
      return Math.round(value * 100) / 100;
    }
  }
}
