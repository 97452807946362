import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IWaiterAuthObject } from '../interfaces/IWaiterAuthObject';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DebtCardService {
  private API_URL = environment.API_URL;
  private selectedTabIndex: number = 0;
  constructor(
    private http: HttpClient
    ) {}

  setSelectedTabIndex(index: number) {
    this.selectedTabIndex = index;
  }

  getSelectedTabIndex() {
    return this.selectedTabIndex;
  }
  
  getCompanyId(): string {
    const token = JSON.parse(localStorage.getItem('waiterObject')) as IWaiterAuthObject;
    if (token) {
      return token.companyID.toString();
    } else {
      return '';
    }
  }
  getUserId(): string {
    const token = JSON.parse(localStorage.getItem('waiterObject')) as IWaiterAuthObject;
    if (token) {
      return token.waiterID.toString();
    } else {
      return '';
    }
  }

  getSaleDebts(): Observable<any>{
    var companyId = this.getCompanyId(); 
    return this.http.get<any>(`${environment.API_URL}/debtcardapi/SaleDebts`,{ params: {companyId}});
  }
  getSaleDebtDetails(id): Observable<any>{
    return this.http.get<any>(`${environment.API_URL}/debtcardapi/GetSaleDebtDetails`,{ params: {id}});
  }

  deleteSaleDebt(id):Observable<any>{
    return this.http.delete<any>(`${environment.API_URL}/debtcardapi/DeleteDebt`,{params: {id}});
  }

  createSaleDebts(id,amount,PersonWhoPaid, date):Observable<any>{
    let userId = this.getUserId();
    return this.http.post<any>(this.API_URL + '/debtcardapi/Create', {}, {params: {id:id,amount:amount,PersonWhoPaid:PersonWhoPaid,userId:userId, dateTimeString: date}})
  }

  getDebtsByClient(){
    var companyId = this.getCompanyId();
    return this.http.get<any>(`${environment.API_URL}/debtcardapi/SortByClient`,{ params: {companyId}});
  }

  getClientDebt(id):Observable<any>{
    return this.http.get<any>(`${environment.API_URL}/debtcardapi/GetDebtsByClient`,{ params: {id}});
  }

  createClientDebt(clientId, totalAmount, name,bankNumber, date):Observable<any>{
    let userId = this.getUserId();
    return this.http.post<any>(this.API_URL + '/debtcardapi/CreateDebt', {}, {params: {clientId:clientId,totalAmount:totalAmount,name:name,bankNumber:bankNumber,userId:userId, dateTimeString: date}})
  }

  deleteClientDebt(id):Observable<any>{
    return this.http.delete<any>(`${environment.API_URL}/debtcardapi/DeleteDebtFromClient`,{params: {id}});
  }
  
  editSaleDebt(id,saleId,amount,totalLeft,PersonWhoPaid,userId, date){
    return this.http.put(this.API_URL + '/debtcardapi/edit',{}, {params: {id,saleId,amount,totalLeft,PersonWhoPaid,userId, dateTimeString: date}});
  }

  payAllSaleDebts(id): Observable<any>{
    return this.http.get<any>(this.API_URL + '/debtcardapi/PayAllSaleDebt' , {params: {id}});
  }

  payAllSaleClientDebts(clientId): Observable<any>{
    return this.http.get<any>(this.API_URL + '/debtcardapi/PayAllSaleClientDebt' , {params: {clientId}});
  }

}
