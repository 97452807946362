<div class="content">
  <div [style.width.%]="leftWidth" class="left-panel">
    <app-navbar showSearchInput="true" (searchTextChanged)="filterProducts($event)"></app-navbar>
    <div class="salesContainer">
      <div class="col-md-12 pt-3 createSale shadow bg-white">
        <h1 class="title-wholesale">{{ "CREATE_SALES" | translate }}</h1>
        <mat-accordion>
          <!-- Client information -->
          <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "CLIENT_INFO" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="saleDescription">
              <div class="row m-0">
                <div class="col-md-6 row m-0 p-0">
                  <div class="form-group col-md-12 inputNumbers">
                    <!-- sale nr -->
                    <div class="m-0 row">
                      <div class="col-md-6">
                        <label for="client">
                          {{ "INOVICE_NO" | translate }}
                        </label>
                      </div>
                      <div class="col-md-6">
                        <button class="iconButton" *ngIf="!editSaleNo" (click)="editSaleNoFnc()">
                          <!-- <img width="20px" src="/assets/icons/pencil.svg"> -->
                          <img src="../../../assets/images/edit.svg" alt="edit-icon" />
                        </button>
                        <div class="iButton">
                          <button [disabled]="
                                  saleDescription.controls.saleNo.invalid ||
                                  saleDescription.controls.saleYear.invalid
                                " *ngIf="editSaleNo" (click)="checkSaleNo()" class="btn-n btn-success-n">
                            {{ "DONE" | translate }}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <label for="groupClient" *ngIf="groupofClients">{{"GROUP-OF-CLIENT" | translate}}: {{groupofClients}}</label>
                    </div>
                    <div class="m-0 row w-100">
                      <div class="col-md-6">
                        <input class="form-control inputs1" id="nr" type="number" formControlName="saleNo"  [ngClass]="showSaleNoError ? 'msg-error':''" (click)="clearError()" />
                      </div>
                      <div class="col-md-6">
                        <input class="form-control inputs2" id="nr" type="number" [max]="99" formControlName="saleYear" [ngClass]="showSaleNoError ? 'msg-error':''" (click)="clearError()" />
                      </div>
                    </div>
                  </div>

                  <!-- sale date -->
                  <div class="form-group col-md-12 m-0 row">
                    <label class="w-100" for="sale_date">
                      {{ "DATA_OF_SALES" | translate }}
                    </label>
                    <mat-form-field appearance="outline" class="w-100 date">
                      <input matInput [matDatepicker]="dp" [datePickerFormat]="configs?.settings?.dateFormat"
                        formControlName="dateSale" />
                      <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                      <mat-datepicker #dp></mat-datepicker>
                    </mat-form-field>
                  </div>

                  <div class="form-check pt-3 ml-4 col-md-3" *ngIf="client && client.isMKChecked">
                    <label class="container">
                      <input class="form-check-input" [disabled]="saleDetails.length > 0" [value]="sale.isMKChecked"
                        type="checkbox" id="mk32" (change)="sale.isMKChecked = $event.target.checked" />
                      <span class="checkmark"></span>
                      <label class="form-check-label pt-1" for="mk32">
                        MK:32
                      </label>
                    </label>
                  </div>

                  <div class="form-check pt-3 ml-4 col-md-3">
                    <label class="container">
                      <input class="form-check-input" [checked]="sale.isDebtChecked" type="checkbox" id="debt"
                        (change)="debtChecked($event.target.checked)" />
                      <span class="checkmark"></span>
                      <label class="form-check-label pt-1" for="debt">
                        {{ "DEBT" | translate }}
                      </label>
                    </label>
                  </div>
                  <div class="col-md-12 pt-3 bank-input">
                    <label for="bank"> {{ "BANK" | translate }} </label>
                    <app-conome-autocomplete #bankAutoComplete label="{{ 'BANK' | translate }}" [data]="bankDetails"
                      [displayFieldName]="bankDisplayFieldName" [filterKeys]="bankDetailsFilterKeys"
                      (onSelectedData)="onSelectedBank($event)">
                    </app-conome-autocomplete>
                  </div>
                </div>

                <!-- Client -->
                <div class="col-md-6 row m-0 p-0">
                  <div class="col-md-12 m-0 form-group">
                    <label for="client"> {{ "CLIENT" | translate }} </label>
                    <app-client-autocomplete-input class="client-input" #clientAutocomplete [clients]="clients"
                      (onSelectedClientChange)="selectedClient($event)" [disabled]="clientDisabled" [wholesale]="true">
                    </app-client-autocomplete-input>
                  </div>

                  <!-- Comment -->
                  <div class="col-md-12 form-group">
                    <label for="comment">{{ "COMMENT" | translate }}</label>
                    <textarea class="form-control" name="comment" id="comment" cols="15" rows="5"
                      formControlName="comment"></textarea>
                  </div>
                </div>
              </div>
            </form>
          </mat-expansion-panel>

          <!-- Products list -->
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "PRODUCTS" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="col pl-1 pr-0 pb-2">
              <ul class="scroll nav nav-pills d-flex categoriesMenu list-unstyled" role="tablist" #scroll>
                <li class="nav-item p2" *ngFor="let category of categories; let i = index">
                  <a class="nav-link h-100 category text-center mr-2" id="pills-{{ category.id }}-tab" data-toggle="pill"
                    href="#pills-{{ category.id }}" role="tab" aria-selected="false" (click)="categoryClick(i, category.id)">
                    <div class="row mx-auto justify-content-center text-center align-self-center align-items-center cImage">
                      <img *ngIf="i===0" src="../assets/images/all.png" class="img-thumbnail border-0 p-0 bg-transparent"/>
                      <img [src]="getCategoryImage(category.image, i)" class="img-thumbnail border-0 p-0 bg-transparent" />
                    </div>
                    <span class="col p-0 m-0 mt-2">{{
                      category[
                      "name" + this.translate.currentLang.toUpperCase()
                      ]
                      }}</span>
                  </a>
                </li>
              </ul>
            </div>
            <div class="tab-content pl-3">
              <div class="tab-pane d-none active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                A
              </div>
              <div class="tab-pane show" *ngFor="let category of categories" id="pills-{{ category.id }}" role="tabpanel">
                <!-- (click)="editPriceId = -1" -->
                <div class="row pb-2 pl8" style="overflow: hidden !important"
                  *ngIf="filteredProductCategories.length == 0">
                  <div class="card productCard m-3" data-toggle="modal" data-target="#exampleModal"
                    (click)="setCurrentProduct(product)" *ngFor="let product of category.products; let i = index">
                    <div class="cardImage justify-content-center img-thumbnail border-0" [style.backgroundImage]="
                            'url(' + IMG_PATH + product.image + ')'
                          "></div>

                    <div class="card-body p-0 pt-3 m-0">
                      <h6 class="card-title m-0 productCardName">
                        {{
                        product[
                        "name" + this.translate.currentLang.toUpperCase()
                        ]
                        }}
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="row pb-2 pl8" style="overflow: hidden !important"
                  *ngIf="filteredProductCategories.length > 0">
                  <div class="card productCard m-3" data-toggle="modal" data-target="#exampleModal" *ngFor="
                          let product of filteredProductCategories;
                          let i = index
                        " (click)="filterProductsClick(product)">
                    <div class="cardImage justify-content-center img-thumbnail border-0" [style.backgroundImage]="
                            'url(' + IMG_PATH + product.image + ')'
                          "></div>

                    <div class="card-body p-0 pt-3 m-0">
                      <h6 class="card-title m-0 productCardName">
                        {{
                        product[
                        "name" + this.translate.currentLang.toUpperCase()
                        ]
                        }}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>

  <div class="grabber" [style.width.%]="grabber" (mousedown)="onMouseDown($event)" (touchstart)="onTouchStart($event)"
  (touchend)="onTouchEnd($event)" (selectstart)="preventDefault($event)" (mouseup)="onMouseUp($event)"
  (mousemove)="onMouseMove($event)">
  </div>

  <div [style.width.%]="rightWidth" class="right-panel border" [ngStyle]="{'width':rightScreenDB+'%'}">
      <div class="col h-100 p-0">
        <div class="col w-100">
          <div class="row pt-2 pb-2">
            <div class="col-6 fontArial18 my-auto">
              {{ "CHECKOUT" | translate }}
            </div>
          </div>
          <div class="row diplay-name fontArial12 border-bottom pt-3 pb-3">
            <div class="col-4">{{ "NAME" | translate }}</div>
            <div class="col-2 quantity-label pl-0">{{ "QUANTITY" | translate }}</div>
            <div class="col-2 price-label pl-2">{{ "PRICE" | translate }}</div>
            <div class="col-3 p-0"><div class="edit-label">{{'EDIT' | translate}}</div></div>
          </div>

          <div class="col overflow-auto p-0" id="productDiv">
            <div class="row pt-2 pb-2 m-0 p-0 fontarial12Black border-bottom product-item" *ngFor="let item of saleDetails; let i = index">
              <div class="col-4 my-auto pl-0">
                <div class="col p-0 my-auto" *ngIf="item.hasOwnProperty('selectedOptions')">
                  {{ item.productName }}
                </div>
              </div>
              <div class="col-2 my-auto quantity-number">{{ item.selectedOptions.qt }}</div>
              <div class="col-2 my-auto price-number">{{ item.total?.toFixed(2) }}</div>
              <div class="col-3 my-auto p-0">
                <div class="row buttonGroup">
                  <div>
                    <button class="btn icon-plus btn-block plus-button p-0"
                      (click)="editItemplus(i)"></button>
                  </div>
                  <div>
                    <button class="btn icon-minus btn-block minus-button p-0"
                    (click)="editItemminus(i)"></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      <div class="col-12 p-0 bottomRow w-100">
        <div class="col-12 bgCheckout">
          <div class="row border pt-4 pb-4">
            <div class="col-6">
              <!-- <app-client-autocomplete-input #clientAutocomplete  (onSelectedClientChange)="selectedClient($event)"></app-client-autocomplete-input> -->
            </div>
            <div class="col-6 row">
              <div class="col-6 text-right fontArial">
                {{ "SUBTOTAL" | translate }}
              </div>
              <div class="col-6 text-left">{{ calculationData.subTotal }}</div>
              <div class="col-6 text-right fontArial12">
                {{ "TAX" | translate }}
              </div>
              <div class="col-6 text-left">{{ calculationData.vat }}</div>
            </div>
          </div>
        </div>
        <div class="col-12 bgCheckout">
          <div class="row pt-2 pb-2 border pt-4 pb-4">
            <div class="col-8 text-right fontArial18">
              {{ "TOTAL" | translate }}
            </div>
            <div class="col-4 text-left fontArial18">
              {{ calculationData.total?.toFixed(2) }}
            </div>
          </div>
        </div>
        <div class="col-12 p-0">
          <button [disabled]="saleDetails.length === 0 || !saleCreateRole" class="btn btn-block payOrder"
            data-target="#paymentModal" data-toggle="modal">
            {{ "PAY" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="dialog">
    <div class="modal-content">
      <div class="modal-header">
        <span
          class="modal-title fontArialM18"
          id="exampleModalLabelsq"
          *ngIf="currentProduct"
          >{{
            currentProduct["name" + translate.currentLang.toUpperCase()]
          }}</span
        >
        <button
          type="button"
          class="close"
          #closeModalBtn
          id="closeModal"
          data-dismiss="modal"
          aria-label="Close"
        >
          <img src="assets/images/x-inactive-new.png" class="closeIcon" />
        </button>
      </div>
      <div class="modal-body mPadding text-center fontArialM">
        <form>
          <div class="row p-0 m-0">
            <div
              class="col-12 fontArialM"
              *ngIf="currentProduct && currentProduct.options.length > 0"
            >
              <div class="row">
                <div class="col-5 p-0  text-left mb6">{{'SIZE' | translate }}</div>
                <div class="col-3 p-0 text-center mb6 vatprice">{{'PRICE_WITH_VAT' | translate }}</div>
                <div class="col-2 p-0 text-center mb6 percentage">%</div>
              </div>
              <div class="row pb-3">
                <div
                  class="col-md-12"
                  *ngFor="let option of currentProduct.options; let i = index"
                  (click)="selectedOptionClick(i, option)"
                >
                  <div class="row padding-bottom-1" #selectOption>

                    <div class="col-md-5 padding-left-0">
                      <p class="border rounded-lg h-100 custom-box" (click)="editPriceId = -1"
                        [ngClass]="selectedOptionID == i ? 'active' : ''">
                        {{option?.productName}}</p>
                    </div>
                    <div class="col-md-3 padding-left-0" *ngIf="editPriceId != i">
                      <p class="border rounded-lg h-100 custom-box" [ngClass]="selectedOptionID == i ? 'active' : ''">
                        {{option?.sellingPriceWithVat}}</p>
                    </div>
                    <span class="col-md-3" *ngIf="editPriceId == i && !isChanged">
                      <input type="number"
                              [value]="option.sellingPriceWithVat"
                             ([ngModel])="option.sellingPriceWithVat"
                             #priceWithRebate
                             [attr.id]="option.id"
                             (keyup)="onKeyUpRebate(option)"
                             (input)="calculateOptionPrices(option, priceWithRebate.value)"
                             class="form-control col text-center inputText  d-block p-0">
                    </span>
                    <span class="col-md-3" *ngIf="editPriceId == i && isChanged">
                      <input type="number"
                              [value]="currentProduct?.selectedOptions?.sellingPriceWithRebate"
                             ([ngModel]) = "currentProduct.selectedOptions.sellingPriceWithRebate"
                             #priceWithRebate
                             [attr.id]="option.id"
                             (keyup)="onKeyUpRebate(option)"
                             (input)="calculateOptionPrices(option, priceWithRebate.value)"
                             class="form-control col text-center inputText  d-block p-0">
                    </span>
                    <div class="cold-md-1 padding-left-0 d-flex" [attr.for]="option.id"
                      (click)="editPriceIdClick(i)">
                      <img width="20px" (click)="onChange()" src="../../../assets/icons/pencil.svg">
                    </div>
                    <div class="col-md-3 padding-left-0" *ngIf="editPriceId != i">
                      <p class="border rounded-lg h-100 custom-box" [ngClass]="selectedOptionID == i ? 'active' : ''">{{option.percentage}}</p>
                    </div>
                    <div class="col-md-3 percentage-input" *ngIf="editPriceId == i">
                      <input type="number"
                             name="percentage"
                             [value]="currentProduct?.selectedOptions?.rebatePercentage"
                             [(ngModel)]="currentProduct.selectedOptions.rebatePercentage"
                             [min]="0"
                             [max]="99"
                             [attr.id]="option.id"
                             (input)="onPercentage(option)"
                             (keyup)="onKeyUpPercentage(option)"
                             class="form-control col text-center inputText  d-block">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 pl-0 pr-0 justify-content-center pb-3">
              <div class="col-12 p-0 text-left mb6">
                {{ "QUANTITY" | translate }}
              </div>
              <div class="col-12 row p-0 m-0">
                <div class="col p-0">
                  <button
                    type="button"
                    name="minus"
                    class="btn btn-block amountMinus align-middle"
                    (click)="minusQuantity()"
                  >
                    -
                  </button>
                </div>
                <div class="col p-0">
                  <div class="from-group pl-2 pr-2">
                    <input
                      type="number"
                      [(ngModel)]="currentProductQuantity"
                      [min]="1"
                      maxlength="2"
                      [ngModelOptions]="{ standalone: true }"
                      id="productQuantity"
                      class="form-control col text-center inputText d-block"
                    />
                  </div>
                </div>
                <div class="col p-0">
                  <button
                    type="button"
                    name="plus"
                    class="btn btn-block text-info amountPlus align-middle"
                    (click)="plusQuantity()"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>
          <button
            class="btn btn-block saveStyle mt-2"
            (click)="addProduct(currentProduct.productID, currentProduct.selectedOptions.id)"
          >
            {{ "ORDER" | translate }}
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  class="modal"
  id="paymentModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="paymentModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="paymentModalLabel">
          {{ "TYPE_OF_PAYMENT" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          id="paymentModalClose"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="payment-wrapper">
          <div class="payment">
            <label>{{ "PAYMENT" | translate }}</label>
            <input
              class="payment-input test"
              type="text"
              [(ngModel)]="payment"
              (ngModelChange)="changeEvent()"
              [readonly]="calculationData.total == 0"
            />
          </div>
          <div class="change-div">
            <label>{{ "CHANGE" | translate }}</label>
            <input
              class="change-input"
              type="text"
              name="change"
              ngDefaultControl
              [(ngModel)]="change"
              [readonly]="true"
            />
          </div>
        </div>
        <div class="dialog-buttons">
          <div *ngFor="let payment of payments">
            <button
              class="btn btn-block dialog-btn"
              [disabled]="payment.disabled"
              *ngIf="payment.dialogId"
              data-dismiss="modal"
              data-toggle="modal"
              [attr.data-target]="payment.dialogId"
              [ngClass]="{ 'green-btn': payment.name === 'BILL' }"
            >
              <img
                *ngIf="payment.name === 'BILL'"
                src="assets/icons/fature.svg"
                alt="Pro Invoice Icon"
              />
              {{ payment.name | translate }}
            </button>
            <button
              class="btn btn-block dialog-btn"
              [disabled]="payment.disabled"
              *ngIf="!payment.dialogId"
              data-dismiss="modal"
              (click)="registerSale(payment.type)"
              [ngClass]="{
                'blue-btn': payment.name === 'PRO_INOVICE',
                'yellow-btn': payment.name === 'RESERVED',
                'orange-btn': payment.name === 'DELIVERY_NOTE',
                'red-btn': payment.name === 'CANCEL'
              }"
            >
              <img
                *ngIf="payment.name === 'PRO_INOVICE'"
                src="assets/icons/Profakture11.svg"
                alt="Pro Invoice Icon"
              />
              <img
                *ngIf="payment.name === 'RESERVED'"
                src="assets/icons/reserve.svg"
                alt="Reserved Icon"
              />
              <img
                *ngIf="payment.name === 'DELIVERY_NOTE'"
                src="assets/icons/delivery_note.svg"
                alt="Delivery Note Icon"
              />
              <img
                *ngIf="payment.name === 'CANCEL'"
                src="assets/icons/canceled.svg"
                alt="Cancel Icon"
              />
              {{ payment.name | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="PaymentPublish"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" style="max-width: 650px" role="document">
    <div class="modal-content">
      <div class="modal-header mt-3">
        <div class="col-md-6">
          <span class="font-weight-bold">{{ "PAY" | translate }}</span>
        </div>
        <div class="col-md-6">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <!-- <span aria-hidden="true">×</span> -->
            <img
              src="../../../assets/images/menu close.svg"
              class="closeModal"
              alt=""
            />
          </button>
        </div>
      </div>
      <div class="modal-body">
        <form [formGroup]="paymentInvoice">
          <div class="row col-md-12 col-sm-12 m-0 mt-4 mb-4">
            <div class="col-md-6 col-sm-6">
              <div class="form-group">
                <label>{{ "DEADLINE" | translate }}</label>

                <mat-form-field
                  appearance="outline"
                  class="w-100 date-deadline"
                >
                  <input
                    matInput
                    [min]="todayDate"
                    [matDatepicker]="deadline"
                    [datePickerFormat]="configs?.settings?.dateFormat"
                    formControlName="deadline"
                    (dateChange)="daysToDate($event.target.value)"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="deadline"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #deadline></mat-datepicker>
                </mat-form-field>
              </div>
              <div>
                <input
                  type="checkbox"
                  formControlName="status"
                  [checked]="
                    paymentInvoice.controls.deadline.value ? true : false
                  "
                />
                <label mt-2 class="payLabel">{{ "UNPAID" | translate }}</label>
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="form-group">
                <label>{{ "DAY" | translate }}</label>
                <input
                  type="number"
                  autocomplete="off"
                  class="form-control inputsDay"
                  formControlName="days"
                  min="0"
                  (keypress)="posNumbersOnly($event)"
                  (input)="addDays($event.target.value)"
                />
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary mr-3 px-5 saleButton"
          data-dismiss="modal"
          (click)="registerSale(1)"
        >
          <span>
            {{
              !paymentInvoice.controls.status.value
                ? ("PAY" | translate)
                : ("SAVE" | translate)
            }}
          </span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal Fiscal Debt -->
<div
  class="modal fade"
  id="fiscalDebt"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" style="max-width: 650px" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="col-md-6">
          <span>{{ "PAY" | translate }}</span>
        </div>
        <div class="col-md-6">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </div>
      <div class="modal-body">
        <form [formGroup]="paymentInvoice">
          <div class="row col-md-12 col-sm-12 m-0">
            <div class="col-md-6 col-sm-6">
              <div class="form-group">
                <label>{{ "PAY" | translate }}</label>
                <input
                  type="number"
                  autocomplete="off"
                  class="form-control"
                  formControlName="totalPaid"
                  min="0"
                />
              </div>
            </div>

            <div class="col-md-6 col-sm-6">
              <div class="form-group">
                <label>{{ "PERSON_WHO_PAID" | translate }}</label>
                <input
                  autocomplete="off"
                  class="form-control"
                  formControlName="personWhoPaid"
                />
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="registerSale(5, 'Fiscal')"
        >
          <span>
            {{ "SAVE" | translate }}
          </span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal Fiscal invoice -->
<div
  class="modal fade"
  id="invoiceDebt"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" style="max-width: 650px" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="col-md-6">
          <span>{{ "PAY" | translate }}</span>
        </div>
        <div class="col-md-6">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </div>
      <div class="modal-body">
        <form [formGroup]="paymentInvoice">
          <div class="row col-md-12 col-sm-12 m-0">
            <div class="col-md-6 col-sm-6">
              <div class="form-group">
                <label>{{ "DEADLINE" | translate }}</label>

                <mat-form-field>
                  <input
                    matInput
                    [min]="todayDate"
                    [matDatepicker]="deadlineFiscalInvoice"
                    [datePickerFormat]="configs?.settings?.dateFormat"
                    formControlName="deadline"
                    (dateChange)="daysToDate($event.target.value)"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="deadlineFiscalInvoice"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #deadlineFiscalInvoice></mat-datepicker>
                </mat-form-field>
              </div>
              <div>
                <input type="checkbox" checked disabled />
                <label>{{ "UNPAID" | translate }}</label>
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="form-group">
                <label>{{ "DAY" | translate }}</label>
                <input
                  type="number"
                  autocomplete="off"
                  class="form-control"
                  formControlName="days"
                  min="0"
                  (keypress)="posNumbersOnly($event)"
                  (input)="addDays($event.target.value)"
                />
              </div>
            </div>

            <div class="col-md-6 col-sm-6">
              <div class="form-group">
                <label>{{ "PAY" | translate }}</label>
                <input
                  type="number"
                  autocomplete="off"
                  class="form-control"
                  formControlName="totalPaid"
                  min="0"
                />
              </div>
            </div>

            <div class="col-md-6 col-sm-6">
              <div class="form-group">
                <label>{{ "PERSON_WHO_PAID" | translate }}</label>
                <input
                  autocomplete="off"
                  class="form-control"
                  formControlName="personWhoPaid"
                />
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <button
          [disabled]="!paymentInvoice.controls.days.value"
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="registerSale(5, 'Invoice')"
        >
          <span>
            {{ "SAVE" | translate }}
          </span>
        </button>
      </div>
    </div>
  </div>
</div>
