<div *ngIf="data.isCashIn">
    <div class="modal-header">
        {{ 'CASH_IN' | translate }}
    </div>
    <form [formGroup]="cashInForm" (ngSubmit)="closeDialog()">
        <div class="modal-body">
            <input id="cashIn" type="text" class="form-control" formControlName="cashIn">
        </div>
        <div class="modal-footer">
            <button class="btn btn-light" mat-dialog-close>{{'CLOSE' | translate}}</button>
            <button class="btn btn-primary">CashIn</button>
        </div>
    </form>  
</div>

<div *ngIf="!data.isCashIn">
    <div class="modal-header"></div>
    <form [formGroup]="periodicDateForm" (ngSubmit)="closeDialog()">
        <div class="modal-body">
            <div class="form-group">
                <label for="">{{'DATE_FROM' | translate}}</label>
                <input type="date" class="form-control" formControlName="periodicDateFrom">
            </div>  
            <div class="form-group">
                <label for="">{{'DATE_TO' | translate}}</label>
                <input type="date" class="form-control" formControlName="periodicDateTo">
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-light" mat-dialog-close>{{'CLOSE' | translate}}</button>
            <button class="btn btn-primary" >{{'PRINT' | translate}}</button>
        </div>
    </form>
</div>
 