import { Component, OnInit, Output } from '@angular/core';
import { DataService } from './data.service';
import { TranslateService } from '@ngx-translate/core';
import { OfflineCrud } from './services/offline-crud.service';
import { ConfigsService } from './services/configs.service';
import { IConfigs } from './interfaces/IConfigs';
import { SignalrService } from './services/signalr.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  constructor(
    private configsService: ConfigsService,
    private api: DataService,
    private translate: TranslateService,
    private offlineCrud: OfflineCrud,
    private signalrService: SignalrService
  ) {
    translate.addLangs(['sq', 'mk']);

  }
  title = 'POS';
  isUserLoggedIn;
  ngOnInit() {
    if (!this.signalrService.connected$.getValue()) {
      this.signalrService.startConnection();
      this.signalrService.recieveMessage('RecieveMessage').subscribe(({user, message}) => {
        this.signalrService.createFiscal(message);
      })
    }
    this.getConfigs();
  }

  getConfigs(): void {
    this.configsService.getConfigs().subscribe(
      (res: IConfigs) => {
        this.offlineCrud.addOrUpdateConfigs(res);
      },
      (err) => {
        console.log(err);
      }
    );
  }


}
