
<div class="my-auto text-center mainBG" >
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-10   my-auto p-0  mx-auto my-auto loginBG">
    <div class="col-12 radius50 p-0 d-flex logoHeader text-center mx-auto justify-content-center">
      <div class="col-md-4 my-auto mx-auto">
        <img src="assets/images/conome-logo.png" class="logo img-fluid" >
      </div>
    </div>
    <div class="col-sm-11 col-md-11 col-lg-11 col-xl-9 mx-auto">
      <label class="text-center pt-3 spanText">{{waiterObject.username}}</label>
     
     
      <form class="form  mx-auto pt-3 pl-3 pr-3 justify-content-center ">
        <!-- <div class="form-group ">
          <input type="password"
                 class="form-control inputClass text-center my-auto"
                 [(ngModel)]="userData.password"
                 name="password" #password id="password"
                 maxlength="4"
                 [maxLength]="4"
                  
                 autofocus>
                 <button type="button" class="btn btn-outline-secondary" ngbTooltip={{waiterObject?.passwordHint}} triggers="click:blur">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                  </svg>
                </button>
        </div> -->

        <div class="input-group flex-nowrap">
          
          <input id="pinInput" type="password"
          class="form-control inputClass text-center my-auto"
          [(ngModel)]="userData.password"
          name="password" #password id="password"
          maxlength="4"
          [maxLength]="4"
          autofocus
          placeholder="PIN" aria-label="Username" 
           aria-describedby="addon-wrapping">
          <span style="border-radius:0; "id="addon-wrapping">
            <button  type="button" id="pwHintButton"  class="btn " ngbTooltip={{waiterObject?.passwordHint}} triggers="click:blur">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
              </svg>
            </button>
          </span>
        </div>
      
        

<div>


</div>

        
        
      </form>
    </div>
    <div class="col-sm-11 col-md-11 col-lg-11 col-xl-9 pb-4   btn-group-vertical align-items-center mx-auto my-auto">
      <div class="btn-group justify-content-center ">
        <div class="col-md-4  p-3">
          <button class="btn numpad btn-block" value="1"  (click)="type(1)">1</button>
        </div>
        <div class="col-md-4 p-3 ">
          <button class="btn numpad btn-block" value="2" (click)="type(2)">2</button>
        </div>
        <div class="col-md-4 p-3 ">
          <button class="btn numpad btn-block" value="3" (click)="type(3)">3</button>
        </div>
      </div>
      <div class="btn-group justify-content-center">
        <div class="col-md-4 p-3">
          <button class="btn numpad btn-block" value="4" (click)="type(4)">4</button>
        </div>
        <div class="col-md-4 p-3">
          <button class="btn numpad btn-block" value="5"  (click)="type(5)">5</button>
        </div>
        <div class="col-md-4 p-3">
          <button class="btn numpad btn-block" value="6"  (click)="type(6)">6</button>
        </div>
      </div>
      <div class="btn-group justify-content-center">
        <div class="col-md-4 p-3">
          <button class="btn numpad btn-block" value="7"  (click)="type(7)">7</button>
        </div>
        <div class="col-md-4 p-3">
          <button class="btn numpad btn-block" value="8" (click)="type(8)">8</button>
        </div>
        <div class="col-md-4 p-3">
          <button class="btn numpad btn-block" value="9" (click)="type(9)">9</button>
        </div>
      </div>
      <div class="btn-group justify-content-center">
        <div class="col-md-4 p-3">
          <button class=" btn numpadX btn-block "   (click)="resetInput()">X</button>
        </div>
        <div class="col-md-4 p-3">
          <button class="btn numpad btn-block" value="0"  (click)="type(0)">0</button>
        </div>
        <div class="col-md-4 p-3">
          <button class="btn btnEnter active btn-block" value="Enter" (click)="loginPin(userData.password)"><span> > </span></button>
        </div>
      </div>
    </div>
  </div>
</div>
