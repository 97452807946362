import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { ISale } from '../interfaces/ISale';
import { DataService } from '../data.service';
import { ITableStatus } from '../interfaces/ITableStatus';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { IWaiters } from '../interfaces/IWaiters';
import { IWaiterAuthObject } from '../interfaces/IWaiterAuthObject';
import { IConfigs } from '../interfaces/IConfigs';

class PosDatabase extends Dexie {
  public Sales: Dexie.Table<ISale, number>;
  public Waiters: Dexie.Table<IWaiters, number>;
  public WaiterAuthObject: Dexie.Table<IWaiterAuthObject, number>;
  public Configs: Dexie.Table<IConfigs, number>;
  public constructor() {
    super('POSDatabase');
    this.version(1.1).stores({
      sales:
        '++idbId,created,modified,modUser,isDeleted,saleType,noSale,dailyID,companyID,clientID,accountantClientID,type,dateSale,total,hasReport,hasFiscal,comment,isMkChecked,isDebtChecked,status,deadline,paymentID,currencyName,CurrencyValue,saleDetails,tableId',
      waiters: '++idbId,id,username,pin',
      waiterAuthObject: '++idbId,companyID,username,waiterID,companyType,fiscalType',
      configs: '++idbId,settings,validUntil'
    });
    this.Sales = this.table('sales');
    this.Waiters = this.table('waiters');
    this.WaiterAuthObject = this.table('waiterAuthObject');
    this.Configs = this.table('configs');
  }
}

@Injectable({
  providedIn: 'root',
})
export class OfflineCrud {
  db = new PosDatabase();
  onlineSubject = new BehaviorSubject<boolean>(true);
  saleUpdated = new Subject<number>();

  constructor(private api: DataService) {
    this.handleOnlineChange(navigator.onLine);
    fromEvent(window, 'online').subscribe(() => this.handleOnlineChange(true));
    fromEvent(window, 'offline').subscribe(() =>
      this.handleOnlineChange(false)
    );
  }

  handleOnlineChange(online: boolean) {
    if (online !== this.onlineSubject.getValue()) {
      this.onlineSubject.next(online);
    }
  }

  public isOnline(): boolean {
    return navigator.onLine;
  }

  getOfflineConfigs(): Promise<IConfigs> {
    return new Promise((resolve, reject) => {
      this.db
        .transaction('rw', this.db.Configs, async () => {
          const configs = (await this.db.Configs.toArray())[0];
          resolve(configs);
        })
        .catch((e) => {
          reject();
          console.log('Error getOfflineConfigs ', String(e));
        });
    });
  }
  addOrUpdateConfigs(configs: IConfigs) {
    this.db
      .transaction('rw', this.db.Configs, async () => {
          this.db.Configs.update(1, configs).then(totalUpdated => {
            if (totalUpdated === 0) {
              this.db.Configs.add(configs);
            }
          });
      })
      .catch((e) => {
        console.log('Error addConfigs ', String(e));
      });
  }

  addSaleToLocalDB(sale: ISale): Promise<ISale> {
    return new Promise((resolve, reject) => {
      this.db
      .transaction('rw', this.db.Sales, async () => {
          await this.db.Sales.add(sale);
          resolve(sale);
      })
      .catch((e) => {
        reject(e);
        console.log('Error addSaleToLocalDB ', String(e));
      });
    });
  }

  // v2 Fast sales POS without tables and tableStutauses
    getSalesFromLocalDb(): Promise<ISale[]> {
    return new Promise((resolve, reject) => {
      this.db
        .transaction('rw', this.db.Sales, async () => {
          var sales = await this.db.Sales.toArray();
          resolve(sales);
        })
        .catch((e) => {
          reject();
          console.log('Error getSalesFromLocalDb ', String(e));
        });
    });
  }

   countSales(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.db
        .transaction('rw', this.db.Sales, async () => {
          resolve(this.db.Sales.count());
        })
        .catch((e) => {
          reject();
          console.log('Error countSales ', String(e));
        });
    });
  }
  // v1 This was used in POS with tables 
  // getSalesFromLocalDb(tableId): Promise<ISale> {
  //   return new Promise((resolve, reject) => {
  //     this.db
  //       .transaction('rw', this.db.Sales, async () => {
  //         var sale = this.db.Sales.where('tableId').equals(tableId).first();
  //         resolve(sale);
  //       })
  //       .catch((e) => {
  //         reject();
  //         console.log('Error getSalesFromLocalDb ', String(e));
  //       });
  //   });
  // }

  // Updated by B4M
  syncSalesWithServer(): Promise<boolean> {
    return new Promise((resolve, reject) => {
    this.db
      .transaction('rw', this.db.Sales, async () => { 
        var localDbSales = await this.db.Sales.toArray();
        // v2 bcs of performance bug
        // if there are more than 20 sales in local db send only first 20 sales 
        if(localDbSales.length > 20){
          localDbSales = localDbSales.slice(0, 20);
        }
        this.api.syncSales(localDbSales).subscribe(() => {
          // Remove synces sales from local DB
          this.clearSales(localDbSales.map(x => Number(x.idbId)));
          resolve(true);
       }, err => {
         reject(false);
       })
      });
    });
  }

  clearSales(ids: number[]): void {
    this.db.transaction('rw', this.db.Sales, async () => {
      for(const idbId of ids) {
        await this.db.Sales.where("idbId").equals(idbId).delete();
      }
    });
  }

  // syncSalesWithServer(tableId: number) {
  //     this.db
  //     .transaction('rw', this.db.Sales, this.db.TableStatus, async () => {
  //       const sale = await this.db.Sales.where('tableId').equals(tableId).first();
  //       const tableStatus = await this.db.TableStatus.where('tableID').equals(tableId).first();;
  //       // if sale exists then update sale
  //       if (sale) {
  //         if (tableStatus && tableStatus.saleID) {
  //           sale.tableStatus = tableStatus;
  //           // Check if server and local sales are same else update sale
  //           this.api.getSaleById(tableStatus.saleID).subscribe((serverSale: ISale) => {
  //             if (serverSale[0].saleDetails.length !== sale.saleDetails.length) {
  //               this.updateSale(sale, tableStatus, tableId);
  //             }
  //             else {
  //               let i = sale.saleDetails.length - 1;
  //               while ( i >= 0) {
  //                 // tslint:disable-next-line:max-line-length
  //                 if (JSON.stringify(new SaleDetailsModel(serverSale[0].saleDetails[i] as any)) !== JSON.stringify(new SaleDetailsModel(sale.saleDetails[i] as any)))
  //                   {
  //                     this.updateSale(sale, tableStatus, tableId);
  //                     return;
  //                   }
  //                 i--;
  //               }
  //             }
  //           });
  //         } else {
  //           // create sale and add table status
  //        this.api
  //             .registerSale(sale, tableId, tableStatus)
  //             .subscribe((response: { tableStatus: ITableStatus; message: string }) => {
  //               this.addTableStatusToLocalDB(response.tableStatus, tableId);
  //             });
  //         }
  //       }
  //     })
  //     .catch((e) => {
  //       console.log('Error syncSalesWithServer', String(e));
  //     });
  // }

  getWaitersFromLocalDb() {
    return new Promise((resolve, reject) => {
      this.db
        .transaction('rw', this.db.Waiters, async () => {
          var waiters = this.db.Waiters.toArray();
          resolve(waiters);
        })
        .catch((e) => {
          reject();
          console.log('Error getWaitersFromLocalDb ', String(e));
        });
    });
  }

  syncWaitersWithLocalDb(waiters: IWaiters[]) {
    this.db
    .transaction('rw', this.db.Waiters, async() => {
      this.db.Waiters.clear();
      this.db.Waiters.bulkAdd(waiters);
    }).catch((err) => {
      console.log("syncWaitersWithLocalDb ", String(err));
    });
  }

  getWaiterAuthObject() {
    return new Promise((resolve, reject) => {
      this.db
        .transaction('rw', this.db.WaiterAuthObject, async () => {
          var waiterAuthObject = this.db.WaiterAuthObject.toArray();
          resolve(waiterAuthObject);
        })
        .catch((e) => {
          reject();
          console.log('Error  getWaiterAuthObject', String(e));
        });
    });
  }

  syncWaiterAuthObject(waiterAuthObject: IWaiterAuthObject) {
    this.db
    .transaction('rw', this.db.WaiterAuthObject, async() => {
      this.db.WaiterAuthObject.clear();
      this.db.WaiterAuthObject.add(waiterAuthObject);
    }).catch((err) => {
     console.log("syncWaiterAuthObject ", String(err));
    });
  }

}
