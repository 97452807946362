<app-navbar [showSearchInput]="false"></app-navbar>

<div id="content">
  <section class="shadow-body">
    <mat-tab-group class="label1" (selectedTabChange)="tabChanged($event)" [selectedIndex]="selectedTabIndex">
      <mat-tab class="all-debts" label="{{'BILL' | translate}}">
        <div class="bill-mat-tab border-none">

          <button class="m-2 btn btn-outline-primary saleButton" [ngClass]="{'active' : filterBy === undefined }" (click)="filterSales(undefined)" >{{'ALL' | translate}}</button>
          <button class="m-2 btn btn-outline-primary saleButton" [ngClass]="{'active' : filterBy === true }" (click)="filterSales(true)" >{{'PAID' | translate}}</button>
          <button class="m-2 btn btn-outline-primary saleButton" [ngClass]="{'active' : filterBy === false }" (click)="filterSales(false)" >{{'UNPAID' | translate}}</button>

            <app-data-tables *ngIf="!isLoading" [filterBy]="filterBy" (currentSaleUpdate)="setDataSource($event)" (getCurrentSale)="setCurrentSale($event)" [apiCall]="apiCall" [allColumns]="allColumns" [displayedColumns]="displayedColumns"></app-data-tables>
            
        </div>
      </mat-tab>
      <mat-tab label="{{'CUSTOMER' | translate}}">
        <div class="bill-mat-tab border-none">
          <app-data-tables 
          *ngIf="!isLoading" 
          (currentSaleUpdate)="setDataSource($event)" 
          (getCurrentSale)="setCurrentSale($event)" 
          [apiCall]="apiCallClient" 
          [allColumns]="allColumnsClient"></app-data-tables>
        </div>
      </mat-tab>
      <br>
    </mat-tab-group>
  </section>
</div>


<div class="modal fade" id="saleDebtDetailsModal" #saleDebtDetailsModal backDrop tabindex="-1" role="dialog"
  aria-labelledby="saleDebtDetailsModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="saleDebtDetailsModalLabeL"></h5>
        <div class="modal-buttons">
          <div class="invoice">
            <button (click)="createSaleReport(saleId)" class="invoice-button details-button">{{"CREATE_INVOICE" |
              translate}}</button>
          </div>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="markControlsAsUntouched()">
          <img src="../../../assets/images/x-inactive-new.png" class="closeIcon">
        </button>
      </div>
      <div class="modal-body p-4">

        <table class="table table-striped" *ngIf="saleDebts?.length > 0">
          <div class="col p-0  border rounded-lg fontArial tableStyle">
            <thead>
              <tr>
                <th>{{'TOTAL_DEBT' | translate }}</th>
                <th>{{'TOTAL_PAID' | translate }}</th>
                <th>{{'TOTAL_DEBT_LEFT' | translate }}</th>
                <th>{{'PAID_DEBT' | translate }}</th>
                <th>{{'NAME' | translate }}</th>
                <th>{{'PERSON_WHO_PAID' | translate }}</th>
                <th>{{'DATE' | translate }}</th>
                <th *ngIf="saleDebtEditRole"></th>
              </tr>
            </thead>
            <tbody *ngFor="let saleDebt of saleDebts;last as isLast">
              <tr>
                <td>{{saleDebt?.totalDebt}}</td>
                <td>{{saleDebt?.totalPaid}}</td>
                <td>{{saleDebt?.totalLeft?.toFixed(2)}}</td>
                <td>{{saleDebt?.amountPaid}}</td>
                <td>{{saleDebt?.name}}</td>
                <td>{{saleDebt?.personWhoPaid}}</td>
                <td>{{saleDebt?.date | date: 'dd/MM/yyyy hh:mm:ss'}}</td>
                <td *ngIf="saleDebtEditRole" class="text-center modifyButton" data-toggle="modal"
                  data-target="#modifySaleDebtDetailsModal">
                  <span *ngIf="isLast">
                    {{'EDIT' | translate }}
                  </span>
                </td>
              </tr>
            </tbody>
          </div>
        </table>


        <form [formGroup]="saleDebtForm" (ngSubmit)="addSaleDebt(saleDebtId)">
          <div class="d-flex justify-content-between align-items-center mt-3 mr-5"
            *ngIf="!debtPaid && saleDebtCreateRole">
            <div class="row paidForm">
              <div class="d-flex flex-column saledebt-input col-sm-12 col-md-4">
                  <label>{{'PAID_DEBT' | translate }}:</label>
                  <input type="number"
                      [ngClass]="{'notValid': saleDebtForm.controls['totalPaid'].invalid && saleDebtForm.controls['totalPaid'].touched}"
                      inputmode="numeric" formControlName="totalPaid" class="saledebt-input-style">
              </div>
              <div class="d-flex flex-column saledebt-input col-sm-12 col-md-4">
                  <label>{{'PERSON_WHO_PAID' | translate }}:</label>
                  <input type="text"
                      [ngClass]="{'notValid': saleDebtForm.controls['personWhoPaid'].invalid && saleDebtForm.controls['personWhoPaid'].touched}"
                      formControlName="personWhoPaid" class="saledebt-input-style">
              </div>
              <div class="d-flex flex-column saledebt-input col-sm-12 col-md-4">
                  <label>{{ 'DATE' | translate }}:</label>
                  <input type="date" formControlName="date" class="saledebt-input-style">
              </div>
              <div class="d-flex justify-content-end col-sm-12 col-md-12">
                <div class="form-check pt-3 col-md-3">
                  <label class="container">
                    <input class="form-check-input" [checked]="isPayAllChecked" type="checkbox" id="payAll"
                      (change)="payAllChecked($event.target.checked)" />
                    <span class="checkmark"></span>
                    <label class="form-check-label pt-1" for="payAll">
                      {{ 'PAY' | translate }} {{ 'ALL' | translate | lowercase }}
                    </label>
                  </label>
                </div>
                <button class="invoice-button details-button h-50 mt-4">{{ 'ADD' | translate }}</button>
              </div>
          </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modifySaleDebtDetailsModal" tabindex="-1" role="dialog"
  aria-labelledby="modifySaleDebtDetailsModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modifySaleDebtDetailsModalLabel"></h5>
        <div class="modal-buttons">

          <div class="invoice">
            <p>{{"EDIT" | translate}}</p>
          </div>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <img src="../../../assets/images/x-inactive-new.png" class="closeIcon">
        </button>
      </div>
      <div class="modal-body p-4">
        <form [formGroup]="editSaleDebtForm">
          <div class="d-flex justify-content-between align-items-center mt-3 mr-5">
            <div class="row paidForm">
            <div class="d-flex flex-column saledebt-input col-sm-12 col-md-3">
              <label>{{'PAID_DEBT' | translate }}:</label>
              <input type="number" [value]="editSaleDebtForm.value.totalPaid"
                [ngClass]="{'notValid': editSaleDebtForm.controls['totalPaid'].invalid && editSaleDebtForm.controls['totalPaid'].touched}"
                required inputmode="numeric" formControlName="totalPaid" class="saledebt-input-style">
            </div>
            <div class="d-flex flex-column saledebt-input col-sm-12 col-md-4">
              <label>{{'PERSON_WHO_PAID' | translate }}:</label>
              <input type="text" [value]="editSaleDebtForm.value.personWhoPaid"
                [ngClass]="{'notValid': editSaleDebtForm.controls['personWhoPaid'].invalid && editSaleDebtForm.controls['personWhoPaid'].touched}"
                required formControlName="personWhoPaid" class="saledebt-input-style">
            </div>
            <div class="d-flex flex-column saledebt-input col-sm-12 col-md-3">
              <label>{{ 'DATE' | translate }}:</label>
              <input type="date" formControlName="date" class="saledebt-input-style">
            </div> 
            <div class="col-sm-12 col-md-2">
              <button class="invoice-button details-button h-50 mt-4" (click)="editSaleDebt(saleDebtDetailId)"
                data-dismiss="modal">{{'ADD' | translate }}</button>
            </div>
          </div>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="clientDetailsModal" backDrop tabindex="-1" role="dialog"
  aria-labelledby="clientDetailsModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="clientDetailsModalLabeL"></h5>
        <div class="modal-buttons">
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <img src="../../../assets/images/x-inactive-new.png" class="closeIcon">
        </button>
      </div>
      <div class="modal-body p-4">

        <div class="col p-0  border rounded-lg fontArial clientTable" *ngIf="clientData?.clientDebts.length > 0">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>{{'PAID_DEBT' | translate }}</th>
                <th>{{'NAME' | translate }}</th>
                <th>{{'DATE' | translate }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody *ngFor="let clientDebt of clientData?.clientDebts;last as isLast">
              <tr>
                <td>{{clientDebt?.totalAmount}}</td>
                <td>{{clientDebt?.name}}</td>
                <td>{{clientDebt?.created | date: 'dd/MM/yyyy hh:mm:ss'}}</td>
                <td class="text-center deleteButton"
                  (click)="deleteClientModal(clientDebt.id);$event.stopPropagation()">{{'Delete' | translate }}</td>
              </tr>
            </tbody>
          </table>
        </div>


        <form [formGroup]="clientDebtForm" (ngSubmit)="addClientDebt(clientDebtId)">
          <div class="d-flex justify-content-between align-items-center mt-3 mr-5"
            *ngIf="!debtPaid && saleDebtCreateRole">
            <div class="row">
              <div class="d-flex flex-column clientdebt-input col-sm-12 col-md-3">
                <label>{{'PAID_DEBT' | translate }}:</label>
                <input type="number" inputmode="numeric" formControlName="totalPaid" class="saledebt-input-style">
              </div>
              <div class="d-flex flex-column clientdebt-input col-sm-12 col-md-3">
                <label>{{'PERSON_WHO_PAID' | translate }}:</label>
                <input type="text" formControlName="personWhoPaid" class="saledebt-input-style">
              </div>
              <div class="d-flex flex-column clientdebt-input col-sm-12 col-md-3">
                <label>{{'ACCOUNT' | translate }}:</label>
                <input type="text" formControlName="account" class="saledebt-input-style">
              </div>
              <div class="d-flex flex-column clientdebt-input col-sm-12 col-md-3">
                <label>{{ 'DATE' | translate }}:</label>
                <input type="date" formControlName="date" class="saledebt-input-style">
              </div> 
              <div class="d-flex justify-content-end col-sm-12 col-md-12">
                <div class="form-check pt-3 col-md-3">
                  <label class="container">
                    <input class="form-check-input" [checked]="isPayAllCheckedClient" type="checkbox" id="payAllClient"
                      (change)="payAllCheckedClient($event.target.checked)" />
                    <span class="checkmark"></span>
                    <label class="form-check-label pt-1" for="payAllClient">
                      {{ 'PAY' | translate }} {{ 'ALL' | translate | lowercase }}
                    </label>
                  </label>
                </div>
                <button class="invoice-button details-button h-50 mt-4">{{'ADD' | translate }}</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
