import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appSelectButton]'
})
export class SelectButtonDirective {

  constructor(private el: ElementRef) { }

  @HostListener('click', ['$event'])
  onClick(){
    const prevSelectedBtn = document.getElementsByClassName('selectedButton');
    if(prevSelectedBtn.length > 0){
      prevSelectedBtn[0].classList.remove('selectedButton');
    }
    this.el.nativeElement.classList.add('selectedButton')
  }
}
