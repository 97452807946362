import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { Client } from 'src/app/interfaces/IClient';
import { ClientSelectedType } from 'src/app/types/ClientSelectedType';

@Component({
  selector: 'app-client-autocomplete-input',
  templateUrl: './client-autocomplete-input.component.html',
  styleUrls: ['./client-autocomplete-input.component.css']
})
export class ClientAutocompleteInputComponent implements OnInit, OnChanges {
  clientSearchInput = new FormControl();
  filteredClients: Observable<Client[]>;
  @Input() value: Client = null;
  @Input() clients: Client[] = [];
  @Input() disabled: boolean;
  @Input() wholesale:boolean;
  @Output() onSelectedClientChange = new EventEmitter<ClientSelectedType>();
  @Output() onRemovedLoyaltyCard = new EventEmitter<any>();
  @Output() onLoyaltyCardChange = new EventEmitter<ClientSelectedType>();

  
  ngOnInit(): void {
    this.onUserInput();
  }

  ngOnChanges(changes: SimpleChanges): void {
      if ('clients' in changes) {
        this.onUserInput();
      }
      if ('disabled' in changes) {
        this.disabled ? this.clientSearchInput.disable() : this.clientSearchInput.enable();
      }
      if ('value' in changes) {
        this.clientSearchInput.setValue(this.value);
      }
  }
  

  onUserInput(): void {
    this.filteredClients = this.clientSearchInput.valueChanges.pipe(debounceTime(200)).pipe(
      startWith(''),
      map((value: any) => {
       
        return this.filterClients(value)
      }));
  }
  // private filterClients(value: string): Client[] {
  //   const filterValue = value?.toString()?.toLowerCase();
  //       var clients: Client[] = this.clients.filter(client => client.nameSQ.toLowerCase().indexOf(filterValue) === 0);
  //   if (clients.length === 0) {
  //     clients = this.clients?.filter(client => client?.code !== null && client?.code?.indexOf(filterValue) === 0);
  //   }

  //   console.log('klient', clients)

  //       return clients;
  // }

  // private filterClients(value: string): Client[] {
  //   if(value == undefined || typeof value === 'object' ){
  //     value = ""
  //   }
  //   const filterValue = value?.toString()?.toLowerCase();
  //   let clients: Client[] = this.clients.filter(client => 
  //       client.nameSQ.toLowerCase().includes(filterValue) ||
  //       client.loyaltyCards?.some((card:any) =>
  //           card.isActive && card.code.toLowerCase().includes(filterValue)
  //       )
  //   );
  //   console.log(clients)
  //   if(value != ""){
  //   clients.forEach((client: any) => {
  //     if (client.loyaltyCards) {
  //         client.loyaltyCards = client.loyaltyCards.filter(card =>
  //             card.isActive && card.code.toLowerCase().includes(filterValue)
  //         );
  //     }
  //   });
  // }
  //   console.log(clients)

  //   if(value?.length == 5){
  //     var client = clients[0];
  //     var clientAndCard = { ...client, ...client.loyaltyCards[0] };
  //     delete clientAndCard.loyaltyCards;

  //      this.onSelectedClientChange.emit(clientAndCard)
  //   }
  //   return clients;
  // }

  private filterClients(value: string): Client[] {
        if (value == undefined || typeof value === 'object') {
        value = "";
    }
    const filterValue = value?.toString()?.toLowerCase();
    let filteredClients: Client[] = [];

    if (value !== "") {
        filteredClients = this.clients.filter(client =>
            client.nameSQ.toLowerCase().includes(filterValue) ||
            (client.loyaltyCards && client.loyaltyCards.some((card: any) =>
                card.isActive && card.code.startsWith(filterValue.toLowerCase())
            ))
        );

        filteredClients.forEach((client: any) => {
            if (client.loyaltyCards) {
                client.filteredLoyaltyCards = client.loyaltyCards.filter(card =>
                    card.isActive && card.code.startsWith(filterValue.toLowerCase())
                );
            }
        });
    } else {
        filteredClients = this.clients.map(client => ({ ...client, filteredLoyaltyCards: client.loyaltyCards }));
    }


    if ( filteredClients.length == 1 && filteredClients[0].filteredLoyaltyCards.length == 1 && value!='') {
        const client = filteredClients[0];
        const clientAndCard = { ...client, ...(client.loyaltyCards && client.filteredLoyaltyCards[0]) };
        delete clientAndCard.filteredLoyaltyCards;
        delete clientAndCard.loyaltyCards;

        this.onSelectedClientChange.emit(clientAndCard);
    }
    return filteredClients;
}

  onOptionSelected(event: any, client: Client): void {
    this.onRemovedLoyaltyCard.emit(undefined)

    // Select first loyalty card by defalut
    const clientLoyaltyCard = client['loyaltyCards']
    .filter(card => card.isActive)
    .map(card => ({
      id: client['id'],
      companyID: client['companyID'],
      nameMK: client['nameMK'],
      nameSQ: client['nameSQ'],
      ...card,
      loyaltyCards: client['loyaltyCards'],
      filteredLoyaltyCards: client['filteredLoyaltyCards']
    }));

    if(this.wholesale){
      this.onSelectedClientChange.emit(client);
    }else{
      this.onSelectedClientChange.emit(clientLoyaltyCard[0]);
    }
  }

 public clearInput(): void {
    this.onSelectedClientChange.emit(undefined);
    this.filterClients('')
    this.clientSearchInput.setValue('');
  }

  getOptionText(client: Client): string {
    return client ? client.nameSQ : '';
  }

}
