<div class="content">
  <div [style.width.%]="leftWidth" class="left-panel" [ngClass]="{ 'happy-hour-active': happyHour }">
    <app-navbar showSearchInput="true" [toSyncData]="offlineSales.length" (searchTextChanged)="filterProducts($event)" (syncCompleted)="refreshClients()" (getHappyHourPrices)="getHappyHourPrices()"></app-navbar>

    <div class="col pl-1 pr-0 pb-2">
      <ul class="scroll nav nav-pills d-flex categoriesMenu list-unstyled" role="tablist" #scroll>
        <li class="nav-item">
          <a class="nav-link active d-none" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab"
            aria-controls="pills-home" aria-selected="true">Home</a>
        </li>
        <li class="nav-item p10" *ngFor="let category of categories; let i = index" >
          <a class="nav-link h-100 category text-center" id="pills-{{ category.id }}-tab" data-toggle="pill"
            href="#pills-{{ category.id }}" role="tab" aria-selected="false" (click)="categoryClick(i, category.id)">
            <div class="row mx-auto justify-content-center text-center align-self-center align-items-center cImage">
                <img [src]="getCategoryImage(category.image, i)" class="img-thumbnail border-0 p-0 bg-transparent" />
            </div>
            <span class="col p-0 m-0 mt-2">{{
              category["name" + this.translate.currentLang.toUpperCase()]
              }}</span>
          </a>
        </li>
      </ul>
    </div>

    <div class="tab-content">
      <div class="tab-pane d-none active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
        A
      </div>
      <div class="tab-pane show" *ngFor="let category of categories" id="pills-{{ category.id }}" role="tabpanel"
        (click)="editPriceId = -1">
        <div class="row pb-2 pl8" style="overflow: hidden !important" *ngIf="filteredProductCategories.length === 0">
          <div class="card productCard" data-toggle="modal" data-target="#exampleModal"
            (click)="productClick(product)"
            *ngFor="let product of category.products; let i = index">
            <!-- Badge-->
            <!-- <div class="badge-info text-center d-flex align-items-center justify-content-center">4</div> -->
            <div class="cardImage justify-content-center img-thumbnail border-0" [style.backgroundImage]="
                    'url(' + IMG_PATH + product.image + ')'
                  "></div>

            <div class="card-body p-0 pt-3 m-0">
              <h6 class="card-title m-0 productCardName">
                {{
                product["name" + this.translate.currentLang.toUpperCase()]
                }}
              </h6>
            </div>
          </div>
        </div>
          <div class="row pb-2 pl8" style="overflow: hidden !important;" *ngIf="filteredProductCategories.length > 0">
          <div class=" card  productCard    m-3"
          data-toggle="modal"
          data-target="#exampleModal"
          (click)="getProductIngridients(product);selectDefaultOption()"
          *ngFor="let product of filteredProductCategories;let i=index;">
          <!-- Badge-->
       <!-- <div class="badge-info text-center d-flex align-items-center justify-content-center">4</div> -->
       <div class=" cardImage justify-content-center img-thumbnail border-0" style="background-image: url({{IMG_PATH}}{{product.image}})">
       </div>

       <div class="card-body p-0  pt-3 m-0">
         <h6 class="card-title m-0  productCardName">{{product['name' + this.translate.currentLang.toUpperCase()]}}</h6>
       </div>
     </div>
        </div>
      </div>


    </div>

  </div>
  <div class="grabber" [style.width.%]="grabber" (mousedown)="onMouseDown($event)" (touchstart)="onTouchStart($event)"
  (touchend)="onTouchEnd($event)" (selectstart)="preventDefault($event)" (mouseup)="onMouseUp($event)"
  (mousemove)="onMouseMove($event)">
  </div>


    <div *ngIf="!isOnMobile" [style.width.%]="rightWidth" class="right-panel border" [ngStyle]="{'width':rightScreenDB+'%'}">
        <div class="col h-100 p-0">
          <div class="col w-100">
            <div class="row pt-2 pb-2">
              <div class="col-6 fontArial18 my-auto">
                {{ "CHECKOUT" | translate }}
              </div>
              <div *ngIf="showSpecialVat" class="col-2 fontArial18 my-auto"></div>
              <div *ngIf="showSpecialVat"  class="col-4 my-auto px-sm-1">
                <button class="btn btnConome active btn-block p-0 "
                [ngClass]="selectedSpecialVatForDelivery ? 'focus' : ''"
                (click)="changeStatusSpecialVatForDelivery(); enableSpecialVatForDelivery()"
                >
                  <span class="align-middle ">{{ "DELIVERY_VAT" | translate }}</span>
                </button>
              </div>
            </div>
            <div class="row diplay-name fontArial12 border-bottom pt-3 pb-3">
              <div class="col-4">{{ "NAME" | translate }}</div>
              <div class="col-2 quantity-label pl-0">{{ "QUANTITY" | translate }}</div>
              <div class="col-2 price-label pl-2">{{ "PRICE" | translate }}</div>
              <div class="col-3 p-0"><div class="edit-label">{{'EDIT' | translate}}</div></div>
            </div>

            <div class="col overflow-auto p-0" id="productDiv" >
              <div class="row pt-2 pb-2 m-0 p-0 fontarial12Black border-bottom product-item" *ngFor="let item of orderObject; let i = index">
                <div class="col-4 my-auto pl-0">
                  <div class="col p-0 my-auto" *ngIf="item.hasOwnProperty('selectedOptions')">{{ item.productName }}
                    <div class="col p-0" *ngFor="let ing of item?.selectedIngredients">
                      <span *ngIf="ing.hasExtraIngredients">Extra {{ ing.name }} {{ ing.qt }}</span>
                      <span *ngIf="!ing.hasExtraIngredients">Standard {{ ing.name }} {{ ing.qt }}</span>
                    </div>
                  </div>
                  <div class="col p-0 my-auto" *ngIf="!item.hasOwnProperty('selectedOptions')">
                    {{ item["name" + this.translate.currentLang.toUpperCase()] }}
                    <div class="col p-0" *ngFor="let ing of item?.selectedIngredients">
                      {{ ing.name }} {{ ing.qt }}
                    </div>
                  </div>
                </div>
                <div class="col-2 my-auto quantity-number">{{ item.selectedOptions.qt }}</div>
                <div class="col-2 my-auto price-number">{{ item.total.toFixed(2) }}</div>
                <div class="col-3 my-auto p-0">
                  <div class="row buttonGroup">
                    <div>
                      <button
                        class="btn icon-plus plus-button btn-block p-0"
                        (click)="editItemplus(i)"
                      ></button>
                    </div>
                    <div>
                      <button
                        class="btn icon-minus minus-button btn-block p-0"
                        (click)="editItemminus(i)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        <div class="col-12 p-0 bottomRow w-100">
          <div class="col-12 bgCheckout">
            <div class="row border pt-4 pb-4">
              <div class="col-6">
                <app-client-autocomplete-input
                  #clientAutocomplete
                  [clients]="clients"
                  [value] = "client"
                  (onSelectedClientChange)="selectedClient($event)"
                ></app-client-autocomplete-input>
                <span *ngIf="client"><app-loyaltyCard-autocomplete-input
                  #loyaltyCardAutocomplete
                  [clients]="clients"
                  [value] = "client"
                  (onLoyaltyCardChange)="selectedClient($event)"
                  (onRemovedLoyaltyCard)="removeLoyaltyCard($event)"
                ></app-loyaltyCard-autocomplete-input></span>
                

              <!-- /* Loyalty card info */ -->
                <div class="row d-flex align-items-center" *ngIf="client" >
                  <div *ngIf="client.name!='' && (client.points >= 0 || client.percentage >= 0)" class="col-7 col-sm-7 col-md-7 col-lg-7 m-0 pr-0">
                    <!-- nese ka perqindje lirim -->
                    <!-- <span *ngIf="client?.percentage != 0 && client?.percentage != null" class="d-block">{{'POINTS' | translate }}: {{ client?.points }}<br>{{'PERCENTAGE' | translate }}: {{ client?.percentage }}%</span> -->
                    <span *ngIf="checkCondition(client?.percentage)" class="d-block">
                      {{ 'POINTS' | translate }}: {{ client?.points }}<br>
                      {{ 'PERCENTAGE' | translate }}: {{ client?.percentage }}%
                    </span>
                    
                    <!-- nese ska perqindje lirim -->
                    <span *ngIf="client?.percentage == 0 || client.percentage == null" class="d-block">{{'POINTS' | translate }}: {{ client?.points }}</span>
                  </div>
                  <div class="col-5 col-sm-5 col-md-5 col-lg-5 pr-0">
                    <button
                      class="btn btn-primary"
                      *ngIf="client?.name != '' && client?.points > 0 && pointsToUse === 0 && calculationData.total > 0"
                      #points
                      (click)="useClientPoints($event)"
                    >
                      Use points
                    </button>
                    <img
                      src="/assets/images/x-inactive.svg"
                      *ngIf="pointsToUse > 0"
                      (click)="returnPoints()"
                    />
                  </div>
                </div>
              </div>
              <div class="col-6 row">
                <div class="col-6 text-right fontArial">
                  {{ "SUBTOTAL" | translate }}
                </div>
                <div class="col-6 text-left">{{ calculationData.subTotal }}</div>
                <div class="col-6 text-right fontArial12">
                  {{ "TAX" | translate }}
                </div>
                <div class="col-6 text-left">{{ calculationData.vat }}</div>
              </div>
            </div>
          </div>
          <div class="col-12 bgCheckout">
            <div class="row pt-2 pb-2 border pt-4 pb-4">
              <div class="col-8 text-right fontArial18">
                {{ "TOTAL" | translate }}
              </div>
              <div class="col-4 text-left fontArial18">
                {{ calculationData.total - pointsToUse }}
                <span class="text-danger" *ngIf="pointsToUse > 0">
                  ( - {{ pointsToUse }})
                </span>
              </div>
            </div>
          </div>
          <div class="col-12 p-0">
            <div class=" pt-0 pb-0">
              <div class="col-md-12 p-0 d-flex ">
                <div class="col-md-{{configs?.settings?.orderStatus ? '6 pl-0 pr-0' : '12 p-0'}}">
                <button class="btn btn-block payOrder"
                        [disabled]="!saleCreateRole"
                        [attr.data-target]="paymentModalVisible ? '#paymentModal' : ''"
                        data-toggle="modal" (click)="payClick()">
                  {{'PAY' | translate }}
                </button>
              </div>
              <div class="col-md-6 pr-0 pl-0" *ngIf="configs?.settings?.orderStatus">

                <button class="btn btn-block btnOrder"
                  [disabled]="!saleCreateRole"
                  [attr.data-target]="paymentModalVisible ? '#paymentModal' : ''"
                  data-toggle="modal" (click)="bookClick()"
                >
                  {{'BOOK' | translate }}
                </button>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>



<div class="modal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="dialog">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title fontArialM18" id="exampleModalLabelsq" *ngIf="currentProduct">{{currentProduct['name' + translate.currentLang.toUpperCase()]}}</span>
        <button type="button" class="close" #closeModal id="closeModal" (click)="deselect(selectOption)" data-dismiss="modal" aria-label="Close">
          <img src="assets/images/x-inactive-new.png" class="closeIcon">
        </button>
      </div>
      <div class="modal-body mPadding text-center fontArialM">
        <form>
          <div class="row p-0 m-0">
            <div class="col-12 fontArialM" *ngIf="currentProduct && currentProduct.options && currentProduct.options.length>0">
              <div class="row">
                <div class="col-5 p-0  text-left mb6">{{'SIZE' | translate }}</div>
                <div class="col-3 p-0 text-center mb6 vatprice">{{'PRICE_WITH_VAT' | translate }}</div>
                <div class="col-2 p-0 text-center mb6 percentage">%</div>
              </div>
              <div class="row pb-3">
                <div class="col-md-12" *ngFor="let option of currentProduct.options; let i=index"
                  (click)="selectedOptionID = i; setSelectedOption(option); resetPriceValues()">
                  <div class="row padding-bottom-1" #selectOption>
                    <div class="col-md-5 padding-left-0">
                      <p class="border rounded-lg h-100 custom-box"
                        [ngClass]="selectedOptionID == i ? 'active' : ''">{{option?.productName}}</p>
                    </div>
                    <div class="col-md-3 padding-left-0" *ngIf="editPriceId != i">
                      <p class="border rounded-lg h-100 custom-box" [ngClass]="selectedOptionID == i ? 'active' : ''">
                          {{(option.rebatePercentage && option.rebatePercentage > 0) ? option.sellingPriceWithRebate : option.sellingPriceWithVat}}
                      </p>
                    </div>
                    <span class="col-md-3" *ngIf="editPriceId == i && !isChanged">

                      <input type="number" [value]="(option.rebatePercentage && option.rebatePercentage > 0) ? option.sellingPriceWithRebate : option.sellingPriceWithVat" ([ngModel])="option.sellingPriceWithVat"
                        #priceWithRebate [attr.id]="option.id" (keyup)="onKeyUpRebate(option)"
                        (input)="calculateOptionPrices(option, priceWithRebate.value)"
                        class="form-control col text-center inputText  d-block p-0">
                    </span>
                    <span class="col-md-3" *ngIf="editPriceId == i && isChanged && selectedOptionID == i">
                      <input type="number" [value]="currentProduct?.selectedOptions?.sellingPriceWithRebate"
                        ([ngModel])="currentProduct.selectedOptions.sellingPriceWithRebate" #priceWithRebate
                        [attr.id]="option.id" (keyup)="onKeyUpRebate(option)"
                        (input)="calculateOptionPrices(option, priceWithRebate.value)"
                        class="form-control col text-center inputText  d-block p-0">
                    </span>
                    <span class="col-md-3" *ngIf="editPriceId == i && isChanged && selectedOptionID != i">
                   
                      <input type="number" [value]="option?.sellingPriceWithRebate"
                        ([ngModel])="currentProduct.selectedOptions.sellingPriceWithRebate"
                        class="form-control col text-center inputText  d-block p-0">
                    </span>
                    <div class="cold-md-1 padding-left-0 d-flex" [attr.for]="option.id"
                      (click)="editPriceId == i ? editPriceId = -1 : editPriceId = i; onPencilChange(option)">
                      <img #pencilImage width="20px" (click)="onChange(); " src="../../../assets/icons/pencil.svg">
                    </div>
                    <div class="col-md-3 padding-left-0" *ngIf="editPriceId != i">
                      <p class="border rounded-lg h-100 custom-box" [ngClass]="selectedOptionID == i ? 'active' : ''">
                        {{(option.rebatePercentage && option.rebatePercentage > 0) ? option.rebatePercentage : 0}}
                      </p>
                      </div>
                    <div class="col-md-3 percentage-input" *ngIf="editPriceId == i && selectedOptionID == i">

                      <input type="number" name="percentage" [value]="currentProduct?.selectedOptions?.rebatePercentage"
                        [(ngModel)]="currentProduct.selectedOptions.rebatePercentage" [min]="0" [max]="99"
                        [attr.id]="option.id" (input)="onPercentage(option)" (keyup)="onKeyUpPercentage(option)"
                        class="form-control col text-center inputText  d-block">
                    </div>
                    <div class="col-md-3 percentage-input" *ngIf="editPriceId == i && selectedOptionID != i">

                      <input type="number" name="percentage" [value]="option?.rebatePercentage"
                        [(ngModel)]="option.rebatePercentage" [min]="0" [max]="99"
                        [attr.id]="option.id" (input)="onPercentage(option)" (keyup)="onKeyUpPercentage(option)"
                        class="form-control col text-center inputText  d-block">
                    </div>
                  </div>
                </div>
                <div class="input-group">
                  <label>{{'ProductDescription' | translate}}</label>
                  <input type="text" name="productDescription" [(ngModel)]="currentProduct.productDescription" class="comment-product">
                </div>
              </div>
            </div>
            <div class="col-12 pt-3 pb-2 pr-0 pl-0 justify-content-center"
                 *ngIf="currentProduct && currentProduct.productIngredients?.length > 0">
              <button class="btn saveStyle btn-block"
                      data-toggle="modal"
                      data-target="#ingridientModal"
                      (click)="setDefaultIngredients()"
              >
                {{'INGREDIENTS' | translate}}
              </button>
            </div>
            <div class="col-12 pl-0 pr-0 justify-content-center pb-3">
              <div class="col-12 p-0 text-left mb6 ">{{'REMAINING_QUANTITY' | translate }}: {{ currentProduct?.selectedOptions?.optionQt }}</div>
              <div class="col-12 p-0 text-left mb6 ">{{'QUANTITY' | translate }}</div>
              <div class="col-12 row p-0 m-0">
                <div class="col p-0">
                  <button   type="button"
                            name="minus"
                            class="btn  btn-block  amountMinus  align-middle"
                            (click)="minusQuantity()">-
                  </button>
                </div>
                <div class="col p-0">
                  <div class="from-group  pl-2 pr-2">
                    <input type="number"
                           [(ngModel)]="currentProductQuantity"
                           [min]="1"
                           maxlength="2"
                           [ngModelOptions]="{standalone: true}"
                           id="productQuantity"
                           class="form-control col text-center inputText  d-block">
                  </div>
                </div>
                <div class="col p-0">
                  <button type="button"
                          name="plus"
                          class="btn  btn-block text-info amountPlus align-middle"
                          (click)="plusQuantity()">+</button>
                </div>
              </div>
            </div>

          </div>
          <button
            class="btn btn-block saveStyle mt-2"
            (click)="onSubmit(currentProduct.productID, currentProduct.selectedOptions.id);  calculateQuantity()"
          >
            {{ "ORDER" | translate }}
          </button>
        </form>
      </div>
    </div>
  </div>
</div>



<div class="modal " #paymentModal id="paymentModal" [hidden]="closeModalButton()" tabindex="-1" role="dialog" aria-labelledby="paymentModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="paymentModalLabel">{{'TYPE_OF_PAYMENT' | translate }}</h5>
        <h5 class="modal-title m-auto">{{ isSplit ? ('TOTAL_LEFT' | translate) + ": " + splitTotal.toFixed(2) : '' }}</h5>
        <button type="button" class="close" id="paymentModalClose" data-dismiss="modal" aria-label="Close" (click)="closeModalButton()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="payment">
          <div class="input-group payment-group">
            <label class="col-2">{{'PAYMENT' | translate}}</label>
            <div class="input-container col-6">
              <input class="payment-input" type="number" [(ngModel)]="payment" (ngModelChange)="changeEvent()" [readonly]="calculationData.total == 0">
            </div>
            <span class="input-text col-3">{{ clickedPayments.length > 0 ? (clickedPayments[0].name + ': ' + clickedPayments[0].total) : '' }}</span>
            <span class="col-1" *ngIf="clickedPayments.length > 0">
              <img
              class="edit-payment"
              (click)="editPayemnt(clickedPayments[0].id)"
              src="assets/images/edit.svg"
              />
            </span>
          </div>
          <div class="input-group payment-group">
            <label class="col-2">{{'CHANGE' | translate}}</label>
            <div class="input-container col-6">
              <input class="change-input" type="text" name="change" ngDefaultControl [(ngModel)]="change" [readonly]="true">
            </div>
              <span class="input-text col-3">{{ clickedPayments.length > 1 ? (clickedPayments[1].name + ': ' + clickedPayments[1].total) : '' }}</span>
              <span class="col-1" *ngIf="clickedPayments.length > 1">
                <img
                class="edit-payment"
                (click)="editPayemnt(clickedPayments[1].id)"
                src="assets/images/edit.svg"
                />
              </span>
          </div>
          <div class="input-group payment-group">
            <label class="col-2">{{'COMMENT' | translate}}</label>
            <div class="input-container col-6">
              <input class="payment-input" type="text" name="comment" ngDefaultControl [(ngModel)]="comment">
            </div>
              <span class="input-text col-3">{{ clickedPayments.length > 2 ? (clickedPayments[2].name + ': ' + clickedPayments[2].total) : '' }}</span>
              <span class="col-1" *ngIf="clickedPayments.length > 2">
                <img
                class="edit-payment"
                (click)="editPayemnt(clickedPayments[2].id)"
                src="assets/images/edit.svg"
                />
              </span>
          </div>
        </div>
        
        <div class="dialog-buttons">
          <div *ngFor="let payment of filteredPayments">
            <button
              [disabled]="isPaymentClicked(payment)"
              class="btn btn-block dialog-btn"
              [attr.data-dismiss]="isSplit ? '' : 'modal'"
              (click)="handlePayment(payment)"
              [ngClass]="{
                'blue-btn': payment.name.toLowerCase() === 'cash',
                'yellow-btn': payment.name.toLowerCase() === 'mastercard',
                'orange-btn': payment.name.toLowerCase() === 'visa',
                'red-btn': payment.name.toLowerCase() === 'printo'
              }"
              >
              <img
              *ngIf="payment.name.toLowerCase() === 'cash'"
              src="assets/icons/money.svg"
              />
              <img
              *ngIf="payment.name.toLowerCase() === 'mastercard'"
              src="assets/icons/mastercard.svg"
              />
              <img
              *ngIf="payment.name.toLowerCase() === 'visa'"
              src="assets/icons/visa.svg"
              />
              <img
              *ngIf="payment.name.toLowerCase() === 'printo'"
              src="assets/icons/points.svg"
              />
              {{''+payment.name+'' | translate }}
            </button>
          </div>
          <button
            class="btn btn-block dialog-btn"
            [attr.data-dismiss]="splitTotal <= 0 && isSplit ? 'modal' : ''"
            (click)="paySplitButton()"
            [ngClass]="{
              'split-btn-true': (isSplit && this.splitTotal > 0),
              'split-btn-false': !isSplit,
              'split-btn-pay': (isSplit && this.splitTotal <= 0)
            }"
            >
              {{ splitText() }}
          </button>
        </div>
      </div>
      <div class="modal-footer">

        </div>
      </div>
    </div>
  </div>


  <div class="modal " #resetLoyaltyCardModal id="resetLoyaltyCardModal"  tabindex="-1" role="dialog" aria-labelledby="resetLoyaltyCardModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{'INFO' | translate }}</h5>
          <button type="button" class="close" #closeModal1 id="closeModal1" (click)="closeModalFunction()"  data-dismiss="modal" aria-label="Close">
            <img src="assets/images/x-inactive-new.png" class="closeIcon">
          </button>
        </div>
        <div class="modal-body">
          <div>

            <div class="information">
              <p>{{'REFRESHWINDOWALERT' | translate}}</p>

            </div>
            
          </div>
          
          <div class="confirm-buttons">
            <button
              class="btn confirm-btn"
              (click)="refreshWindow()"
              >
                {{ 'OK' | translate }}
            </button>
          </div>
        </div>
 
        </div>
      </div>
    </div>

<!-- </div> -->

<div class="modal fade " id="ingridientModal" tabindex="-1" role="dialog" aria-labelledby="ingridientModalLabel" aria-hidden="true">
  <div class="modal-dialog " *ngIf="currentProduct" [ngClass]="currentProduct.options.length< 1? '': 'modal-xl'" role="document">
    <div class="modal-content ingridientModal-content" style="backdrop-filter: blur(10px); overflow-y: scroll">
      <div class="modal-header">
        <h5 class="modal-title" id="ingridientModalLabel">{{'EDIT_INGREDIENTS' | translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body  p-3">
        <div class="row" *ngIf="currentProduct && currentProduct.options.length> 0; else noOptions">
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <span>{{currentProduct.options[selectedOptionID]?.productName}} {{'INGREDIENTS' | translate}}</span>
            <div class="row" *ngFor="let ingredient of currentProduct.options[selectedOptionID]?.optionIngredients; let i = index">
              <div class="col-sm-7 col-md-6 col-xl-7 col-lg-7 p-2">
                <button
                  [ngClass]="ingredient.qt > 0? 'focus' : ''"
                  class="btn btnIngredient btn-block"

                >{{ingredient.name}}</button>
              </div>
              <div class="col-sm-5  col-md-6 col-lg-5 col-xl-5">
                <div class="row text-center justify-content-center">
                  <div class="col m-2 ingQty">{{ingredient.qt}}</div>
                  <button class="btn col m-2 btn-block  amountMinus" (click)="minusIngredient(ingredient, i )">-</button>
                  <button class="btn col m-2  btn-block amountPlus" (click)="plusIngredient(ingredient)">+</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6" *ngIf="this.currentProduct">
            <span>{{'ALL_INGREDIENTS' | translate}}</span>
            <div class="row " *ngFor="let ingredient of currentProduct.productIngredients; let i = index">
              <div class="col-sm-7 col-md-6 col-xl-7 col-lg-7 p-2" *ngIf="!hideIngredient(ingredient)">
                <button
                  [ngClass]="ingredient.qt > 0? 'focus' : ''"
                  class="btn btnIngredient  btn-block"
                >{{ingredient.name}}</button>
              </div>
              <div class="col-sm-5  col-md-6 col-lg-5 col-xl-5" *ngIf="!hideIngredient(ingredient)">
                <div class="row text-center justify-content-center">
                  <div class="col m-2 ingQty">{{ingredient.qt}}</div>
                  <button class="btn col m-2 btn-block  amountMinus" (click)="minusExtraIngredient(ingredient, i )">-</button>
                  <button class="btn col m-2 btn-block  amountPlus" (click)="plusExtraIngredient(ingredient, i )">+</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-template #noOptions>
          <div class="row" *ngIf="currentProduct && currentProduct.options.length < 1">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.currentProduct">
              <span>Gjith Përbërësit</span>
              <div class="row p-2" *ngFor="let ingredient of currentProduct.productIngredients; let i = index">
                <div class="col-sm-6 col-md-6 col-xl-6 col-lg-6 p-2">
                  <button
                    [ngClass]="ingredient.qt > 0? 'focus' : ''"
                    class="btn btnIngredient  btn-block"
                  >{{ingredient.name}}</button>
                </div>
                <div class="col-sm-6  col-md-6 col-lg-6 col-xl-6">
                  <div class="row text-center justify-content-center">
                    <div class="col m-2 ingQty">{{ingredient.qt}}</div>
                    <button
                      class="btn btn-block col m-2  amountMinus"
                      (click)="minusExtraIngredient(ingredient, i)">
                      -
                    </button>
                    <button
                      class="btn btn-block col m-2   amountPlus"
                      (click)="plusExtraIngredient(ingredient, i)">
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<div (click)="toggleStickyModal()" [style.transform]="isStickyModalOpen ? 'translateY(-80vh)' : 'translateY(0)'" *ngIf="isOnMobile" class="sticky-button">
  <button>DEN {{calculationData.total.toFixed(2)}}</button>
  <span>
    <img src="../../../assets/icons/shopping-cartbl.svg" alt="">
    <i class="cart-items-num">{{quantity}}</i>
  </span>
</div>

<div *ngIf="isOnMobile" [style.opacity]="isStickyModalOpen ? '1' : '0'" [style.height]="isStickyModalOpen ? '80%' : '0'" class="right-panel border">
  <div class="col p-0">
    <div class="col w-100">
      <div class="row pt-2 pb-2">
        <div class="col-6 fontArial18 my-auto">
          {{ "CHECKOUT" | translate }}
        </div>
        <div *ngIf="showSpecialVat" class="col-2 fontArial18 my-auto"></div>
        <div *ngIf="showSpecialVat"  class="col-4 my-auto px-sm-1">
          <button class="btn btnConome active btn-block p-0 "
          [ngClass]="selectedSpecialVatForDelivery ? 'focus' : ''"
          (click)="changeStatusSpecialVatForDelivery(); enableSpecialVatForDelivery()"
          >
            <span class="align-middle ">{{ "DELIVERY_VAT" | translate }}</span>
          </button>
        </div>
      </div>
      <div class="row diplay-name fontArial12 border-bottom pt-3 pb-3">
        <div class="col-4">{{ "NAME" | translate }}</div>
        <div class="col-2 quantity-label pl-0">{{ "QUANTITY" | translate }}</div>
        <div class="col-2 price-label pl-2">{{ "PRICE" | translate }}</div>
        <div class="col-3 p-0"><div class="edit-label">{{'EDIT' | translate}}</div></div>
      </div>

      <div class="col overflow-auto p-0" id="productDiv" >
        <div class="row pt-2 pb-2 m-0 p-0 fontarial12Black border-bottom product-item" *ngFor="let item of orderObject; let i = index">
          <div class="col-4 my-auto pl-0">
            <div class="col p-0 my-auto" *ngIf="item.hasOwnProperty('selectedOptions')">{{ item.productName }}
              <div class="col p-0" *ngFor="let ing of item?.selectedIngredients">
                <span *ngIf="ing.hasExtraIngredients">Extra {{ ing.name }} {{ ing.qt }}</span>
                <span *ngIf="!ing.hasExtraIngredients">Standard {{ ing.name }} {{ ing.qt }}</span>
              </div>
            </div>
            <div class="col p-0 my-auto" *ngIf="!item.hasOwnProperty('selectedOptions')">
              {{ item["name" + this.translate.currentLang.toUpperCase()] }}
              <div class="col p-0" *ngFor="let ing of item?.selectedIngredients">
                {{ ing.name }} {{ ing.qt }}
              </div>
            </div>
          </div>
          <div class="col-2 my-auto quantity-number">{{ item.selectedOptions.qt }}</div>
          <div class="col-2 my-auto price-number">{{ item.total.toFixed(2) }}</div>
          <div class="col-3 my-auto p-0">
            <div class="row buttonGroup">
              <div>
                <button
                  class="btn icon-plus plus-button btn-block p-0"
                  (click)="editItemplus(i); calculateQuantity()"
                ></button>
              </div>
              <div>
                <button
                  class="btn icon-minus minus-button btn-block p-0"
                  (click)="editItemminus(i); calculateQuantity()"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  <div class="col-12 p-0 bottomRow w-100">
    <div class="col-12 bgCheckout">
      <div class="row border-top pt-2">
        <div class="col-6">
                    <app-client-autocomplete-input
            #clientAutocomplete
            [clients]="clients"
            (onSelectedClientChange)="selectedClient($event)"
          ></app-client-autocomplete-input>
          <span *ngIf="client"><app-loyaltyCard-autocomplete-input
            #loyaltyCardAutocomplete
            [clients]="clients"
            [value] = "client"
            (onLoyaltyCardChange)="selectedClient($event)"
            (onRemovedLoyaltyCard)="removeLoyaltyCard($event)"
          ></app-loyaltyCard-autocomplete-input></span>
          
        </div>
        <div class="col-6 row">
          <div class="col-6 text-right fontArial">
            {{ "SUBTOTAL" | translate }}
          </div>
          <div class="col-6 text-left">{{ calculationData.subTotal }}</div>
          <div class="col-6 text-right fontArial12">
            {{ "TAX" | translate }}
          </div>
          <div class="col-6 text-left">{{ calculationData.vat }}</div>
        </div>
      </div>
        <!-- /* Loyalty card info */ -->
        <div class="row d-flex align-items-center pb-1" *ngIf="client">
          <div class="col-6 col-sm-6 col-md-6 col-lg-6  loyality-card">
            <span class="loyality-points">Points: {{ client?.points }}</span>
          <!-- </div>
          <div class="col-6 col-sm-6 col-md-6 col-lg-6"> -->
            <button class="btn btn-primary btn-sm" *ngIf=" client?.points > 0 && pointsToUse === 0 && calculationData.total > 0 " #points (click)="useClientPoints($event)" >
              Use points
            </button>
            <img src="/assets/images/x-inactive.svg" *ngIf="pointsToUse > 0" (click)="returnPoints()" />
          </div>
        </div>

    </div>
    <div class="col-12 bgCheckout">
      <div class="row pt-2 pb-2 border pt-3 pb-3">
        <div class="col-8 text-right fontArial18">
          {{ "TOTAL" | translate }}
        </div>
        <div class="col-4 text-left fontArial18">
          {{ calculationData.total - pointsToUse }}
          <span class="text-danger" *ngIf="pointsToUse > 0">
            ( - {{ pointsToUse }})
          </span>
        </div>
      </div>
    </div>
    <div class="col-12 p-0">
      <div class=" pt-0 pb-0">
        <div class="col-md-12 p-0 d-flex ">
          <div class="col-md-{{configs?.settings?.orderStatus ? '6 pl-0 pr-0' : '12 p-0'}}">
          <button class="btn btn-block payOrder"
                  [disabled]="!saleCreateRole"
                  [attr.data-target]="paymentModalVisible ? '#paymentModal' : ''"
                  data-toggle="modal" (click)="payClick(); closeStickyModal();  openPaymentModal()">
            {{'PAY' | translate }}
          </button>
        </div>
        <div class="col-md-6 pr-0 pl-0" *ngIf="configs?.settings?.orderStatus">
          <button class="btn btn-block btnOrder"
            [disabled]="!saleCreateRole"
            [attr.data-target]="paymentModalVisible ? '#paymentModal' : ''"
            data-toggle="modal" (click)="bookClick(); closeStickyModal();  openPaymentModal()">
            {{'BOOK' | translate }}
          </button>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
