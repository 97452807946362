import { IIngredient } from "../interfaces/IIngredient";
import { IOptions } from "../interfaces/IOptions";
import { IProduct } from "../interfaces/IProduct";

export class Product {
        productName: string;
        nameSQ: string;
        nameMK: string;
        productID: number;
        buyingPrice: number;
        vatID: number;
        buyingPriceWithVat: number;
        profit: number;
        price: number;
        sellingPriceWithVat: any;
        sellingPriceNoVat: any;
        unitID: number;
        categoryID: number;
        companyID: number;
        barcode: number;
        noProduct: number;
        min: number;
        rebate: number;
        notes: string;
        image: string;
        productDetailsID: number;
        isMacedonianProduct: boolean;
        productPoints: number;
        productIngredients: IIngredient[];
        options: IOptions[];
        id?: number;
        created?: string;
        modified?: string;
        modUser?: number;
        isDeleted?: boolean;
        qt?: number;
        selectedOptions?: any;
        optionName?: string;
        optionPrice?: number;
        optionID?: number;
        selectedIngredients?: any;
        chairNumber?: number;
        total?: number;
        saleIngredients?: IIngredient[];
        draftSelectedIngredients?: IIngredient[];
        appointedMinutes?: number;

        constructor(currentProduct: IProduct) {
            this.productName = currentProduct.productName;
            this.nameSQ = currentProduct.nameSQ;
            this.nameMK = currentProduct.nameMK;
            this.productID = currentProduct.productID;
            this.buyingPrice = currentProduct.buyingPrice;
            this.vatID = currentProduct.vatID;
            this.buyingPriceWithVat = currentProduct.buyingPriceWithVat;
            this.profit = currentProduct.profit;
            this.price = currentProduct.price;
            this.sellingPriceWithVat = currentProduct.sellingPriceWithVat;
            this.sellingPriceNoVat = currentProduct.sellingPriceNoVat;
            this.unitID = currentProduct.unitID;
            this.categoryID = currentProduct.categoryID;
            this.companyID = currentProduct.companyID;
            this.barcode = currentProduct.barcode;
            this.noProduct = currentProduct.noProduct;
            this.min = currentProduct.min;
            this.rebate = currentProduct.rebate;
            this.notes = currentProduct.notes;
            this.image = currentProduct.image;
            this.productDetailsID = currentProduct.productDetailsID;
            this.isMacedonianProduct = currentProduct.isMacedonianProduct;
            this.productPoints = currentProduct.productDetailsID;
            this.productIngredients = currentProduct.productIngredients || [];
            this.options = currentProduct.options;
            this.id = currentProduct.id;
            this.created = currentProduct.created;
            this.modified = currentProduct.modified;
            this.modUser = currentProduct.modUser;
            this.isDeleted = currentProduct.isDeleted;
            this.qt= currentProduct.qt;
            this.selectedOptions = currentProduct.selectedOptions;
            this.optionName = currentProduct.optionName;
            this.optionPrice = currentProduct.optionPrice;
            this.optionID = currentProduct.optionID;
            this.selectedIngredients = currentProduct.selectedIngredients;
            this.chairNumber = currentProduct.chairNumber;
            this.total = currentProduct.total;
            this.saleIngredients = currentProduct.saleIngredients || [];
            this.draftSelectedIngredients = currentProduct.draftSelectedIngredients || [];
            this.appointedMinutes = currentProduct.appointedMinutes;
        }
}