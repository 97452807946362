<form class="client-form">
  <mat-form-field class="client-form-field" appearance="outline">
    <mat-label>{{'CLIENT' | translate }}</mat-label>
    <input type="text" matInput [formControl]="clientSearchInput" [matAutocomplete]="auto">
    <img *ngIf="clientSearchInput.value && !disabled" src="/assets/images/x-inactive.svg" (click)="clearInput()">

    <mat-autocomplete #auto="matAutocomplete" [displayWith]="getOptionText" autoActiveFirstOption>
      <mat-option *ngFor="let client of filteredClients | async" [value]="client" 
        (click)="onOptionSelected($event, client)">
        {{client.nameSQ}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
