import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/data.service';
import { ISale } from 'src/app/interfaces/ISale';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IConfigs } from 'src/app/interfaces/IConfigs';
import { IWaiters } from 'src/app/interfaces/IWaiters';
import { OfflineCrud } from 'src/app/services/offline-crud.service';
import { AlertService } from 'src/app/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { SaleType } from 'src/app/Enums/SaleType';

@Component({
  selector: 'app-canceled-sales-list',
  templateUrl: './canceled-sales-list.component.html',
  styleUrls: ['./canceled-sales-list.component.scss']
})
export class CanceledSalesListComponent  {

  constructor(
    protected api: DataService,
    private translate: TranslateService,
    private alertService: AlertService,
    private offlineService: OfflineCrud
    ) { 
      this.translate.onLangChange.subscribe((event) => {
        this.getAllSales();
      });
    }

  subscriptions: Subscription[] = [];
  canceledSales: ISale[] = [];
  currentSale: ISale;
  pageSize: number;
  selectedDropdown = 10;
  paginatorSelectednumber = 1;
  pager:any = {};
  waiter: IWaiters;
  waiterId=JSON.parse(localStorage.getItem('waiterObject')).waiterID || 0;
  configs: IConfigs;
  dataSource!: any;
  isLoading = false;
  waiterRolesLoaded = false;
  hasSaleDeleteRole : boolean;
  
  displayedColumns: string[] = ['daily-id', 'date-sale', 'comment', 'total', 'status', 'created', 'modified', 'order-status', 'is-deleted'];
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.dataSource) {
      this.dataSource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
    }
  }

  ngOnInit() {
    this.getAllSales();
    this.getWaiterRoles();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.pageSize = this.dataSource.filteredData.length;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
      this.paginatorSelectednumber = 1;
      this.pager = this.getPager(this.pageSize, this.paginatorSelectednumber, this.selectedDropdown);
    }
  }

  updateReadySale(data: ISale) {
    this.getAllSales();
  }
  
  allColumns: any = [ 
    { name: 'No_Sale', resizeable: false, prop: 'No_Sale' },  
    { name: 'ORDINAL_NUMBER', resizeable: false, prop: 'ordinalNumber' },  
    { name: 'DATASALE', resizeable: false, prop: 'dateSale' },   
    { name: 'COMMENT', resizeable: false, prop: 'comment' },  
    { name: 'TOTAL', resizeable: false, prop: 'total' },  
    { name: 'STATUS', resizeable: false, prop: 'status' },  
    { name: 'CREATED', resizeable: false, prop: 'created' },  
    { name: 'MODIFIED', resizeable: false, prop: 'modified' },  
    { name: 'ORDER_STATUS', resizeable: false, prop: 'orderStatus' }
  ];

  getAllSales() {
    const companyID = JSON.parse(localStorage.getItem('waiterObject')).companyID;
    this.isLoading = true;
    const saleTypes =  [SaleType.isCanceled, SaleType.DeliveryNote]
    const saleTypesString = saleTypes.join(',');
    this.subscriptions.push(
      this.api.getSales(companyID, saleTypesString).subscribe((sales: ISale[]) => {
        this.dataSource = sales;
        this.isLoading = false;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pager = this.getPager(this.pageSize, this.paginatorSelectednumber, this.selectedDropdown);

      }));
  }

  setCurrentSale(currentSale: any) {
    this.currentSale = currentSale
  }

  setDataSource(dataSource: any) {
    console.log(dataSource);
    this.dataSource = dataSource
  }

  nextPage() {
    if (this.dataSource.paginator.hasNextPage()) {
      this.dataSource.paginator.nextPage();
    }
    this.paginatorSelectednumber++;
    this.pager = this.getPager(this.pageSize, this.paginatorSelectednumber, this.selectedDropdown);
  }

  numberPage(page) {
    this.dataSource.paginator.firstPage();
    for (let i = 0; i < page - 1; i++) {
      this.dataSource.paginator.nextPage();
    }
    this.paginatorSelectednumber = page;
    this.pager = this.getPager(this.pageSize, this.paginatorSelectednumber, this.selectedDropdown);
  }

  tableLength(tableLengthValue) {
    this.dataSource.paginator._changePageSize(+tableLengthValue);
    this.paginatorSelectednumber = this.dataSource.paginator.pageIndex + 1;
    this.selectedDropdown = tableLengthValue;
    this.pager = this.getPager(this.pageSize, this.paginatorSelectednumber, this.selectedDropdown);
  }

  apiCall() {
    const companyID = JSON.parse(localStorage.getItem('waiterObject')).companyID;
    const saleTypes =  [SaleType.isCanceled, SaleType.DeliveryNote]
    const saleTypesString = saleTypes.join(',');
    return this.api.getSales(companyID, saleTypesString);
  }

  async getWaiterRoles() {
    this.waiterRolesLoaded = true;
    const waiterObject = JSON.parse(localStorage.getItem('waiterObject'));
    /**
     * checks configurations of permission for waiter
     */
    this.configs = await this.getConfigs();
    if (this.configs?.settings.offlineSales === false) {
      this.api.getWaiters().subscribe(async (waiters: IWaiters[]) => {
        this.waiter = waiters.find(x => x.id === waiterObject.waiterID);
        this.waiter.userRole.forEach(data => {
          //checks if waiter has role "Sale delete"
          if (data.rolename === "Sale delete") {
            this.allColumns.push({ name: 'DELETE', resizeable: false, prop: 'isDeleted' });
            this.hasSaleDeleteRole = true;
            this.displayedColumns= ['daily-id', 'date-sale', 'comment', 'total', 'status', 'created', 'modified', 'order-status', 'is-deleted', 'delete-column'];
          }
          this.waiterRolesLoaded = false;
        });
      });
    }
    /**
     * checks configurations of permission for waiter if service is Offline
     */
    else {
      this.offlineService.getWaitersFromLocalDb().then(async (waiters: IWaiters[]) => {
        this.waiter = waiters.find(x => x.id === waiterObject.waiterID);
        this.waiter.userRole.forEach(data => {
            //checks if waiter has role "Sale delete"
          if (data.rolename === "Sale delete") {
            this.allColumns.push({ name: 'DELETE', resizeable: false, prop: 'isDeleted' });
            this.hasSaleDeleteRole  = true;
            this.displayedColumns= ['daily-id', 'date-sale', 'comment', 'total', 'status', 'created', 'modified', 'order-status', 'is-deleted', 'delete-column'];
          }
          this.waiterRolesLoaded = false;
        });
      });
    }
  }

  getPager(totalItems: number, currentPage: number, pageSize: number) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);
  let startPage: number, endPage: number;
  
      if (totalPages <= 5) {
          startPage = 1;
          endPage = totalPages;
      } else {
          if (currentPage <= 3) {
              startPage = 1;
              endPage = 5;
          } else if (currentPage + 1 >= totalPages) {
              startPage = totalPages - 4;
              endPage = totalPages;
          } else {
  
              if((totalPages - (currentPage - 2)) == 5) {
                 startPage = currentPage - 1;
                endPage = currentPage+3;
              } else {
                 startPage = currentPage - 2;
              endPage = currentPage+2;
              }
          }
      }
  
      // calculate start and end item indexes
      let startIndex = (currentPage - 1) * pageSize;
      let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
  
      // create an array of pages to ng-repeat in the pager control
      let pages = Array(endPage - startPage + 1).fill(0).map((_, idx) => startPage + idx);
  
      // return object with all pager properties required by the view
      return {
          totalItems: totalItems,
          currentPage: currentPage,
          pageSize: pageSize,
          totalPages: totalPages,
          startPage: startPage,
          endPage: endPage,
          startIndex: startIndex,
          endIndex: endIndex,
          pages: pages
      };
  }

  deleteSaleModal(id: number) {
    this.alertService.swalFire(
      'warning',
      this.translate.instant('DELETE_MESSAGE'),
      true,
      true,
      this.translate.instant('YES_DELETE'),
      this.translate.instant('NO')
    ).then(result => {
      if (result.isConfirmed) {
        this.deleteSale(id);
      }
    });
  }

  deleteSale(id: number) {
    this.api.deleteSale(id, this.waiterId ).subscribe(data => {
      // checks if the sale has no debt and successfully can delete
      if (data.status) {
        this.alertService.swalFire(
          'success',
          this.translate.instant('DELETED_SUCCESS'),
          true,
          false,
          this.translate.instant('CLOSE'),
          ''
        )
        this.getAllSales();
      }
      // checks if the sale has debt and can't delete it
      else {
        this.alertService.swalFire(
          'warning',
          this.translate.instant('DEBT_EXIST'),
          true,
          false,
          this.translate.instant('CLOSE'),
          ''
        )
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  getConfigs(): Promise<IConfigs> {
    return this.offlineService.getOfflineConfigs();
  }

  getReservedSaleById(id: number) {
    this.currentSale = this.dataSource.find(x => x.id === id);
  }
}
