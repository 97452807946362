import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Client } from '../interfaces/IClient';
import { IPriceList } from '../interfaces/IPriceList';
import { IWaiterAuthObject } from '../interfaces/IWaiterAuthObject';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private http: HttpClient) { }

  getClients(): Observable<Client[]> {
    const companyId = this.getCompanyId();
    return this.http.get<Client[]>(`${environment.API_URL}/clientsapi/clients`, { params: {companyId}});
  }

  getClientById(clientId: string): Observable<Client> {
    return this.http.get<Client>(`${environment.API_URL}/clientsapi/GetClientById`, {params: { id: clientId }});
  }
  getClientByloyaltyId(loyaltyId: string): Observable<Client> {
    return this.http.get<any>(`${environment.API_URL}/clientsapi/GetClientByLoyaltyId`, {params: { loyaltyId: loyaltyId }});
  }

  getSaleClients(): Observable<Client[]> {
    const companyId = this.getCompanyId();
    return this.http.get<Client[]>(`${environment.API_URL}/clientsapi/saleclients`, { params: {companyId}});
  }

  getCompanyId(): string {
    const token = JSON.parse(localStorage.getItem('waiterObject')) as IWaiterAuthObject;
    if (token) {
      return token.companyID.toString();
    } else {
      return '';
    }
  }

  getPriceListByClientID(clientID: number): Observable<IPriceList> {
    return this.http.get<IPriceList>(`${environment.API_URL}/clientsapi/pricelist`, {params: {id: clientID.toString() }});
  }
}
