<nav class="navbar navbar-expand-lg navbar-light bg-white border-bottom" style="z-index: 999 !important;">
  <div class="nav">
    <img src="assets/images/conome-30pxh.png" (click)="navigateTo()" class="navbar-brand">

    <div class="w-auto input-group" *ngIf="showSearchInput">
      <input class="form-control border-end-0 border rounded-pill searchText1" type="text" id="navbar-search-input"
        placeholder="{{'SEARCH' | translate}}" [formControl]="searchFormControl" #searchInput>
    </div>

    <button class="btn btn-outline-primary ml-1 syncButton" [disabled]="!syncFinished" *ngIf="toSyncData > 0"
    data-toggle="tooltip" data-placement="bottom" title="Sync sales with server" (click)="syncSalesWithServer()">
      <!-- TODO translate -->
      {{ 'SYNC' | translate }} <span class="badge badge-light">{{ toSyncData }}</span>
    </button>
    <button class="btn btn-outline-primary ml-1 saleButton" data-target="#saleModal" data-toggle="modal" (click)="openSaleModal()">
      {{'SALE' | translate }}
    </button>
    <button *ngIf="showOrderStatus" class="btn btn-outline-primary ml-2 orderButton"
      (click)="newWindow('/orderstatus')">
      {{'STATUS' | translate }}
    </button>
    <button *ngIf="showOrderStatus" class="btn btn-outline-primary ml-2 orderButton"
      (click)="openConfigs()">
      {{'SETTINGS' | translate }}
    </button>
    <button class="btn btn-outline-primary ml-2 orderButton" data-target="#happyHourModal" data-toggle="modal">
      Happy Hour
    </button>
  </div>
  <button class="navbar-toggler collapsed border-0" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-icon"><img src="../../../assets/images/menu open.svg" /></span>
    <div class="close-icon"><img src="../../../assets/images/menu close.svg" class="menuClose" /></div>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav">
      <div class="form-group ml-auto mr-1 my-auto">
        <select class="custom-select" name="selectedLanguage" #selectedLanguage
          (change)="translate.use(selectedLanguage.value);changeLanguage(selectedLanguage)">
          <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">
            {{lang}}</option>
        </select>
      </div>

      <div class="dropdown1 show">
        <img src="../../../assets/images/fiscal.svg" alt="" height="40px" width="40px" class="dropdown-toggle" data-target="#saleModal"
          id="fiscal" data-toggle="modal" aria-haspopup="true" aria-expanded="false" (click)="openOtherModal()">
      </div>

      <li class="nav-item ml-2 mr-2  pl-2  pr-2">
        <a class="nav-link  overflow-hidden">
          <div class="row">
            <img src="../../../assets/images/checkout.svg" height="40px" width="40px" (click)="openDailyReport()"
              alt="checkoutLogo">
          </div>
        </a>
      </li>
      <li class="nav-item ml-2 mr-2">
        <a class="nav-link  overflow-hidden">
          <div class="row">
            <img src="../../../assets/images/logout.svg" height="40px" width="40px" class="my-auto logoutImage"
              (click)="logOut()" alt="logoutLogo">
          </div>
        </a>
      </li>
      <img src="{{imgLogo}}" class="navbar-brand companyLogo">
    </ul>
  </div>
</nav>

 <!-- Modal to display happy hour times -->
 <div class="modal fade" id="happyHourModal" tabindex="-1" aria-labelledby="happyHourModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="happyHourModalLabel">{{ 'HAPPY_HOUR_TODAY' | translate }}</h5>
        <button type="button" class="close" id="saleModalClose" data-dismiss="modal" aria-label="Close">
          <img src="../../../assets/images/menu close.svg" class="closeMenu" alt="closemenu">
        </button>
      </div>
      <div class="modal-body modal-body-happyHours">
        <div class="card-container">
          <ng-container *ngIf="happyHours.length > 0; else noHappyHours">
            <div *ngFor="let happyHour of happyHours" [ngClass]="{'bg-info': isActiveHappyHour(happyHour)}" class="card card-happy-hour mb-2">
              <div class="card-body">
                <h5>{{ happyHour.name }}</h5><br/>
                {{ happyHour.start }} - {{ happyHour.end }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Display this div when there are no happy hours -->
<ng-template #noHappyHours>
  <div class="info-message">
    {{ 'NO_HAPPY_HOURS_TODAY' | translate }}
  </div>
</ng-template>

<!-- Modal -->
<div class="modal" id="saleModal" tabindex="-1" role="dialog" aria-labelledby="saleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <ng-container *ngIf="currentModal==='sale'">
        <h5 class="modal-title" id="saleModalLabel">{{ 'SALE' | translate }}</h5></ng-container>
        <ng-container *ngIf="currentModal==='fiscal'">
          <img src="../../../assets/images/fiscal.svg" alt="" height="40px">

        </ng-container>
        <button type="button" class="close" id="saleModalClose" data-dismiss="modal" aria-label="Close">
          
          <img src="../../../assets/images/menu close.svg" class="closeMenu" alt="closemenu">
        </button>
      </div>
      <div class="modal-body mx-4" [ngClass]="currentModal==='fiscal'? 'modalBody' : ''">
        <div class="container">
          <div class="row">
            
            <ng-container *ngIf="currentModal === 'sale'">
              <div class="row row1">
                  <div class="col-12" [ngClass]="i === 0 ? 'offset-md-2 col-md-4 d-flex justify-content-end' : i === 1 ? 'col-md-4 offset-right-2' : 'col-md-4'" *ngFor="let item of saleItems let i = index">
                    <button class="btn saleModal-Button {{i < 2 ? 'special-size' : ''}}" data-dismiss="modal" 
                      [class.disabled]="currentUrl == item.route"
                      (click)="saleItemsClick(item.route)"
                      [style.background-color]="item.color"
                      [style.color]="item['text-color']">
                      <img src="{{item['icon']}}" class="saleModal-Icon" [ngClass]="{'saleModal-Icon--border': item.id === '1'}">
                      {{ item.text | translate }}
                    </button>
                  </div>
                </div>

            </ng-container>

            <!-- Fiscal Modal Items -->
            <ng-container *ngIf="currentModal === 'fiscal'">
              <div class="row row1">
                <div class="col-12" [ngClass]="i%2==0 ? 'offset-md-2 col-md-4 d-flex justify-content-end' : 'col-md-4 offset-right-2'" *ngFor="let item of fiscalItems let i = index">
                  <button class="btn btn-block fiscalModal-Button" data-dismiss="modal"
                  [style.background-color]="item.color"
                  [style.color]="item['text-color']"
                  (click)="navigateToFiscalItems(item.id)">
                  <img src="{{item['icon']}}" class="fiscalModal-Icon">
                  {{item.text | translate}}</button>
                </div>
              </div>                
              
            </ng-container>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

