import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {interval, Observable, Subject} from "rxjs";
import {filter, takeUntil, tap} from "rxjs/operators";

@Directive({
  selector: '[appHoldButon]'
})
export class HoldButonDirective {
  @Output() holdButton: EventEmitter < any > = new EventEmitter();
  @Input() currentTable: any;
  value;
  state: Subject < any > = new Subject<any>();
  cancel: Observable<string>;
  constructor() {
    this.cancel = this.state.pipe(
      filter(v => v === 'cancel'),
      tap(t => {
        this.holdButton.emit(0)
      })
    );
  }

  @HostListener('mouseup', ['$event'])
  @HostListener('mouseleave', ['$event'])
  onExit()
  {
    this.state.next('cancel');
  }
  @HostListener('mousedown', ['$event'])
  onHold()
  {
    this.state.next('start');
    const n = 100;
    interval(n).pipe(
      takeUntil(this.cancel),
      tap(v => {
        this.holdButton.emit(v * n);
        this.holdButton.subscribe(val =>{
          if(val > 1500){
            const data = {
              status: true,
              table: this.currentTable
            }
            this.holdButton.emit(data)
          }
        })
      })
    ).subscribe();
  }
}
