import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements HttpInterceptor {
  constructor(protected router: Router) {}

  getToken() {
    return localStorage.getItem('token') || "";
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authRequest = req.clone({
      headers: req.headers.set('Authorization', this.getToken()),
    });

    return next.handle(authRequest).pipe(
      catchError((err, caught) => {
        console.log(err);
        if (err.status === 403) {
          return of(err);
        }
        throw err;
      })
    );
  }
}
