import { WholeSalePaymentType } from "../Enums/WholeSalePaymentType";


export const WholesalePayments = [
  {
    name: 'BILL',
    type: WholeSalePaymentType.Invoice,
    dialogId: '#PaymentPublish',
    disabled: false
  },
  {
    name: 'PRO_INOVICE',
    type: WholeSalePaymentType.ProInvoice,
    dialogId: null,
    disabled: false
  },
  {
    name: 'RESERVED',
    type: WholeSalePaymentType.Reserve,
    dialogId: null,
    disabled: false
  },
  {
    name: 'DELIVERY_NOTE',
    type: WholeSalePaymentType.DeliveryNote,
    dialogId: null,
    disabled: true
  },
  {
    name: "CANCEL",
    type: WholeSalePaymentType.Cancel,
    dialogId: null,
    disabled: false
  }
]
