import {Component, ElementRef, OnInit,  ViewChild} from '@angular/core';
import {DataService} from '../../data.service';
import {IReservations} from '../../interfaces/IReservations';
import {NgbCalendar, NgbDateStruct, NgbTimepickerConfig} from '@ng-bootstrap/ng-bootstrap';
import {ITable} from '../../interfaces/ITable';
import {FormControl, FormGroup} from '@angular/forms';
import {AlertService} from '../../services/alert.service';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {TranslateKeysService} from '../../services/translate-keys.service';
@Component({
  selector: 'app-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.css'],
})
export class ReservationsComponent implements OnInit {

  constructor(
    private api: DataService,
    private calendar: NgbCalendar,
    config: NgbTimepickerConfig,
    private alertService: AlertService,
    public translate: TranslateService,
    private translateKeys: TranslateKeysService
  ) {
    config.spinners = false;
  }
  model: NgbDateStruct;
  timeFrom: {hour: number, minute: number, second: number };
  timeTo: {hour: number, minute: number,  second: number };
  date: {year: number, month: number};
  tables: ITable[] = [];
  reservations: {active: IReservations[], closed: IReservations[]} = {active: [], closed: []};
  reservationForm: FormGroup;
  editMode = false;
  currentReservation: IReservations;
  companyID = this.getCompanyID();
  @ViewChild('timeFrom') timeF: ElementRef;
  @ViewChild('timeTo') timeT: ElementRef;
  Language;
  ngOnInit() {
    this.selectToday();
    this.getAllReservationsByDate(this.model);
    this.getTables();

    this.reservationForm = new FormGroup({
      companyID: new FormControl(this.getCompanyID()),
      date: new FormControl(),
      timeFrom: new FormControl(),
      timeTo: new FormControl(),
      personsNo: new FormControl(),
      tableID: new FormControl(),
      personName: new FormControl(),
      phone: new FormControl(),
    });
  }


  createReservation(){
    this.checkTime().then(result => {
      // @ts-ignore
      if (result.data.statusCode === 409){
        this.alertService.swalFire('error', this.translateKeys.doTranslate('CHOOSEN_TIME_RESERVED'), false, true, '', this.translateKeys.doTranslate('CLOSE'), 2000);
        return;
      }else {
        // @ts-ignore
        if (result.data.statusCode === 408){
                // @ts-ignore
          this.alertService.swalFire('error', result.data.message, false, true, '', this.translateKeys.doTranslate('CLOSE'));
          return;
        }

      }
      const data = this.reservationForm.value;
      data.companyID = this.getCompanyID();
      data.date = this.model.year + '-' + this.model.month + '-' + this.model.day
      data.timeFrom = this.parsedTime().timeFrom;
      data.timeTo = this.parsedTime().timeTo;
      data.status = true;
      data.ModUser = this.api.getWaiterID();
      this.api.createReservation(data)
        .subscribe((response) => {
          this.resetForm(this.reservationForm);
          this.alertService.swalFire('success',  this.translateKeys.doTranslate('RESERVATION_CREATED'), true, false, this.translateKeys.doTranslate('OK'), '', 1000)
            .then(res => {
              document.getElementById('reservationModal').click();
            });
          this.selectToday()
          this.getAllReservationsByDate(this.model)
        },  (error) => {
          this.alertService.swalFire('error', this.translateKeys.doTranslate('RESERVATION_NOT_CREATED'), false, true, '', this.translateKeys.doTranslate('CLOSE'), 2000);
        });
    });
  }


  // function used to retrieve all active and closed reservations
  getAllReservations() {
    this.api.getAllReservations(this.companyID).subscribe((reservations: {active: IReservations[], closed: IReservations[]}) => {
      this.reservations.active = reservations.active;
      this.reservations.closed = reservations.closed;
    });
  }
   // function used to retrieve all active and closed reservations filtered by date
  getAllReservationsByDate(dateForm) {
    const date = new Date(dateForm.year, dateForm.month - 1, dateForm.day + 1).toISOString().split('T');
    this.api.getAllReservationsByDate(this.companyID, date).subscribe((reservations: {active: IReservations[], closed: IReservations[]}) => {
      this.reservations.active = reservations.active;
      this.reservations.closed = reservations.closed;
    });
  }

  selectToday() {
    this.model = this.calendar.getToday();
    return this.model;
  }

  getCompanyID(){
    return JSON.parse(localStorage.getItem('waiterObject')).companyID;
  }

  checkTime(){
    const date = new Date(this.model.year, this.model.month - 1, this.model.day + 1).toISOString().split('T')[0];
    const parsedTime = this.parsedTime();
    const tableID = parseInt(this.reservationForm.value.tableID, 10);
    return this.api.checkReservationTime(parsedTime.timeFrom, parsedTime.timeTo, this.companyID, tableID, date)
      .toPromise();
  }

  getTables(){
    this.api.getTablesfromDB(this.getCompanyID())
      .subscribe((response) => {
        this.tables = response;
      });
  }

  resetForm(form){
    form.reset();
  }

  filterByID(id){
    this.editMode = true;
    const allReservations = [...this.reservations.active, ... this.reservations.closed];
    this.currentReservation =  allReservations.find(x => x.id === id);
    if (this.currentReservation) {
      this.reservationForm.patchValue(this.currentReservation);
      const formatedDate = moment(this.currentReservation.date, 'YYYY-MM-DD').format('YYYY-MM-DD');
      const timeFrom = moment(this.currentReservation.timeFrom, 'HH:mm:ss').format('HH:mm:ss');
      const timeTo = moment(this.currentReservation.timeTo, 'HH:mm:ss').format('HH:mm:ss');

      // this is required by ngbDatepicker
      this.model = {
        year: parseInt(formatedDate.split('-')[0], 10),
        month: parseInt(formatedDate.split('-')[1], 10),
        day: parseInt(formatedDate.split('-')[2], 10),
      };
      // these are required by ngb-timepicker
      this.timeFrom = {
        hour: parseInt(timeFrom.split(':')[0], 10),
        minute: parseInt(timeFrom.split(':')[1], 10),
        second: parseInt(timeFrom.split(':')[2], 10)
      };
      this.timeTo = {
        hour: parseInt(timeTo.split(':')[0], 10),
        minute: parseInt(timeTo.split(':')[1], 10),
        second: parseInt(timeTo.split(':')[2], 10)
      };
    }
  }

  checkTable(){
    let isReserved = false;
    const parsedTime = this.parsedTime();

    const tableID = parseInt(this.reservationForm.value.tableID, 10);
    this.api.checkReservationTime(parsedTime.timeFrom, parsedTime.timeTo, this.companyID, tableID, this.currentReservation.date)
      .subscribe((response) => {
        const {statusCode} = response.data;
        if (statusCode !== 200){
          isReserved = true;
          this.alertService.swalFire('error', this.translateKeys.doTranslate('TABLE_HAS_RESERVATION'), false, false, '', '', 2000);
        }
      });
    return isReserved;
  }

  // function for parsing and formatting time
  private parsedTime() {
    const timeFrom = this.reservationForm.controls.timeFrom.value;
    const timeTo = this.reservationForm.controls.timeTo.value;
    if (timeFrom && timeTo) {
    return {
      timeFrom: moment(timeFrom).format('HH:mm'),
      timeTo: moment(timeTo).format('HH:mm')
    };
  }
  }

  updateReservation(){
    const data = this.reservationForm.value;
    data.companyID = this.getCompanyID();
    // data.date = '' + data.date.year + '-' + '' + data.date.month + '-' + '' + data.date.day + '';
    data.date = new Date(data.date.year, data.date.month - 1, data.date.day + 1).toISOString();
    data.date = data.date.split('T')[0];
    data.timeFrom = this.parsedTime().timeFrom;
    data.timeTo = this.parsedTime().timeTo;
    data.status = true;
    data.ModUser = this.api.getWaiterID()
    parseInt(data.tableID, 10);
    data.Id = this.currentReservation.id;
    if (this.reservationForm.value.tableID !== this.currentReservation.tableID){
    if (!this.checkTable()){
      this.checkTime().then(result => {
        // @ts-ignore
        if (result.data.statusCode === 409) {
          this.alertService.swalFire('error', this.translateKeys.doTranslate('CHOOSEN_TIME_IS_RESERVED'), false, true, '', this.translateKeys.doTranslate('CLOSE'), 2000);
          return;
        } else {
          // @ts-ignore
          if (result.data.statusCode === 408) {
            // @ts-ignore
            this.alertService.swalFire('error', result.data.message, false, false);
            return;
          }
        }
        this.api.updateReservation(data)
          .subscribe((response) => {
            this.alertService.swalFire('success', this.translateKeys.doTranslate('RESERVATION_UPDATED'), true, false, this.translateKeys.doTranslate('OK'));
            this.resetForm(this.reservationForm);
            document.getElementById('closeModal').click();
            // this.getAllReservations();
            this.selectToday()
            this.getAllReservationsByDate(this.model)
          }, (error) => {
          });
      });
    }
    }
  }
  changeMode(){
    this.editMode = false;
    this.resetForm(this.reservationForm);
  }

  holdHandler(e){
    if (e.delete === true){
      this.alertService.swalFire('info', this.translateKeys.doTranslate('RESERVATION_DELETE') + e.id + '?', true, true, this.translateKeys.doTranslate('YES'), this.translateKeys.doTranslate('NO'))
        .then(result => {
          if (result.isConfirmed){
              this.api.deleteReservation(e.id)
                .subscribe((response) => {
                  this.getAllReservationsByDate(this.model);
                  this.alertService.swalFire('success', this.translateKeys.doTranslate('RESERVATION_DELETE_SUCCESS'), true, false, this.translateKeys.doTranslate('OK'), '', 2000);
                }, (error) => {
                });
          }else{
            return;
          }
        });
    }else{
      return 0;
    }
  }


  deleteReservation(reservationID){
    this.alertService.swalFire('warning', this.translateKeys.doTranslate('RESERVATION_DELETE') + reservationID + '', true, true, this.translateKeys.doTranslate('YES'), this.translateKeys.doTranslate('NO'))
      .then(result => {
        if (result.isConfirmed){
          this.api.deleteReservation(reservationID)
            .subscribe((response) => {
              this.selectToday();
              this.getAllReservationsByDate(this.model);
              const closeModal = document.getElementById('closeModal') as HTMLElement
              closeModal.click();
              this.alertService.swalFire(
                'success',
                this.translateKeys.doTranslate('RESERVATION_DELETE_SUCCESS'),
                true,
                false,
                this.translateKeys.doTranslate('OK'),
                '', 2000)
            }, (error) => {
            });
        }else{
          return;
        }
      });
  }

  selectedTable(tableID){
     const cTable = this.tables.find(x => x.id === parseInt(tableID, 10))
     return cTable;
  }
  checkSeatsNumber(el, event){
    const {value, placeholder} = el;
    if(parseInt(value, 10) > parseInt(placeholder, 10)){
      this.alertService.swalFire(
        'info', this.translateKeys.doTranslate('TABLE_HAS_LESS_CHAIRS'),
        true,
        false,
        this.translateKeys.doTranslate('OK')).then(data => {
        if(data.isConfirmed){
          el.value = '';
        }
      });
    }
  }
}
