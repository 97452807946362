import { Component, EventEmitter, Input, OnInit, Output, ViewChild, HostListener, ElementRef, TemplateRef} from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/data.service';
import { ISale } from 'src/app/interfaces/ISale';
import { Router } from '@angular/router';
import { DebtCardService } from 'src/app/services/debt-card.service';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { DatatableComponent } from '@swimlane/ngx-datatable'; 

@Component({
  selector: 'app-data-tables',
  templateUrl: './data-tables.component.html',
  styleUrls: ['./data-tables.component.scss'],
  providers: [TranslatePipe]
})
export class DataTablesComponent implements OnInit {
  constructor
  (
    protected api: DataService,
    private router: Router,
    public debtCardService: DebtCardService, 
    private translatePipe: TranslatePipe, 
    private datePipe: DatePipe,
    private translate:TranslateService
  ) {}

  sales: ISale[] = [];
  currentSale: ISale;
  subscriptions: Subscription[] = [];
  dataSource = this.sales as any;
  clientData!: any;
  activeButton = 1;
  currentPath = "";
  // This modalsName stores the name of the modals data-target so we can change it according to the page is opened
  modalsName = {
    debtcard:"#saleDebtDetailsModal",
    debtcardclient:"#clientDetailsModal",
    salelist:"#saleDetailsModal"
  }

  // Input
  @Input('displayedColumns') displayedColumns: any;
  @Input('allColumns') allColumns: any;
  @Input('apiCall') apiCall: any;
  @Input('lang') lang: any;
  @Input() tabIndex!: number;
  @Input('saleDebtDetails') saleDebtDetails: any;
  @Input('clientDebtDetails') clientDebtDetails: any;
  @Input('filterBy') filterBy:string;
  
  // Output
  @Output() debtPaid = new EventEmitter<any>();
  @Output() getCurrentSale = new EventEmitter<any>();
  @Output() currentSaleUpdate = new EventEmitter<any>();
  @Output() sendClientData = new EventEmitter<any>();
  @Output() payments: any;
  @Output() sendCliendDebtDetails = new EventEmitter<any>();
  @Output() deleteSale = new EventEmitter<any>();

  @ViewChild(DatatableComponent) table: DatatableComponent;

  async executeFunction(row: any) {
      this.setCurrentSale();
      this.updateCurrentSale();
  }

  filteredData = this.dataSource
  dtOptions:any | null = null;

  // Filter to be added
  filterSales(e) {
    this.activeButton = e.target.id 

    if(e.target.id == 2) {
      this.dataSource = this.filteredData
    }

    if(e.target.id == 2) {
      this.dataSource = this.dataSource.filter(x => x.status == true && x.isDeleted == false);
    }

    if(e.target.id == 3) {
      this.dataSource = this.dataSource.filter(x => x.status == false && x.isDeleted == false);
    }

  }

  getClientDebtDetails(row): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.clientDebtDetails(row.modUser).subscribe(
        data => {
          if (data.debtPaid) {
            this.debtPaid.emit(true);
          } else {
            this.debtPaid.emit(false);
          }
    
          resolve(data); 
        },
        error => {
          reject(error); 
        }
      );
    });
  }

  getSaleDebtDetails(row): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.saleDebtDetails(row.id).subscribe(data => { 
        resolve([data, row.id])
      }),
      error => {
        reject(error); 
      }
    })
  }

  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this.displayedColumns = this.displayedColumns.filter(c => {
        return c.name !== col.name;
      });
    } else this.displayedColumns = [...this.displayedColumns, col];
    
  }

  isChecked(col) {
    return (
      this.displayedColumns.find(c => {
        return c.name === col.name;
      }) !== undefined
    );
  }

  updateCurrentSale() {
    this.currentSaleUpdate.emit(this.dataSource)
  }

  setCurrentSale() {
    this.getCurrentSale.emit(this.currentSale)
  }

  updateClientData(clientData) {
    this.sendClientData.emit(clientData)
  }

  getAllSales() {
      const urlSplit = this.router.url.split("/");
      const urlName = urlSplit[urlSplit.length-1];
     this.subscriptions.push(
        this.apiCall().subscribe((sales: any) => {
        if(typeof this.filterBy === "undefined"){

          sales.map(x => {
            x.isPaid = x.status;
            x.status = x.status ? this.translatePipe.transform('PAID') :  this.translatePipe.transform('UNPAID')
            x.isReady = x.orderStatus;
            x.orderStatus = x.orderStatus ? this.translatePipe.transform('READY') : this.translatePipe.transform('NOT_READY')
            x.created = this.datePipe.transform(x.created); 
            x.modified = this.datePipe.transform(x.modified);
            x.dateSale = this.datePipe.transform(x.dateSale);
            x.totalDebt = x.totalDebt?.toFixed(2) || "";
            x.totalLeft = x.totalLeft?.toFixed(2) || "";
            x.total = x.total?.toFixed(2) || "";
            x.deadline = this.datePipe.transform(x.deadline);
            x.date = this.datePipe.transform(x.date);
            x.nameSQ = x.nameSQ;
            return x;
          })

        } else{
          sales = sales.filter(s=>s.status === this.filterBy).map(x => {
            x.isPaid = x.status;
            x.status = x.status ? this.translatePipe.transform('PAID') :  this.translatePipe.transform('UNPAID')
            x.isReady = x.orderStatus;
            x.orderStatus = x.orderStatus ? this.translatePipe.transform('READY') : this.translatePipe.transform('NOT_READY')
            x.created = this.datePipe.transform(x.created); 
            x.modified = this.datePipe.transform(x.modified);
            x.dateSale = this.datePipe.transform(x.dateSale);
            x.totalDebt = x.totalDebt?.toFixed(2) || "";
            x.totalLeft = x.totalLeft?.toFixed(2) || "";
            x.total = x.total?.toFixed(2) || "";
            x.deadline = this.datePipe.transform(x.deadline);
            x.date = this.datePipe.transform(x.date);
            return x;
          })
        }

        if(sales && sales.length>0) {
          this.dataSource = sales;

        } else {
          let filteredSales = sales.filter((x: any) => x.isDeleted == false);

          if (this.tabIndex === 1) filteredSales = filteredSales.filter((x: any) => x.status === true);
          else if (this.tabIndex === 2) filteredSales = filteredSales.filter((x: any) => x.status === false);
          this.dataSource = filteredSales;
          }

          let i = this.allColumns.length;
          while(i--){
            for(let item of this.dataSource){
                  if(!Object.keys(item).includes(this.allColumns[i].prop)){
                    this.allColumns.splice(i, 1);
                  }
                }
          }
         
          this.dtOptions = {
            pagingType: 'simple_numbers',
            pageLength: 10,
            processing: true,
            ordering: true,
            searching: true,
            paging: true,
            data: this.dataSource,
            columns: this.allColumns.map((c) => {
              let columnConfig = {};
              if(urlName.includes("debtcard")){
                columnConfig = {
                    render: (data, type, full, meta) => {           
                        if (meta.col === 0 && full.isPaid === false && this.isDeadlinePassed(full.deadline)) {
                            return `<div style="display: flex; align-items: center;"><img src="../../../assets/images/reddcrd2.svg" class="deadline-image">${data}</div>`;
                        }else if (meta.col === 0 && full.isPaid === false && this.isDeadlineWithinFiveDays(full.deadline)) {
                          return `<div style="display: flex; align-items: center;"><img src="../../../assets/images/yellowcrd2.svg" class="deadline-image">${data}</div>`;
                        } else if(meta.col === 0 && full.isPaid === false) {
                            return `<div style="display: flex; align-items: center;"><img src="../../../assets/images/yellowcrd2.svg" class="deadline-image blue-image">${data}</div>`;
                        } else if(meta.col === 0 && full.isPaid === true) {
                          return `<div style="display: flex; align-items: center;"><img src="../../../assets/images/greencrd2.svg" class="deadline-image">${data}</div>`;
                        } else{
                          return data
                        }
                    }
                };
              }

              // Setting up delete button for DELETE column
              if (c.name === "DELETE") {
                  columnConfig = {
                      ...columnConfig,
                      render: (data, type, full, meta) => {
                          return `<button class="btn btn-sm btn-light text-danger font-weight-bold delete-btn">${this.translate.instant("DELETE")}</button>`;
                      }
                  };
              }

              return {
                  title: this.translate.instant(c.name),
                  data: c.prop,
                  ...columnConfig
              };
            }),
            language: {
              paginate: {
                first:      "«",
                last:       "»",
                next:       `»`,
                previous:   `«`
              },
              emptyTable:     `${this.translate.instant("NO-DATA-IN-TABLE")}`,
              info:           `${this.translate.instant("SHOWING")} _START_ ${this.translate.instant("TO")} _END_ ${this.translate.instant("OF")} _TOTAL_ ${this.translate.instant("OF_ENTRIES")}`,
              infoEmpty:      `${this.translate.instant("SHOWING")} 0 ${this.translate.instant("TO")} 0 ${this.translate.instant("OF")} 0 ${this.translate.instant("OF_ENTRIES")}`,
              infoFiltered:   `(${this.translate.instant("FILTERED")} ${this.translate.instant("FROM")} _MAX_ ${this.translate.instant("TO")} ${this.translate.instant("OF_ENTRIES")})`,
              infoPostFix:    ``,
              thousands:      `,`,
              lengthMenu:     `${this.translate.instant("SHOW")} _MENU_ ${this.translate.instant("OF_ENTRIES")}`,
              loadingRecords: `${this.translate.instant("LOADING")}...`,
              processing:     `${this.translate.instant("PROCESSING")}...`,
              search:         `${this.translate.instant("SIMPLE-SEARCH")}:`,
              zeroRecords:    `${this.translate.instant("NO-MATCHING-RECORDS")}`,
          },
            // Declare the use of the extension in the dom parameter
            /*
            The built-in table control elements in DataTables are:
            l - length changing input control
            f - filtering input
            t - The table!
            i - Table information summary
            p - pagination control
            r - processing display element
            B - Buttons
            R - ColReorder
            S - Scroller
            P - SearchPanes
            Q - SearchBuilder
            Changing the position of the letters in the dom attribute, will change the position of the controls in the UI
            */
            dom: 'Bfrtipl',
            initComplete: function (settings: any, json: any) {
              $('button').removeClass('dt-button');
              $('button').removeClass('buttons-excel');
              $('button').removeClass('buttons-html5 ');
            },
            buttons: [
              {
                extend: 'colvis',
                text: this.translate.instant("COLUMNS"),
                className: 'mx-2 btn btn-outline-primary ml-1 saleButton',
              },
              {
                extend: 'excel',
                text: 'Excel',
                className: 'mx-2 btn btn-outline-primary ml-1 saleButton',
              }              
            ],        
          rowCallback: (row: Node, data: any[] | Object, index: number) => {
            // Unbind first in order to avoid any duplicate handler
            // (see https://github.com/l-lin/angular-datatables/issues/87)
            // Note: In newer jQuery v3 versions, `unbind` and `bind` are 
            // deprecated in favor of `off` and `on`
            $('td', row).off('click');
            $('td', row).on('click', (e) => {
              //Checks if the clicked happened on the delete button
              if(e.target.className.includes("delete-btn")){
                this.deleteSale.emit(data["id"])
              } else{
                this.getSaleById(data);
                document.getElementById("the-modal").click();
              }

            });
            return row;
          }
          };

          if(urlName.includes("debtcard")){
            this.dtOptions.ordering = false;
          }
        }
      ));
  }

  isDeadlinePassed(deadline) {
    if(deadline == null){
      return false;
    }else{  
      const today = new Date();
      const deadlineDate = new Date(deadline);
      return deadlineDate <= today;
    }
  }

  isDeadlineWithinFiveDays(deadline) {
    if(deadline == null){
      return false;
    }else{  
      const today = new Date();
      const fiveDaysLater = new Date();
      fiveDaysLater.setDate(today.getDate() + 5);
      const deadlineDate = new Date(deadline);
      return deadlineDate <= fiveDaysLater;
    }
}

  ngOnInit() {
    const urlSplit = this.router.url.split("/");
    const urlName = urlSplit[urlSplit.length-1];
    if(urlName.includes("salelist") || urlName.includes("proinvoice") || urlName.includes("offerslist") || urlName.includes("reservedlist") || urlName.includes("canceledsales") || urlName.includes("voidsales")){
      this.currentPath = "salelist";
    }else{
      this.currentPath = urlSplit[urlSplit.length-1];
      const selectedIndex = this.debtCardService.getSelectedTabIndex();
      if (selectedIndex === 1){
        this.currentPath = "debtcardclient";
      }
    }
    this.getAllSales();
  }

  getSaleById(e: any) {
      this.currentSale = this.dataSource.find((x: any) => x.id === e.id);
      this.executeFunction(e)
  }

  getDataTarget(){
    return this.modalsName[this.currentPath];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }
}
