import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-configs',
  templateUrl: './configs.component.html',
  styleUrls: ['./configs.component.scss']
})
export class ConfigsComponent {
    constructor(
        protected api: DataService,
        private translate: TranslateService,
        private alertService: AlertService) {
        this.translate.onLangChange.subscribe((event) => {
            this.shortcutFunstions();
        });
    }

    WaiterID: number = this.api.getWaiterID();
    functions: { value: string, text: string }[];

    // Selected values
    selectedFirstFunction: string;
    selectedSecondFunction: string;
    selectedThirdFunction: string;
    selectedFourthFunction: string;
    selectedFifthFunction: string;
    selectedSixthFunction: string;


  ngOnInit() {
    this.shortcutFunstions();
    this.api.getWaiterConfigs(this.WaiterID).subscribe((waiterShortcuts: any) =>{
        this.selectedFirstFunction = waiterShortcuts.firstFunction;
        this.selectedSecondFunction = waiterShortcuts.secondFunction;
        this.selectedThirdFunction = waiterShortcuts.thirdFunction;
        this.selectedFourthFunction = waiterShortcuts.fourthFunction;
        this.selectedFifthFunction = waiterShortcuts.fifthFunction;
        this.selectedSixthFunction = waiterShortcuts.sixthFunction;
    })
  }

  shortcutFunstions(){
    this.functions = [
        { value: "dashboard", text: this.translate.instant('RETAIL') },
        { value: "wholesale", text: this.translate.instant('WHOLE_SALE') },
        { value: "sales/register", text: this.translate.instant('PAY') },
        { value: "sales/void", text: this.translate.instant('PRINT') },
        { value: "salelist", text: this.translate.instant('LIST') },
        { value: "offerslist", text: this.translate.instant('OFFERS_LIST') },
        { value: "proinvoice", text: this.translate.instant('PRO_INOVICE') },
        { value: "reservedlist", text: this.translate.instant('RESERVED') },
        { value: "canceledsales", text: this.translate.instant('CANCELED') },
        { value: "debtcard", text: this.translate.instant('EXPENSE_CARD') },
        { value: "voidsales", text: this.translate.instant('NONFISCAL_SALES') },
    ]
  }

  submit() {
    this.api.updateWaiterConfigs(this.WaiterID,
        this.selectedFirstFunction, 
        this.selectedSecondFunction, 
        this.selectedThirdFunction, 
        this.selectedFourthFunction,
        this.selectedFifthFunction,
        this.selectedSixthFunction).subscribe((response) => {
            if(response){
                this.alertService.swalFire(
                    'success',
                    this.translate.instant('CONFIG_UPDATED_SUCCESS'),
                    true,
                    false,
                    this.translate.instant('CLOSE'),
                    ''
                    ).then((e) =>{
                      window.location.reload();
                    })
            }else{
                this.alertService.swalFire(
                    'warning',
                    this.translate.instant('INVALID_USER'),
                    true,
                    false,
                    this.translate.instant('CLOSE'),
                    ''
                    )
            }
        });
  }
}
