import { ISaleDebtDetails } from "../interfaces/ISaleDebtDetails";


export class SaleDebt {
    saleId: number;
    totalDebt: number;
    totalPaid: number;
    date: Date;
    deadline: string;
    saleDebtDetails: ISaleDebtDetails[];

    constructor(saleId: number, totalDebt: number, totalPaid: number, date: Date, deadline: string, saleDebtDetails: ISaleDebtDetails[]) {
        this.saleId = saleId;
        this.totalDebt = totalDebt;
        this.totalPaid = totalPaid;
        this.date = date;
        this.deadline = deadline;
        this.saleDebtDetails = saleDebtDetails;
    }
}