<div class="order-home">
    <nav class="navbar navbar-expand-md navbar-light bg-light">
        <div class="navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2">
            <a class="navbar-brand" href="#">
                <img src="assets/images/conome-30pxh.png" width="100" height="30" alt="">
                pos
              </a>
        </div>
        <div class="mx-auto order-0">
            <a class="navbar-brand mx-auto navbar-color title-navbar">Order Status by <strong>Conome</strong></a>
        </div>
        <div class="navbar-collapse collapse w-100 order-3 dual-collapse2">
            <a class="navbar-brand ml-auto" href="#">
                <img src="{{imgLogo}}" class="companyLogo" alt="">
              </a>
        </div>
    </nav>
    <section class="order-home">
        <div class="col-md-12 vh-100 row">
            <div class="col-md-6 line-break pt-4">
                <div class="preparing-order">
                    <div class="col-md-12 title-top row">
                        <div class="col-md-6">
                            <ng-container *ngIf="isGaming == true; else showPreparing">   
                                <h1 class="title-preparing">{{'STAY-IN-THE-GAME' | translate}}</h1>
                            </ng-container>
                            <ng-template #showPreparing>
                                <h1 class="title-preparing">{{'PREPARING-ORDERS' | translate}}</h1>
                            </ng-template>
                        </div>
                        <div class="col-md-6 text-align-right ">
                        <img src="assets/images/time-left.svg"  class="{{preparingOrders.length > 12 ? 'images-icon' : 'images-icon-large'}}" alt="">
                        </div>
                    </div>
                </div>
                <div class="col-md-12 pt-4 pr-0 m-0 row" >
                    <div class="p-0 margin-side animate__animated animate__fadeInDown animate__delay-0.5s " *ngFor="let item of preparingOrders">
                        <ng-container *ngIf="isGaming == true; else showOnlyId">
                            <label class=" col-md-12 order-preparing text-order-small" >#{{item.dailyID}} {{item.comment}}</label>
                        </ng-container>
                        <ng-template #showOnlyId>
                            <label class=" col-md-12 order-preparing text-order-small" >#{{item.dailyID}}</label>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div class="col-md-6 pt-4">
                    <div class="ready-order">
                        <div class="col-md-12 title-top row">
                            <div class="col-md-6">
                                <ng-container *ngIf="isGaming == true; else showReady">
                                    <h1 class="title-ready">{{'EXIT-THE-PLAYER' | translate}}</h1>
                                </ng-container>
                                <ng-template #showReady>
                                    <h1 class="title-ready">{{'READY' | translate}}</h1>
                                </ng-template>
                                </div>
                                <div class="col-md-6 text-align-right ">
                                <img src="assets/images/desk-bell.svg"  class="{{preparingOrders.length > 12 ? 'images-icon' : 'images-icon-large'}}" alt="">
                                </div>
                        </div>
                    </div>
                <div class="col-md-12 pt-4 pr-0 m-0 row" >
                    <div class="p-0 margin-side animate__animated animate__fadeInDown animate__delay-0.5s" *ngFor="let item of ordersReady">
                        <ng-container *ngIf="isGaming == true; else showId">
                            <label class="col-md-12 text-ready-small order-ready" >#{{item.dailyID}} {{item.comment}}</label>
                        </ng-container>
                        <ng-template #showId>
                            <label class="col-md-12 text-ready-small order-ready" >#{{item.dailyID}}</label>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
