import { Component, Inject} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DataService } from '../../data.service';
import { AlertService } from 'src/app/services/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-fiscal-modal',
  templateUrl: './fiscal-modal.component.html',
  styleUrls: ['./fiscal-modal.component.css']
})
export class FiscalModalComponent {

  cashInForm = this.formBuilder.group({ cashIn: '' });
  periodicDateForm = this.formBuilder.group(
    {
      periodicDateFrom: '', 
      periodicDateTo: ''
    })

  constructor(
    public dialogRef: MatDialogRef<FiscalModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {isCashIn: boolean, userId: number},
    private formBuilder: FormBuilder,
    private api: DataService,
    private alertService: AlertService,
    private translate: TranslateService
  ) { }



  getCompanyID() {
    return JSON.parse(localStorage.getItem('waiterObject')).companyID;
  }

  closeDialog(): void {
    let waiterId = this.data.userId;
    if(this.data.isCashIn == true){
      let amount = this.cashInForm.value.cashIn;
      let companyId = this.getCompanyID();
      this.api.addMoney(amount, companyId, waiterId).subscribe((response) => {
        if(response){
          this.alertService.swalFire(
            'success',
            this.translate.instant('ADDED_MONEY_SUCCESS'),
            true,
            false,
            this.translate.instant('CLOSE'),
            ''
            )
        }
      });
      window.location.href = `crm:/10/${waiterId}/${this.cashInForm.value.cashIn}`;
      this.dialogRef.close();
    }
    else {
      window.location.href = `crm:/5/${waiterId}/${this.periodicDateForm.value.periodicDateFrom}/${this.periodicDateForm.value.periodicDateTo}`;
      this.dialogRef.close();
    }
  }

  onNoClick(){
    this.dialogRef.close();
  }

}
