import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateKeysService {

  constructor(private  translate: TranslateService) { }


  doTranslate(key: string): string {
    let result = 'UNKNOWN';
    this.translate.get(key, {value: 'world'}).subscribe((res: string) => {
        result = res;
      }
    );
    return result;
  }
}
