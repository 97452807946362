<form class="client-form">
    <mat-form-field class="client-form-field" appearance="outline">
      <mat-label>{{label | translate }}</mat-label>
      <input type="text"
             matInput
             [formControl]="searchInput"
             [matAutocomplete]="auto">
              <img *ngIf="searchInput.value && !disabled" src="/assets/images/x-inactive.svg" (click)="clearInput()">

      <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="displayFn.bind(this, displayFieldName)">
        <mat-option *ngFor="let x of filteredData | async" [value]="x" (onSelectionChange)="onOptionSelected(x)">
          {{x[displayFieldName]}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>   
  </form>