import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import { DataService } from 'src/app/data.service';
import { ISale } from 'src/app/interfaces/ISale';
import { IWaiters } from 'src/app/interfaces/IWaiters';
import { AlertService } from 'src/app/services/alert.service';
import { WindowService } from 'src/app/services/window.service';
import { environment } from 'src/environments/environment';
import { TranslateKeysService } from '../../services/translate-keys.service';

@Component({
  selector: 'app-sale-details-modal',
  templateUrl: './sale-details-modal.component.html',
  styleUrls: ['./sale-details-modal.component.css']
})

export class SaleDetailsModalComponent implements OnInit {

  constructor (
    public translate: TranslateService,
    public cdr: ChangeDetectorRef,
    protected api: DataService,
    protected activatedRoute: ActivatedRoute,
    protected alertService: AlertService,
    public translateKeys: TranslateKeysService,
    private windowService: WindowService
  ) { }

  waiterObject;
  saleCreateRole:boolean = false;
  saleEditRole:boolean = false;
  waiters;
  @Input() data;
  @Input() sale!: ISale;
  @Output() updateReadySale: EventEmitter<ISale> = new EventEmitter();

  bC = new BroadcastChannel("pos_channel");
  
  ngOnInit() {
    this.waiterObject = JSON.parse(localStorage.getItem('waiterObject'))
    this.getWaiterRoles();
  }

  printInvoice(){
    let mywindow = window.open('', 'PRINT', 'height=400,width=600');

    mywindow.document.write('<html><head><title>' + document.title  + '</title>');
    mywindow.document.write('</head><body >');
    mywindow.document.write('<h1>' + document.title  + '</h1>');
    mywindow.document.write(this.data[0]);
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return true;
  }

  getWaiterRoles(){
    this.api.getWaiters()
    .subscribe(async (waiters: IWaiters[]) => {
      this.waiters = waiters.find(x => x.id == this.waiterObject.waiterID);
      this.waiters.userRole.forEach(data => {
        if(data.rolename == "Sale create"){
          this.saleCreateRole = true;
        }
        if(data.rolename == "Sale edit"){
          this.saleEditRole = true;
        }
      })
    });
  }

  createSaleReport(saleID: number): void {
    const domain = environment.API_URL.split('/api')[0];
    const url = `${domain}/Reports/CreateSaleReport/${saleID}`

    window.open(url, '_blank').focus();
  }

  shortSaleReport(saleID: number): void {
    const domain = environment.API_URL.split('/api')[0];
    const url = `${domain}/Reports/CreateShortSaleReport1/${saleID}`

    window.open(url, '_blank').focus();
  }

  offerReport(saleID: number): void {
    const domain = environment.API_URL.split('/api')[0];
    const url = `${domain}/Reports/CreateOfferReport/${saleID}`

    window.open(url, '_blank').focus();
  }

  proInvoiceReport(saleID: number): void {
    const domain = environment.API_URL.split('/api')[0];
    const url = `${domain}/Reports/CreateProInvoiceReport/${saleID}`

    window.open(url, '_blank').focus();
  }

  toDecimal(colorString){
    return parseInt(colorString, 16);
  }

  updateOrderSale(sale: ISale){
   this.api.updateSales(sale.id, true).subscribe(() => {
     sale.orderStatus = true;
     this.updateReadySale.emit(sale);
     this.bC.postMessage(
      {status: 'update', sale }
     )
   });
  }

  updateSale(){
      this.api.updateTypeSale(this.sale.id)
      .subscribe(async (response: {message: string, saleID: number, statusCode: number}) => {
        if (response.statusCode === 201) {
          this.alertService.swalFire('success',  this.translate.instant('ORDER_CREATED'), true, false, this.translate.instant("Close")).then(promise => {
            window.location.reload();
          });
        } else {
          this.alertService.swalFire('error', response.message, false, true, '' , this.translate.instant('CLOSE')) .then(promise => {
            window.location.reload();
          });
        }
      })
  }

  getCompanyID() {
    return JSON.parse(localStorage.getItem('waiterObject')).companyID;
  }

  getUserId() {
    let userId: number;
    this.activatedRoute.paramMap.subscribe((param) => {
      userId = Number(param.get('id'));
    });
    return userId;
  }

  cancelFiscal(id: number){
    var userId = this.getUserId();
    this.api.cancelFiscal(id,userId).subscribe(data => {
      if(data.hasFiscal == true && data.paymentID == 1){
        this.windowService.windowRef.location = `crm:/2/${data.user}/${id}`;
      }
      if (data.hasFiscal == true && data.paymentID != 1) {
        this.windowService.windowRef.location = `crm:/10/${data.user}/${id}`;
      }
      location.reload();
    })
  }
}

