<app-navbar></app-navbar>

<div class="container-fluid pt-5">
  <div class="row">
    <div class="col-md-12">
      <form #filter="ngForm">
        <div class="row form-group form-inline">
          <div class="col-md-12 col-lg-2 col-xl-2 pb-2 pt-2">
            <input type="text"
                   class="form-control w-100 text-center "
                   id="dateFilter"
                   name="dateFilter"
                   ngModel
                   placeholder="{{'CHOOSE_DATE' | translate}}"
                   ngbDatepicker
                   [(ngModel)]="model"
                   #dateFilter="ngbDatepicker"
                   (click)="dateFilter.toggle();"
                   (dateSelect)="showByDate($event)"
            />
          </div>
          <div class="col-md-12 col-lg-2 col-xl-2 pb-2 pt-2">
            <button class="btn reserveButton btn-block" appSelectButton (click)="showAll();resetForm(filter)">{{'ALL' | translate }}</button>
          </div>
          <div class="col-md-12 col-lg-2 col-xl-2 pb-2 pt-2">
            <button class="btn reserveButton btn-block" appSelectButton (click)="selectToday();showByDate(model)">{{'TODAY' | translate }}</button>
          </div>
          <div class="col-md-12 col-lg-2 col-xl-2 pb-2 pt-2 ml-auto">
            <button class="btn reserveButton btn-block focus" (click)="goToOrder()"  >{{'NEW_ORDER' | translate}}</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col-md-12">
      <button
        class="btn buttonCollapse border p-3 rounded-lg active btn-block text-right dropdown-toggle"
        type="button" data-toggle="collapse" data-target="#collapseExample"
        aria-expanded="false"
        aria-controls="collapseExample"

      >
        <span class="float-left txtOverflow">{{'ACTIVE_ORDERS' | translate }}</span> <span class="caret"></span>
      </button>
    </div>
  </div>

  <div class="row justify-content-center pb-4">
    <div class="col-md-12">
      <div class="collapse pt-2" id="collapseExample">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{{'DATE' | translate }}</th>
              <th scope="col">{{'HOUR' | translate }}</th>
              <th scope="col">{{'STATUS' | translate }}</th>
              <th scope="col">{{'TOTAL' | translate }}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let sale of activeSales"
                data-toggle="modal"
                data-target="#saleDetailsModal"
                (click)="getSaleById(sale.id)"
            >
              <th scope="row">{{ sale.id }}</th>
              <td>{{ sale.modified | date: 'dd/MM/yyyy' }}</td>
              <td>{{sale.modified | date : 'HH:mm'}}</td>
              <td *ngIf="translate.currentLang === 'sq'">{{sale.status ? 'Paguar': 'Pa paguar'}}</td>
              <td *ngIf="translate.currentLang === 'mk'">{{sale.status ? 'Платено': 'Не Платено'}}</td>
              <td>{{sale.total | number : '1.2-2'}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-12 ">
      <button
        class="btn buttonCollapse border txtOverflow p-3 rounded-lg active btn-block text-right dropdown-toggle "
        type="button" data-toggle="collapse"
        data-target="#closedOrders"
        aria-expanded="false"
        aria-controls="closedOrders">
        <span class="float-left txtOverflow">{{'CLOSED_ORDERS' | translate }}</span> <span class="caret"></span>
      </button>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col-md-12">
      <div class="collapse pt-2" id="closedOrders">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{{'DATE' | translate }}</th>
              <th scope="col">{{'HOUR' | translate }}</th>
              <th scope="col">{{'STATUS' | translate }}</th>
              <th scope="col">{{'TOTAL' | translate }}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let sale of closedSales"
                data-toggle="modal"
                data-target="#saleDetailsModal"
                (click)="getSaleById(sale.id)"

            >
              <td scope="row">{{ sale.id }}</td>
              <td>{{ sale.modified | date: 'dd/MM/yyyy' }}</td>
              <td>{{sale.modified | date : 'HH:mm'}}</td>
              <td *ngIf="translate.currentLang === 'sq'">{{sale.status ? 'Paguar': 'Pa paguar'}}</td>
              <td *ngIf="translate.currentLang === 'mk'">{{sale.status ? 'Платено': 'Не Платено'}}</td>
              <td>{{sale.total | number : '1.2-2'}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<app-sale-details-modal [sale]="currentSale"></app-sale-details-modal>
