import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { WindowService } from './window.service';
import { DataService } from '../data.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  hubConnection: signalR.HubConnection;
  message$ = new Subject<string>();

  connected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  constructor(private windowService: WindowService, private api: DataService, private http: HttpClient) {
    this.hubConnection = new signalR.HubConnectionBuilder().withUrl(`${environment.API_URL}/signalPrint/pos-offline`).withAutomaticReconnect().configureLogging(signalR.LogLevel.Information).build();
  }

  startConnection(): void {
    this.hubConnection.start().then(() => {
      this.hubConnection.onclose(() => {
        this.connected$.next(false);
      })
      this.connected$.next(true);
      console.log("Connection Started");
      this.hubConnection.onreconnected(() => {
        this.joinCompanyGroup();
      })
      try {
        this.joinCompanyGroup();
      } catch (error) {
        console.error("Join Error: ", error);
      }
    }).catch((err) => {
      console.error(`Error while starting connection: ${err}`)
      setTimeout(() => this.startConnection(), 5000);
    })
  }

  joinCompanyGroup() {
    const companyID = this.api.getWaiterData()?.companyID || null;
    if (companyID) {
        this.joinGroup(companyID);
      }
  }

  leaveCompanyRoom() {
    const companyID = this.api.getWaiterData()?.companyID || null;
    if (companyID) {
      this.leaveRoom(companyID);
    }
  }

  joinGroup(userId: string) {
    this.hubConnection.invoke('JoinGroup', userId);
  }

  leaveRoom(userId: string) {
    this.hubConnection.invoke('LeaveRoom', userId);
  }

  sendMessage(event:string, message: string) {
    const companyID = this.api.getWaiterData().companyID;
    this.hubConnection.invoke(event, companyID, message);
  }

  recieveMessage(event: string): Observable<any> {
    return new Observable<any>(observer => {
      this.hubConnection.on(event, (user, message) => {
        observer.next({user, message})
      })
    })
  }

  createFiscal(fiscal: string) {
    if (fiscal !== null) {
      let saleId = fiscal.split('/')[3];
      this.windowService.windowRef.location = fiscal;
      // setTimeout(() => {
      // this.hasPrinted(saleId).subscribe((res: any) => {
      //     if (!res.isExecuted) {
      //       this.createFiscal(fiscal);
      //     }
      //   })
      // }, 2000)
    }
  }

  hasPrinted(saleId: string) {
    return this.http.get(`${environment.API_URL}/salesapi/GetDesktopExecution?saleId=${saleId}`)
  }

}
