<app-navbar></app-navbar>


<div class="container-fluid pt-5">
  <div class="row">
    <div class="col-md-12">
      <form #filter="ngForm" class="">
        <div class="form-group form-inline row">
          <div class="col-md-12 col-lg-2 col-xl-2">
          <input type="text"
                 class="form-control w-100 pb-2 pt-2 text-center"
                 id="dateFilter"
                 name="dateFilter"
                 ngModel
                 placeholder="{{'CHOOSE_DATE' | translate}}"
                 ngbDatepicker
                 [(ngModel)]="model"
                 #dateFilter="ngbDatepicker"
                 (click)="dateFilter.toggle();"
                 (dateSelect)="getAllReservationsByDate($event)"/>
          </div>
            <div class="col-md-12 col-lg-2 col-xl-2  pb-2 pt-2">
              <button class="btn reserveButton btn-block " appSelectButton (click)="getAllReservations();resetForm(filter)">{{'ALL' | translate }}</button>
            </div>
            <div class="col-md-12 col-lg-2 col-xl-2  pb-2 pt-2">
              <button class="btn reserveButton btn-block" appSelectButton (click)="selectToday();getAllReservationsByDate(model)">{{'TODAY' | translate }}</button>
            </div>

          <div class="col-md-12 col-lg-2 col-xl-2  pb-2 pt-2 ml-auto">
            <button class="btn reserveButton btn-block focus active"
              data-toggle="modal"
              data-target="#reservationModal" (click)="changeMode()">{{'CREATE_RESERVATION' | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <button
        class="btn buttonCollapse border txtOverflow p-3 rounded-lg active btn-block text-right dropdown-toggle "
        type="button" data-toggle="collapse"
        data-target="#activeReservations"
        aria-expanded="false"
        aria-controls="activeReservations">
        <span class="float-left txtOverflow">{{'ACTIVE_RESERVATIONS' | translate}}</span> <span class="caret"></span>
      </button>
    </div>
  </div>

    <div class="row justify-content-center pb-4">
      <div class="col-md-12 ">
        <div class="collapse pt-2" id="activeReservations">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">{{'DATE' | translate }}</th>
                <th scope="col">{{'HOUR' | translate }}</th>
                <th scope="col">{{'PERSON_NUMBER' | translate }}</th>
                <th scope="col">{{'TABLE' | translate }}</th>
                <th scope="col">{{'PERSON_NAME' | translate }}</th>
                <th scope="col">{{'CONTACT' | translate }}</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let reservation of reservations?.active"
                  (click)="filterByID(reservation.id)"
                  data-toggle="modal"
                  data-target="#reservationModal">
                <td scope="row" class="font-weight-bold" >{{reservation.id}}</td>
                <td scope="row" class="font-weight-bold" >{{reservation.id}}</td>
                <td >{{reservation.date | date:  'dd/MM/yyyy'}}</td>
                <td >{{reservation.timeFrom}} - {{reservation.timeTo}}</td>
                <td >{{reservation.personsNo}}</td>
                <td >{{reservation?.customTableID}}</td>
                <td >{{reservation.personName}}</td>
                <td >{{reservation.phone}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  <div class="row">
    <div class="col-md-12">
      <button
        class="btn  buttonCollapse border txtOverflow p-3 rounded-lg active btn-block text-right dropdown-toggle "
        type="button" data-toggle="collapse"
        data-target="#closedReservations"
        aria-expanded="false"
        aria-controls="closedReservations">
        <span class="float-left txtOverflow">{{'CLOSED_RESERVATIONS' | translate}}</span> <span class="caret"></span>
      </button>
    </div>
  </div>

  <div class="row justify-content-center pb-4">
    <div class="col-md-12 ">
      <div class="collapse pt-2" id="closedReservations">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{{'DATE' | translate }}</th>
              <th scope="col">{{'HOUR' | translate }}</th>
              <th scope="col">{{'PERSON_NUMBER' | translate }}</th>
              <th scope="col">{{'TABLE' | translate }}</th>
              <th scope="col">{{'PERSON_NAME' | translate }}</th>
              <th scope="col">{{'CONTACT' | translate }}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let reservation of reservations?.closed"
                (click)="filterByID(reservation.id)"
                data-toggle="modal"
                data-target="#reservationModal">
              <td scope="row" class="font-weight-bold" >{{reservation.id}}</td>
              <td >{{reservation.date | date:  'dd/MM/yyyy'}}</td>
              <td >{{reservation.timeFrom}} - {{reservation.timeTo}}</td>
              <td >{{reservation.personsNo}}</td>
              <td >{{reservation?.customTableID}}</td>
              <td >{{reservation.personName}}</td>
              <td >{{reservation.phone}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</div>


<div class="modal fade" id="reservationModal" tabindex="-1" role="dialog" aria-labelledby="reservationModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title fontArial18" id="reservationModalLabel">{{'RESERVE' | translate }}</h5>
        <button type="button" id="closeModal" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
        <form *ngIf="!this.editMode; else editForm"
              [formGroup]="reservationForm"
              (ngSubmit)="createReservation()"
              class="tpadding30 tableFormLabel" >
          <div class="form-group">
            <input type="hidden"
                   class="form-control"
                   id="companyID"
                   name="companyID"
                   formControlName="companyID"
                   placeholder=""/>
          </div>
          <div class="form-group">
        <input
               class="form-control text-center"
               id="date"
               name="date"
               ngModel
               [(ngModel)]="model"
               ngbDatepicker
               placeholder="Data"
               formControlName="date"
               #date="ngbDatepicker" (click)="date.toggle()"  />
      </div>
        <div class="row">
          <div class="form-group col-6 text-center">
            <label for="timeFrom">{{'TIME_FROM' | translate }}</label>
            <ngb-timepicker
              class="row justify-content-center"
              [(ngModel)]="timeFrom"
              id="timeFrom"
              formControlName="timeFrom">
            </ngb-timepicker>
          </div>
          <div class="form-group col-6 text-center">
            <label for="timeTo">{{'TIME_TO' | translate }}</label>
            <ngb-timepicker
              class="row justify-content-center"
              ngModel [(ngModel)]="timeTo"
              id="timeTo"
              formControlName="timeTo">
            </ngb-timepicker>
          </div>
        </div>
          <div class="form-group">
            <label for="tableID">{{'TABLE' | translate }}</label>
            <select class="custom-select dropdownOption" (change)="selectedTable(tableID.value)"
                    id="tableID"
                    name="tableID"
                    #tableID
                    formControlName="tableID"
                  >
              <option *ngFor="let table of tables;let i=index"
                      [value]="table.id">
                {{table.customID}}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="personsNo">{{'PERSON_NUMBER' | translate }}</label>
            <input type="number"
                   class="form-control"
                   id="personsNo"
                   minlength="1"
                   name="personsNo"
                   #personsNo
                   formControlName="personsNo"
                   (keyup)="checkSeatsNumber(personsNo, $event)"
                   [placeholder]="selectedTable(tableID.value)?.tableChairs !== null? selectedTable(tableID.value).tableChairs: '1'"
            >
          </div>
          <div class="form-group">
            <label for="personName">{{'PERSON_NAME' | translate }}</label>
            <input type="text"
                   id="personName"
                   name="personName"
                   class="form-control"
                   formControlName="personName"
            >
          </div>
          <div class="form-group">
            <label for="phone">{{'CONTACT' | translate }}</label>
            <input type="text"
                   id="phone"
                   name="phone"
                   class="form-control"
                   formControlName="phone"
            >
          </div>
          <button type="submit" class="btn btn-block saveStyle">{{'RESERVE' | translate }}</button>
        </form>
        <ng-template #editForm>
          <form [formGroup]="reservationForm" (ngSubmit)="updateReservation()" class="tpadding30 tableFormLabel" >
            <div class="form-group">
              <input type="hidden"
                     class="form-control"
                     name="companyID"
                     formControlName="companyID"
                     placeholder=""
              />
            </div>

            <div class="form-group">
              <input
                class="form-control"
                name="date"
                ngModel
                [(ngModel)]="model"
                ngbDatepicker
                formControlName="date"

                #date="ngbDatepicker" (click)="date.toggle()"  />
            </div>
            <div class="row">
              <div class="form-group col-6 text-center">
                <label for="timeFrom">{{'TIME_FROM' | translate }}</label>
                <ngb-timepicker
                  class="row justify-content-center"
                  [(ngModel)]="timeFrom"

                  formControlName="timeFrom">
                </ngb-timepicker>
              </div>
              <div class="form-group col-6 text-center">
                <label for="timeTo">{{'TIME_TO' | translate }}</label>
                <ngb-timepicker
                  class="row justify-content-center"
                  ngModel [(ngModel)]="timeTo"
                  formControlName="timeTo">
                </ngb-timepicker>
              </div>
            </div>
            <div class="form-group">
              <label for="tableID">{{'TABLE' | translate }}</label>
              <select class="custom-select dropdownOption"
                      name="tableID"
                      formControlName="tableID"
                      (change)="checkTable()"
              >
                <option *ngFor="let table of tables" [value]="table.id">{{table.customID}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="personsNo">{{'PERSON_NUMBER' | translate }}</label>
              <input type="number"
                     class="form-control"
                     minlength="1"
                     value="1"
                     name="personsNo"
                     formControlName="personsNo"
              >
            </div>

            <div class="form-group">
              <label for="personName">{{'PERSON_NAME' | translate }}</label>
              <input type="text"
                     name="personName"
                     class="form-control"
                     formControlName="personName"
              >
            </div>
            <div class="form-group">
              <label for="phone">{{'CONTACT' | translate }}</label>
              <input type="number"
                     name="phone"
                     class="form-control"
                     formControlName="phone"
              >
            </div>
            <button type="submit" class="btn btn-block saveStyle">{{'SAVE' | translate }}</button>
          </form>
          <div class="col tpadding30 pt-0">
            <button (click)="deleteReservation(this.currentReservation.id)" class="btn btn-block deleteStyle">{{'DELETE' | translate }}</button>
          </div>
        </ng-template>
      </div>
  </div>
</div>
</div>

