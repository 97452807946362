export class SaleDebtDetail {
    saleDebtId: number;
    totalPaid: number;
    totalLeft: number;
    amountPaid: number;
    personWhoPaid: string;
    date: Date;

    constructor(
        saleDebtId: number,
        totalPaid: number,
        totalLeft: number,
        amountPaid: number,
        personWhoPaid: string,
        date: Date ) { 
            this.saleDebtId = saleDebtId;
            this.totalPaid = totalPaid;
            this.totalLeft = totalLeft;
            this.amountPaid = amountPaid;
            this.personWhoPaid = personWhoPaid;
            this.date = date;
        }
}