import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/data.service';
import { IConfigs } from 'src/app/interfaces/IConfigs';
import { ISale } from 'src/app/interfaces/ISale';
import { IWaiters } from 'src/app/interfaces/IWaiters';
import { AlertService } from 'src/app/services/alert.service';
import { DebtCardService } from 'src/app/services/debt-card.service';
import { OfflineCrud } from 'src/app/services/offline-crud.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-debt-card',
  templateUrl: './debt-card.component.html',
  styleUrls: ['./debt-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DebtCardComponent implements OnInit {
  configs: IConfigs;
  saleDebtForm = new FormGroup({
    totalPaid: new FormControl('', Validators.required),
    personWhoPaid: new FormControl('', Validators.required),
    date: new FormControl('')
  });
  editSaleDebtForm = new FormGroup({
    totalPaid: new FormControl('', Validators.required),
    personWhoPaid: new FormControl('', Validators.required),
    date: new FormControl('')
  });
  clientDebtForm = new FormGroup({
    totalPaid: new FormControl(''),
    personWhoPaid: new FormControl(''),
    account: new FormControl(''),
    date: new FormControl('')
  });
  saleDebts: any;
  clientData: any;
  saleId: number;
  saleDebtId: number;
  clientDebtId: number;
  saleDebtDetailId: number;
  debtPaid: boolean = false;
  saleDebtCreateRole: boolean = false;
  saleDebtEditRole: boolean = false;
  saleDebtDeleteRole: boolean;
  lastAmountPaid;
  waiters;
  totalDebtLeft;
  today: string = new Date().toISOString().split('T')[0];

  currentSale!: any;
  dataSource!: any;
  selectedButton = "";
  isLoading = false;

  filterBy: boolean | undefined = undefined;
  selectedTabIndex = 0;
  totalPaid: number;
  totalDebt: number;
  isPayAllChecked: boolean = false;
  isPayAllCheckedClient: boolean = false;

  setCurrentSale(currentSale: any) {
    this.saleDebtForm.get('totalPaid').setValue('');
    this.saleDebtForm.get('personWhoPaid').setValue('');
    this.saleDebtForm.get('date').setValue(this.today);
    this.clientDebtForm.get('totalPaid').setValue('');
    this.clientDebtForm.get('personWhoPaid').setValue('');
    this.clientDebtForm.get('account').setValue('');
    this.clientDebtForm.get('date').setValue(this.today);
    this.isPayAllChecked = false;
    this.isPayAllCheckedClient = false;
    this.saleId = currentSale.saleId;
    this.saleDebtId = currentSale.id;
    this.currentSale = currentSale
    this.totalPaid = currentSale.totalPaid;
    this.totalDebt = currentSale.totalDebt;
    if(this.selectedTabIndex === 0){
      this.saleDebtDetails(currentSale.id);
    }else{
      this.clientDebtDetails(currentSale.id);
    }
  }

  setDataSource(dataSource: any) {
    this.dataSource = dataSource
  }

  
  updateReadySale(data: ISale) {
    const index = this.dataSource.data.findIndex((x: any) => x.id === data.id);
    this.dataSource.data[index] = data;
  }

  displayedColumns: any = [ 
    { name: 'No_Sale', resizeable: false, prop: 'noSale' },  
    { name: 'ORDINAL_NUMBER', resizeable: false, prop: 'ordinalNumber' },
    { name: 'totalDebt', resizeable: false, prop: 'totalDebt' },   
    { name: 'TOTAL_PAID', resizeable: false, prop: 'totalPaid' },  
    { name: 'TOTAL_DEBT_LEFT', resizeable: false, prop: 'totalLeft' },
    { name: 'date', resizeable: false, prop: 'date' },  
    { name: 'DEADLINE', resizeable: false, prop: 'deadline' },  
    { name: 'TOTAL', resizeable: false, prop: 'total' },  
    { name: 'STATUS', resizeable: false, prop: 'status' },  
    { name: 'CREATED', resizeable: false, prop: 'created' },  
    { name: 'MODIFIED', resizeable: false, prop: 'modified' },  
    { name: 'ORDER_STATUS', resizeable: false, prop: 'orderStatus' },
  ];

  allColumns: any = [ 
    { name: 'No_Sale', resizeable: false, prop: 'noSale' },  
    { name: 'ORDINAL_NUMBER', resizeable: false, prop: 'ordinalNumber' },  
    { name: 'totalDebt', resizeable: false, prop: 'totalDebt' },   
    { name: 'TOTAL_PAID', resizeable: false, prop: 'totalPaid' },  
    { name: 'TOTAL_DEBT_LEFT', resizeable: false, prop: 'totalLeft' },
    { name: 'DATE', resizeable: false, prop: 'date' },  
    { name: 'DEADLINE', resizeable: false, prop: 'deadline' },   
    { name: 'TOTAL', resizeable: false, prop: 'total' },  
    { name: 'STATUS', resizeable: false, prop: 'status' },  
    { name: 'CREATED', resizeable: false, prop: 'created' },  
    { name: 'MODIFIED', resizeable: false, prop: 'modified' },  
    { name: 'ORDER_STATUS', resizeable: false, prop: 'orderStatus' },
  ];

  
  allColumnsClient =  [ 
    { name: 'name', resizeable: false, prop: 'nameSQ' },  
    { name: 'totalDebt', resizeable: false, prop: 'totalDebt' },  
    { name: 'TOTAL_DEBT_LEFT', resizeable: false, prop: 'totalLeft' },  
    { name: 'TOTAL_PAID', resizeable: false, prop: 'totalPaid' },  
  ]
  
  apiCall(): Observable<any> {    
    return new Observable((observer) => {
      let waiterObj = JSON.parse(localStorage.getItem('waiterObject'));
      this.api.getSaleDebts(waiterObj.companyID).subscribe((data: any) => {
        if (this.filterBy === true) {
          data = data.filter((x: any) => x.status === true);
        }else if(this.filterBy === false){
          data = data.filter((x: any) => x.status === false);
        }
        observer.next(data);
        observer.complete();
      }, (error: any) => {
        observer.error(error);
      });
    });
  }

  apiCallClient(){
    return this.debtCardService.getDebtsByClient();
  }

  clientDataSource = new MatTableDataSource<any>();
  clientColumns: string[] = ['name', 'totalDebt', 'totalLeft', 'totalPaid',];
  selectedLanguage: string = 'sq';
  selectedDropdown = 10;
  selectedDropdown1 = 10;
  selectedDropdown2 = 10;
  selectedDropdownClient = 10
  paginatorSelectednumber = 1;
  paginatorSelectednumber1 = 1;
  paginatorSelectednumber2 = 1;
  clientPaginator = 1;
  clientPageSize: number;
  pager: any = {};

  clientPager:any = {};

  @ViewChild('allSaleDebtPaginator') allSaleDebtPaginator: MatPaginator;
  @ViewChild('paidSaleDebtPaginator') paidSaleDebtPaginator: MatPaginator;
  @ViewChild('unpaidSaleDebtPaginator') unpaidSaleDebtPaginator: MatPaginator;
  @ViewChild('clientDebtPaginator') clientDebtPaginator: MatPaginator;

  @ViewChild('allSaleDebtSort') allSaleDebtSort: MatSort;
  @ViewChild('paidSaleDebtSort') paidSaleDebtSort: MatSort;
  @ViewChild('unpaidSaleDebtSort') unpaidSaleDebtSort: MatSort;
  @ViewChild('clientDebtSort') clientDebtSort: MatSort;

  @ViewChild('saleDebtDetailsModal', { static: false }) saleDebtDetailsModal: ElementRef;
  constructor(
    private debtCardService: DebtCardService,
    private translate: TranslateService,
    private alertService: AlertService,
    private api: DataService,
    private offlineService: OfflineCrud,
  ) { 
    this.translate.onLangChange.subscribe((event) => {
      this.filterSales(undefined);
    });
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(data => {
      this.selectedLanguage = data.lang;
    })
    this.getWaiterRoles();
    this.registerClickOutsideListener();
  }

  registerClickOutsideListener() {
    document.addEventListener('click', this.onDocumentClick.bind(this));
  }

  onDocumentClick(event: MouseEvent) {
    const clickedInside = this.saleDebtDetailsModal.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.markControlsAsUntouched();
    }
  }

  markControlsAsTouched() {
    this.saleDebtForm.controls['totalPaid'].markAsTouched();
    this.saleDebtForm.controls['personWhoPaid'].markAsTouched();
  }

  markControlsAsUntouched() {
    this.saleDebtForm.controls['totalPaid'].markAsUntouched();
    this.saleDebtForm.controls['personWhoPaid'].markAsUntouched();
  }

  //All SalesDebt Paginator
  previousPage() {
    if (this.dataSource.paginator.hasPreviousPage()) {
      this.dataSource.paginator.previousPage();
    }
    this.paginatorSelectednumber--;
    // this.pager = this.getPager(this.pageSize, this.paginatorSelectednumber, this.selectedDropdown);
  }

  nextPage() {
    if (this.dataSource.paginator.hasNextPage()) {
      this.dataSource.paginator.nextPage();
    }
    this.paginatorSelectednumber++;
    // this.pager = this.getPager(this.pageSize, this.paginatorSelectednumber, this.selectedDropdown);
  }

  numberPage(page) {
    this.dataSource.paginator.firstPage();
    for (let i = 0; i < page - 1; i++) {
      this.dataSource.paginator.nextPage();
    }

    this.paginatorSelectednumber = page;
    // this.pager = this.getPager(this.pageSize, this.paginatorSelectednumber, this.selectedDropdown);

  }

  tableLength(tableLengthValue) {
    this.dataSource.paginator._changePageSize(+tableLengthValue);
    this.paginatorSelectednumber = this.dataSource.paginator.pageIndex + 1;
    this.selectedDropdown = tableLengthValue;
    // this.pager = this.getPager(this.pageSize, this.paginatorSelectednumber, this.selectedDropdown);
  }

  //Client Debts Paginator

  previousClientPage() {
    if (this.clientDataSource.paginator.hasPreviousPage()) {
      this.clientDataSource.paginator.previousPage();
    }
    this.clientPaginator--;
    this.clientPager = this.getPager(this.clientPageSize, this.clientPaginator, this.selectedDropdownClient);
  }

  nextClientPage() {
    if (this.clientDataSource.paginator.hasNextPage()) {
      this.clientDataSource.paginator.nextPage();
    }
    this.clientPaginator++;
    this.clientPager = this.getPager(this.clientPageSize, this.clientPaginator, this.selectedDropdownClient);
  }

  numberClientPage(page) {
    this.clientDataSource.paginator.firstPage();
    for (let i = 0; i < page - 1; i++) {
      this.clientDataSource.paginator.nextPage();
    }
    this.clientPaginator = page;
    this.clientPager = this.getPager(this.clientPageSize, this.clientPaginator, this.selectedDropdownClient);
  }

  tableClientLength(tableLengthValue) {
    this.clientDataSource.paginator._changePageSize(+tableLengthValue);
    this.clientPaginator = this.clientDataSource.paginator.pageIndex + 1;
    this.selectedDropdownClient = tableLengthValue;
    this.clientPager = this.getPager(this.clientPageSize, this.clientPaginator, this.selectedDropdownClient);
  }


  getWaiterRoles() {
    const waiterObject = JSON.parse(localStorage.getItem('waiterObject'));

    if (this.configs?.settings.offlineSales == false) {
      this.api.getWaiters()
        .subscribe(async (waiters: IWaiters[]) => {

          this.waiters = waiters.find(x => x.id == waiterObject.waiterID);
          this.waiters.userRole.forEach(data => {
            if (data.rolename == "Debt create") {
              this.saleDebtCreateRole = true;
            }
            if (data.rolename == "Debt edit") {
              this.saleDebtEditRole = true;
            }
            if (data.rolename == "Debt delete") {
              this.saleDebtDeleteRole = true;
              this.displayedColumns = [ 
                { name: 'name', resizeable: false, prop: 'name' },  
                { name: 'No_Sale', resizeable: false, prop: 'noSale' },  
                { name: 'ORDINAL_NUMBER', resizeable: false, prop: 'ordinalNumber' },  
                { name: 'totalDebt', resizeable: false, prop: 'totalDebt' },   
                { name: 'TOTAL_PAID', resizeable: false, prop: 'totalPaid' },  
                { name: 'TOTAL_DEBT_LEFT', resizeable: false, prop: 'totalLeft' },
                { name: 'DATE', resizeable: false, prop: 'date' },  
                { name: 'DEADLINE', resizeable: false, prop: 'deadline' },  
                { name: 'TOTAL', resizeable: false, prop: 'total' },  
                { name: 'STATUS', resizeable: false, prop: 'status' },  
                { name: 'CREATED', resizeable: false, prop: 'created' },  
                { name: 'MODIFIED', resizeable: false, prop: 'modified' },  
                { name: 'ORDER_STATUS', resizeable: false, prop: 'orderStatus' }
              ];
            
               this.allColumns = [ 
                { name: 'name', resizeable: false, prop: 'name' },  
                { name: 'No_Sale', resizeable: false, prop: 'noSale' },  
                { name: 'ORDINAL_NUMBER', resizeable: false, prop: 'ordinalNumber' },  
                { name: 'totalDebt', resizeable: false, prop: 'totalDebt' },   
                { name: 'TOTAL_PAID', resizeable: false, prop: 'totalPaid' },  
                { name: 'TOTAL_DEBT_LEFT', resizeable: false, prop: 'totalLeft' },
                { name: 'DATE', resizeable: false, prop: 'date' },  
                { name: 'DEADLINE', resizeable: false, prop: 'deadline' },  
                { name: 'TOTAL', resizeable: false, prop: 'total' },  
                { name: 'STATUS', resizeable: false, prop: 'status' },  
                { name: 'CREATED', resizeable: false, prop: 'created' },  
                { name: 'MODIFIED', resizeable: false, prop: 'modified' },  
                { name: 'ORDER_STATUS', resizeable: false, prop: 'orderStatus' }
              ];
            }
          })
        });
    } else {
      this.offlineService.getWaitersFromLocalDb().then(async (data: IWaiters[]) => {
        this.waiters = data.find(x => x.id == waiterObject.waiterID);
        this.waiters.userRole.forEach(data => {
          if (data.rolename == "Debt create") {
            this.saleDebtCreateRole = true;
          }
          if (data.rolename == "Debt edit") {
            this.saleDebtEditRole = true;
          }
          if (data.rolename == "Debt delete") {
            this.saleDebtDeleteRole = true;

            this.displayedColumns = [ 
              { name: 'name', resizeable: false, prop: 'name' },  
              { name: 'No_Sale', resizeable: false, prop: 'noSale' },  
              { name: 'ORDINAL_NUMBER', resizeable: false, prop: 'ordinalNumber' },  
              { name: 'totalDebt', resizeable: false, prop: 'totalDebt' },   
              { name: 'TOTAL_PAID', resizeable: false, prop: 'totalPaid' },  
              { name: 'TOTAL_DEBT_LEFT', resizeable: false, prop: 'totalLeft' },
              { name: 'DATE', resizeable: false, prop: 'date' },  
              { name: 'DEADLINE', resizeable: false, prop: 'deadline' },  
              { name: 'TOTAL', resizeable: false, prop: 'total' },  
              { name: 'STATUS', resizeable: false, prop: 'status' },  
              { name: 'CREATED', resizeable: false, prop: 'created' },  
              { name: 'MODIFIED', resizeable: false, prop: 'modified' },  
              { name: 'ORDER_STATUS', resizeable: false, prop: 'orderStatus' }
            ];
          
             this.allColumns = [ 
              { name: 'name', resizeable: false, prop: 'name' },  
              { name: 'No_Sale', resizeable: false, prop: 'noSale' },  
              { name: 'ORDINAL_NUMBER', resizeable: false, prop: 'ordinalNumber' },  
              { name: 'totalDebt', resizeable: false, prop: 'totalDebt' },   
              { name: 'TOTAL_PAID', resizeable: false, prop: 'totalPaid' },  
              { name: 'TOTAL_DEBT_LEFT', resizeable: false, prop: 'totalLeft' },
              { name: 'DATE', resizeable: false, prop: 'date' },  
              { name: 'DEADLINE', resizeable: false, prop: 'deadline' },  
              { name: 'TOTAL', resizeable: false, prop: 'total' },  
              { name: 'STATUS', resizeable: false, prop: 'status' },  
              { name: 'CREATED', resizeable: false, prop: 'created' },  
              { name: 'MODIFIED', resizeable: false, prop: 'modified' },  
              { name: 'ORDER_STATUS', resizeable: false, prop: 'orderStatus' }
            ];
          }
        })
      })
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    // this.pageSize = this.dataSource.filteredData.length;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
      this.paginatorSelectednumber = 1;
      // this.pager = this.getPager(this.pageSize, this.paginatorSelectednumber, this.selectedDropdown);
    }
  }



  applyFilterForClient(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.clientDataSource.filter = filterValue.trim().toLowerCase();
    this.clientPageSize = this.clientDataSource.filteredData.length;
    if (this.clientDataSource.paginator) {
      this.clientDataSource.paginator.firstPage();
      this.clientPaginator = 1;
      this.clientPager = this.getPager(this.clientPageSize, this.clientPaginator, this.selectedDropdownClient);
    }
  }

  getSaleDebts(tabIndex?) {
    this.debtCardService.getSaleDebts().subscribe(data => {
      this.dataSource = data.filter(x => x.isDeleted == false);

      // this.dataSource.paginator = this.allSaleDebtPaginator;
      // this.dataSource.sort = this.allSaleDebtSort;
      // if (tabIndex == 1) {
      //   this.dataSource.data = data.filter(x => x.status == true && x.isDeleted == false);
      //   this.dataSource.paginator = this.paidSaleDebtPaginator;
      //   this.dataSource.sort = this.paidSaleDebtSort;
      // }
      // if (tabIndex == 2) {
      //   this.dataSource.data = data.filter(x => x.status == false && x.isDeleted == false);
      //   this.dataSource.paginator = this.unpaidSaleDebtPaginator;
      //   this.dataSource.sort = this.unpaidSaleDebtSort;
      // }
      // this.pageSize = this.dataSource.data.length;
      // this.pager = this.getPager(this.pageSize, this.paginatorSelectednumber, this.selectedDropdown);
      
      // this.pageSize = this.dataSource.filteredData.length;
      // if (this.dataSource.paginator) {
      //   this.dataSource.paginator.firstPage();
      //   this.paginatorSelectednumber = 1;
      //   this.pager = this.getPager(this.pageSize, this.paginatorSelectednumber, this.selectedDropdown);
      // }

      // this.pageSize = this.dataSource.filteredData.length;
      // if (this.dataSource.paginator) {
      //   this.dataSource.paginator.firstPage();
      //   this.paginatorSelectednumber1 = 1;
      //   this.pager = this.getPager(this.pageSize, this.paginatorSelectednumber1, this.selectedDropdown1);
      // }

      // this.pageSize = this.dataSource.filteredData.length;
      // if (this.dataSource.paginator) {
      //   this.dataSource.paginator.firstPage();
      //   this.paginatorSelectednumber2 = 1;
      //   this.pager = this.getPager(this.pageSize, this.paginatorSelectednumber2, this.selectedDropdown2);
      // }
    })
  }

  saleDebtDetails(id: number) {
    this.saleDebtId = id;
    this.debtCardService.getSaleDebtDetails(id).subscribe(data => {
      this.saleDebts = data;
      const date = this.saleDebts[this.saleDebts.length - 1]?.date.split("T")[0];
      this.editSaleDebtForm.controls['totalPaid'].setValue(this.saleDebts[this.saleDebts.length - 1]?.amountPaid);
      this.editSaleDebtForm.controls['personWhoPaid'].setValue(this.saleDebts[this.saleDebts.length - 1]?.personWhoPaid);
      this.editSaleDebtForm.controls['date'].setValue(date);
      this.totalDebtLeft = this.saleDebts[this.saleDebts?.length - 1]?.totalLeft;
      this.lastAmountPaid = this.saleDebts[this.saleDebts?.length - 1]?.amountPaid;
      if (this.totalDebtLeft == 0) {
        this.debtPaid = true;
      } else {
        this.debtPaid = false;
      }
      this.saleDebtDetailId = this.saleDebts[this.saleDebts.length - 1]?.id;
    })
  }

  deleteSaleModal(id) {
    this.alertService.swalFire(
      'warning',
      this.translate.instant('DELETE_MESSAGE'),
      true,
      true,
      this.translate.instant('YES_DELETE'),
      this.translate.instant('NO')
    ).then(result => {
      if (result.isConfirmed) {
        this.deleteSaleDebt(id);
      }
    });
  }


  deleteSaleDebt(id: number) {
    this.debtCardService.deleteSaleDebt(id).subscribe(data => {
      if (data.status != false) {
        this.getSaleDebts();
        this.alertService.swalFire(
          'success',
          this.translate.instant('DELETED_SUCCESS'),
          true,
          false,
          this.translate.instant('CLOSE'),
          ''
        )
      } else {
        this.alertService.swalFire(
          'warning',
          this.translate.instant('DEBT_EXIST'),
          true,
          false,
          this.translate.instant('CLOSE'),
          ''
        )
      }
    });
  }

  createSaleReport(saleID: number): void {
    const domain = environment.API_URL.split('/api')[0];
    const url = `${domain}/Reports/CreateSaleDebtReport/${saleID}`

    window.open(url, '_blank').focus();
  }

  addSaleDebt(id) {
    this.markControlsAsTouched();
    if (this.saleDebtForm.valid) {
      this.debtCardService.createSaleDebts(id, this.saleDebtForm.value.totalPaid, this.saleDebtForm.value.personWhoPaid, this.saleDebtForm.value.date).subscribe(data => {
        if (!("debtStatus" in data) && data.debtStatus !== false) {
            if (data.status) {
              this.debtPaid = true;
            } else {
              this.debtPaid = false;
            }
            this.saleDebts.push(data);
            const date = this.saleDebts[this.saleDebts.length - 1].date.split("T")[0];
            this.editSaleDebtForm.controls['totalPaid'].setValue(this.saleDebts[this.saleDebts.length - 1].amountPaid);
            this.editSaleDebtForm.controls['personWhoPaid'].setValue(this.saleDebts[this.saleDebts.length - 1].personWhoPaid);
            this.editSaleDebtForm.controls['date'].setValue(date);
            this.lastAmountPaid = this.saleDebts[this.saleDebts.length - 1].amountPaid;
            this.saleDebtDetailId = this.saleDebts[this.saleDebts.length - 1].id;
            this.saleDebtForm.reset();
            this.filterSales(undefined);
          } else {
            this.alertService.swalFire(
              'warning',
              this.translate.instant('AMOUNT_EXCEEDS_DEBT'),
              false,
              true,
              '',
              this.translate.instant('CLOSE')
            );
          }
      });
    }
  }
  editSaleDebt(id) {
    if (this.editSaleDebtForm.valid) {
      const userId = JSON.parse(localStorage.getItem('waiterObject')).waiterID;
      const totalDebt = this.totalDebt;
      const totalPaid = this.totalPaid;
      const editTotalLeft = totalDebt - totalPaid;
      const amountPaid = this.editSaleDebtForm.value.totalPaid;
      const personWhoPaid = this.editSaleDebtForm.value.personWhoPaid;
      const date = this.editSaleDebtForm.value.date;
      const totalLeft1 = amountPaid - this.lastAmountPaid?.toFixed(2);
      const totalPaidNew = totalLeft1 + totalPaid;
      const editMaxAmount = editTotalLeft + this.lastAmountPaid;
      if (amountPaid <= editMaxAmount) {
        this.debtCardService.editSaleDebt(id, this.saleId, amountPaid, totalPaidNew, personWhoPaid, userId, date).subscribe((data: any) => {
          if (data.debtStatus != false) {
            if (data[data.length - 1].status) {
              this.debtPaid = true;
            } else {
              this.debtPaid = false;
            }
            this.saleDebts = data;
            this.saleDebts.reverse();
            this.saleDebtForm.reset();
            this.filterSales(undefined);
          }
        });
      } else {
        this.alertService.swalFire(
          'warning',
          this.translate.instant('AMOUNT_EXCEEDS_DEBT'),
          false,
          true,
          '',
          this.translate.instant('CLOSE')
        );
      }
    }
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.isLoading = true;
    this.selectedTabIndex = tabChangeEvent.index;
    this.debtCardService.setSelectedTabIndex(tabChangeEvent.index);
    setTimeout(() => {
      this.isLoading = false;
    }, 0);
  }

  getDebtsByClient() {
    this.debtCardService.getDebtsByClient().subscribe(data => {
      this.clientDataSource.data = data;
      this.clientDataSource.paginator = this.clientDebtPaginator;
      this.clientDataSource.sort = this.clientDebtSort;
      this.clientPageSize = this.clientDataSource.data.length;
      this.clientPager = this.getPager(this.clientPageSize, this.clientPaginator, this.selectedDropdownClient);
      this.clientPageSize = this.clientDataSource.filteredData.length;
    if (this.clientDataSource.paginator) {
      this.clientDataSource.paginator.firstPage();
      this.clientPaginator = 1;
      this.clientPager = this.getPager(this.clientPageSize, this.clientPaginator, this.selectedDropdownClient);
    }
    })
  }

  clientDebtDetails(id) {
    this.debtCardService.getClientDebt(id).subscribe(data => {
      if (data.debtPaid) {
        this.debtPaid = true;
      } else {
        this.debtPaid = false;
      }
      this.clientDebtId = id;
      this.clientData = data;
    })
  }


  addClientDebt(id) {
    this.debtCardService.createClientDebt(id, this.clientDebtForm.value.totalPaid, this.clientDebtForm.value.personWhoPaid, this.clientDebtForm.value.account, this.clientDebtForm.value.date).subscribe(data => {
      if (data.debtStatus != false) {
        this.clientData.clientDebts.push(data);
        this.clientDebtForm.reset();
        this.filterSales(undefined);
      } else {
        this.alertService.swalFire(
          'warning',
          this.translate.instant('AMOUNT_EXCEEDS_DEBT'),
          false,
          true,
          '',
          this.translate.instant('CLOSE')
        );
      }
    });
  }

  getPager(totalItems: number, currentPage: number, pageSize: number) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    let startPage: number, endPage: number;

    if (totalPages <= 5) {
        startPage = 1;
        endPage = totalPages;
    } else {
        if (currentPage <= 3) {
            startPage = 1;
            endPage = 5;
        } else if (currentPage + 1 >= totalPages) {
            startPage = totalPages - 4;
            endPage = totalPages;
        } else {

            if((totalPages - (currentPage - 2)) == 5) {
               startPage = currentPage - 1;
              endPage = currentPage+3;
            } else {
               startPage = currentPage - 2;
            endPage = currentPage+2;
            }
        }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array(endPage - startPage + 1).fill(0).map((_, idx) => startPage + idx);

    // return object with all pager properties required by the view
    return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages
    };
}

  deleteClientModal(id) {
    this.alertService.swalFire(
      'warning',
      this.translate.instant('DELETE_MESSAGE'),
      true,
      true,
      this.translate.instant('YES_DELETE'),
      this.translate.instant('NO')
    ).then(result => {
      if (result.isConfirmed) {
        this.deleteClientDebt(id);
      }
    });
  }
  deleteClientDebt(id) {
    this.debtCardService.deleteClientDebt(id).subscribe(data => {
      this.clientData.clientDebts = this.clientData.clientDebts.filter(x => x.id !== id);
      this.getDebtsByClient();
      this.alertService.swalFire(
        'success',
        this.translate.instant('DELETED_SUCCESS'),
        true,
        false,
        this.translate.instant('CLOSE'),
        ''
      )
    });
  }

  filterSales(filterBy: boolean){
    this.isLoading = true;
    this.filterBy = filterBy;
    setTimeout(()=>{
      this.isLoading = false;
    },0)
  }

  payAllChecked(isPayAllChecked: boolean): void {
    this.isPayAllChecked = isPayAllChecked;
    if(isPayAllChecked){
      this.debtCardService.payAllSaleDebts(this.saleDebtId).subscribe((data: any) => {
        if(data != false){
          this.saleDebtForm.controls['totalPaid'].setValue(data);
        }
      });
    }else{
      this.saleDebtForm.controls['totalPaid'].setValue(0);
    }
  }

  payAllCheckedClient(isPayAllChecked: boolean): void{
    this.isPayAllCheckedClient = isPayAllChecked;
    if(isPayAllChecked){
      this.debtCardService.payAllSaleClientDebts(this.clientDebtId).subscribe((data: any) => {
        if(data != false){
          this.clientDebtForm.controls['totalPaid'].setValue(data);
        }
      });
    }else{
      this.clientDebtForm.controls['totalPaid'].setValue(0);
    }
  }

}
