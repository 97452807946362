import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alert.service';
import { OfflineCrud } from 'src/app/services/offline-crud.service';
import Swal from 'sweetalert2';
import { TranslateKeysService } from '../../services/translate-keys.service';
import { FiscalModalComponent } from '../fiscal-modal/fiscal-modal.component';
import { DataService } from '../../data.service';
import { environment } from 'src/environments/environment';
import { ConfigsService } from 'src/app/services/configs.service';
import { IConfigs } from 'src/app/interfaces/IConfigs';
import { HttpClient } from '@angular/common/http';
import * as saleItems from '../../../assets/itemsModal/saleItems.json';
import * as fiscalItems from '../../../assets/itemsModal/fiscalItems.json';
import { DailyReportModalComponent } from '../daily-report-modal/daily-report-modal.component';



@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {

  


  constructor(
    private configsService: ConfigsService,
    private offlineService: OfflineCrud,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    public translate: TranslateService,
    public translateKeys: TranslateKeysService,
    private api: DataService
  ) { }
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  @Input() showSearchInput = false;
  @Input() toSyncData = 0;
  @Output() searchTextChanged: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() syncCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() getHappyHourPrices: EventEmitter<boolean> = new EventEmitter<boolean>();
  imgLogo = '';
  syncFinished = true;
  searchFormControl = new FormControl();
  closeResult = '';
  cashIn;
  userId: number;
  currentUrl: string;
  configs: IConfigs;
  waiterObject;
  showOrderStatus: boolean = false;
  happyHours = [];
  currentHour = new Date().getHours();
  nextHappyHourTimer: any;

  // Selected values
  selectedFirstFunction: string;
  selectedSecondFunction: string;
  selectedThirdFunction: string;
  selectedFourthFunction: string;
  selectedFifthFunction: string;
  selectedSixthFunction: string;

  ngOnInit(): void {
    this.imgLogo =
      environment.IMG_PATH_LOGO +
      JSON.parse(localStorage.getItem('waiterObject')).logo;
    this.isComponentActive();
    this.searchFormControl.valueChanges.subscribe((v: string) => {
      this.searchTextChanged.emit(v);
    });
    this.userId = this.getUserId();
    this.getRouteLocation();
    this.getHappyHours();
    
    this.waiterObject = JSON.parse(localStorage.getItem('waiterObject'));

    this.api.getWaiterConfigs(this.waiterObject.waiterID).subscribe((waiterShortcuts: any) => {
        localStorage.setItem('waiterShortcuts', JSON.stringify(waiterShortcuts));
        this.selectedFirstFunction = waiterShortcuts.firstFunction;
        this.selectedSecondFunction = waiterShortcuts.secondFunction;
        this.selectedThirdFunction = waiterShortcuts.thirdFunction;
        this.selectedFourthFunction = waiterShortcuts.fourthFunction;
        this.selectedFifthFunction = waiterShortcuts.fifthFunction;
        this.selectedSixthFunction = waiterShortcuts.sixthFunction;
    });
    this.configsService.getConfigs().subscribe((res: IConfigs) => {
      this.configs = res;
      if(res?.settings?.isGaming || res?.settings?.orderStatus){
        this.showOrderStatus = true;
      }
    },
      err => { console.log(err); }
    );
    
  }

  public currentModal: string = '';

public openSaleModal(): void {
  this.currentModal = 'sale'; //Default to "sale" when we click on the button Sale
}

public openOtherModal(): void {
  this.currentModal = 'fiscal'; //Defaul to "fiscal" when we click on the icon fiscal
}

  changeLanguage(selectedLanguage) {
    localStorage.setItem('language', selectedLanguage.value);
    this.translate.use(selectedLanguage.value);
  }
  getUserId() {
    return this.api.getWaiterID();
  }
  getRouteLocation() {
    this.activatedRoute.url.subscribe((param) => {
      if (param.length > 2) {
        this.currentUrl = param[2].path;
      }
    });
  }
  getCompanyID() {
    return JSON.parse(localStorage.getItem('waiterObject')).companyID;
  }

  controlFiscal() {
    window.location.href = `crm:/3/${this.getUserId()}`;
  }

  DailyFiscal() {
    let waiterId = this.getUserId();
    let companyId = this.getCompanyID();
    this.api.closeDailyReport(companyId, waiterId).subscribe((response) => {
      if(response == true){
        this.alertService.swalFire(
          'success',
          this.translate.instant('CLOSED_DAILYREPORT_SUCCESS'),
          true,
          false,
          this.translate.instant('CLOSE'),
          ''
          )
      }
    });
    window.location.href = `crm:/4/${this.getUserId()}`;
  }

  CashInModal() {
    this.dialog.open(FiscalModalComponent, {
      data: { isCashIn: true, userId: this.getUserId() },
    });
  }

  PeriodicModal() {
    this.dialog.open(FiscalModalComponent, {
      data: { isCashIn: false, userId: this.getUserId() },
    });
  }

  logOut() {
    const parsedPin = parseInt('');
    const waiterID = this.api.getWaiterID();
    this.api.logout(waiterID, parsedPin).subscribe((response) => {
      // @ts-ignore
      if (response.value.status === 200) {
        const token = localStorage.getItem('token');
        this.router.navigate(['/'], { queryParams: { token } });
      }
      Swal.close();
    });
  }

  isComponentActive() {
    const snapshot = this.activatedRoute.snapshot;
    if (snapshot.routeConfig.component.name === 'TableLayoutComponent') {
      return true;
    } else {
      return false;
    }
  }
  navigateTo() {
    const waiter = JSON.parse(localStorage.getItem('waiterObject'));
    return this.router.navigate([
      'kamarieri/' + waiter.waiterID + '/dashboard',
    ]);
  }
  getLogo() {
    return JSON.parse(localStorage.getItem('waiterObject')).logo;
  }

  syncSalesWithServer(): void {
    this.syncFinished = false;
    this.alertService.swalAutoLoader(false);
    this.offlineService
      .syncSalesWithServer()
      .then(async (synced: boolean) => {
        if (synced) {
          this.toSyncData = await this.offlineService.countSales();
          Swal.close();
          this.syncFinished = true;
          this.syncCompleted.emit(true);
          //TODO translate
          this.alertService.swalFire(
            'success',
            this.translateKeys.doTranslate('ORDER_CREATED'),
            true,
            false,
            this.translateKeys.doTranslate('OK')
          );
        } else {
          Swal.close();
          this.syncFinished = true;
          // TODO translate
          await this.alertService.swalFire(
            'error',
            this.translateKeys.doTranslate('OOPS!_SOMETHING_WENT_WRONG!'),
            false,
            true,
            '',
            this.translateKeys.doTranslate('CLOSE')
          );
        }
      })
      .catch(async (err) => {
        Swal.close();
        this.syncFinished = true;
        // TODO translate
        await this.alertService.swalFire(
          'error',
          this.translateKeys.doTranslate('OOPS!_SOMETHING_WENT_WRONG!'),
          false,
          true,
          '',
          this.translateKeys.doTranslate('CLOSE')
        );
      });
  }
  newWindow(url: string) {
    window.open(url, '_blank', 'status=yes'); // Open new window
  }
  getConfigs(): void {
    this.configsService.getConfigs().subscribe(
      (res: IConfigs) => {
        this.configs = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  saleItems:any[] = saleItems.items;
  fiscalItems:any[] = fiscalItems.items;

  saleItemsClick(route:any){   
    this.currentUrl=route;
    this.router.navigate(['/kamarieri', this.waiterObject.waiterID ? this.waiterObject.waiterID : this.userId, route ]);
  }
  
   itemActions = {
    controlFiscal: () => this.controlFiscal(),
    dailyFiscal: () => this.DailyFiscal(),
    cashIn: () => this.CashInModal(),
    periodicReport: () => this.PeriodicModal()
  };
  
  navigateToFiscalItems(itemId: string) {
    const action = this.itemActions[itemId];
    if (action) {
      action();
    }
  }
  

  openDailyReport(){
    this.dialog.open(DailyReportModalComponent);
  }

  openConfigs(){
    this.currentUrl="configs";
    this.router.navigate(['/kamarieri', this.waiterObject.waiterID ? this.waiterObject.waiterID : this.userId, "configs" ]);
  }

  shortcutFunctions(shortcut: string){
    if(shortcut != "sales/register" && shortcut != "sales/void"){
      this.currentUrl=shortcut;
      this.router.navigate(['/kamarieri', this.waiterObject.waiterID ? this.waiterObject.waiterID : this.userId, shortcut ]);
    }
  }

  getHappyHours(): void {
    const now = new Date();
    const start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0).toISOString();
    const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59).toISOString();
    const dayOfWeek = now.getDay().toString();

    this.api.currentDayHappyHours(this.getCompanyID(), start, end, dayOfWeek).subscribe(res => {
      if (Array.isArray(res)) {
        this.happyHours = res.map(happyHour => {
          return {
            start: this.formatTime(happyHour.startTime),
            end: this.formatTime(happyHour.endTime),
            name: happyHour.name
          };
        });
          this.setNextHappyHourTimer();
      }
    });
  }

  formatTime(isoString: string): string {
    const date = new Date(isoString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
  
    const formattedTime = (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' + minutes : minutes);
    return formattedTime;
  }

  isActiveHappyHour(happyHour: any): boolean {
    const currentDateTime = new Date();
    const currentHour = currentDateTime.getHours();
    const currentMinute = currentDateTime.getMinutes();
    const currentTotalMinutes = currentHour * 60 + currentMinute;

    const [startHour, startMinute] = happyHour.start.split(':').map(Number);
    const startTotalMinutes = startHour * 60 + startMinute;

    const [endHour, endMinute] = happyHour.end.split(':').map(Number);
    const endTotalMinutes = endHour * 60 + endMinute;

    return currentTotalMinutes >= startTotalMinutes && currentTotalMinutes < endTotalMinutes;
}

  setNextHappyHourTimer(): void {
    const nextHappyHour = this.findNextHappyHour();

    if (nextHappyHour) {
      const now = new Date();
      const nextHappyHourStart = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 
        parseInt(nextHappyHour.start.split(':')[0], 10), parseInt(nextHappyHour.start.split(':')[1], 10));

      const timeUntilNextHappyHour = nextHappyHourStart.getTime() - now.getTime();
      if (timeUntilNextHappyHour > 0) {
        this.nextHappyHourTimer = setTimeout(() => {
          this.showNextHappyHourModal(nextHappyHour);
        }, timeUntilNextHappyHour);
      }
    }
  }

  findNextHappyHour(): any {
    const now = new Date();

    const nextHappyHours = this.happyHours.filter(happyHour => {
      const [startHour, startMinute] = happyHour.start.split(':').map(Number);
      if (startHour > now.getHours()) {
        return true;
      } else if (startHour === now.getHours() && startMinute > now.getMinutes()) {
        return true;
      } else {
        return false;
      }
    });

    return nextHappyHours.length ? nextHappyHours[0] : null;
  }

  showNextHappyHourModal(happyHour: any): void {
    this.getHappyHourPrices.emit(true);
    this.alertService.swalFire(
      'info',
      `Happy Hour ${this.translateKeys.doTranslate('OF')} ${happyHour.start} ${this.translateKeys.doTranslate('TO')} ${happyHour.end}`,
      true,
      false,
      this.translateKeys.doTranslate('OK')
    );
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    switch (event.code) {
      case "F2":
        this.shortcutFunctions(this.selectedFirstFunction);
        break;
      case "F4":
        this.shortcutFunctions(this.selectedSecondFunction);
        break;
      case "F5":
        this.shortcutFunctions(this.selectedThirdFunction);
        break;
      case "F7":
        this.shortcutFunctions(this.selectedFourthFunction);
        break;
      case "F8":
        this.shortcutFunctions(this.selectedFifthFunction);
        break;
      case "F9":
        this.shortcutFunctions(this.selectedSixthFunction);
        break;
      default:
        break;
    }
  }

}
