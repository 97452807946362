import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IConfigs } from '../interfaces/IConfigs';
import { IWaiterAuthObject } from '../interfaces/IWaiterAuthObject';
@Injectable({
  providedIn: 'root'
})
export class ConfigsService {

  constructor(
    private http: HttpClient
  ) { }

  getConfigs(): Observable<IConfigs> {
    const companyId = this.getCompanyId();
    return this.http.get<IConfigs>(`${environment.API_URL}/configsapi/configs`, { params: {companyId}});
  }

  getCompanyId(): string {
    const token = JSON.parse(localStorage.getItem('waiterObject')) as IWaiterAuthObject;
    if (token) {
      return token.companyID.toString();
    } else {
      return '';
    }
  }
}
