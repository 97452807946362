<app-navbar></app-navbar>

<div class="container-fluid p-0 m-0 " (window:resize)="onResize($event)">


  <div class="row w-100 bg-transparent p-2">
    <div class="col-md-12 col-lg-3 col-xl-2 ml-auto " *ngIf="!dragMode">
      <button class="btn editDrag btn-block" (click)="enableDrag()">{{'EDIT_MODE' | translate}}</button>
    </div>
    <div class="col-md-12 col-lg-3 col-xl-2 d-flex ml-auto " *ngIf="dragMode">
      <button class="customSave mr-2 col  ml-auto" (click)="enableDrag();saveDragPositions()">{{'SAVE' | translate}}</button>
      <button class="addButton col" data-toggle="modal" data-target="#tableModal">
        +
      </button>
    </div>
  </div>

  <div *ngIf="getScreenHeightAndWidth().x>1024; else smallScreen">
    <div class="" *ngIf="!this.dragMode;else showThis">
      <div  *ngFor="let table of tables;let i=index"
            [ngClass]="table.tableForm === 'squareTable'? 'squareParent' :
          table.tableForm === 'roundTable'? 'roundParent': table.tableForm === 'rectangleTable'? 'rectangleParent': ''"
            cdkDrag
            [cdkDragDisabled]="!dragMode"
            [cdkDragFreeDragPosition]="{x:table.positionX ,y:table.positionY}"
      >
        <div class="squareChairs">
          <span class="chair-top position-absolute" *ngIf="table.tableForm === 'roundTable' || table.tableForm === 'rectangleTable'" [ngStyle]="{'left': table.tableForm === 'rectangleTable'? 25 + 'px':table.tableForm === 'roundTable'? 32 + 'px': 35+ 'px'}"><img src="assets/images/chair-2.png"></span>
          <span class="chair-top-2-rectangle position-absolute" *ngIf="table.tableForm === 'rectangleTable'"><img src="assets/images/chair-2.png"></span>
          <span class="chair-top-square position-absolute" *ngIf="table.tableForm === 'squareTable'"><img src="assets/images/chair-2.png"></span>
          <span class="chair-right position-absolute"><img src="assets/images/chair-2.png"></span>
          <span class="chair-bottom position-absolute"  [ngStyle]="{'left': table.tableForm === 'rectangleTable'? 25 + 'px': 35+ 'px'}"  *ngIf="table.tableForm === 'roundTable' || table.tableForm === 'rectangleTable'"><img src="assets/images/chair-2.png"></span>
          <span class="chair-bottom-square position-absolute"  *ngIf="table.tableForm === 'squareTable'"><img src="assets/images/chair-2.png"></span>
          <span class="chair-bottom-2-rectangle position-absolute"  *ngIf="table.tableForm === 'rectangleTable'"><img src="assets/images/chair-2.png"></span>
          <span class="chair-left position-absolute"><img src="assets/images/chair-2.png"></span>
        </div>
        <button    #item
                   [ngClass]="table.tableStatus?.status ? 'isReserved':  ''"
                   [cdkDragDisabled]="!dragMode"
                   cdkDrag
                   cdkDragBoundary=".dragContainer"
                   style="position:absolute"
                   [className]="table.tableForm"
                   (cdkDragEnded)="dragEnd($event, table)"
                   (click)="tableButtonClickAction(table)"
                   [value]="table.id"
                   appHoldButon
                   [currentTable]="currentTable"
                   (holdButton)="holdHandler($event, table.id, table.positionY, table.positionX)"
                   [id]="table.id">
          <span *ngIf="table.customID < 10">T0{{table.customID}}</span>
          <span *ngIf="table.customID >= 10">T{{table.customID}}</span>
          <span class="badge badge-primary"
                [ngClass]="table.tableForm === 'squareTable'?  'squareBadge':
              table.tableForm === 'rectangleTable'?  'rectangleBadge':
              'circleBadge'"
                [ngStyle]="{'backgroundColor': table.tableStatus?.status? '#DC3545' : ''}"
          >
          {{table.tableChairs}}
          </span>
          <span class="badge badge-reserved" *ngIf="table.isReserved || table.reminder"
                [ngClass]="table.tableForm === 'squareTable'?  'square-reserved-badge':
            table.tableForm === 'rectangleTable'?  'rectangle-reserved-badge':
            'circle-reserved-badge' " >
        R</span>
        </button>
      </div>
    </div>
    <ng-template #showThis>
      <div class="" >
        <div  *ngFor="let table of tables"
              [ngClass]="table.tableForm === 'squareTable'? 'squareParent' :
          table.tableForm === 'roundTable'? 'roundParent': table.tableForm === 'rectangleTable'? 'rectangleParent': ''"
              cdkDrag
              [cdkDragDisabled]="!dragMode"
              (cdkDragEnded)="dragEnd($event, table)"
              cdkDragBoundary=".dragContainer"

              [cdkDragFreeDragPosition]="{x:table.positionX ,y:table.positionY}"
        >
          <div class="squareChairs">
            <span class="chair-top position-absolute" *ngIf="table.tableForm === 'roundTable' || table.tableForm === 'rectangleTable'" [ngStyle]="{'left': table.tableForm === 'rectangleTable'? 25 + 'px': table.tableForm === 'roundTable'? 32 + 'px': 35+ 'px'}"><img src="assets/images/chair-2.png"></span>
            <span class="chair-top-2-rectangle position-absolute" *ngIf="table.tableForm === 'rectangleTable'"><img src="assets/images/chair-2.png"></span>
            <span class="chair-top-square position-absolute" *ngIf="table.tableForm === 'squareTable'"><img src="assets/images/chair-2.png"></span>
            <span class="chair-right position-absolute"><img src="assets/images/chair-2.png"></span>
            <span class="chair-bottom position-absolute"  [ngStyle]="{'left': table.tableForm === 'rectangleTable'? 25 + 'px': 35+ 'px'}"  *ngIf="table.tableForm === 'roundTable' || table.tableForm === 'rectangleTable'"><img src="assets/images/chair-2.png"></span>
            <span class="chair-bottom-square position-absolute"  *ngIf="table.tableForm === 'squareTable'"><img src="assets/images/chair-2.png"></span>
            <span class="chair-bottom-2-rectangle position-absolute"  *ngIf="table.tableForm === 'rectangleTable'"><img src="assets/images/chair-2.png"></span>
            <span class="chair-left position-absolute"><img src="assets/images/chair-2.png"></span>
          </div>
          <button    #item
                     [ngClass]="table.tableStatus?.status? 'isReserved':  ''"
                     cdkDragBoundary=".dragContainer"
                     style="position:absolute"
                     [className]="table.tableForm"
                     [value]="table.id"
                     [id]="table.id"
          >
            <span *ngIf="table.customID < 10">T0{{table.customID}}</span>
            <span *ngIf="table.customID >= 10">T{{table.customID}}</span>
            <span class="badge badge-primary" *ngIf="!dragMode"
                  [ngClass]="table.tableForm === 'squareTable'?  'squareBadge':
            table.tableForm === 'rectangleTable'?  'rectangleBadge':
            'circleBadge' " >
        {{table.tableChairs}}</span>
            <span class="badge badge-delete" *ngIf="dragMode"
                  [ngClass]="table.tableForm === 'squareTable'?  'square-delete-badge':
            table.tableForm === 'rectangleTable'?  'rectangle-delete-badge':
            'circle-delete-badge' " (click)="deleteTable(table)" >
        -</span>
            <span class="badge badge-reserved" *ngIf="table.isReserved || table.reminder"
                  [ngClass]="table.tableForm === 'squareTable'?  'square-reserved-badge':
            table.tableForm === 'rectangleTable'?  'rectangle-reserved-badge':
            'circle-reserved-badge' " >
        R</span>
          </button>
        </div>
      </div>
    </ng-template>
  </div>
  <ng-template #smallScreen>


    <div class="" *ngIf="!this.dragMode;else showThis">
      <button  *ngFor="let table of tables"  #item
               [ngClass]="table.tableStatus?.status? 'isReserved': ''"
               [cdkDropListDisabled]="!dragMode"
               cdkDropList
               cdkDragBoundary=".dragContainer"
               style="position:relative; margin: 20px"
               [className]="table.tableForm"
               (click)="tableButtonClickAction(table)"
               [value]="table.id"
               [id]="table.id"
      >   <span *ngIf="table.customID < 10">T0{{table.customID}}</span>
        <span *ngIf="table.customID >= 10">T{{table.customID}}</span>
        <span class="badge badge-primary"
              [ngClass]="table.tableForm === 'squareTable'?  'squareBadge':
            table.tableForm === 'rectangleTable'?  'rectangleBadge':
            'circleBadge' " >
        {{table.tableChairs}}
        </span>
        <span class="badge badge-reserved" *ngIf="table.isReserved || table.reminder"
              [ngClass]="table.tableForm === 'squareTable'?  'square-reserved-badge':
            table.tableForm === 'rectangleTable'?  'rectangle-reserved-badge':
            'circle-reserved-badge' " >
        R</span>
      </button>
    </div>
    <ng-template #showThis>
      <div class="" >
        <button  *ngFor="let table of tables"  #item
                 [ngClass]="table.tableStatus?.status? 'isReserved': ''"
                 [cdkDropListDisabled]="!dragMode"
                 cdkDropList
                 cdkDragBoundary=".dragContainer"
                 style="position:relative; margin:30px"
                 [className]="table.tableForm"
                 [value]="table.id"
                 [id]="table.id"

        >
          <span *ngIf="table.customID < 10">T0{{table.customID}}</span>
          <span *ngIf="table.customID >= 10">T{{table.customID}}</span>
          <span class="badge badge-primary"
                [ngClass]="table.tableForm === 'squareTable'?  'squareBadge':
            table.tableForm === 'rectangleTable'?  'rectangleBadge':
            'circleBadge' " >
        {{table.tableChairs}}
          </span>
          <span class="badge badge-reserved" *ngIf="table.isReserved || table.reminder"
                [ngClass]="table.tableForm === 'squareTable'?  'square-reserved-badge':
            table.tableForm === 'rectangleTable'?  'rectangle-reserved-badge':
            'circle-reserved-badge' " >
        R</span>
        </button>
      </div>
    </ng-template>




  </ng-template>
</div>
<ng-template #elsediv>
  <div class="col-md-3 col-sm-12 col-lg-2 col-xl-2 fixed-bottom ml-auto">
    <div class="d-flex p-0 m-0 pb-2">
      <button class="customSave mr-2  ml-auto" (click)="enableDrag();saveDragPositions()">{{'SAVE' | translate}}</button>
      <button class="addButton " data-toggle="modal" data-target="#tableModal">
        <img src="../../../assets/images/plus-big.png" class="plusIcon">
      </button>
    </div>
  </div>
</ng-template>




<!--table modal-->
<div class="modal fade" id="tableModal" tabindex="-1" role="dialog" aria-labelledby="tableModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content ">
      <div class="modal-header">
        <h5 class="modal-title fontArial18" id="tableModalLabel">{{'CREATE_TABLE' | translate}}</h5>
        <button type="button" class="close " data-dismiss="modal" aria-label="Close">
          <img src="../../../assets/images/x-inactive-new.png" class="closeIcon" >
        </button>
      </div>
      <div class="modal-body p-0">
        <form #tableForm="ngForm" class="tpadding30 tableFormLabel" >
          <div class="form-group">
            <label for="customID">{{'TABLE_NUMBER' | translate}}</label>
            <input type="number" class="form-control" id="customID" required
                   #customID="ngModel"
                   name="customID"
                   [(ngModel)]="tableData.customID"
                   (keyup)="checkCustomID(tableData.customID)"
                   [class.invalid]="checkCustomID(tableData.customID)"
            >
            <small class="text-danger" [class.d-none]="!checkCustomID(tableData.customID)">{{'TABLE_ALREADY_EXISTS' | translate}}</small>
            <small class="text-danger" [class.d-none]="customID.valid">{{'TABLE_NUMBER_INVALID' | translate}}</small>
          </div>
          <div class="form-group">
            <label for="tForm">{{'TABLE_FORM' | translate}}</label>
            <input  class="custom-select dropdownOption"
                    id="tForm"
                    required
                    #tForm="ngModel"
                    name="tForm"
                    readonly
                    (click)="showFormDiv()"
                    [(ngModel)]="tableData.tableForm">
            <div class="row p-4" *ngIf="showTableFormDiv">
              <div class="mx-auto squareParentForm mr-4"
                   [ngClass]="selectedTableForm !== null && selectedTableForm === 'squareTable'? 'selectedForm': ''"
                   (click)="selectTableForm('squareTable')">
                <span class="chair-top-square position-absolute" ><img src="assets/images/chair-2.png"></span>
                <span class="chair-left position-absolute"><img src="assets/images/chair-2.png"></span>
                <span class="chair-right position-absolute"><img src="assets/images/chair-2.png"></span>
                <span class="chair-bottom-square position-absolute" ><img src="assets/images/chair-2.png"></span>
                <div class=" squareTable">
                </div>
              </div>

              <div class="mx-auto roundParentForm"
                   [ngClass]="selectedTableForm !== null && selectedTableForm === 'roundTable'? 'selectedForm': ''"
                   (click)="selectTableForm('roundTable')">
                <span class="chair-top position-absolute"  ><img src="assets/images/chair-2.png"></span>
                <span class="chair-left position-absolute"><img src="assets/images/chair-2.png"></span>
                <span class="chair-right position-absolute"><img src="assets/images/chair-2.png"></span>
                <span class="chair-bottom position-absolute"><img src="assets/images/chair-2.png"></span>
                <div class="roundTable"></div>
              </div>
              <div class="mx-auto rectangleParentForm"
                   [ngClass]="selectedTableForm !== null && selectedTableForm === 'rectangleTable'? 'selectedForm': ''"
                   (click)="selectTableForm('rectangleTable')">
                <span class="chair-top position-absolute" style="left: 25px" ><img src="assets/images/chair-2.png"></span>
                <span class="chair-top-2-rectangle position-absolute"  ><img src="assets/images/chair-2.png"></span>
                <span class="chair-left position-absolute"><img src="assets/images/chair-2.png"></span>
                <span class="chair-right position-absolute"><img src="assets/images/chair-2.png"></span>
                <span class="chair-bottom position-absolute"  style="left: 25px" ><img src="assets/images/chair-2.png"></span>
                <span class="chair-bottom-2-rectangle position-absolute"><img src="assets/images/chair-2.png"></span>
                <div class="rectangleTable"></div>
              </div>
            </div>
            <small [class.d-none]="tForm.valid">{{'TABLE_FORM_INVALID' | translate}}</small>
          </div>
          <div class="form-group">
            <label for="tChairs">{{'CHAIR_NUMBER' | translate}}</label>
            <input type="number" class="form-control" id="tCharis"
                   id="tChairs"
                   required
                   #tChairs="ngModel"
                   name="tChairs"
                   [(ngModel)]="tableData.tableChairs">
            <small [class.d-none]="tChairs.valid">{{'CHAIR_NUMBER_INVALID' | translate}}</small>
          </div>
          <button class="btn btn-block saveStyle"
                  [disabled]="tableForm.invalid || checkCustomID(tableData.customID)"
                  type="submit"
                  (click)="createTable(tableForm)"
                  data-dismiss="modal"
          >{{'CREATE' | translate}}</button>
        </form>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
