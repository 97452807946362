import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { WholesalePayments } from 'src/app/const/WholeSalePayments';
import { DataService } from 'src/app/data.service';
import { WholeSalePaymentType } from 'src/app/Enums/WholeSalePaymentType';
import { IBankDetail } from 'src/app/interfaces/IBankDetail';
import { ICategory } from 'src/app/interfaces/ICategory';
import { Client } from 'src/app/interfaces/IClient';
import { IConfigs } from 'src/app/interfaces/IConfigs';
import { IOptions } from 'src/app/interfaces/IOptions';
import { IPriceList } from 'src/app/interfaces/IPriceList';
import { IProduct } from 'src/app/interfaces/IProduct';
import { ISale } from 'src/app/interfaces/ISale';
import { Product } from 'src/app/models/product';
import { AlertService } from 'src/app/services/alert.service';
import { BankDetailsService } from 'src/app/services/bank-details.service';
import { ClientService } from 'src/app/services/client.service';
import { OfflineCrud } from 'src/app/services/offline-crud.service';
import { WholeSaleService } from 'src/app/services/whole-sale.service';
import { WindowService } from 'src/app/services/window.service';
import { ClientAutocompleteInputComponent } from 'src/app/shared/components/client-autocomplete-input/client-autocomplete-input.component';
import { ConomeAutocompleteComponent } from 'src/app/shared/components/conome-autocomplete/conome-autocomplete.component';
import { ClientSelectedType } from 'src/app/types/ClientSelectedType';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-offer-edit',
  templateUrl: './offer-edit.component.html',
  styleUrls: ['./offer-edit.component.scss']
})
export class OfferEditComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  isSaleEdited = false;

  IMG_PATH_CATEGORY = environment.IMG_PATH_CATEGORY;
  IMG_PATH = environment.IMG_PATH;
  @ViewChild('closeModalBtn') closeModal: ElementRef;
  @ViewChild('clientAutocomplete') clientAutocomplete: ClientAutocompleteInputComponent;
  @ViewChild('bankAutoComplete') bankAutoComplete: ConomeAutocompleteComponent;

  clients: Client[] = [];
  bankDetails: IBankDetail[] = [];
  bankDetailsFilterKeys = ["bankNameSQ", "bankNameMK"];
  bankDisplayFieldName = "bankNameSQ";

  todayDate = moment(new Date()).format('yyyy-MM-DD');
  categories: ICategory[] = [];
  saleDetails: Product[] = [];
  selectedCategoryIndex = -1;
  selectedOptionID = 0;
  editPriceId = -1;
  filteredProductCategories: IProduct[] = [];
  currentProduct: Product = null;

  vat = 0;
  calculationData = {
    total: 0,
    vat: 0,
    subTotal: 0
  };
  currentProductQuantity = 1;
  payments = WholesalePayments.filter(x => [WholeSalePaymentType.Invoice, WholeSalePaymentType.Cancel].includes(x.type));
  payment: number;
  change: number;
  configs: IConfigs;
  client: Client;
  bankDetail: IBankDetail;
  editSaleNo = false;
  showSaleNoError = false;

  sale: ISale = {
    modUser: this.api.getWaiterData().waiterID,
    noSale: `0/${new Date().getFullYear()}`,
    dailyID: 1,
    companyID: this.getCompanyID(),
    clientID: null,
    type: 1,
    dateSale: new Date(),
    total: Number(this.calculationData.total),
    hasReport: false,
    hasFiscal: false,
    comment: null,
    isMKChecked: false,
    isDebtChecked: false,
    paymentID: null,
    currencyName: 'MKD',
    accountantClientID: null,
    saleType: 3,
    status: false,
    saleDetails: [],
    salePayments: [],
    ordinalNumber: null,
    orderStatus: false
  };


  saleDescription = new FormGroup({
    saleNo: new FormControl({ value: '', disabled: true }, [Validators.required]),
    saleYear: new FormControl({ value: new Date().getFullYear().toString().substring(2, 4), disabled: true }, [Validators.required]),
    dateSale: new FormControl(new Date(), [Validators.required]),
    comment: new FormControl(),
    isMKChecked: new FormControl(false)
  });

  paymentInvoice = new FormGroup({
    deadline: new FormControl(),
    days: new FormControl(),
    personWhoPaid: new FormControl(),
    totalPaid: new FormControl(),
    status: new FormControl({ value: false, disabled: false })
  })

  constructor(
    private dateAdapter: DateAdapter<Date>,
    private alertService: AlertService,
    private api: DataService,
    private wholeSaleService: WholeSaleService,
    private windowService: WindowService,
    private clientsService: ClientService,
    private offlineService: OfflineCrud,
    public translate: TranslateService,
    protected activatedRoute: ActivatedRoute,
    private bankDetailsService: BankDetailsService,
    private location: Location
  ) {
    this.payments.unshift({
      name: 'PAYMENT',
      type: WholeSalePaymentType.Invoice,
      dialogId: '#PaymentPublish',
      disabled: false
    });

    this.dateAdapter.setLocale(this.translate.currentLang);
  }

  async ngOnInit(): Promise<void> {
    this.subscriptions.push(
      this.activatedRoute.params.subscribe((params) => {
        this.getSaleById(params.id);
      }));

    this.configs = await this.getConfigs();
    this.getCategoriesFromApi();
  }

  getSaleById(saleId: string) {
    this.subscriptions.push(
      this.api.getSaleById(saleId).subscribe((data: any) => {
        this.sale = data[0];
        this.fillSaleDescription(this.sale);
        this.saleDetails = data[0].saleDetails;
        this.total();
      }, async (err) => {
        await this.alertService.swalFire('error', 'Oops! Something went wrong!', false, true, '', this.translate.instant('CLOSE'));
      }));
  }

  getConfigs(): Promise<IConfigs> {
    return this.offlineService.getOfflineConfigs();
  }
  fillSaleDescription(sale: ISale): void {

    this.saleDescription.patchValue({
      saleNo: sale.noSale.split('/')[0],
      saleYear: sale.noSale.split('/')[1].toString().substring(2, 4),
      dateSale: moment(sale.dateSale).format('yyyy-MM-DD'),
      comment: sale.comment,
      isMKChecked: sale.isMKChecked
    });
    this.getClientById();
    this.getBankDetails();
  }

  getClientById(): void {
    this.clientsService.getClientById(String(this.sale.clientID))
      .subscribe((res: Client) => {
        this.client = res;
      }, err => {
        console.log(err);
      })
  }

  getBankDetails(): void {
    this.bankDetailsService.getBankDetails(this.getCompanyID())
      .subscribe((bankDetails: IBankDetail[]) => {
        this.bankDetails = bankDetails;
        const bankDetail = bankDetails.find(x => x.id === this.sale.bankDetailID);

        setTimeout(() => {
          if (bankDetail) {
            this.bankDetail = bankDetail;
          }
        }, 1);

      })
  }

  setCurrentProduct(product: IProduct): void {
    this.currentProduct = new Product(product);
    this.setSelectedOption(this.currentProduct.options[0]);
  }

  addProduct(productId: number) {

    // productCategory.qt -= this.ProductQuantity;
    // Shembull1
    // sellingpricewithvat=118
    // pricewithrabat=59 (cmimi i ri)
    // Rabat=100-(59*100/118)=50
    this.currentProduct.qt = this.currentProductQuantity;
    this.currentProduct.selectedOptions.qt = this.currentProductQuantity;

    const oldSellingPriceWithVat = this.currentProduct.selectedOptions.sellingPriceWithVat;
    const oldSellingPriceNoVat = this.currentProduct.selectedOptions.sellingPriceNoVat;

    if (![null, undefined, ''].includes(this.currentProduct.selectedOptions.sellingPriceWithRebate)) {
      this.currentProduct.selectedOptions.sellingPriceWithVat = this.currentProduct.selectedOptions.sellingPriceWithRebate;
    }


    let newSellingPriceNoVat = (oldSellingPriceNoVat < this.currentProduct.selectedOptions.sellingPriceWithVat) ?
      this.currentProduct.selectedOptions.sellingPriceWithVat / (1 + this.currentProduct.selectedOptions.vat / 100) : oldSellingPriceNoVat;
    this.calculateVATForOption(this.currentProduct.selectedOptions);


    if (this.sale.isMKChecked) {
      const price = (this.currentProduct.selectedOptions.sellingPriceWithRebate ?
        this.currentProduct.selectedOptions.sellingPriceWithRebate :
        this.currentProduct.selectedOptions.sellingPriceWithVat);

      const mk32Price = price - ((price / (1 + this.currentProduct.selectedOptions.vat / 100)) *
        this.currentProduct.selectedOptions.vat) /
        100;

      this.currentProduct.selectedOptions.totalVat = 0;
      this.currentProduct.selectedOptions.sellingPriceWithVat = Number(mk32Price.toFixed(2));
      this.currentProduct.selectedOptions.sellingPriceNoVat = Number(newSellingPriceNoVat.toFixed(2));
      this.currentProduct.selectedOptions.total = this.currentProduct.selectedOptions.qt * Number(mk32Price.toFixed(2));

    } else {

      this.currentProduct.selectedOptions.sellingPriceNoVat = Number(newSellingPriceNoVat.toFixed(2));
      this.currentProduct.selectedOptions.total = this.currentProduct.selectedOptions.qt * Number(this.currentProduct.selectedOptions.sellingPriceWithVat.toFixed(2));
    }

    // Update currentProduct with selectedOptions data
    this.currentProduct.sellingPriceWithVat = this.currentProduct.selectedOptions.sellingPriceWithVat;
    this.currentProduct.sellingPriceNoVat = this.currentProduct.selectedOptions.sellingPriceNoVat;
    this.currentProduct.profit = this.currentProduct.selectedOptions.profit;
    this.currentProduct.vatID = this.currentProduct.selectedOptions.vatId;
    this.currentProduct.total = this.currentProduct.selectedOptions.total;

    if (![null, undefined, ''].includes(this.currentProduct.selectedOptions.sellingPriceWithRebate)) {
      this.currentProduct.sellingPriceWithVat = this.currentProduct.selectedOptions.sellingPriceWithRebate;
    }

    this.currentProduct.rebate =
      Number(
        (100 -
          (this.currentProduct.selectedOptions.sellingPriceWithRebate * 100) /
          oldSellingPriceWithVat
        ).toFixed(4)) || 0;

    if (this.currentProduct.rebate < 0) {
      this.currentProduct.rebate = 0;
    }

    // let newSellingPriceNoVat = (oldSellingPriceWithVat < this.currentProduct.sellingPriceWithVat) ?
    // this.currentProduct.sellingPriceWithVat / (1 + this.currentProduct.selectedOptions.vat / 100) : oldSellingPriceWithVat;
    // this.currentProduct.sellingPriceNoVat = Number(newSellingPriceNoVat.toFixed(2))

    this.currentProduct.selectedOptions.qt = this.currentProductQuantity;

    this.order(this.currentProduct);
    this.currentProductQuantity = 1;
    this.closeProductModal();
  }

  order(data: Product) {

    const nameByBrowserLang = this.getProductNameByBrowserLang(data);
    data.productName = nameByBrowserLang;

    if (data.selectedOptions) {
      data.productName = `${nameByBrowserLang} ${data.selectedOptions.productName}`;
      this.vat += Number(data.selectedOptions.totalVat);
    }

    data.productDetailsID = data.selectedOptions.id;
    this.saleDetails.push(data);
    this.editPriceId = -1;
    this.total();
    this.filteredProductCategories = [];
    (document.getElementById("navbar-search-input") as HTMLInputElement).value = ""
  }

  // calculates and updates total when product is added or quantity is updated
  updateProductTotal(detailIndex?: number) {
    // detailIndex is used from html when product qt is update ( +, - buttons)
    const product = detailIndex >= 0 ? this.saleDetails[detailIndex].selectedOptions : this.currentProduct.selectedOptions;

    product.total = product.qt * product.sellingPriceWithVat;
    const vat = product.sellingPriceWithRebate ? product.totalVat : 0;
    // const price = product.qt * (product.sellingPriceWithRebate ? product.sellingPriceWithRebate : product.sellingPriceNoVat);
    const price = product.qt * (product.sellingPriceWithRebate ? product.sellingPriceWithRebate : product.sellingPriceWithVat);

    product.total = Number(price.toFixed(2));
    product.totalVat = 0;

    // currentProduct is used only for new added products, so we dont have to update it on + or - button clcik
    if (detailIndex == undefined) {
      this.currentProduct.selectedOptions = product;
      this.currentProduct.total = this.currentProduct.selectedOptions.total;
    } else {
      this.saleDetails[detailIndex].selectedOptions = product;
      this.saleDetails[detailIndex].total = product.total;
    }

  }

  calculateVATForOption(option: IOptions) {
    // if user added rebate
    if (![null, undefined].includes(option.sellingPriceWithRebate)) {
      if (option.sellingPriceWithRebate == 0) {
        option.totalVat = 0;
      } else {
        option.totalVat =
          option.qt *
          (((option.sellingPriceWithRebate / (1 + option.vat / 100)) *
            option.vat) /
            100);
      }
    } else {
      // without rebate
      option.totalVat =
        option.qt * (option.sellingPriceWithVat - option.sellingPriceNoVat);
    }
  }

  formatIngredientsDetails(product: Product) {
    product.selectedIngredients.forEach((ingredient) => {
      if (ingredient.qt === ingredient.defaultQt) {
        ingredient.price = 0;
        ingredient.hasExtraIngredients = false;
      } else if (ingredient.qt > ingredient.defaultQt) {
        ingredient.qt -= ingredient.defaultQt;
        ingredient.hasExtraIngredients = true;
      }
    });
    product.saleIngredients = product.selectedIngredients;
  }

  getCategoriesFromApi() {
    const waiterObject = JSON.parse(localStorage.getItem('waiterObject'));
    this.alertService.swalAutoLoader();
    this.api.getPOSCategories(waiterObject.companyID).subscribe(
      (response: ICategory[]) => {
        this.categories = response;
        this.createAllCategories();
        Swal.close();
      },
      (error) => {
        Swal.close();
      }
    );
  }
  createAllCategories() {
    const data = [...this.categories.map(x => x.products)];
    const allProducts: IProduct[] = [];
    const productIDsAdded: Set<number> = new Set();
    data.forEach(products => {
        products.forEach(product => {
            if (!productIDsAdded.has(product.productID)) {
                allProducts.push(product);
                productIDsAdded.add(product.productID);
            }
        });
    });

    this.categories.unshift(
      {
        id: 0,
        created: "02.12.2021",
        modified: "02.12.2021",
        modUser: 0,
        image: '',
        companyID: 0,
        isService: false,
        nameMK: "All",
        nameSQ: "All",
        productIds: 0,
        products: allProducts
      })

    if (this.selectedCategoryIndex !== 0) {
      setTimeout(() => {
        let element: HTMLElement = document.getElementById('pills-0-tab') as HTMLElement;
        element.click();
      }, 1);

    }
  }

  editItemplus(index) {

    let product;
    product = this.saleDetails[index];

    // this.Product.qt++;
    product.selectedOptions.qt++;
    product.qt = product.selectedOptions.qt;
    // if (!this.sale.isMKChecked) {
    //   this.calculateVATForOption(product.selectedOptions);
    // }
    this.updateProductTotal(index);
    this.total();

  }
  editItemminus(index) {
    let product;
    product = this.saleDetails[index];
    if (Number(product.selectedOptions.qt) > 0) {
      // this.Product.qt --;
      product.selectedOptions.qt--;
      product.qt = product.selectedOptions.qt;
      if (!this.sale.isMKChecked) {
        this.calculateVATForOption(product.selectedOptions);
      }
      this.updateProductTotal(index);
      this.returnQt(product.productID);
    }

    if (product.selectedOptions.qt === 0) {
      this.saleDetails.splice(index, 1);
    }

    this.total();
  }

  closeProductModal() {
    this.closeModal.nativeElement.click();
  }

  getProductNameByBrowserLang(data: Product): string {
    const lang = this.translate.currentLang;
    if (lang === 'mk') {
      return data.nameMK;
    } else {
      return data.nameSQ;
    }
  }

  total() {
    let total = 0;
    let vat = 0;
    this.saleDetails.forEach((product) => {

      const oldSellingPriceNoVat = product.selectedOptions.sellingPriceNoVat;

      let newSellingPriceNoVat = (oldSellingPriceNoVat < product.selectedOptions.sellingPriceWithVat) ?
        product.selectedOptions.sellingPriceWithVat / (1 + product.selectedOptions.vat / 100) : oldSellingPriceNoVat;
      this.calculateVATForOption(product.selectedOptions);

      if (this.sale.isMKChecked) {

        const price = (product.selectedOptions.sellingPriceWithRebate ?
          product.selectedOptions.sellingPriceWithRebate :
          product.selectedOptions.sellingPriceWithVat);

        const mk32Price = price - ((price / (1 + product.selectedOptions.vat / 100)) *
          product.selectedOptions.vat) /
          100;

        product.selectedOptions.totalVat = 0;
        product.selectedOptions.sellingPriceWithVat = Number(mk32Price.toFixed(2));
        product.selectedOptions.sellingPriceNoVat = Number(newSellingPriceNoVat.toFixed(2));
        product.selectedOptions.total = product.selectedOptions.qt * Number(mk32Price.toFixed(2));

      } else {

        product.selectedOptions.sellingPriceNoVat = Number(newSellingPriceNoVat.toFixed(2));
        product.selectedOptions.total = product.selectedOptions.qt * Number(product.selectedOptions.sellingPriceWithVat.toFixed(2));
      }

      vat += product.selectedOptions.totalVat;
      total += product.total;
    });

    this.calculationData = {
      total,
      vat: Number(vat.toFixed(2)),
      subTotal: Number((total - Number(vat)).toFixed(2))
    };
  }

  plusQuantity() {
    this.currentProductQuantity += 1;
  }

  minusQuantity() {
    if (this.currentProductQuantity === 1) {
      this.alertService.swalFire(
        'warning',
        this.translate.instant('LOW_QUANTITY_1'),
        false,
        true,
        '',
        this.translate.instant('CLOSE')
      );
    } else {
      this.currentProductQuantity -= 1;
    }
  }
  // Edit item minus add product qt
  returnQt(productId: number) {
    for (let category of this.categories) {
      if (
        ![null, undefined].includes(
          category.products.find((x) => x.productID == productId)
        )
      ) {
        category.products.find((x) => x.productID == productId).qt += 1;
        break;
      }
    }
  }

  filterProducts(text: string): void {
    if (this.selectedCategoryIndex !== -1) {
      this.filteredProductCategories = this.categories[this.selectedCategoryIndex].products.filter(
        // find by name
        (x) => x.nameSQ.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) !== -1 ||
          // or noProduct
          x.noProduct == Number(text) ||
          // or barcode
          x.barcode == Number(text)
      );
    }
  }

  clearFilters(): void {
    this.filteredProductCategories = [];
  }

  calculateOptionPrices(option: IOptions, priceWithRebate: number) {
    if (option.sellingPriceWithRebate === 0) {
      this.currentProduct.selectedOptions.totalVat = 0;
    } else {
      this.currentProduct.selectedOptions.totalVat =
        option.qt *
        ((option.sellingPriceNoVat -
          (option.sellingPriceWithVat - priceWithRebate)) *
          (option.vat / 100));
    }

    this.currentProduct.selectedOptions.sellingPriceWithRebate =
      Number(priceWithRebate);
  }

  setSelectedOption(option: IOptions) {
    this.currentProduct.selectedOptions = {
      created: option.created,
      id: option.id,
      isDeleted: option.isDeleted,
      modUser: option.modUser,
      modified: option.modified,
      optionIngredients: option.optionIngredients,
      productID: option.productID,
      productName: option.productName,
      sellingPriceNoVat: option.sellingPriceNoVat,
      sellingPriceWithVat: option.sellingPriceWithVat,
      vat: option.vat,
      qt: this.currentProductQuantity,
      profit: option.profit,
      vatId: option.vatId
    };
  }

  registerSale(paymentId: number, customType?: any) {

    if (paymentId == WholeSalePaymentType.Invoice) {
      this.updateSale();
    }
    if (paymentId == WholeSalePaymentType.Cancel) {
      this.cancelBill();
    }

  }

  updateSale(): void {
    if (this.saleDescription.valid) {

      const paymentInvoice = this.paymentInvoice.getRawValue();
      const saleDescription = this.saleDescription.getRawValue();
      this.sale.paymentID = 1;
      this.sale.total = Number(this.calculationData.total?.toFixed(2));
      this.sale.saleDetails = this.saleDetails as any;
      this.sale.status = paymentInvoice.status === true ? false : true;
      this.sale.deadline = paymentInvoice.deadline || null;
      this.sale.dateSale = moment(saleDescription.dateSale).format('yyyy-MM-DD');
      this.sale.ordinalNumber = this.sale.noSale = saleDescription.saleNo;
      this.sale.comment = saleDescription.comment;

      if (this.client !== undefined && this.client !== null) {
        this.sale.clientID = this.client.id;
      } else {
        this.sale.clientID = null;
      }

      if (this.bankDetail !== undefined && this.bankDetail !== null) {
        this.sale.bankDetailID = this.bankDetail.id;
      } else {
        this.sale.bankDetailID = null;
      }

      this.alertService.swalAutoLoader();
      // this.sale.salePayments = this.createSalePayments(paymentId);

      this.wholeSaleService.updateSale(this.sale, `20${saleDescription.saleYear}`)
        .subscribe(async (response: { message: string, saleID: number, statusCode: number }) => {
          if (response.statusCode === 201) {
            // this.createFiscal(response.saleID, this.sale.paymentID);
            this.calculationData = {
              total: 0,
              subTotal: 0,
              vat: 0
            }

            // this.pointsToUse = 0;
            //this.refreshClients();
            this.resetFormsToDefaultValues();
            this.sale.isMKChecked = false;
            await this.alertSuccessClearData(this.translate.instant('OPEN_THE_REPORT'), response.saleID, WholeSalePaymentType.Invoice);
            this.location.back();

            // await this.alertSuccessClearData('Hap Raportin', response.saleID);
          } else {
            this.alertService.swalFire('error', response.message, false, true, '', this.translate.instant('CLOSE')).then();
          }

        }, (error) => {
          this.alertService.swalFire('error', error.message, false, true, '', this.translate.instant('CLOSE')).then();
        });

      this.payment = null;
      this.change = 0;
    } else {
      alert('Inalid form');
    }
  }

  cancelBill(): void {

    if (this.saleDescription.valid) {
      const saleDescription = this.saleDescription.getRawValue();
      this.sale.paymentID = 1;
      this.sale.total = Number(this.calculationData.total?.toFixed(2));
      this.sale.saleDetails = this.saleDetails as any;
      this.sale.status = true;
      this.sale.dateSale = moment(saleDescription.dateSale).format('yyyy-MM-DD');
      this.sale.ordinalNumber = this.sale.noSale = saleDescription.saleNo;
      this.sale.comment = saleDescription.comment;


      let clientId = 0;
      if (this.client !== undefined && this.client !== null) {
        clientId = this.client.id;
        this.sale.clientID = this.client.id;
      } else {
        this.sale.clientID = null;
      }

      if (this.bankDetail !== undefined && this.bankDetail !== null) {
        this.sale.bankDetailID = this.bankDetail.id;
      } else {
        this.sale.bankDetailID = null;
      }

      this.alertService.swalAutoLoader();
      // this.sale.salePayments = this.createSalePayments(paymentId);

      this.wholeSaleService.cancelBill(this.sale, `20${this.saleDescription.controls.saleYear.value}`)
        .subscribe(async (response: { message: string, saleID: number, statusCode: number }) => {
          if (response.statusCode === 201) {
            // this.createFiscal(response.saleID, this.sale.paymentID);
            this.calculationData = {
              total: 0,
              subTotal: 0,
              vat: 0
            }

            this.resetFormsToDefaultValues();
            this.sale.isMKChecked = false;
            await this.alertSuccessClearData(this.translate.instant('OPEN_THE_REPORT'), response.saleID, WholeSalePaymentType.Cancel);
          } else {
            this.alertService.swalFire('error', response.message, false, true, '', this.translate.instant('CLOSE')).then();
          }

        }, (error) => {
          this.alertService.swalFire('error', error.message, false, true, '', this.translate.instant('CLOSE')).then();
        });

      this.payment = null;
      this.change = 0;
    } else {
      alert('Inalid form');
    }

  }

  resetFormsToDefaultValues(): void {
    this.paymentInvoice.patchValue({
      deadline: null,
      days: null,
      personWhoPaid: null,
      totalPaid: null,
      status: false
    });
    this.saleDescription.patchValue({
      saleNo: '',
      saleYear: new Date().getFullYear().toString().substring(2, 4),
      dateSale: new Date(),
      comment: null
    });
  }

  getUserId() {
    let userId: number;
    this.activatedRoute.paramMap.subscribe((param) => {
      userId = Number(param.get('id'));
    });
    return userId;
  }

  createFiscal(saleId: number, paymentID: number) {
    if (paymentID === -1) {
      // print void fiscal
      this.windowService.windowRef.location = `crm:/12/${this.getUserId()}/${saleId}`;
    } else if (paymentID == 1) {
      // print fiscal
      this.windowService.windowRef.location = `crm:/1/${this.getUserId()}/${saleId}`;
    } else {
      // payment with card
      this.windowService.windowRef.location = `crm:/9/${this.getUserId()}/${saleId}`;
    }
  }

  changeEvent() {
    if (this.payment > this.calculationData.total) {
      this.change = this.payment - this.calculationData.total;
    } else {
      this.change = null;
    }
  }

  getCompanyID() {
    return JSON.parse(localStorage.getItem('waiterObject')).companyID;
  }

  async alertSuccessClearData(buttonText: string = 'OK', id?: number, type?: WholeSalePaymentType): Promise<void> {
    Swal.close();
    this.alertService.swalFire('success', this.translate.instant('ORDER_CREATED'), true, false, this.translate.instant(buttonText))
      .then((res: any) => {
        this.sale.saleDetails = [];
        this.saleDetails = [];
        this.clearAutoComplete();
        this.clearBankAutoComplete();
        if (res.isConfirmed && id) {
          this.createSaleReport(id);
        }

      });
  }

  editSaleNoFnc(): void {
    this.editSaleNo = true;
    this.saleDescription.controls.saleNo.enable();
    this.saleDescription.controls.saleYear.enable();
  }

  checkSaleNo(): void {
    if (this.saleDescription.controls.saleNo.valid &&
      this.saleDescription.controls.saleYear.valid) {
      this.wholeSaleService.noSaleExists(
        this.getCompanyID(),
        this.saleDescription.controls.saleNo.value,
        `20${this.saleDescription.controls.saleYear.value}`,
      )
        .subscribe((isInvalid: boolean) => {
          if (isInvalid) {
            this.showSaleNoError = true;
          } else {
            this.showSaleNoError = false;
            this.editSaleNo = false;
            this.saleDescription.controls.saleNo.disable();
            this.saleDescription.controls.saleYear.disable();
          }
        })
    }
  }

  clearAutoComplete(): void {
    this.clientAutocomplete.clearInput();
  }

  clearBankAutoComplete(): void {
    this.bankAutoComplete.clearInput();
  }

  addDays(days: number): void {
    this.paymentInvoice.controls.deadline.setValue(moment(new Date, "yyyy-MM-dd").add(days, 'days').format('yyyy-MM-DD'));
    this.paymentInvoice.controls.status.setValue(true);
    this.paymentInvoice.controls.status.disable();
  }

  daysToDate(date: Date): void {
    const date1: any = new Date();
    const date2: any = new Date(date);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    this.paymentInvoice.controls.days.setValue(diffDays);
    this.paymentInvoice.controls.status.setValue(true);
    this.paymentInvoice.controls.status.disable();
  }

  posNumbersOnly(event: any): boolean {
    return event.charCode >= 48
  }

  selectedClient(client: ClientSelectedType) {
    this.client = client;
    if (client) {
      this.getPriceList(client.id);
      this.payments.find(x => x.type === WholeSalePaymentType.DeliveryNote) ?
        this.payments.find(x => x.type === WholeSalePaymentType.DeliveryNote).disabled = false : null;
    } else {
      this.payments.find(x => x.type === WholeSalePaymentType.DeliveryNote) ?
        this.payments.find(x => x.type === WholeSalePaymentType.DeliveryNote).disabled = true : null;
      this.sale.isMKChecked = false;
    }
  }

  onSelectedBank(bankDetail: IBankDetail): void {
    this.bankDetail = bankDetail;
  }

  getPriceList(clientID: number) {
    this.clientsService.getPriceListByClientID(clientID)
      .subscribe((res: IPriceList) => {
        console.log(res);
      })
  }

  createSaleReport(id: number): void {

    const domain = environment.API_URL.split('/api')[0];

    switch (this.sale.type) {
      case WholeSalePaymentType.Invoice: {
        const url = `${domain}/Reports/CreateSaleReport/${id}`
        window.open(url, '_blank').focus();
        break;
      }
      case WholeSalePaymentType.ProInvoice: {
        const url = `${domain}/Reports/CreateProInvoiceReport/${id}`
        window.open(url, '_blank').focus();
        break;
      }
      case WholeSalePaymentType.DeliveryNote: {
        const url = `${domain}/Reports/CreateDeliveryReport/${id}`
        window.open(url, '_blank').focus();
        break;
      }
      case WholeSalePaymentType.Debt: {
        const url = `${domain}/Reports/CreateSaleReport/${id}`
        window.open(url, '_blank').focus();
        break;
      }
      case WholeSalePaymentType.Cancel: {
        const url = `${domain}/Reports/CreateSaleReport/${id}`
        window.open(url, '_blank').focus();
        break;
      }
      default: {
        break;
      }

    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

}


