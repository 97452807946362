
export class TableForm {
  constructor(
    public name: string,
    public customID: number,
    public tableForm: string,
    public tableChairs: number,
    public positionX: number,
    public positionY: number,
    public offsetX: number,
    public offsetY: number,
    public initialPositionX: number,
    public initialPositionY: number,
    public isReserved: boolean,
    public companyID: number,

  ) {}
}
