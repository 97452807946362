import { Component, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../data.service';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/auth.service';
import { AlertService } from '../../services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateKeysService } from '../../services/translate-keys.service';
import { environment } from '../../../environments/environment';
import { DatePipe } from '@angular/common';
import { ICheckOut } from 'src/app/interfaces/ICheckOut';

@Component({
  selector: 'app-waiter-checkout',
  templateUrl: './waiter-checkout.component.html',
  styleUrls: ['./waiter-checkout.component.css'],
  providers: [DatePipe],
})
export class WaiterCheckoutComponent implements OnInit {
  userData = {
    password: '',
    startedShift: Date(),
  };
  waiterObject;
  myDate = new Date();
  myTime = new Date();

  constructor(
    public alertService: AlertService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private api: DataService,
    private autoLogout: AuthService,
    private translateKeys: TranslateKeysService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.waiterObject = JSON.parse(localStorage.getItem('waiterObject'));
  }
  /**
   * each time a user clicks on the numpad we pass the value to userData.password
   * the numpad password input is an ngModel to userData.password
   * @param numValue
   */
  type(numValue: number) {
    if (this.userData.password.length < 4) {
      this.userData.password += numValue;
    } else {
      return 'Length allowed only 4 max elements';
    }
  }

  /**
   * Each time the user presses X button on numpad we slice(remove) the last item
   */
  resetInput(): any {
    const oldpw = this.userData.password;
    const newpw = oldpw.slice(0, -1);
    this.userData.password = newpw;
  }

  /**
   * when the users clicks Enter Button ( > ) in numpad we pass the pin to this function
   * if succeeded we redirect the user to the next route
   * if not we display an error with wrong pin
   * @param pin
   */
  loginPin(pin: string) {
    const parsedPin = parseInt(pin, 10);
    const waiterID = this.api.getWaiterID();
    if (pin === null || pin === '') {
      this.alertService.swalFire(
        'info',
        this.translateKeys.doTranslate('EMPTY_PIN'),
        true,
        false,
        'OK',
        '',
        2000
      );
      return;
    } else if (waiterID === null || waiterID === '') {
      this.alertService.swalFire(
        'error',
        this.translateKeys.doTranslate('LOGIN_ERROR'),
        true,
        false,
        'Mbyll',
        '',
        1000
      );
      const token = localStorage.getItem('token');
      this.router.navigate(['/'], { queryParams: { token } });
    } else {
      this.alertService.swalAutoLoader();
      this.api.checkOutWaiter(waiterID, parsedPin).subscribe(
        (response) => {
          // @ts-ignore
          if (response.value.status === 200) {
            const token = localStorage.getItem('token');
            this.router.navigate(['/'], { queryParams: { token } });
          }
          Swal.close();
        },
        (error) => {
          if (error.status === 401) {
            this.alertService.swalFire(
              'error',
              this.translateKeys.doTranslate('WRONG_PIN'),
              false,
              true,
              '',
              this.translateKeys.doTranslate('CLOSE')
            );
            this.userData.password = '';
          } else if (error.status === 402) {
            this.alertService.swalFire(
              'error',
              this.translateKeys.doTranslate('Missing EmployeeID'),
              false,
              true,
              '',
              this.translateKeys.doTranslate('CLOSE')
            );
            this.userData.password = '';
          }
        }
      );
    }
  }
}
