<div>
    <div class="modal-header">
        <span class="modal-title"></span>
        <button type="button" class="close-modal-icon" (click)="closeDialog()"
        data-dismiss="modal" aria-label="Close">
        <img src="assets/images/x-inactive-new.png" class="closeIcon">
        </button>
    </div>
    <div class="modal-body">
        <div id="addedMoneytxt" class="d-flex justify-content-between">
            <div class="p-2 m-3 left-content">
                <div class="d-flex align-items-center justify-content-between">
                    <p class="mr-3 p-text">{{"ALL_DAILY_SALES" | translate}}</p>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                    <p class="mr-3 waiter-sales p-text">{{"NONFISCAL_SALES" | translate}}: </p>
                    <span class="span-number">{{ noneFiscalSales }}</span>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                    <p class="mr-3 waiter-sales p-text">{{"FISCAL_SALES" | translate}}: </p>
                    <span class="span-number">{{ fiscalSales }}</span>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                    <p class="mr-3 waiter-sales p-text">{{"TOTAL_SALES" | translate}}: </p>
                    <span class="span-number">{{ totalSales }}</span>
                </div>
            </div>
            <div class="p-2 m-3">
                <div class="d-flex align-items-center justify-content-between">
                    <p class="mr-3 p-text">{{waiterName}}</p>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                    <p class="mr-3 waiter-sales"></p>
                    <span class="span-number">{{ noneFiscalWaiterSales }}</span>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                    <p class="mr-3 waiter-sales"></p>
                    <span class="span-number">{{ fiscalWaiterSales }}</span>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                    <p class="mr-3 waiter-sales"></p>
                    <span class="span-number">{{ totalWaiterSales }}</span>
                </div>
            </div>
        </div>
        <div class="modal-footer d-flex align-items-end justify-content-between">
            <div class="row col-md-6 m-0">
            </div>
            <div class="form-group col-md-4 padding-0 text-align-right">
                <button type="button" (click)="closeReport()"  class="btn btn-primary float-lg-right">{{"DAY_SHIFT" | translate}}</button>
            </div>
        </div>
    </div>
</div>
