import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import {TranslateKeysService} from './translate-keys.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private router: Router, public translateKeys: TranslateKeysService) { }
  public autoLogoutTimeMS = (30 * 60) * 1000;

  autoLogout(){
    setTimeout(() => {
      this.swalFire('info', this.translateKeys.doTranslate('SESSION_EXPIRED'), true, true, 'Vazhdo', 'Mbyll')
        .then(result => {
          if(result.isConfirmed){
            this.autoLogout();
          }else{
          this.logOut();
          }
        });

    }, this.autoLogoutTimeMS);
  }

  logOut(){
    const token = localStorage.getItem('token');
    this.router.navigate(['/'], {queryParams: { token}} );
  }

  swalFire(type: string, message, showConfirmButton: boolean, showCancelButton: boolean, confirmButtonText?: string, cancelButtonText?: string, timer?: number) {
  return Swal.fire({
      // icon: 'info',
      html: '<div class="row justify-content-center pt-4"><img src="../../assets/images/sweet_' + type + '_icon.png"></div>' +
        '<div class="row justify-content-center  swal2-text"> ' + message + ' </div>',
      showCloseButton: true,
      showCancelButton,
      confirmButtonText,
      cancelButtonText,
      customClass: {
        header: 'customHeader',
        closeButton: 'closeButtonclass',
        confirmButton: '' + type + 'ConfirmButton',
        cancelButton: '' + type + 'CancelButton'
      },
      showConfirmButton,
      timer
    });
  }
}
