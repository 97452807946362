import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WaiterModuleComponent } from './components/waiter-module/waiter-module.component';
import { WaiterLoginComponent } from './components/waiter-login/waiter-login.component';
import { OrderComponent } from './components/order/order.component';
import { OrderReportsComponent } from './components/order-reports/order-reports.component';
import { TableLayoutComponent } from './components/table-layout/table-layout.component';
import { ReservationsComponent } from './components/reservations/reservations.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { WaiterCheckoutComponent } from './components/waiter-checkout/waiter-checkout.component';
import { WholesaleComponent } from './components/wholesale/wholesale.component';
import { SaleListComponent } from './components/sale-list/sale-list.component';
import { OffersListComponent } from './components/offers-list/offers-list.component';
import { ProinvoiceListComponent } from './components/proinvoice-list/proinvoice-list.component';
import { ReservedSalesListComponent } from './components/reserved-sales-list/reserved-sales-list.component';
import { CanceledSalesListComponent } from './components/canceled-sales-list/canceled-sales-list.component';
import { OfferComponent } from './components/offer/offer.component';
import { WholesaleEditComponent } from './wholesale-edit/wholesale-edit.component';
import { OfferEditComponent } from './components/offer-edit/offer-edit.component';
import { OrderstatusComponent } from './components/orderstatus/orderstatus.component';
import { DebtCardComponent } from './components/debt-card/debt-card.component';
import { VoidSalesListComponent } from './components/void-sales-list/void-sales-list.component';
import { ConfigsComponent } from './components/configs/configs.component';

const routes: Routes = [
  { path: '', component: WaiterModuleComponent },
  { path: 'kamarieri/:id', component: WaiterLoginComponent },
  { path: 'kamarieri/:id/dashboard', component: OrderComponent },
  { path: 'kamarieri/:id/wholesale', component: WholesaleComponent },
  { path: 'wholesale/edit/:id', component: WholesaleEditComponent },
  { path: 'offer/edit/:id', component: OfferEditComponent },
  { path: 'create-offer', component: OfferComponent },
  { path: 'kamarieri/:id/salelist', component: SaleListComponent },
  { path: 'kamarieri/:id/offerslist', component: OffersListComponent },
  { path: 'kamarieri/:id/proinvoice', component: ProinvoiceListComponent },
  { path: 'kamarieri/:id/reservedlist', component: ReservedSalesListComponent },
  {
    path: 'kamarieri/:id/canceledsales',
    component: CanceledSalesListComponent,
  },
  { path: 'kamarieri/:id/voidsales', component: VoidSalesListComponent},
  // {path: 'kamarieri/:id/dashboard', component: TableLayoutComponent},
  { path: 'kamarieri/:id/dashboard/:table', component: OrderComponent },
  { path: 'kamarieri/:id/dashboard/edit/:table', component: OrderComponent },
  { path: 'reports', component: OrderReportsComponent },
  { path: 'layout', component: TableLayoutComponent },
  { path: 'reservations', component: ReservationsComponent },
  { path: 'kamarieri/:id/order/edit/:table', component: OrderComponent },
  { path: 'kamcheckout', component: WaiterCheckoutComponent },
  { path: 'orderstatus', component: OrderstatusComponent },
  { path: 'kamarieri/:id/debtcard', component: DebtCardComponent },
  { path: 'kamarieri/:id/configs', component: ConfigsComponent},
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
