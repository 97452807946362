import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../data.service';
import Swal from 'sweetalert2';
import { IWaiters } from '../../interfaces/IWaiters';
import { AlertService } from '../../services/alert.service';
import { TranslateKeysService } from '../../services/translate-keys.service';
import { AuthService } from '../../services/auth.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { OfflineCrud } from 'src/app/services/offline-crud.service';
import { IWaiterAuthObject } from 'src/app/interfaces/IWaiterAuthObject';
import { TranslateService } from '@ngx-translate/core';
import { SignalrService } from 'src/app/services/signalr.service';

@Component({
  selector: 'app-waiter-module',
  templateUrl: './waiter-module.component.html',
  styleUrls: ['./waiter-module.component.css'],
})
export class WaiterModuleComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private api: DataService,
    private alertService: AlertService,
    private autoLogout: AuthService,
    private fb: FormBuilder,
    private translateKeys: TranslateKeysService,
    private offlineService: OfflineCrud,
    private translate: TranslateService,
    private signalrService: SignalrService
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      const selectedLanguage = params['lang'];
      if (selectedLanguage) {

        this.translate.use(selectedLanguage);
        localStorage.setItem('selectedLanguage', selectedLanguage);

      } else {
        const storedLanguage = localStorage.getItem('selectedLanguage');
        this.translate.use(storedLanguage || 'sq'); // Set default language here
      }
    });
  }

  private subscriptions: Subscription[] = [];
  waiterObject: IWaiterAuthObject;
  waiters: IWaiters[];
  loginForm = this.fb.group({
    username: ['', [Validators.required]],
    pin: [
      '',
      [Validators.required, Validators.maxLength(4), Validators.minLength(4)],
    ],
  });
  totalGrid = new Array(20);
  @ViewChild('username') username: ElementRef;

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      pin: new FormControl('', [Validators.required, Validators.maxLength(4)]),
    });
    // We read here the param token in URL HEADER and then we replace empty space with + and join
    this.activatedRoute.queryParams.subscribe((params) => {

      if (params.token) {
        const token = params.token.trim().replace(' ', '+').split(' ').join('');
        localStorage.setItem('token', token);
      }
    });

    this.subscriptions.push(
      this.offlineService.onlineSubject.subscribe((isOnline: boolean) => {
        if (isOnline) {
          this.getWaiters();
          this.getCompanyID();
        } else {
          this.getWaitersFromLocalDb();
          this.offlineService
            .getWaiterAuthObject()
            .then((waiterAuthObject: IWaiterAuthObject) => {
              this.waiterObject = waiterAuthObject[0];
            });
        }
      })
    );
  }

  /**
   * We get company id here by passing the token in Authorization header in Data Service.ts
   */
  getCompanyID() {
    this.alertService.swalAutoLoader();
    this.api.getCompanyId().subscribe(
      (response: any) => {
        this.waiterObject = response;
        this.offlineService.syncWaiterAuthObject(response);
        localStorage.setItem('waiterObject', JSON.stringify(this.waiterObject));
        Swal.close();
      },
      (error) => {
        if (error.status === 0) {
          this.alertService.swalFire(
            'error',
            this.translateKeys.doTranslate('FAILED_TO_CONNECT_TO_API'),
            false,
            true,
            '',
            this.translateKeys.doTranslate('CLOSE')
          );
        }
      }
    );
  }

  /**
   * We save here the waiterObject (waiter data ex id = waiterID ,username, companyID,) in local storage of browser
   * @param data
   */
  goToLogin(data: IWaiters) {
    this.waiterObject.waiterID = data.id;
    this.waiterObject.username = data.username;
    this.waiterObject.passwordHint = data.passwordHint;
    this.signalrService.leaveCompanyRoom();
    localStorage.setItem('waiterObject', JSON.stringify(this.waiterObject));
    this.signalrService.joinCompanyGroup();
    this.router.navigate(['kamarieri', data.id]);
  }

  /**
   * this is a function in which we get all waiter for this particular company
   * we pass the token in authorization header here
   */

  getWaiters() {
    this.api.getWaiters().subscribe(async (waiters: IWaiters[]) => {
      this.waiters = waiters;
      this.totalGrid = new Array(20 - this.waiters.length);
      var localDbWaiters = await this.offlineService.getWaitersFromLocalDb();
      if (!localDbWaiters || this.waiters !== localDbWaiters) {
        this.offlineService.syncWaitersWithLocalDb(this.waiters);
      }
    });
  }
  getWaitersFromLocalDb() {
    this.offlineService.getWaitersFromLocalDb().then((waiters: IWaiters[]) => {
      this.waiters = waiters;
      this.totalGrid = new Array(20 - this.waiters.length);
    });
  }
  /**
   * function which logs in the waiter in the next process
   * we have two options here if waiter tries to log in with inputs we search if that
   * waiter exists in waiters array and try the loginPIN function ,
   * the 2 process is if he clicks his name we redirect him to NUMPAD component(waiter-login.component.html)
   */
  loginPin() {
    const { username, pin } = this.loginForm.value;
    const waiter = this.waiters.find((x) => x.username === username);
    if (waiter) {
      if (this.offlineService.isOnline()) {
        this.api.loginPIN(waiter.id, pin).subscribe(
          (data) => {
            this.autoLogout.autoLogout();
            this.router.navigate(['dashboard'], {
              relativeTo: this.activatedRoute,
            });
          },
          (error) => {
            this.alertService.swalFire(
              'error',
              this.translateKeys.doTranslate('WRONG_PIN'),
              false,
              true,
              '',
              this.translateKeys.doTranslate('CLOSE')
            );
            this.setInvalidInput('pin');
          }
        );
      } else {
        if (waiter.pin === pin) {
          this.autoLogout.autoLogout();
          this.router.navigate(['dashboard'], {
            relativeTo: this.activatedRoute,
          });
        } else {
          this.alertService.swalFire(
            'error',
            this.translateKeys.doTranslate('WRONG_PIN'),
            false,
            true,
            '',
            this.translateKeys.doTranslate('CLOSE')
          );
          this.setInvalidInput('pin');
        }
      }
    } else {
      this.setInvalidInput('username');
      this.username.nativeElement.focus();
    }
  }

  /**
   * sets invalid input if error is in username
   * @param inputID
   */
  setInvalidInput(inputID) {
    this.loginForm.controls[inputID].setErrors({ invalid: true });
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => {
      s.unsubscribe();
    });
  }
}
