import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DataService } from '../../data.service';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ISale } from '../../interfaces/ISale';
import { TranslateService } from "@ngx-translate/core";
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-reports',
  templateUrl: './order-reports.component.html',
  styleUrls: ['./order-reports.component.css']
})
export class OrderReportsComponent implements OnInit {
  constructor(
    private api: DataService,
    private calendar: NgbCalendar,
    public translate: TranslateService,
    private router: Router) { }

  public date = new Date().getFullYear();
  public hours = new Date().getHours();

  allSales: ISale[] = [];
  activeSales: ISale[] = [];
  closedSales: ISale[] = [];
  currentSale: ISale;

  model: NgbDateStruct;
  Language;
  @Output() payments;

  async ngOnInit() {
    await this.getAllSales().then(() => {
      this.getactiveSales();
      this.getclosedSales();
    });
    this.getPayments();
  }

  getAllSales() {
    const companyID = JSON.parse(localStorage.getItem('waiterObject')).companyID;
    return this.api.getAllSales(companyID).toPromise().then((response: ISale[]) => {
      this.allSales = response;
    });
  }
  getactiveSales() {
    this.activeSales = this.allSales.filter(x => x.status === false && x.isDeleted === false)
      .sort((a, b) => {
        if (a.id > b.id) { return -1; }
        if (b.id > a.id) { return 1; }
        return 0;
      });
    return this.activeSales;
  }
  getclosedSales() {
    this.closedSales = this.allSales.filter(x => x.status === true && x.isDeleted === false)
      .sort((a, b) => {
        if (a.id > b.id) { return -1; }
        if (b.id > a.id) { return 1; }
        return 0;
      });
    return this.closedSales;
  }

  getSaleById(id) {
    this.currentSale = this.allSales.find(x => x.id === id);
  }

  showByDate(form) {
    this.getAllSales();
    const dt = new Date(form.year, form.month - 1, form.day + 1).toISOString().split('T');
    const companyID = this.getCompanyID();
    this.allSales = this.allSales.filter(x => x.modified.toString().split('T')[0] === dt[0]);
    this.getactiveSales();
    this.getclosedSales();
  }
  showAll() {
    this.getAllSales();
    this.getactiveSales();
    this.getclosedSales();
  }
  showToday(todayDate) {
    const sales: ISale[] = [];
    sales.push(this.allSales.find(x => x.modified.toString().split('T')[0] === todayDate));
    this.allSales = sales;
    this.getactiveSales();
    this.getclosedSales();
  }

  selectToday() {
    this.model = this.calendar.getToday();
    return this.model;
  }

  getCompanyID() {
    return JSON.parse(localStorage.getItem('waiterObject')).companyID;
  }
  resetForm(form) {
    form.reset();
  }

  getPayments() {
    this.api.getPayments()
      .subscribe((payments) => {
        this.payments = payments;
      }, (error) => {
      });
  }
  goToOrder() {
    const wID = this.api.getWaiterData().waiterID;
    this.router.navigate(['kamarieri/' + wID + '/dashboard'])
  }


}
