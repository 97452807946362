<app-navbar [showSearchInput]="false"></app-navbar>
<div class="d-flex justify-content-center settings-title">
  {{'SETTINGS' | translate }}
</div>
<div class="mt-5 d-flex justify-content-center">
  <div class="mt-3 mr-5">
    <div>
      <p>{{'HOTKEY' | translate }} (f2)</p>
      <select class="form-control select-dropdown" [(ngModel)]="selectedFirstFunction">
        <option *ngFor="let function of functions" [value]="function.value">{{ function.text }}</option>
      </select>
    </div>
    <div class="mt-3">
      <p>{{'HOTKEY' | translate }} (f4)</p>
      <select class="form-control select-dropdown" [(ngModel)]="selectedSecondFunction">
        <option *ngFor="let function of functions" [value]="function.value">{{ function.text }}</option>
      </select>
    </div>
    <div class="mt-3">
      <p>{{'HOTKEY' | translate }} (f5)</p>
      <select class="form-control select-dropdown" [(ngModel)]="selectedThirdFunction">
        <option *ngFor="let function of functions" [value]="function.value">{{ function.text }}</option>
      </select>
    </div>
  </div>
  <div>
    <div class="mt-3">
      <p>{{'HOTKEY' | translate }} (f7)</p>
      <select class="form-control select-dropdown" [(ngModel)]="selectedFourthFunction">
        <option *ngFor="let function of functions" [value]="function.value">{{ function.text }}</option>
      </select>
    </div>
    <div class="mt-3">
      <p>{{'HOTKEY' | translate }} (f8)</p>
      <select class="form-control select-dropdown" [(ngModel)]="selectedFifthFunction">
        <option *ngFor="let function of functions" [value]="function.value">{{ function.text }}</option>
      </select>
    </div>
    <div class="mt-3">
      <p>{{'HOTKEY' | translate }} (f9)</p>
      <select class="form-control select-dropdown" [(ngModel)]="selectedSixthFunction">
        <option *ngFor="let function of functions" [value]="function.value">{{ function.text }}</option>
      </select>
    </div>
    <div class="d-flex justify-content-end mt-5">
      <button class="btn btn-outline-primary" (click)="submit()">{{'EDIT' | translate }}</button>
    </div>
  </div>
</div>
