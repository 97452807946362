import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ISale } from '../interfaces/ISale';
import { SaleDebt } from '../models/SaleDebt';

@Injectable({
  providedIn: 'root'
})
export class WholeSaleService {
  private API_URL = environment.API_URL;

  constructor(
    private http: HttpClient
  ) { }

  createSaleInvoice(sale: ISale, noSaleYear: string, companyID?:any, groupClientID?:any): Observable<any> {
    return this.http.post(`${this.API_URL}/salesapi/createsaleinvoice`, {...sale},
    {params: {
      noSaleYear, companyID, groupClientID
    }});
  }

  createProInvoice(sale: ISale, noSaleYear: string, companyID?:any,groupClientID?:any): Observable<any> {
    return this.http.post(`${this.API_URL}/salesapi/CreateProInvoice`, {...sale},
    {params: {
      noSaleYear, companyID, groupClientID
    }});
  }

  reserve(sale: ISale): Observable<any> {
    return this.http.post(`${this.API_URL}/salesapi/reserve`, {...sale});
  }

  createDeliveryNote(sale: ISale): Observable<{id: number, isReport: boolean, isService: boolean}> {
    return this.http.post<{id: number, isReport: boolean, isService: boolean}>(`${this.API_URL}/salesapi/createdeliverynote`, {...sale});
  }
  
  createSaleWithDebt(sale: ISale, saleDebt: SaleDebt, noSaleYear: string, groupClientID?: any): Observable<any> {
    return this.http.post(`${this.API_URL}/salesapi/createsalewithdebt`, {sale, saleDebt},
    {params: { groupClientID, noSaleYear}});
  }

  cancelSale(sale: ISale, noSaleYear: string): Observable<any> {
    return this.http.post(`${this.API_URL}/salesapi/CancelSale`, {...sale},
    {params: {
      noSaleYear
    }});
  }

  cancelBill(sale: ISale, noSaleYear: string): Observable<any> {
    return this.http.post(`${this.API_URL}/salesapi/CancelBill`, {...sale},
    {params: {
      noSaleYear
      }
    });
  }

  createOffer(sale: ISale, noSaleYear: string): Observable<any> {
    return this.http.post(`${this.API_URL}/salesapi/createOffer`, {...sale},
    {params: {
      noSaleYear
    }});
  }

  

  getNoSale(companyId: string, clientId?: any): Observable<number> {
    return this.http.get<number>(`${this.API_URL}/salesapi/getnosale`, {
      params: { companyId, clientId },
    });
  }

  getGroupClient(companyId: string, clientId?: any): Observable<number> {
    return this.http.get<number>(`${this.API_URL}/salesapi/GetGroupClientId`, {
      params: { companyId, clientId },
    });
  }
  
  noSaleExists(companyId: string, noSale: string, year: string, clientID?:any): Observable<boolean> {
    return this.http.get<boolean>(`${this.API_URL}/salesapi/NoSaleExists`, {params: {
      companyId,
      noSale, 
      year,
      clientID
    }});
  }

  updateSale(sale: ISale, noSaleYear: string, groupClientID?:any): Observable<any> {
    return this.http.post(`${this.API_URL}/salesapi/UpdateWholeSale`, {...sale},
    {params: {
      noSaleYear,
      groupClientID
    }});
  }
}
