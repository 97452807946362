import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { IBankDetail } from 'src/app/interfaces/IBankDetail';

@Component({
  selector: 'app-conome-autocomplete',
  templateUrl: './conome-autocomplete.component.html',
  styleUrls: ['./conome-autocomplete.component.css']
})
export class ConomeAutocompleteComponent implements OnChanges {
  searchInput = new FormControl();
  filteredData: Observable<any>;

  @Input() label: string;
  @Input() data: any[];
  @Input() value: any;
  @Input() displayFieldName: string;
  @Input() filterKeys: string[] = [];
  @Input() disabled: boolean;

  @Output() onSelectedData = new EventEmitter<any>();


  ngOnChanges(changes: SimpleChanges): void {
    if ('data' in changes) {
      this.onUserInput();
    }
    if ('disabled' in changes) {
      this.disabled ? this.searchInput.disable() : this.searchInput.enable();
    }
    if ('value' in changes) { 
        this.searchInput.setValue(this.value);
    }
  }
  
  onUserInput(): void {
    this.filteredData = this.searchInput.valueChanges.pipe(debounceTime(200)).pipe(
      startWith(''),
      map((value: any) => {
        if (value === undefined || value === "") {
          this.onSelectedData.emit(undefined);
        } else if (typeof value === "string") {
          this.onSelectedData.emit(null);
        } else {
          this.onSelectedData.emit(value);
        }
        return this.filterData(value)
      }));
  }

  public clearInput(): void {
    this.onSelectedData.emit(undefined);
    this.searchInput.setValue('');
  }

  private filterData(value: string): any[] {
    const filterValue = value.toString().toLowerCase();

    let filteredData;
    for (let key of this.filterKeys) {
       filteredData = this.data.filter(x => x[key].toLowerCase().indexOf(filterValue) === 0);
       if (filteredData.length > 0) {
         break;
       }
    }

    return filteredData;
  }

  onOptionSelected(data: any): void {
    this.onSelectedData.emit(data);
  }

  displayFn( displayFieldName: string, data: IBankDetail) {
    if (data) {
      return data[displayFieldName];
    } else {
      return '';
    }
  }

}
