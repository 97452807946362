import { DatePipe, formatDate } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { WholesalePayments } from 'src/app/const/WholeSalePayments';
import { DataService } from 'src/app/data.service';
import { WholeSalePaymentType } from 'src/app/Enums/WholeSalePaymentType';
import { IBankDetail } from 'src/app/interfaces/IBankDetail';
import { ICategory } from 'src/app/interfaces/ICategory';
import { Client } from 'src/app/interfaces/IClient';
import { IConfigs } from 'src/app/interfaces/IConfigs';
import { IOptions } from 'src/app/interfaces/IOptions';
import { IPriceList } from 'src/app/interfaces/IPriceList';
import { IProduct } from 'src/app/interfaces/IProduct';
import { ISale } from 'src/app/interfaces/ISale';
import { IWaiters } from 'src/app/interfaces/IWaiters';
import { Product } from 'src/app/models/product';
import { SaleDebt } from 'src/app/models/SaleDebt';
import { SaleDebtDetail } from 'src/app/models/SaleDebtDetail';
import { AlertService } from 'src/app/services/alert.service';
import { BankDetailsService } from 'src/app/services/bank-details.service';
import { ClientService } from 'src/app/services/client.service';
import { ConfigsService } from 'src/app/services/configs.service';
import { OfflineCrud } from 'src/app/services/offline-crud.service';
import { WholeSaleService } from 'src/app/services/whole-sale.service';
import { WindowService } from 'src/app/services/window.service';
import { ClientAutocompleteInputComponent } from 'src/app/shared/components/client-autocomplete-input/client-autocomplete-input.component';
import { ConomeAutocompleteComponent } from 'src/app/shared/components/conome-autocomplete/conome-autocomplete.component';
import { ClientSelectedType } from 'src/app/types/ClientSelectedType';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-wholesale',
  templateUrl: './wholesale.component.html',
  styleUrls: ['./wholesale.component.scss']
})
export class WholesaleComponent implements OnInit {
  IMG_PATH_CATEGORY = environment.IMG_PATH_CATEGORY;
  IMG_PATH = environment.IMG_PATH;
  @ViewChild('closeModalBtn') closeModal: ElementRef;
  @ViewChild('clientAutocomplete')
  clientAutocomplete: ClientAutocompleteInputComponent;
  @ViewChild('bankAutoComplete') bankAutoComplete: ConomeAutocompleteComponent;
  CompanyID: number = this.getCompanyID();
  WaiterID: number = this.api.getWaiterID();
  rightScreenDB: number
  leftWidth: number;
  rightWidth: number;
  grabber:number = 1;
  grabberClicked: boolean = false;
  grabberIsSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  grabbed: boolean = false;
  screenWidth: number;
  roundRightWidth: number;
  percentageLeft: number = 0.68;
  percentageRight: number = 0.31;
  percentageGrabber: number = 0.01;
  clients: Client[] = [];
  bankDetails: IBankDetail[] = [];
  bankDetailsFilterKeys = ['bankNameSQ', 'bankNameMK'];
  bankDisplayFieldName = 'bankNameSQ';

  todayDate = moment(new Date()).format('yyyy-MM-DD');
  categories: ICategory[] = [];
  saleDetails: Product[] = [];
  selectedCategoryIndex: number = -1;
  categoryIndex: number = -1;
  selectedOptionID = 0;
  editPriceId = -1;
  filteredProductCategories: IProduct[] = [];
  currentProduct: Product = null;
  clientDisabled = false;

  vat = 0;
  calculationData = {
    total: 0,
    vat: 0,
    subTotal: 0,
  };

  currentProductQuantity = 1;
  payments = WholesalePayments;
  payment: number;
  change: number;
  configs: IConfigs;
  client: Client;
  bankDetail: IBankDetail;
  editSaleNo = false;
  isChanged: boolean = false;
  showSaleNoError = false;
  waiters;
  saleCreateRole: boolean = false;
  groupClientID:number;
  groupofClients:string;

  sale: ISale = {
    modUser: this.api.getWaiterData().waiterID,
    noSale: `0/${new Date().getFullYear()}`,
    dailyID: 1,
    companyID: this.getCompanyID(),
    clientID: null,
    type: 1,
    dateSale: new Date(),
    total: Number(this.calculationData.total),
    hasReport: false,
    hasFiscal: false,
    comment: null,
    isMKChecked: false,
    isDebtChecked: false,
    paymentID: null,
    currencyName: 'MKD',
    accountantClientID: null,
    saleType: 3,
    status: false,
    saleDetails: [],
    salePayments: [],
    ordinalNumber: null,
    orderStatus: false,
  };

  saleDescription = new FormGroup({
    saleNo: new FormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
    saleYear: new FormControl(
      {
        value: new Date().getFullYear().toString().substring(2, 4),
        disabled: true,
      },
      [Validators.required]
    ),
    dateSale: new FormControl(new Date(), [Validators.required]),
    comment: new FormControl(),
    isMKChecked: new FormControl(false),
    
  });

  paymentInvoice = new FormGroup({
    deadline: new FormControl(),
    days: new FormControl(),
    personWhoPaid: new FormControl(),
    totalPaid: new FormControl(),
    status: new FormControl({ value: false, disabled: false }),
  });
  constructor(
    private dateAdapter: DateAdapter<Date>,
    private alertService: AlertService,
    private api: DataService,
    private wholeSaleService: WholeSaleService,
    private windowService: WindowService,
    private clientsService: ClientService,
    private offlineService: OfflineCrud,
    public translate: TranslateService,
    protected activatedRoute: ActivatedRoute,
    private configsService: ConfigsService,
    private bankDetailsService: BankDetailsService,
    private datePipe: DatePipe
  ) {
    this.dateAdapter.setLocale(this.translate.currentLang);
  }

  async ngOnInit(): Promise<void> {
      this.grabberIsSelected.subscribe(res => {
        this.grabbed = res;
      })
    this.getScreenSizeFromDB();
    this.configs = await this.getConfigs();
    if ([null, undefined].includes(this.configs)){
      this.configsService.getConfigs().subscribe((res: IConfigs) => {
        this.configs = res;
      },
      err => { console.log(err); }
      );
    }
    this.getCategoriesFromApi();
    this.getGroupClient();
    
    this.getClients();
    this.getBankDetails();
    this.getBankDetails();
    this.getWaiterRoles();
  }

// click events
categoryClick(index:number, categoryId: number){
  this.selectedCategoryIndex = categoryId;
  this.categoryIndex = index;
  this.clearFilters();
}
filterProductsClick(product: IProduct){
  this.setCurrentProduct(product);
  this.clearFilters();
}
selectedOptionClick(index: number, option: IOptions){
  this.selectedOptionID = index;
  this.setSelectedOption(option);
}
editPriceIdClick(i){
  this.editPriceId === i ? (this.editPriceId = -1): (this.editPriceId = i)
}

// Resizable panel
getScreenSizeFromDB(){
  this.screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  this.api.getWaiterConfigurationByID(this.WaiterID).subscribe(
    (res) => {
      try {
        this.rightScreenDB = res[0].screenSize;
        this.percentageRight = this.rightScreenDB;
        this.percentageLeft = 100 - this.rightScreenDB;
        this.percentageGrabber = 1;
        this.leftWidth = this.percentageLeft;
        this.rightWidth = this.percentageRight;
        this.grabber = this.percentageGrabber;
      } catch (error) {
          this.handleFallback();
        const rightSize = this.percentageRight * 100;
        this.api.createWaiterConfiguration(this.WaiterID, this.CompanyID, rightSize).subscribe()
      }
    },
    (error) => {
      this.handleFallback();
      const rightSize = this.percentageRight * 100;
      this.api.createWaiterConfiguration(this.WaiterID, this.CompanyID, rightSize).subscribe()
    });
}
handleFallback() {
  this.screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  this.percentageLeft = 0.68;
  this.percentageRight = 0.31;
  this.percentageGrabber = 0.01;
  this.leftWidth = this.screenWidth * this.percentageLeft;
  this.rightWidth = this.screenWidth * this.percentageRight;
  this.grabber = this.screenWidth * this.percentageGrabber;
  this.grabberClicked = false;
}
onMouseDown(event: MouseEvent) {
  this.grabberClicked = true;
  this.grabberIsSelected.next(true);
}
onTouchStart(event: TouchEvent) {
  this.grabberClicked = true;
  const touch = event.touches[0];
  this.grabberIsSelected.next(true);
  event.preventDefault();
}
@HostListener('document:mousemove', ['$event'])
onMouseMove(event: MouseEvent) {
  if (!this.grabberClicked) {
    return;
  }
  this.resize(event.clientX);
  event.preventDefault();
}
@HostListener('document:touchmove', ['$event'])
onTouchMove(event: TouchEvent) {
  if (!this.grabberClicked) {
    return;
  }
  const touch = event.touches[0];
  this.resize(touch.clientX);
  event.preventDefault();
}
@HostListener('document:mouseup', ['$event'])
onMouseUp(event: MouseEvent) {
  if (this.grabbed && this.rightScreenDB!==this.rightWidth){
    this.saveScreenSize();
  }
  this.grabberIsSelected.next(false);
  this.grabberClicked = false;
}
@HostListener('document:touchend', ['$event'])
onTouchEnd(event: TouchEvent) {
  if (this.grabbed){
    this.saveScreenSize();
  }
  this.grabberIsSelected.next(false);
  this.grabberClicked = false;
}
preventDefault(event: Event) {
  event.preventDefault();
}
resize(clientX: number) {
  this.screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const containerWidth = document.querySelector('.content')?.clientWidth;
  if (!containerWidth) return;
  const availableWidth = containerWidth - this.grabber; // Adjust for grabber width
  const minLeftWidth = Math.round(this.screenWidth * 0.40); // Set minimum width for left panel
  const minRightWidth = Math.round(this.screenWidth * 0.22); // Set minimum width for right panel
  const newPosition = Math.max(minLeftWidth, Math.min(clientX, availableWidth - minRightWidth));
  const newLeftPercentage = Math.round((newPosition / containerWidth) * 100);
  const newRightPercentage = Math.round(((availableWidth - newPosition) / containerWidth) * 100);
  this.leftWidth = newLeftPercentage;
  this.rightWidth = newRightPercentage;
  this.grabber = 1;
}
saveScreenSize() {
  const roundRightWidth = Math.round(this.rightWidth);
  const rightSize = this.percentageRight * 100
  if (this.rightScreenDB !== this.rightWidth) {
    if (this.rightWidth + this.leftWidth > 100) {
      if(!rightSize){
        this.api.updateWaiterConfiguration(this.WaiterID, rightSize).subscribe()
      }
    }
    else {
      this.api.updateWaiterConfiguration(this.WaiterID, roundRightWidth).subscribe()
    }
  }
}
//end of resizable panel

  getWaiterRoles() {
    const waiterObject = JSON.parse(localStorage.getItem('waiterObject'));
    this.api.getWaiters().subscribe(async (waiters: IWaiters[]) => {
      this.waiters = waiters.find((x) => x.id == waiterObject.waiterID);
      this.waiters.userRole.forEach((data) => {
        if (data.rolename == 'Sale create') {
          this.saleCreateRole = true;
        }
      });
    });
  }

  getConfigs(): Promise<IConfigs> {
    return this.offlineService.getOfflineConfigs();
  }

  clearError(): void {
    this.showSaleNoError = false;
  }

  getClients(): void {
    this.clientsService.getSaleClients().subscribe(
      (clients: Client[]) => {
        this.clients = clients;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getBankDetails(): void {
    this.bankDetailsService
      .getBankDetails(this.getCompanyID())
      .subscribe((bankDetails: IBankDetail[]) => {
        this.bankDetails = bankDetails;
      });
  }

  setCurrentProduct(product: IProduct): void {
    this.currentProduct = new Product(product);
    this.setSelectedOption(this.currentProduct.options[0]);
  }


  getGroupClient(clientId?:any):void{
    this.wholeSaleService
    .getGroupClient(this.getCompanyID(), clientId)
    .subscribe((noSale: any)=>{
      this.saleDescription.controls.saleNo.setValue(+noSale.noSale);
      this.groupofClients =  noSale.groupClientName;

      this.groupClientID = noSale.groupClientId;
    })
  }

  getNoSale(clientId = null): void {
    this.wholeSaleService
      .getNoSale(this.getCompanyID(),clientId)
      .subscribe((noSale: number) => {
        this.saleDescription.controls.saleNo.setValue(noSale);
      });
  }

  addProduct(productId: number, productDetailId: number) {
    if (this.checkAndUpdateQuantity(productId, productDetailId)) {
      // productCategory.qt -= this.ProductQuantity;
      // Shembull1
      // sellingpricewithvat=118
      // pricewithrabat=59 (cmimi i ri)
      // Rabat=100-(59*100/118)=50

      let isProductInTheCard = this.saleDetails.findIndex(x =>
        x.productID === this.currentProduct.productID &&
        x.selectedOptions.id === this.currentProduct.selectedOptions.id &&
        x.selectedOptions.sellingPriceWithRebate === this.currentProduct.selectedOptions.sellingPriceWithRebate &&
        x.selectedOptions.sellingPriceWithVat === this.currentProduct.selectedOptions.sellingPriceWithVat
      );  

      if (isProductInTheCard === -1) {
      this.currentProduct.qt = this.currentProductQuantity;
      this.currentProduct.selectedOptions.qt = this.currentProductQuantity;

      const oldSellingPriceWithVat =
        this.currentProduct.selectedOptions.sellingPriceWithVat;
      const oldSellingPriceNoVat =
        this.currentProduct.selectedOptions.sellingPriceNoVat;

      if (
        ![null, undefined, ''].includes(
          this.currentProduct.selectedOptions.sellingPriceWithRebate
        )
      ) {
        this.currentProduct.selectedOptions.sellingPriceWithVat =
          this.currentProduct.selectedOptions.sellingPriceWithRebate;
      }

      let newSellingPriceNoVat =
        oldSellingPriceNoVat <
        this.currentProduct.selectedOptions.sellingPriceWithVat
          ? this.currentProduct.selectedOptions.sellingPriceWithVat /
            (1 + this.currentProduct.selectedOptions.vat / 100)
          : oldSellingPriceNoVat;
      this.calculateVATForOption(this.currentProduct.selectedOptions);

      if (this.sale.isMKChecked) {
        const price = this.currentProduct.selectedOptions.sellingPriceWithRebate
          ? this.currentProduct.selectedOptions.sellingPriceWithRebate
          : this.currentProduct.selectedOptions.sellingPriceWithVat;

        const mk32Price =
          price -
          ((price / (1 + this.currentProduct.selectedOptions.vat / 100)) *
            this.currentProduct.selectedOptions.vat) /
            100;

        this.currentProduct.selectedOptions.totalVat = 0;
        this.currentProduct.selectedOptions.sellingPriceWithVat = Number(
          mk32Price.toFixed(2)
        );
        this.currentProduct.selectedOptions.sellingPriceNoVat = Number(
          newSellingPriceNoVat.toFixed(2)
        );
        this.currentProduct.selectedOptions.total =
          this.currentProduct.selectedOptions.qt * Number(mk32Price.toFixed(2));
      } else {
        this.currentProduct.selectedOptions.sellingPriceNoVat = Number(
          newSellingPriceNoVat.toFixed(2)
        );
        this.currentProduct.selectedOptions.total =
          this.currentProduct.selectedOptions.qt *
          Number(
            this.currentProduct.selectedOptions.sellingPriceWithVat.toFixed(2)
          );
      }

      // Update currentProduct with selectedOptions data
      this.currentProduct.sellingPriceWithVat =
        this.currentProduct.selectedOptions.sellingPriceWithVat;
      this.currentProduct.sellingPriceNoVat =
        this.currentProduct.selectedOptions.sellingPriceNoVat;
      this.currentProduct.profit = this.currentProduct.selectedOptions.profit;
      this.currentProduct.vatID = this.currentProduct.selectedOptions.vatId;
      this.currentProduct.total = this.currentProduct.selectedOptions.total;

      if (
        ![null, undefined, ''].includes(
          this.currentProduct.selectedOptions.sellingPriceWithRebate
        )
      ) {
        this.currentProduct.sellingPriceWithVat =
          this.currentProduct.selectedOptions.sellingPriceWithRebate;
      }

      this.currentProduct.rebate =
        Number(
          (
            100 -
            (this.currentProduct.selectedOptions.sellingPriceWithRebate * 100) /
              oldSellingPriceWithVat
          ).toFixed(4)
        ) || 0;

      if (this.currentProduct.rebate < 0) {
        this.currentProduct.rebate = 0;
      }

      // let newSellingPriceNoVat = (oldSellingPriceWithVat < this.currentProduct.sellingPriceWithVat) ?
      // this.currentProduct.sellingPriceWithVat / (1 + this.currentProduct.selectedOptions.vat / 100) : oldSellingPriceWithVat;
      // this.currentProduct.sellingPriceNoVat = Number(newSellingPriceNoVat.toFixed(2))

      this.currentProduct.selectedOptions.qt = this.currentProductQuantity;

      this.order(this.currentProduct);
    } else {
      for (let i = 0; i < this.currentProductQuantity; i++) {
        this.editItemplus(isProductInTheCard);
      }
    }
    this.currentProductQuantity = 1;
    this.closeProductModal();
    }
  }

  order(data: Product) {
    const nameByBrowserLang = this.getProductNameByBrowserLang(data);
    data.productName = nameByBrowserLang;

    if (data.selectedOptions) {
      data.productName = `${nameByBrowserLang} ${data.selectedOptions.productName}`;
      this.vat += Number(data.selectedOptions.totalVat);
    }

    data.productDetailsID = data.selectedOptions.id;
    this.saleDetails.push(data);
    this.clientDisabled = true;
    this.editPriceId = -1;
    this.total();
    this.filteredProductCategories = [];
    (document.getElementById('navbar-search-input') as HTMLInputElement).value =
      '';
  }

  // calculates and updates total when product is added or quantity is updated
  updateProductTotal(detailIndex?: number) {
    // detailIndex is used from html when product qt is update ( +, - buttons)
    const product =
      detailIndex >= 0
        ? this.saleDetails[detailIndex].selectedOptions
        : this.currentProduct.selectedOptions;

    product.total = product.qt * product.sellingPriceWithVat;
    const vat = product.sellingPriceWithRebate ? product.totalVat : 0;
    // const price = product.qt * (product.sellingPriceWithRebate ? product.sellingPriceWithRebate : product.sellingPriceNoVat) - vat;
    const price =
      product.qt *
        (product.sellingPriceWithRebate
          ? product.sellingPriceWithRebate
          : product.sellingPriceWithVat) -
      vat;

    product.total = Number(price.toFixed(2));
    product.totalVat = 0;

    // } else {
    //   if (product.sellingPriceWithVat) {
    //     // check if user added sellingPriceWithRebate
    //     if (![undefined, null].includes( product.sellingPriceWithRebate )) {
    //       product.total = product.qt * product.sellingPriceWithRebate;
    //     } else {
    //       product.total = product.qt * product.sellingPriceWithVat;
    //     }
    //   }
    // }

    // currentProduct is used only for new added products, so we dont have to update it on + or - button clcik
    if (detailIndex == undefined) {
      this.currentProduct.selectedOptions = product;
      this.currentProduct.total = this.currentProduct.selectedOptions.total;
    } else {
      this.saleDetails[detailIndex].selectedOptions = product;
      this.saleDetails[detailIndex].total = product.total;
    }
  }

  calculateVATForOption(option: IOptions) {
    // if user added rebate
    if (![null, undefined].includes(option.sellingPriceWithRebate)) {
      if (option.sellingPriceWithRebate == 0) {
        option.totalVat = 0;
      } else {
        option.totalVat =
          option.qt *
          (((option.sellingPriceWithRebate / (1 + option.vat / 100)) *
            option.vat) /
            100);
      }
    } else {
      // without rebate
      option.totalVat =
        option.qt * (option.sellingPriceWithVat - option.sellingPriceNoVat);
    }
  }

  formatIngredientsDetails(product: Product) {
    product.selectedIngredients.forEach((ingredient) => {
      if (ingredient.qt === ingredient.defaultQt) {
        ingredient.price = 0;
        ingredient.hasExtraIngredients = false;
      } else if (ingredient.qt > ingredient.defaultQt) {
        ingredient.qt -= ingredient.defaultQt;
        ingredient.hasExtraIngredients = true;
      }
    });
    product.saleIngredients = product.selectedIngredients;
  }

  getCategoriesFromApi() {
    const waiterObject = JSON.parse(localStorage.getItem('waiterObject'));
    this.alertService.swalAutoLoader();
    this.api.getPOSCategories(waiterObject.companyID).subscribe(
      (response: ICategory[]) => {
        this.categories = response;
        this.createAllCategories();
        Swal.close();
      },
      (error) => {
        Swal.close();
      }
    );
  }
  createAllCategories() {
    const data = [...this.categories.map((x) => x.products)];
    const allProducts: IProduct[] = [];
    const productIDsAdded: Set<number> = new Set();
    data.forEach(products => {
        products.forEach(product => {
            if (!productIDsAdded.has(product.productID)) {
                allProducts.push(product);
                productIDsAdded.add(product.productID);
            }
        });
    });

    this.categories.unshift({
      id: 0,
      created: '02.12.2021',
      modified: '02.12.2021',
      modUser: 0,
      image: '',
      companyID: 0,
      isService: false,
      nameMK: 'All',
      nameSQ: 'All',
      productIds: 0,
      products: allProducts,
    });

    if (this.selectedCategoryIndex !== 0) {
      setTimeout(() => {
        let element: HTMLElement = document.getElementById(
          'pills-0-tab'
        ) as HTMLElement;
        element.click();
      }, 1);
    }
  }

  editItemplus(index) {
    let product;
    product = this.saleDetails[index];
    if (this.checkAndUpdateQuantity(product.productID, product.selectedOptions.id)) {
      // this.Product.qt++;
      product.selectedOptions.qt++;
      product.qt = product.selectedOptions.qt;
      if (!this.sale.isMKChecked) {
        this.calculateVATForOption(product.selectedOptions);
      }
      this.updateProductTotal(index);
      this.total();
    }
  }
  editItemminus(index) {
    let product;
    product = this.saleDetails[index];
    if (Number(product.selectedOptions.qt) > 0) {
      // this.Product.qt --;
      product.selectedOptions.qt--;
      product.qt = product.selectedOptions.qt;
      if (!this.sale.isMKChecked) {
        this.calculateVATForOption(product.selectedOptions);
      }
      this.updateProductTotal(index);
      this.returnQt(product.productID);
    }

    if (product.selectedOptions.qt === 0) {
      this.saleDetails.splice(index, 1);
      if (this.saleDetails.length === 0) {
        this.clientDisabled = false;
      }
    }

    this.total();
  }

  closeProductModal() {
    this.closeModal.nativeElement.click();
  }

  getProductNameByBrowserLang(data: Product): string {
    const lang = this.translate.currentLang;
    if (lang === 'mk') {
      return data.nameMK;
    } else {
      return data.nameSQ;
    }
  }

  checkAndUpdateQuantity(productId: number, productDetailId: number): boolean {
    for (let category of this.categories) {
      if (
        ![null, undefined].includes(
          category.products.find((x) => x.productID == productId && x.options.find(y => y.id === productDetailId))
        )
      ) {
        if (this.configs.settings.minusSale == false) {
          var qt = category.products.find((x) => x.productID === productId).options.find(y => y.id === productDetailId).qt;
          if (this.currentProductQuantity <= qt) {
            category.products.find((x) => x.productID === productId).options.find(y => y.id === productDetailId).qt -=
              this.currentProductQuantity;
            return true;
          } else {
            this.alertService.swalFire(
              'warning',
              this.translate.instant('NO_QUANTITY'),
              false,
              true,
              '',
              this.translate.instant('CLOSE')
            );
            return false;
          }
        } else {
          var qt = category.products.find((x) => x.productID === productId).options.find(y => y.id === productDetailId).qt;
          category.products.find((x) => x.productID === productId).qt -=
            this.currentProductQuantity;
          return true;
        }
      }
    }
  }

  total() {
    let total = 0;
    let vat = 0;
    this.saleDetails.forEach((product) => {
      vat += product.selectedOptions.totalVat;
      total += product.total;
    });
    this.calculationData = {
      total,
      vat: Number(vat.toFixed(2)),
      subTotal: Number((total - Number(vat)).toFixed(2)),
    };
  }

  plusQuantity() {
    this.currentProductQuantity += 1;
  }

  minusQuantity() {
    if (this.currentProductQuantity === 1) {
      this.alertService.swalFire(
        'warning',
        this.translate.instant('LOW_QUANTITY_1'),
        false,
        true,
        '',
        this.translate.instant('CLOSE')
      );
    } else {
      this.currentProductQuantity -= 1;
    }
  }
  // Edit item minus add product qt
  returnQt(productId: number) {
    for (let category of this.categories) {
      if (
        ![null, undefined].includes(
          category.products.find((x) => x.productID == productId)
        )
      ) {
        category.products.find((x) => x.productID == productId).qt += 1;
        break;
      }
    }
  }

  filterProducts(text: string): void {
    if (this.selectedCategoryIndex !== -1) {
      this.filteredProductCategories = this.categories[
        this.selectedCategoryIndex
      ].products.filter(
        // find by name
        (x) =>
          x.nameSQ.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) !==
            -1 ||
          // or noProduct
          x.noProduct == Number(text) ||
          // or barcode
          x.barcode == Number(text)
      );
    }
  }

  clearFilters(): void {
    this.filteredProductCategories = [];
  }

  calculateOptionPrices(option: IOptions, priceWithRebate: number) {
    if (option.sellingPriceWithRebate === 0) {
      this.currentProduct.selectedOptions.totalVat = 0;
    } else {
      this.currentProduct.selectedOptions.totalVat =
        option.qt *
        ((option.sellingPriceNoVat -
          (option.sellingPriceWithVat - priceWithRebate)) *
          (option.vat / 100));
      this.currentProduct.selectedOptions.rebatePercentage = (option.sellingPriceWithVat - priceWithRebate) / option.sellingPriceWithVat * 100;
    }
    if (!isNaN(priceWithRebate)) {
      this.currentProduct.selectedOptions.sellingPriceWithRebate = Number(priceWithRebate);
    }
  }

  onPercentage(option: IOptions) {
    if (!isNaN(option.sellingPriceWithVat - (this.currentProduct.selectedOptions.rebatePercentage / 100) * option.sellingPriceWithVat)) {
      this.currentProduct.selectedOptions.sellingPriceWithRebate =
        option.sellingPriceWithVat - (this.currentProduct.selectedOptions.rebatePercentage / 100) * option.sellingPriceWithVat;
    }
    this.isChanged = true;
  }

  onChange() {
    this.isChanged = false;
  }

  onKeyUpPercentage(option: IOptions) {
    if (this.currentProduct.selectedOptions.rebatePercentage >= 100) {
      this.currentProduct.selectedOptions.rebatePercentage = 99;
      this.currentProduct.selectedOptions.sellingPriceWithRebate =
        option.sellingPriceWithVat - (this.currentProduct.selectedOptions.rebatePercentage / 100) * option.sellingPriceWithVat;

    } else if (this.currentProduct.selectedOptions.rebatePercentage < 0) {
      this.currentProduct.selectedOptions.rebatePercentage = 0;
      this.currentProduct.selectedOptions.sellingPriceWithRebate = this.currentProduct.selectedOptions.sellingPriceWithVat;
    }
  }

  onKeyUpRebate(option: IOptions) {
    if (this.currentProduct.selectedOptions.sellingPriceWithRebate > option.sellingPriceWithVat) {
      this.currentProduct.selectedOptions.rebatePercentage = 0;
    }
  }

  setSelectedOption(option: IOptions) {
    this.currentProduct.selectedOptions = {
      created: option.created,
      id: option.id,
      isDeleted: option.isDeleted,
      modUser: option.modUser,
      modified: option.modified,
      optionIngredients: option.optionIngredients,
      productID: option.productID,
      productName: option.productName,
      sellingPriceNoVat: option.sellingPriceNoVat,
      sellingPriceWithVat: option.sellingPriceWithVat,
      vat: option.vat,
      qt: this.currentProductQuantity,
      profit: option.profit,
      vatId: option.vatId,
    };
  }

  registerSale(paymentId: number, customType?: any) {
    if (this.sale.isDebtChecked) {
      paymentId = 5;
    }
    // 0 is fiscal
    if (paymentId == WholeSalePaymentType.Fiscal) {
      this.createSaleFiscal();
    }
    if (paymentId == WholeSalePaymentType.Invoice) {
      this.createSaleInvoice();
    }
    if (paymentId == WholeSalePaymentType.ProInvoice) {
      this.createProInvoice();
    }
    if (paymentId == WholeSalePaymentType.Reserve) {
      this.reserve();
    }
    if (paymentId == WholeSalePaymentType.DeliveryNote) {
      this.createDeliveryNote();
    }
    if (paymentId == WholeSalePaymentType.Debt) {
      this.createSaleDebt(customType);
    }
    if (paymentId == WholeSalePaymentType.Cancel) {
      this.cancelSale();
    }
  }

  createSaleFiscal(): void {
    // TODO
    // this.sale.paymentID = paymentId;
    this.sale.paymentID = 1;
    this.sale.total = Number(this.calculationData.total.toFixed(2));
    this.sale.status = true;
    this.sale.saleDetails = this.saleDetails as any;
    const saleDescription = this.saleDescription.getRawValue();
    this.sale.dateSale = moment(saleDescription.dateSale).format('yyyy-MM-DD');

    let clientId = 0;
    if (this.client !== undefined && this.client !== null) {
      clientId = this.client.id;
      this.sale.clientID = this.client.id;
    } else {
      this.sale.clientID = null;
    }
    this.alertService.swalAutoLoader();
    // this.sale.salePayments = this.createSalePayments(paymentId);

    this.api.registerSaleNoTable(this.sale, clientId).subscribe(
      async (response) => {
        this.createFiscal(response.saleID, this.sale.paymentID);
        this.calculationData = {
          total: 0,
          subTotal: 0,
          vat: 0,
        };

        // this.pointsToUse = 0;
        //this.refreshClients();
        this.clientDisabled = false;
        await this.alertSuccessClearData();
      },
      (error) => {
        this.alertService
          .swalFire(
            'error',
            error.message,
            false,
            true,
            '',
            this.translate.instant('CLOSE')
          )
          .then();
      }
    );

    this.payment = null;
    this.change = 0;
  }

  createSaleInvoice(): void {
    if (this.saleDescription.valid) {
      const paymentInvoice = this.paymentInvoice.getRawValue();
      const saleDescription = this.saleDescription.getRawValue();

      this.sale.paymentID = 1;
      this.sale.saleType = 6;
      this.sale.total = Number(this.calculationData.total.toFixed(2));
      this.sale.saleDetails = this.saleDetails as any;
      this.sale.status = paymentInvoice.status === true ? false : true;
      this.sale.orderStatus = true;
      this.sale.deadline = paymentInvoice.deadline || null;
      this.sale.dateSale = moment(saleDescription.dateSale).format(
        'yyyy-MM-DD'
      );
      this.sale.ordinalNumber = this.sale.noSale = saleDescription.saleNo;
      this.sale.comment = saleDescription.comment;

      let clientId = 0;
      if (this.client !== undefined && this.client !== null) {
        clientId = this.client.id;
        this.sale.clientID = this.client.id;
      } else {
        this.sale.clientID = null;
      }

      if (this.bankDetail !== undefined && this.bankDetail !== null) {
        this.sale.bankDetailID = this.bankDetail.id;
      } else {
        this.sale.bankDetailID = null;
      }

      this.alertService.swalAutoLoader();
      // this.sale.salePayments = this.createSalePayments(paymentId);

      this.wholeSaleService
        .createSaleInvoice(
          this.sale,
          `20${this.saleDescription.controls.saleYear.value}`,
          this.CompanyID,this.groupClientID
        )
        .subscribe(
          async (response: {
            message: string;
            saleID: number;
            statusCode: number;
          }) => {
            if (response.statusCode === 201) {  
              // this.createFiscal(response.saleID, this.sale.paymentID);
              this.calculationData = {
                total: 0,
                subTotal: 0,
                vat: 0,
              };
              this.getGroupClient();

              // this.pointsToUse = 0;
              //this.refreshClients();
              this.resetFormsToDefaultValues();
              
              this.sale.isMKChecked = false;
              await this.alertSuccessClearData(
                this.translate.instant('OPEN_THE_REPORT'),
                response.saleID,
                WholeSalePaymentType.Invoice
              );

              // await this.alertSuccessClearData('Hap Raportin', response.saleID);
            } else {
              this.alertService
                .swalFire(
                  'error',
                  response.message,
                  false,
                  true,
                  '',
                  this.translate.instant('CLOSE')
                )
                .then();
            }
          },
          (error) => {
            this.alertService
              .swalFire(
                'error',
                error.message,
                false,
                true,
                '',
                this.translate.instant('CLOSE')
              )
              .then();
          }
        );

      this.payment = null;
      this.change = 0;
    } else {
      alert('Inalid form');
    }
  }

  createProInvoice(): void {
    if (this.saleDescription.valid) {
      const saleDescription = this.saleDescription.getRawValue();

      this.sale.paymentID = 1;
      this.sale.total = Number(this.calculationData.total.toFixed(2));
      this.sale.saleDetails = this.saleDetails as any;
      this.sale.dateSale = moment(saleDescription.dateSale).format(
        'yyyy-MM-DD'
      );
      this.sale.ordinalNumber = this.sale.noSale = saleDescription.saleNo;
      this.sale.comment = saleDescription.comment;

      let clientId = 0;

      if (this.client !== undefined && this.client !== null) {
        clientId = this.client.id;
        this.sale.clientID = this.client.id;
      } else {
        this.sale.clientID = null;
      }

      if (this.bankDetail !== undefined && this.bankDetail !== null) {
        this.sale.bankDetailID = this.bankDetail.id;
      } else {
        this.sale.bankDetailID = null;
      }

      this.alertService.swalAutoLoader();

      this.wholeSaleService
        .createProInvoice(
          this.sale,
          `20${this.saleDescription.controls.saleYear.value}`, this.CompanyID,this.groupClientID
        )
        .subscribe(
          async (response: {
            message: string;
            saleID: number;
            statusCode: number;
          }) => {
            if (response.statusCode === 201) {
              // this.createFiscal(response.saleID, this.sale.paymentID);
              this.calculationData = {
                total: 0,
                subTotal: 0,
                vat: 0,
              };
              this.getGroupClient();

              this.resetFormsToDefaultValues();
              
              this.sale.isMKChecked = false;
              await this.alertSuccessClearData(
                this.translate.instant('OPEN_THE_REPORT'),
                response.saleID,
                WholeSalePaymentType.ProInvoice
              );
            } else {
              this.alertService
                .swalFire(
                  'error',
                  response.message,
                  false,
                  true,
                  '',
                  this.translate.instant('CLOSE')
                )
                .then();
            }
          },
          (error) => {
            this.alertService
              .swalFire(
                'error',
                error.message,
                false,
                true,
                '',
                this.translate.instant('CLOSE')
              )
              .then();
          }
        );

      this.payment = null;
      this.change = 0;
    } else {
      alert('Inalid form');
    }
  }

  reserve(): void {
    if (this.saleDescription.valid) {
      const saleDescription = this.saleDescription.getRawValue();
      this.sale.paymentID = 1;
      this.sale.total = Number(this.calculationData.total.toFixed(2));
      this.sale.saleDetails = this.saleDetails as any;
      this.sale.status = false;
      this.sale.dateSale = moment(saleDescription.dateSale).format(
        'yyyy-MM-DD'
      );
      this.sale.ordinalNumber = this.sale.noSale = saleDescription.saleNo;
      this.sale.comment = saleDescription.comment;

      let clientId = 0;
      if (this.client !== undefined && this.client !== null) {
        clientId = this.client.id;
        this.sale.clientID = this.client.id;
      } else {
        this.sale.clientID = null;
      }

      if (this.bankDetail !== undefined && this.bankDetail !== null) {
        this.sale.bankDetailID = this.bankDetail.id;
      } else {
        this.sale.bankDetailID = null;
      }

      this.alertService.swalAutoLoader();
      // this.sale.salePayments = this.createSalePayments(paymentId);

      this.wholeSaleService.reserve(this.sale).subscribe(
        async (response: {
          message: string;
          saleID: number;
          statusCode: number;
        }) => {
          if (response.statusCode === 201) {
            // this.createFiscal(response.saleID, this.sale.paymentID);
            this.calculationData = {
              total: 0,
              subTotal: 0,
              vat: 0,
            };

            // this.pointsToUse = 0;
            // this.refreshClients();
            this.resetFormsToDefaultValues();
            this.getGroupClient();
            this.sale.isMKChecked = false;
            await this.alertSuccessClearData();
          } else {
            this.alertService
              .swalFire(
                'error',
                response.message,
                false,
                true,
                '',
                this.translate.instant('CLOSE')
              )
              .then();
          }
        },
        (error) => {
          this.alertService
            .swalFire(
              'error',
              error.message,
              false,
              true,
              '',
              this.translate.instant('CLOSE')
            )
            .then();
        }
      );

      this.payment = null;
      this.change = 0;
    } else {
      alert('Inalid form');
    }
  }

  createDeliveryNote(): void {
    if (this.saleDescription.valid) {
      const saleDescription = this.saleDescription.getRawValue();
      this.sale.total = Number(this.calculationData.total.toFixed(2));
      this.sale.saleDetails = this.saleDetails as any;
      this.sale.status = false;
      this.sale.dateSale = moment(saleDescription.dateSale).format(
        'yyyy-MM-DD'
      );
      this.sale.ordinalNumber = this.sale.noSale = saleDescription.saleNo;
      this.sale.comment = saleDescription.comment;

      let clientId = 0;
      if (this.client !== undefined && this.client !== null) {
        clientId = this.client.id;
        this.sale.clientID = this.client.id;
      } else {
        this.sale.clientID = null;
      }

      if (this.bankDetail !== undefined && this.bankDetail !== null) {
        this.sale.bankDetailID = this.bankDetail.id;
      } else {
        this.sale.bankDetailID = null;
      }

      this.alertService.swalAutoLoader();
      // this.sale.salePayments = this.createSalePayments(paymentId);

      this.wholeSaleService.createDeliveryNote(this.sale).subscribe(
        async (response: {
          id: number;
          isReport: boolean;
          isService: boolean;
        }) => {
          // this.createFiscal(response.saleID, this.sale.paymentID);
          this.calculationData = {
            total: 0,
            subTotal: 0,
            vat: 0,
          };

          // this.pointsToUse = 0;
          // this.refreshClients();
          this.resetFormsToDefaultValues();
          this.getGroupClient();
          this.sale.isMKChecked = false;
          await this.alertSuccessClearData(
            this.translate.instant('OPEN_THE_REPORT'),
            response.id,
            WholeSalePaymentType.DeliveryNote
          );
        },
        (error) => {
          this.alertService
            .swalFire(
              'error',
              error.message,
              false,
              true,
              '',
              this.translate.instant('CLOSE')
            )
            .then();
        }
      );

      this.payment = null;
      this.change = 0;
    } else {
      alert('Inalid form');
    }
  }

  createSaleDebt(type: 'Fiscal' | 'Invoice'): void {
    if (this.saleDescription.valid) {
      const saleDescription = this.saleDescription.getRawValue();
      const paymentInformation = this.paymentInvoice.getRawValue();

      let saleDebt: SaleDebt | null = null;
      if (paymentInformation.totalPaid || paymentInformation.personWhoPaid) {
        const saleDebtDetails = new SaleDebtDetail(
          0,
          paymentInformation.totalPaid,
          0,
          paymentInformation.totalPaid,
          paymentInformation.personWhoPaid,
          new Date()
        );

        saleDebt = new SaleDebt(0, 0, 0, new Date(), saleDescription.deadline, [
          saleDebtDetails,
        ]);
      }

      this.sale.paymentID = 1;
      this.sale.total = Number(this.calculationData.total.toFixed(2));
      this.sale.saleDetails = this.saleDetails as any;
      this.sale.status = false;
      this.sale.isDebtChecked = true;
      this.sale.dateSale = moment(saleDescription.dateSale).format(
        'yyyy-MM-DD'
      );
      this.sale.ordinalNumber = this.sale.noSale = saleDescription.saleNo;
      this.sale.comment = saleDescription.comment;
      this.sale.deadline = paymentInformation.deadline || null;

      let clientId = 0;
      if (this.client !== undefined && this.client !== null) {
        clientId = this.client.id;
        this.sale.clientID = this.client.id;
      } else {
        this.sale.clientID = null;
      }

      if (this.bankDetail !== undefined && this.bankDetail !== null) {
        this.sale.bankDetailID = this.bankDetail.id;
      } else {
        this.sale.bankDetailID = null;
      }

      this.alertService.swalAutoLoader();
      // this.sale.salePayments = this.createSalePayments(paymentId);

      this.wholeSaleService
        .createSaleWithDebt(
          this.sale,
          saleDebt,
          `20${this.saleDescription.controls.saleYear.value}`,
          this.groupClientID
        )
        .subscribe(
          async (response: {
            message: string;
            saleID: number;
            statusCode: number;
          }) => {
            if (response.statusCode === 201) {
              // this.createFiscal(response.saleID, this.sale.paymentID);
              this.calculationData = {
                total: 0,
                subTotal: 0,
                vat: 0,
              };

              // this.pointsToUse = 0;
              // this.refreshClients();
              this.resetFormsToDefaultValues();
              this.getNoSale();
              this.sale.isMKChecked = false;

              if (type === 'Invoice') {
                await this.alertSuccessClearData(
                  this.translate.instant('OPEN_THE_REPORT'),
                  response.saleID,
                  WholeSalePaymentType.Debt
                );
              } else {
                this.createFiscal(response.saleID, this.sale.paymentID);
                await this.alertSuccessClearData();
              }

              this.debtChecked(false);
            } else {
              this.alertService
                .swalFire(
                  'error',
                  response.message,
                  false,
                  true,
                  '',
                  this.translate.instant('CLOSE')
                )
                .then();
            }
          },
          (error) => {
            this.alertService
              .swalFire(
                'error',
                error.message,
                false,
                true,
                '',
                this.translate.instant('CLOSE')
              )
              .then();
          }
        );

      this.payment = null;
      this.change = 0;
    } else {
      alert('Inalid form');
    }
  }

  cancelSale(): void {
    if (this.saleDescription.valid) {
      const paymentInvoice = this.paymentInvoice.getRawValue();
      const saleDescription = this.saleDescription.getRawValue();
      this.sale.paymentID = 1;
      this.sale.total = Number(this.calculationData.total.toFixed(2));
      this.sale.saleDetails = this.saleDetails as any;
      this.sale.status = true;
      this.sale.dateSale = moment(saleDescription.dateSale).format(
        'yyyy-MM-DD'
      );
      this.sale.ordinalNumber = this.sale.noSale = saleDescription.saleNo;
      this.sale.comment = saleDescription.comment;

      let clientId = 0;
      if (this.client !== undefined && this.client !== null) {
        clientId = this.client.id;
        this.sale.clientID = this.client.id;
      } else {
        this.sale.clientID = null;
      }

      if (this.bankDetail !== undefined && this.bankDetail !== null) {
        this.sale.bankDetailID = this.bankDetail.id;
      } else {
        this.sale.bankDetailID = null;
      }

      this.alertService.swalAutoLoader();
      // this.sale.salePayments = this.createSalePayments(paymentId);

      this.wholeSaleService
        .cancelSale(
          this.sale,
          `20${this.saleDescription.controls.saleYear.value}`
        )
        .subscribe(
          async (response: {
            message: string;
            saleID: number;
            statusCode: number;
          }) => {
            if (response.statusCode === 201) {
              // this.createFiscal(response.saleID, this.sale.paymentID);
              this.calculationData = {
                total: 0,
                subTotal: 0,
                vat: 0,
              };

              // this.pointsToUse = 0;
              // this.refreshClients();
              this.resetFormsToDefaultValues();
              this.getNoSale();
              this.sale.isMKChecked = false;
              await this.alertSuccessClearData(
                this.translate.instant('OPEN_THE_REPORT'),
                response.saleID,
                WholeSalePaymentType.Cancel
              );
            } else {
              this.alertService
                .swalFire(
                  'error',
                  response.message,
                  false,
                  true,
                  '',
                  this.translate.instant('CLOSE')
                )
                .then();
            }
          },
          (error) => {
            this.alertService
              .swalFire(
                'error',
                error.message,
                false,
                true,
                '',
                this.translate.instant('CLOSE')
              )
              .then();
          }
        );

      this.payment = null;
      this.change = 0;
    } else {
      alert('Inalid form');
    }
  }

  resetFormsToDefaultValues(): void {
    this.paymentInvoice.patchValue({
      deadline: null,
      days: null,
      personWhoPaid: null,
      totalPaid: null,
      status: false,
    });
    this.saleDescription.patchValue({
      saleNo: '',
      saleYear: new Date().getFullYear().toString().substring(2, 4),
      dateSale: new Date(),
      comment: null,
    });
    this.clientDisabled = false;
  }

  getUserId() {
    let userId: number;
    this.activatedRoute.paramMap.subscribe((param) => {
      userId = Number(param.get('id'));
    });
    return userId;
  }

  createFiscal(saleId: number, paymentID: number) {
    if (paymentID === -1) {
      // print void fiscal
      this.windowService.windowRef.location = `crm:/12/${this.getUserId()}/${saleId}`;
    } else if (paymentID == 1) {
      // print fiscal
      this.windowService.windowRef.location = `crm:/1/${this.getUserId()}/${saleId}`;
    } else {
      // payment with card
      this.windowService.windowRef.location = `crm:/9/${this.getUserId()}/${saleId}`;
    }
  }

  changeEvent() {
    if (this.payment > this.calculationData.total) {
      this.change = +(this.payment - this.calculationData.total).toFixed(2);
    } else {
      this.change = null;
    }
  }

  getCompanyID() {
    return JSON.parse(localStorage.getItem('waiterObject')).companyID;
  }

  async alertSuccessClearData(
    buttonText: string = 'OK',
    id?: number,
    type?: WholeSalePaymentType
  ): Promise<void> {
    Swal.close();
    this.alertService
      .swalFire(
        'success',
        this.translate.instant('ORDER_CREATED'),
        true,
        false,
        this.translate.instant(buttonText)
      )
      .then((res: any) => {
        this.sale.saleDetails = [];
        this.saleDetails = [];
        this.clearAutoComplete();
        this.clearBankAutoComplete();
        if (res.isConfirmed && id) {
          this.createSaleReport(id, type);
        }
      });
  }

  editSaleNoFnc(): void {
    this.editSaleNo = true;
    this.saleDescription.controls.saleNo.enable();
    this.saleDescription.controls.saleYear.enable();
  }

  checkSaleNo(): void {
    if (
      this.saleDescription.controls.saleNo.valid &&
      this.saleDescription.controls.saleYear.valid
    ) {
      const clientID = this.client ? this.client.id : null;
      this.wholeSaleService
        .noSaleExists(
          this.getCompanyID(),
          this.saleDescription.controls.saleNo.value,
          `20${this.saleDescription.controls.saleYear.value}`,
          clientID
        )
        .subscribe((isInvalid: boolean) => {
          if (isInvalid) {
            this.showSaleNoError = true;
          } else {
            this.showSaleNoError = false;
            this.editSaleNo = false;
            this.saleDescription.controls.saleNo.disable();
            this.saleDescription.controls.saleYear.disable();
          }
        });
    }
  }

  clearAutoComplete(): void {
    this.clientAutocomplete.clearInput();
  }
  // refreshClients(): void {
  //   this.getClients();
  // }

  clearBankAutoComplete(): void {
    this.bankAutoComplete.clearInput();
  }

  addDays(days: number): void {
    this.paymentInvoice.controls.deadline.setValue(
      moment(new Date(), 'yyyy-MM-dd').add(days, 'days').format('yyyy-MM-DD')
    );
    this.paymentInvoice.controls.status.setValue(true);
    this.paymentInvoice.controls.status.disable();
  }

  daysToDate(date: Date): void {
    const date1: any = new Date();
    const date2: any = new Date(date);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    this.paymentInvoice.controls.days.setValue(diffDays);
    this.paymentInvoice.controls.status.setValue(true);
    this.paymentInvoice.controls.status.disable();
  }

  posNumbersOnly(event: any): boolean {
    return event.charCode >= 48;
  }

  selectedClient(client: ClientSelectedType) {
    this.client = client;
    
    if (client) {
       this.getGroupClient(client.id);
      this.getPriceList(client.id);
      this.payments.find((x) => x.type === WholeSalePaymentType.DeliveryNote)
        ? (this.payments.find(
            (x) => x.type === WholeSalePaymentType.DeliveryNote
          ).disabled = false)
        : null;
    } else {
      this.payments.find((x) => x.type === WholeSalePaymentType.DeliveryNote)
        ? (this.payments.find(
            (x) => x.type === WholeSalePaymentType.DeliveryNote
          ).disabled = true)
        : null;
      this.sale.isMKChecked = false;
    }
  }
  onSelectedBank(bankDetail: IBankDetail): void {
    this.bankDetail = bankDetail;
  }

  getPriceList(clientID: number) {
    this.clientsService
      .getPriceListByClientID(clientID)
      .subscribe((res: IPriceList) => {});
  }

  createSaleReport(id: number, type: WholeSalePaymentType): void {
    const domain = environment.API_URL.split('/api')[0];

    switch (type) {
      case WholeSalePaymentType.Invoice: {
        const url = `${domain}/Reports/CreateSaleReport/${id}`;
        window.open(url, '_blank').focus();
        break;
      }
      case WholeSalePaymentType.ProInvoice: {
        const url = `${domain}/Reports/CreateProInvoiceReport/${id}`;
        window.open(url, '_blank').focus();
        break;
      }
      case WholeSalePaymentType.DeliveryNote: {
        const url = `${domain}/Reports/CreateDeliveryReport/${id}`;
        window.open(url, '_blank').focus();
        break;
      }
      case WholeSalePaymentType.Debt: {
        const url = `${domain}/Reports/CreateSaleReport/${id}`;
        window.open(url, '_blank').focus();
        break;
      }
      case WholeSalePaymentType.Cancel: {
        const url = `${domain}/Reports/CreateSaleReport/${id}`;
        window.open(url, '_blank').focus();
        break;
      }
      default: {
        break;
      }
    }
  }

  debtChecked(isDebtChecked: boolean): void {
    this.sale.isDebtChecked = isDebtChecked;
    this.payments = this.payments.filter((x) =>
      [WholeSalePaymentType.Fiscal, WholeSalePaymentType.Invoice].includes(
        x.type
      )
    );

    if (this.sale.isDebtChecked) {
      // this.payments.find(
      //   (x) => x.type === WholeSalePaymentType.Fiscal
      // ).dialogId = '#fiscalDebt';
      this.payments.find(
        (x) => x.type === WholeSalePaymentType.Invoice
      ).dialogId = '#invoiceDebt';
    } else {
      this.payments = WholesalePayments;
      this.payments.find(
        (x) => x.type === WholeSalePaymentType.Invoice
      ).dialogId = '#PaymentPublish';
    }
  }

    /**
   * this method is used to get path image, if don't have image on DB we use a default image
   * @param img is the path of image category
   * @param index is the selected index category
   * @returns the path os selected category image
   */
    getCategoryImage(img: string | null, index: number){
      // checks if selected Index is the same with provided Index and if 'All Categories' is NOT selected
      if( index === this.categoryIndex && index>0){
        // checks if category doesn't have image, returns a default image
        if(img=== null){
          return '../assets/images/ipos_contrast.png'
        }
        // if category has image, returns that image
        else {
          return this.IMG_PATH_CATEGORY+img
        }
      }
      // checks if category is not selected and if 'All Categories' is NOT selected
      else if( index !== this.categoryIndex && index>0){
       // checks if category doesn't have image, returns a default image
        if(img=== null){
          return '../assets/images/ipos.png'
        }
        // if category has image, returns that image
        else {
          return this.IMG_PATH_CATEGORY+img
        }
      }
    }
}
