import {Directive, EventEmitter, HostListener, Output} from '@angular/core';
import {BehaviorSubject, interval, Subject} from "rxjs";
import {takeUntil, tap} from "rxjs/operators";

@Directive({
  selector: '[appShowSideBar]'
})
export class ShowSideBarDirective {
  @Output() showSideBarEvent: EventEmitter < any > = new EventEmitter();
  state: Subject < any > = new BehaviorSubject(false);
  stateVal;
  constructor() {
    this.state.subscribe(val => {
      this.stateVal = val
    })
  }
  @HostListener('click', ['$event'])
  onHold()
  {

      if(this.stateVal === true){
        this.state.next(false);
      }else{
        this.state.next(true);
      }
      this.showSideBarEvent.emit(this.state);
  }
}
