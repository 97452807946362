import { Component, OnInit, Inject} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../data.service';
import { AlertService } from '../../services/alert.service';
import { TranslateKeysService } from '../../services/translate-keys.service';


@Component({
  selector: 'app-daily-report-modal',
  templateUrl: './daily-report-modal.component.html',
  styleUrls: ['./daily-report-modal.component.css']
})
export class DailyReportModalComponent implements OnInit {

  waiterName: string;
  noneFiscalSales: number;
  fiscalSales: number;
  totalSales: number;
  fiscalWaiterSales: number;
  noneFiscalWaiterSales: number;
  totalWaiterSales: number;

  constructor(
    public dialogRef: MatDialogRef<DailyReportModalComponent>,
    protected alertService: AlertService,
    public translateKeys: TranslateKeysService,
    private activatedRoute: ActivatedRoute,
    private api: DataService,
  ) { }

  ngOnInit(): void {
    this.waiterName = JSON.parse(localStorage.getItem('waiterObject')).username;
    this.api.getDailyReport(this.getCompanyID(), this.getUserId()).subscribe((response: any) =>{
      this.noneFiscalSales = response.noneFiscalSales.toFixed(2);
      this.fiscalSales = response.fiscalSales.toFixed(2);
      this.totalSales = response.totalSales.toFixed(2);
      this.fiscalWaiterSales = response.fiscalWaiterSales.toFixed(2);
      this.noneFiscalWaiterSales = response.noneFiscalWaiterSales.toFixed(2);
      this.totalWaiterSales = response.totalWaiterSales.toFixed(2);
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  getUserId() {
    return this.api.getWaiterID();
  }

  getCompanyID() {
    return JSON.parse(localStorage.getItem('waiterObject')).companyID;
  }

  closeReport(){
    this.api.closeReport(this.getCompanyID(), this.getUserId()).subscribe((response: any) =>{
      this.closeDialog();
      if(response == true){
        this.alertService.swalFire(
          'success',
          this.translateKeys.doTranslate('CLOSED_DAYSHIFT_SUCCESS'),
          true,
          false,
          this.translateKeys.doTranslate('CLOSE'),
          ''
          )
      }
    });
}


}
