import {
  Component,
  ElementRef, HostListener,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CdkDragEnd, CdkDropList } from '@angular/cdk/drag-drop';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ITable } from '../../interfaces/ITable';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/auth.service';
import { AlertService } from '../../services/alert.service';
import * as moment from 'moment';
import {NgForm} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {TranslateKeysService} from '../../services/translate-keys.service';
import {TableForm} from "../../models/table-form";

@Component({
  selector: 'app-table-layout',
  templateUrl: './table-layout.component.html',
  styleUrls: ['./table-layout.component.css'],
})
export class TableLayoutComponent implements OnInit {
  constructor(
    private api: DataService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private autoLogout: AuthService,
    private alertService: AlertService,
    private translateKeys: TranslateKeysService,
    public translate: TranslateService
  ) {}
  protected companyId = JSON.parse(localStorage.getItem('waiterObject')).companyID;
  dragMode = false;
  tables: any = [];
  @Output() currentTable;
  public tableData: ITable = {
    name: 'tableCompany' + this.companyId,
    customID: null,
    tableForm: null,
    tableChairs: null,
    positionX: this.getScreenHeightAndWidth().x - 250,
    positionY: this.getScreenHeightAndWidth().y - 350,
    offsetX: 0,
    offsetY: 0,
    initialPositionX: 0,
    initialPositionY: 0,
    isReserved: false,
    companyID: this.companyId,
    reminder: false,
  };
  reservations = [];
  @ViewChild('item')
  item: ElementRef;
  private apiresponse: any;
  showSideMenu = {
    status: false,
    top: 0,
    left: 0,
  };
  showTableFormDiv = false;
  selectedTableForm = null;

  @HostListener('window:resize')
  onResize(event){
    this.getScreenHeightAndWidth();
  }
  ngOnInit() {
    // this.autoLogout.setTimer();
    this.getScreenSize();
    this.isTableLayoutActive();
    this.companyId = JSON.parse(localStorage.getItem('waiterObject')).companyID;
    this.getTables();
    // this.getTimer()
  }

  /**
   * gets all reservations for table which we use to check
   * if there are any reservations
   */
  getReservations() {
    this.api
      .getReservationsByDate(
        moment().format('YYYY-MM-DD'),
        this.getCompanyID(),
        true)
      .subscribe((res) => {
        this.reservations = res;
      });
  }

  /**
   * this function is helping us to drag the tables around the div
   * we pass here the event and the table on Drag
   * @param event
   * @param table
   */
  dragEnd(event: CdkDragEnd, table: ITable) {
    const elData = { ...(event.source._dragRef as any) };
    // let offset= {...(<any>event.source._dragRef)._passiveTransform};

    // We extract here the position y and x  of the table
    const lat = parseInt(String(event.source.getFreeDragPosition().y), 10);
    const long = parseInt(String(event.source.getFreeDragPosition().x), 10);
    this.tables.filter((x) => x.id === table.id);
    // for the selected table on drag we update the position x and y (long, lat )
    this.tables.forEach((item) => {
      if (table.id === item.id) {
        if (long < 0) {
          table.positionX += event.distance.x;
        } else if (lat < 0) {
          table.positionY += event.distance.y;
        } else {
          table.positionX = long;
          table.positionY = lat;
        }
      }
    });
    // on each drag drop we call the this.saveDragPositions() to save the table positions in database
    this.saveDragPositions();
  }

  /**
   * saves the positions for all tables in database
   */
  saveDragPositions() {
    this.getReservations();
    this.api.updateTableCoordinates(this.tables)
      .subscribe((response) => {
          this.getTables();
        },
        (error) => {
        }
      );
  }

  /**
   * this function allows us to enable drag or disable , when clicking Edito
   * it goes in edit mode and enables the dragMode (which allows us to drag tables around the container)
   * on lower scrrens < 768 px the dragging is not allowed
   */
  enableDrag() {
    if (this.getScreenHeightAndWidth().x <= 768) {
      this.alertService.swalFire(
        'info',
        'Edit mode is only available on larger screens',
        true,
        false,
        'OK'
      );
      return;
    } else {
      if (this.dragMode === false) {
        return (this.dragMode = true);
      } else {
        return (this.dragMode = false);
      }
    }
  }

  /**
   * this function retrieves all Tables from db
   */
  getTables() {
    this.getReservations();
    this.alertService.swalAutoLoader()
    this.api.getTablesfromDB(this.companyId).subscribe((response: ITable[]) => {
      this.tables = this.setReservedTables(response);
      Swal.close()
    });
  }

  /**
   * this function us used to create a table
   *
   * @param form
   */
  createTable(form: NgForm) {
    const {customID, tForm, tChairs} = form.value;
    if(this.checkCustomID(customID)){
      return
    }
    const positionX = this.getScreenHeightAndWidth().x - 250;
    const positionY = this.getScreenHeightAndWidth().y - 350;
    const companyID = this.getCompanyID();
    const offsetX = 0;
    const offsetY = 0;
    const initialPositionX = 0;
    const initialPositionY = 0;
    const isReserved = false;
    const reminder =  false;
    const name =  'Table ' + customID;
    const table: ITable = new TableForm(
      name, customID, tForm, tChairs, positionX, positionY,
      offsetX, offsetY, initialPositionX, initialPositionY, isReserved, companyID)

    this.alertService.swalAutoLoader()
    this.api.saveTable(table)
      .subscribe((response) => {
        Swal.close()
        this.alertService.swalFire('success', this.translateKeys.doTranslate('TABLE_CREATED'), true, false, this.translateKeys.doTranslate('OK'));
        this.dragMode = true;
        this.getTables();
        form.reset();
        this.selectedTableForm = null
      });
  }

  /**
   * we delete a table here by passing the table itself to this function
   * if table has an open order we do not allow the deletion of it
   * @param table
   */
  deleteTable(table: ITable) {
    if (table === undefined) {
      return;
    } else if (table.tableStatus != null && table.tableStatus.status === true) {
      this.alertService.swalFire(
        'warning',
        this.translateKeys.doTranslate('TABLE_HAS_UNPAID_INVOICE'),
        false,
        true,
        '',
        this.translateKeys.doTranslate('CLOSE')
      );
      return;
    }else if (table.isReserved){
      this.alertService.swalFire(
        'info',
        this.translateKeys.doTranslate('TABLE_IS_RESERVED'),
        true,
        false,
        this.translateKeys.doTranslate('OK')
      );
      return;
    }
    else if (table) {
      this.alertService
        .swalFire(
          'warning',
          this.translateKeys.doTranslate('ARE_YOU_SURE'),
          true,
          true,
          this.translateKeys.doTranslate('YES_DELETE'),
          this.translateKeys.doTranslate('NO'),

        )
        .then((result) => {
          if (result.value) {
            this.api.tableToDelete(table).subscribe((response) => {
              this.alertService.swalFire(
                'success',
                this.translateKeys.doTranslate('TABLE_DELETE_SUCCESS'),
                true,
                false,
                this.translateKeys.doTranslate('OK'),
              );
              this.getTables();
            }, (error) => {
            });
          }
        });
    }
  }

  drop(event: CdkDropList<any>) {
  }

  /**
   * this function gives us the active component name
   */
  isTableLayoutActive() {
    const snapshot = this.activatedRoute.snapshot;
  }

  /**
   * returns screen size < 700 = true
   */
  getScreenSize() {
    const screenSize = window.screen.width;
    if (screenSize < 700) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * returns the screen (monitor) size x and y
   */
  getScreenHeightAndWidth() {
    const screenSize = {
      x: window.innerWidth,
      y: window.innerHeight,
    };
    return screenSize;
  }

  /**
   * return the company id of the user
   * company id is stored in waiterObject (localstorage) of BROWSER
   */
  getCompanyID() {
    return JSON.parse(localStorage.getItem('waiterObject')).companyID;
  }

  /**
   * sets the reserved tables if there are any reservations
   * each time the component loads it checks if there is a reminder true and the difference(timenow) < 30min
   * before reservation
   * @param tables
   */
  setReservedTables(tables: ITable[]) {
    var i = this.reservations.length;
    while (i--) {
      // check if table is not busy
      if (
        tables.find(
          (x) =>
            x.id === this.reservations[i].tableID &&
            x.isReserved === true &&
            (x.tableStatus === null || x.tableStatus.status === false)
        )
      ) {
        // find minutes difference between reservation timeFrom and now
        let minutesDiff = moment(this.reservations[i].timeFrom, 'HH:mm').diff(
          moment(),
          'minutes'
        );
        if (minutesDiff <= 30) {
          // if 30 minutes before reservation highlight table
          tables.find(
            (x) =>
              x.id === this.reservations[i].tableID &&
              (x.tableStatus === null || x.tableStatus.status === false)
          ).reminder = true;
        }
      }
    }
    return tables;
  }

  /**
   * if there is a reservation and the reservation time has entered it prompts the user
   * if he wants to close the reservation or continue order
   * @param table
   */
  tableButtonClickAction(table: ITable) {
    // change if
    if (table.tableStatus !== null && table.tableStatus.status) {
      this.goToEdit(table.id);
    }   else if (table.tableStatus == null || !table.tableStatus.status) {
      if (table.isReserved) {
        this.tableReservedDialog(table);
      } else {
        this.goToOrder(table.id);
      }
    }
  }


  holdHandler(e, tableID, top, left) {
    const time = 100;
    this.currentTable = this.tables.find((x) => x.id === tableID);
    if (e.status) {
      this.showSideMenu = {
        status: true,
        top,
        left,
      };
    }
  }
  hideMenu() {
    this.showSideMenu.status = false;
  }

  tableReservedDialog(table) {
    const reservation = this.reservations.find((x) => x.tableID === table.id);
    const waiterObject = JSON.parse(localStorage.getItem('waiterObject'));
    if (![null, undefined].includes(reservation)) {
      const minutesDiff = moment(reservation.timeFrom, 'HH:mm').diff(
        moment(),
        'minutes'
      );
      const secondsDiff = moment(reservation.timeFrom, 'HH:mm').diff(
        moment(),
        'seconds'
      );
      if (minutesDiff > 30) {
        // if table is free and has reservation after more than 30 minutes
        this.goToOrder(table.id);
      }
      if (minutesDiff <= 30 && secondsDiff > 0) {
        // if it's 30 minutes before reservation
        this.alertService
          .swalFire(
            'info',
            `${this.translateKeys.doTranslate('TABLE_HAS_RESERVATION_FROM_TIME')} ${moment(reservation.timeFrom, 'HH:mm').format('HH:mm')}`,
            true,
            true,
            this.translateKeys.doTranslate('CONTINUE'),
            this.translateKeys.doTranslate('CLOSE')
          )
          .then((action) => {
            if (action.isConfirmed) {
              this.router.navigate([
                `kamarieri/${waiterObject.waiterID}/dashboard/${table.id}`,
              ]);
            }
          });
      } else if (secondsDiff <= 0 &&  moment(reservation.timeTo, 'HH:mm').diff( moment(), 'minutes') >= 0) {
        // if it is in reservation time
        this.goToOrder(table.id);
      }
      else if (
        moment(reservation.timeTo, 'HH:mm').diff( moment(), 'seconds') < 0 &&
        (table.status == null || table.tableStatus.status === false)
      ) {
        // if reservation has expired
        this.alertService
          .swalFire(
            'info',
            this.translateKeys.doTranslate('RESERVATION_EXPIRED'),
            true,
            true,
            this.translateKeys.doTranslate('YES_DELETE'),
            this.translateKeys.doTranslate('CONTINUE'),
          )
          .then((res) => {
            if (res.isConfirmed) {
              this.api.expireReservation(reservation.id).subscribe((response: {statusCode: number, message: string}) => {
                if (response.statusCode === 200) {
                  this.goToOrder(table.id);
                }
              });
            } else if (res.dismiss.toString() === 'cancel'){
              this.router.navigate([
                `kamarieri/${waiterObject.waiterID}/dashboard/${table.id}`,
              ]);
            }
          });
      }
    } else {
      this.goToOrder(table.id);
    }
  }
  showAlert() {
    const alertModal: HTMLElement = document.getElementById(
      'alertModal'
    ) as HTMLElement;
    alertModal.classList.add('show');
  }

  /**
   * navigates to Order  example kamarieri/waiterID/dashboard/tableID
   * @param id
   */
  goToOrder(id) {
    this.router.navigate([this.router.url + '/' + id + ''], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * this function shows the div with table forms
   * when we want to create new TAble
   */
  showFormDiv(){
    if(this.showTableFormDiv === false){
      this.showTableFormDiv = true
    }
    return false
  }

  /**
   * if table.tableStatus.status === true (table has an open order , or is busy)
   * go to edit route of the function
   * @param id
   */
  goToEdit(id) {
    this.router.navigate([this.router.url + '/edit/', id], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * selects the table form
   * @param tableForm
   */
  selectTableForm(tableForm: string){
    this.tableData.tableForm = tableForm;
    this.selectedTableForm = tableForm
  }

  /**
   * when trying to create a new table it checks if table with customID already is registered
   * @param cid
   */
  checkCustomID(cid){
    let exists = false;
    this.tables.forEach(table => {
      if(table.customID === cid){
        exists = true;
      }
    })
    return exists;
  }
  expandH = 100;
  expandContainer(){
    this.expandH += 50
  }

  setContainerHeight(){
    let positionY = 0;
    this.tables.forEach(table => {
      if(table.positionY > positionY){
        positionY = table.positionY;
      }
    });
    return positionY + 100
  }
}
