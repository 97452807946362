<form class="client-form">
  <mat-form-field class="client-form-field" appearance="outline">
    <mat-label>{{'CARDHOLDER' | translate }}</mat-label>
    <input type="text" matInput [formControl]="loyaltyCardSearchInput" [matAutocomplete]="auto">
    <img *ngIf="loyaltyCardSearchInput.value && !disabled && points " src="/assets/images/x-inactive.svg" (click)="clearInput1()">

    <mat-autocomplete #auto="matAutocomplete" [displayWith]="getOptionText" autoActiveFirstOption>
      <mat-option *ngFor="let loyaltyCard of filteredLoyaltyCards | async" [value]="loyaltyCard"
        (click)="onOptionSelected($event, loyaltyCard)">
        {{loyaltyCard.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
