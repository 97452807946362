import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() { }

  swalFire(
    type: string,
    message: string,
    showConfirmButton: boolean,
    showCancelButton: boolean,
    confirmButtonText?: string,
    cancelButtonText?: string,
    timer?: number) {
    return Swal.fire({
      // icon: 'info',
      html: '<div class="row justify-content-center pt-4"><img src="../../assets/images/sweet_' + type + '_icon.png"></div>' +
        '<div class="row justify-content-center  swal2-text"> ' + message + ' </div>',
      showCloseButton: true,
      showCancelButton,
      confirmButtonText,
      cancelButtonText,
      customClass: {
        header: 'customHeader',
        closeButton: 'closeButtonclass',
        confirmButton: '' + type + 'ConfirmButton',
        cancelButton: '' + type + 'CancelButton'
      },
      showConfirmButton,
      timer
    });
  }

  swalAutoLoader(outsideClick: boolean = true) {
     return Swal.fire({
      // title: 'Loading Data',
      html: '<div class="row p-5 justify-content-center text-center bg-transparent">' +
          '<div class="spinner-grow text-primary" style="width: 5rem;height: 5rem" role="status">\n' +
          '  <span class="sr-only">Loading...</span>\n' +
          '</div>' +
        '</div>',
      showCloseButton: false,
      showConfirmButton: false,
      timerProgressBar: true,
      allowOutsideClick: outsideClick,
       customClass: {
        container: 'autoLoaderContainer',
         popup: 'autoLoaderContainer'
       }
    });
  }
}
