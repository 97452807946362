import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {Injectable, NgModule} from '@angular/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { AppRoutingModule } from './app-routing.module';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { WaiterModuleComponent } from './components/waiter-module/waiter-module.component';
import { WaiterLoginComponent } from './components/waiter-login/waiter-login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DataService} from './data.service';
import { OrderComponent } from './components/order/order.component';
import { OrderReportsComponent } from './components/order-reports/order-reports.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {TableLayoutComponent} from './components/table-layout/table-layout.component';
import {CdkTableModule} from '@angular/cdk/table';
import { ReservationsComponent } from './components/reservations/reservations.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SaleDetailsModalComponent } from './components/sale-details-modal/sale-details-modal.component';
import { HoldButonDirective } from './directives/hold-buton.directive';
import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateModule, TranslateLoader, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ShowSideBarDirective} from "./directives/show-side-bar.directive";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SelectButtonDirective } from './directives/select-button.directive';
import { WaiterCheckoutComponent } from './components/waiter-checkout/waiter-checkout.component';
import { ClientAutocompleteInputComponent } from './shared/components/client-autocomplete-input/client-autocomplete-input.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { FiscalModalComponent } from './components/fiscal-modal/fiscal-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import { WholesaleComponent } from './components/wholesale/wholesale.component';
import { ConomeAutocompleteComponent } from './shared/components/conome-autocomplete/conome-autocomplete.component';
import { SaleListComponent } from './components/sale-list/sale-list.component';
import { OffersListComponent } from './components/offers-list/offers-list.component';
import { ProinvoiceListComponent } from './components/proinvoice-list/proinvoice-list.component';
import { ReservedSalesListComponent } from './components/reserved-sales-list/reserved-sales-list.component';
import { CanceledSalesListComponent } from './components/canceled-sales-list/canceled-sales-list.component';
import { OfferComponent } from './components/offer/offer.component';
import { WholesaleEditComponent } from './wholesale-edit/wholesale-edit.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { DatePickerFormatDirective } from './directives/date-picker-format.directive';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { registerLocaleData } from '@angular/common';
import sq from '@angular/common/locales/sq';
import mk from '@angular/common/locales/mk';
import { OfferEditComponent } from './components/offer-edit/offer-edit.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { OrderstatusComponent } from './components/orderstatus/orderstatus.component';
import { AuthGuard } from './auth/auth.guard';
import { NgxDatatableModule } from '@swimlane/ngx-datatable'; 
import { DebtCardComponent } from './components/debt-card/debt-card.component';
import { MatTabsModule } from '@angular/material/tabs';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { DataTablesComponent } from './components/data-tables/data-tables.component';
import { DataTablesModule } from "angular-datatables";
import { DailyReportModalComponent } from './components/daily-report-modal/daily-report-modal.component';
import { VoidSalesListComponent } from './components/void-sales-list/void-sales-list.component';
import { ConfigsComponent } from './components/configs/configs.component';
import { LoyaltyCardAutocompleteInputComponent } from './shared/components/loyaltyCard-autocomplete-input/loyaltyCard-autocomplete-input.component';

registerLocaleData(sq, 'sq');
registerLocaleData(mk, 'mk');

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = {
    pinch: {enable: false},
    rotate: {enable: false},
    swipe: {direction: Hammer.DIRECTION_ALL}
  };
}

export function createTranslateLoader(http: HttpClient) {
      return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}



@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    WaiterModuleComponent,
    WaiterLoginComponent,
    OrderComponent,
    OrderReportsComponent,
    TableLayoutComponent,
    ReservationsComponent,
    PageNotFoundComponent,
    SaleDetailsModalComponent,
    HoldButonDirective,
    ShowSideBarDirective,  
    SelectButtonDirective,
    WaiterCheckoutComponent,
    ClientAutocompleteInputComponent,
    FiscalModalComponent,
    WholesaleComponent,
    ConomeAutocompleteComponent,
    SaleListComponent,
    OffersListComponent,
    ProinvoiceListComponent,
    ReservedSalesListComponent,
    CanceledSalesListComponent,
    OfferComponent,
    WholesaleEditComponent,
    DatePickerFormatDirective,
    OfferEditComponent,
    OrderstatusComponent,
    DebtCardComponent,
    DataTablesComponent,
    DailyReportModalComponent,
    VoidSalesListComponent,
    LoyaltyCardAutocompleteInputComponent,
    ConfigsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    DragDropModule,
    CdkTableModule,
    MatDialogModule,
    SweetAlert2Module,
    NgbModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatExpansionModule,
    HammerModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatTabsModule,
    MatCheckboxModule,
    NgxDatatableModule,
    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
        }
      }
    ),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DataTablesModule
  ],
  providers: [
    DataService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig
    },
    {
      provide : HTTP_INTERCEPTORS,
      useClass: AuthGuard, 
      multi   : true,
    },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
  
  constructor(private translate: TranslateService) {
    
    const lang = localStorage.getItem('language');
    if (lang) {
      this.translate.setDefaultLang(lang);
      this.translate.use(lang);
    } 
    else {
      const stored = localStorage.getItem('selectedLanguage');
      this.translate.use(stored || 'sq');
    }
  }
}

